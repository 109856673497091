import { CSSProperties, ReactNode, useEffect, useRef } from "react";
import CoursableIcons from "../../utils/CoursableIcons";
import { cn } from "../../utils/UtilityMethods";
import React from "react";
import useDidScroll, { IOnScroll } from "../../utils/Hooks/useDidScroll";

interface PopupModalProps {
  open?: boolean;
  children?: ReactNode;
  tapToClose?: boolean;
  Close?: () => void;
  showCloseButton?: boolean;
  onCompactProgressChange?: (progress: number) => void;
  className?: string;
}

export const PopupModal = ({ open, children, tapToClose, Close, showCloseButton = true, onCompactProgressChange, className }: PopupModalProps) => {
  const header = getHeader(children);
  const footer = getFooter(children);
  const content = getContent(children);
  const otherChildren = getOtherChildren(children);

  const [compactProgress, setCompactProgress] = React.useState(0);
  const [scrollProgress, setScrollProgress] = React.useState(0);
  const [contentOverflows, setContentOverflows] = React.useState(false);

  const headerInitHeight = useRef(0);

  function HandleScroll(scrollTop: number, scrollProgress: number) {
    if (headerInitHeight.current === 0) {
      setCompactProgress(0);
      setScrollProgress(0);
      return;
    }

    const progress = Math.min(headerInitHeight.current, scrollTop) / headerInitHeight.current;
    setCompactProgress(progress);
    onCompactProgressChange?.(progress);
    setScrollProgress(scrollProgress);
  }

  return (
    <div className={cn("max-h-screen overflow-auto fixed inset-0 flex-centered transition-colors z-50 p-4", open ? "visible bg-foreground/20 dark:bg-foreground/10" : "invisible")} onClick={tapToClose ? Close : undefined}>
      <div
        className={cn(
          "flex flex-col max-h-full overflow-auto bg-background rounded-2xl shadow-xl-c dark:shadow-systemGray-50 duration-200 ease-out-back border-4 border-systemGray-200 relative",
          open ? "scale-100 opacity-100" : "scale-90 opacity-0",
          className
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {header && React.cloneElement(header as React.ReactElement, { compactProgress, onHeightInit: (height: number) => (headerInitHeight.current = height), increaseRightPadding: Close && showCloseButton })}
        {content &&
          React.cloneElement(content as React.ReactElement, {
            onScroll: header ? ({ scrollTop, scrollProgress }: IOnScroll) => HandleScroll(scrollTop, scrollProgress) : undefined,
            onOverflowChange: (overflows: boolean) => setContentOverflows(overflows),
            hasHeader: !!header,
            hasFooter: !!footer,
          })}
        {footer && React.cloneElement(footer as React.ReactElement, { scrollProgress, contentOverflows })}
        {otherChildren}
        {Close && showCloseButton && (
          <button onClick={Close} className="absolute top-2 md:top-4 right-2 md:right-4 text-systemGray-400 hover:text-foreground active:text-systemGray-500 text-xl duration-100">
            {CoursableIcons.Xmark()}
          </button>
        )}
      </div>
    </div>
  );
};

const getHeader = (children: ReactNode): ReactNode | undefined => {
  return React.Children.toArray(children).filter((child) => {
    return React.isValidElement(child) && typeof child.type !== "string" && (child.type as any).displayName === "PopupModalHeader";
  })[0];
};
const getFooter = (children: ReactNode): ReactNode | undefined => {
  return React.Children.toArray(children).filter((child) => {
    return React.isValidElement(child) && typeof child.type !== "string" && (child.type as any).displayName === "PopupModalFooter";
  })[0];
};
const getContent = (children: ReactNode): ReactNode | undefined => {
  return React.Children.toArray(children).filter((child) => {
    return React.isValidElement(child) && typeof child.type !== "string" && (child.type as any).displayName === "PopupModalContent";
  })[0];
};
const getOtherChildren = (children: ReactNode): ReactNode[] => {
  return React.Children.toArray(children).filter((child) => {
    if (!React.isValidElement(child) || typeof child.type === "string") return true;

    if ((child.type as any).displayName === "PopupModalHeader" || (child.type as any).displayName === "PopupModalFooter" || (child.type as any).displayName === "PopupModalContent") return false;
    return true;
  });
};

interface PopupModalHeaderProps {
  children?: ReactNode;
  compactProgress?: number;
  variant?: "compact" | "dynamic" | "full";
  dynamicType?: "scale" | "font-size";
  dynamicScaleOrigin?: "left" | "center" | "right";
  onHeightInit?: (height: number) => void;
  className?: string;
  containerClassName?: string;
  increaseRightPadding?: boolean;
}
const PopupModalHeader = ({ children, compactProgress, variant = "dynamic", dynamicType = "font-size", dynamicScaleOrigin, onHeightInit, increaseRightPadding, className, containerClassName }: PopupModalHeaderProps) => {
  const progress = variant === "compact" ? 1 : variant === "dynamic" ? compactProgress ?? 0 : 0;
  const ref = useRef<HTMLDivElement>(null);

  const [isMD, setIsMD] = React.useState(false);

  useEffect(() => {
    const resizeListener = () => setIsMD(window.innerWidth < 768);
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  useEffect(() => {
    onHeightInit?.(ref.current?.clientHeight ?? 0);
  }, []);

  return (
    <div
      ref={ref}
      style={{
        paddingTop: lerp(isMD ? 1 : 1.5, isMD ? 0.5 : 0.75, progress) + "rem",
        paddingBottom: lerp(isMD ? 1 : 1.5, isMD ? 0.5 : 0.75, progress) + "rem",
        fontSize: dynamicType === "font-size" ? lerp(isMD ? 1.25 : 1.5, isMD ? 1 : 1.125, progress) + "rem" : undefined,
        lineHeight: dynamicType === "font-size" ? lerp(isMD ? 1.75 : 2, isMD ? 1.5 : 1.75, progress) + "rem" : undefined,
      }}
      className={cn("w-full font-semibold px-4 md:px-6 border-b border-transparent text-2xl", (progress > 0 || variant === "full") && "border-systemGray-200", increaseRightPadding && "pr-10 md:pr-12", containerClassName)}
    >
      <div
        className={cn(dynamicScaleOrigin === "left" ? "origin-left" : dynamicScaleOrigin === "center" ? "origin-center" : "origin-right", className)}
        style={{ transform: dynamicType === "scale" ? `scale(${lerp(1, 0.7, progress)})` : undefined }}
      >
        {children}
      </div>
    </div>
  );
};

const lerp = (min: number, max: number, t: number) => min * (1 - t) + max * t;

interface PopupModalContentProps {
  children?: ReactNode;
  onScroll?: (values: IOnScroll) => void;
  onOverflowChange?: (overflows: boolean) => void;
  className?: string;
  hasHeader?: boolean;
  hasFooter?: boolean;
}

const PopupModalContent = ({ children, onScroll, onOverflowChange, className, hasHeader, hasFooter }: PopupModalContentProps) => {
  const { scrollRef } = useDidScroll<HTMLDivElement>(onScroll);

  useEffect(() => {
    const resizeListener = () => {
      if (!scrollRef.current) return;
      const overflows = scrollRef.current?.scrollHeight > scrollRef.current?.clientHeight;
      onOverflowChange?.(overflows);
    };
    window.addEventListener("resize", resizeListener);

    resizeListener();
    return () => window.removeEventListener("resize", resizeListener);
  }, [scrollRef.current]);

  return (
    <div ref={scrollRef} className={cn("w-full overflow-auto p-4 md:p-6", hasHeader && "pt-0 md:pt-0", hasFooter && "pb-0 md:pb-0", className)}>
      {children}
    </div>
  );
};

const PopupModalFooter = ({ children, scrollProgress, contentOverflows, style, className }: { children?: ReactNode; scrollProgress?: number; contentOverflows?: boolean; style?: CSSProperties; className?: string }) => {
  return (
    <div style={style} className={cn("w-full p-4 md:p-6 border-t border-transparent", contentOverflows && scrollProgress !== undefined && scrollProgress <= 0.98 && "border-systemGray-200", className)}>
      {children}
    </div>
  );
};

PopupModalHeader.displayName = "PopupModalHeader";
PopupModalContent.displayName = "PopupModalContent";
PopupModalFooter.displayName = "PopupModalFooter";

export { PopupModalHeader, PopupModalContent, PopupModalFooter };
