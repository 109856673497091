import { Helmet } from "react-helmet-async";
import { AuthPageModalBackground } from "./AuthShared";
import CoursableLogo from "../../elements/CoursableLogo";
import LoadingIndicator from "../../elements/LoadingIndicator";
import { useEffect, useState } from "react";
import Checkbox from "../../elements/Checkbox";
import { SaveUserDetails } from "../../../backend/Website";
import { GASetUserProperties } from "../../../firebase/GoogleAnalytics";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthContext";
import { User } from "firebase/auth";
import { useNotifications } from "../../../utils/NotificationsContext";
import Button from "../../elements/Button";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const CompleteRegistrationPage = () => {
  const [saving, setSaving] = useState(false);

  const [affiliation, setAffiliation] = useState<Option | undefined>(undefined);

  const [level, setLevel] = useState<Option | undefined>(undefined);
  const [field, setField] = useState<Option | undefined>(undefined);

  const [source, setSource] = useState<Option | undefined>(undefined);

  const [emailOptIn, setEmailOptIn] = useState(true);

  const naviate = useNavigate();
  const { currentUser, setCurrentUser } = useAuth();
  const { sendInfo } = useNotifications();

  async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!affiliation || !source || !currentUser) return;

    setSaving(true);
    try {
      await SaveUserDetails(affiliation?.value, source?.value, emailOptIn, level?.value, field?.value);
      await GASetUserProperties({
        affiliation: affiliation.value,
        source: source.value,
        emailOptIn,
        educationLevel: level?.value ?? null,
        educationField: field?.value ?? null,
      });
      const newUser: User = Object.create(currentUser);
      newUser.didFinishRegistration = true;
      setCurrentUser(newUser);
      naviate("/app");
    } catch (error) {
      sendInfo("Could not save your details, please try again.", "If the error persists, please contact support.");
      console.error(error);
    }
    setSaving(false);
  }

  useEffect(() => {
    setLevel(undefined);
    setField(undefined);
  }, [affiliation]);

  useEffect(() => {
    setField(undefined);
  }, [level]);

  if (!currentUser) return <Navigate to="/login" />;
  if (currentUser.didFinishRegistration) return <Navigate to="/app" />;

  return (
    <AuthPageModalBackground>
      <Helmet>{pageMetatags({ title: "Complete Registration", canonical: "/complete-registration", noindex: true })}</Helmet>
      <CoursableLogo size={10} />
      <h1 className="font-semibold text-center text-xl">Email verified, just one last thing 👀</h1>
      <p className="text-sm text-center max-w-md text-systemGray-500 pb-4">Please answer these quick questions below to help us optimize the experience for you.</p>
      <form onSubmit={OnSubmit} className="flex flex-col gap-6 w-full">
        <Selection label="What best describes you?" required selectedOption={affiliation} selectOption={setAffiliation} options={affiliationOptions} />
        {(affiliation?.value === "student" || affiliation?.value === "educator") && (
          <>
            <Selection label={`What is your ${affiliation.value === "student" ? "education" : "practice"} level?`} selectedOption={level} selectOption={setLevel} options={levelOptions} />
            {(level?.value === "undergraduate" || level?.value === "graduate" || level?.value === "doctorate") && <Selection label="What is your field?" selectedOption={field} selectOption={setField} options={fieldOptions} />}
          </>
        )}
        <Selection label="How did you find Coursable?" required selectedOption={source} selectOption={setSource} options={sourceOptions} />
        <Checkbox variant="bare" size="sm" label="Receive email product updates and news." checked={emailOptIn} setChecked={setEmailOptIn} />
        <Button loading={saving} disabled={saving || affiliation === undefined || source === undefined} type="submit" className="mt-4">
          Complete Registration
        </Button>
      </form>
    </AuthPageModalBackground>
  );
};

export default CompleteRegistrationPage;

const Selection = ({ label, options, selectedOption, selectOption, required }: { label: string; options: Option[]; selectedOption: undefined | Option; selectOption: (o: Option | undefined) => void; required?: boolean }) => {
  return (
    <label>
      <span className="text-sm">
        {label}
        {required && " *"}
      </span>
      <select
        required={required}
        defaultValue={""}
        onChange={(e) => selectOption(options.find((o) => o.value === e.currentTarget.value))}
        className={`${selectedOption !== undefined ? "text-foreground" : "text-systemGray-500"} w-full px-3 py-2 rounded-md bg-background border focus:border-brand-500 hover:shadow duration-100 mt-1`}
      >
        <option value="" disabled>
          Select an option
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value} className="text-foreground">
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

interface Option {
  label: string;
  value: string;
}

const affiliationOptions: Option[] = [
  { label: "Student", value: "student" },
  { label: "Educator (teacher, professor, TA, etc)", value: "educator" },
  { label: "Professional", value: "professional" },
  { label: "Other", value: "other" },
];

const levelOptions: Option[] = [
  { label: "Secondary (middle or high school)", value: "secondary" },
  { label: "Undergraduate", value: "undergraduate" },
  { label: "Graduate", value: "graduate" },
  { label: "Doctorate", value: "doctorate" },
  { label: "Other", value: "other" },
];

const fieldOptions: Option[] = [
  { label: "Arts and Humanities", value: "arts-humanities" },
  { label: "Social Sciences", value: "social-sciences" },
  { label: "Natural Sciences", value: "natural-sciences" },
  { label: "Engineering and Technology", value: "engineering-technology" },
  { label: "Business and Economics", value: "business-economics" },
  { label: "Health Sciences", value: "health-sciences" },
  { label: "Mathematics and Statistics", value: "mathematics-statistics" },
  { label: "Education", value: "education" },
  { label: "Law and Legal Studies", value: "law-legal-studies" },
  { label: "Agriculture and Forestry", value: "agriculture-forestry" },
  { label: "Environmental Studies and Earth Sciences", value: "environmental-studies-earth-sciences" },
  { label: "Communication and Media Studies", value: "communication-media-studies" },
  { label: "Other", value: "other" },
];

const sourceOptions: Option[] = [
  { label: "Online search", value: "web" },
  { label: "Tools repository (AI, Education, other)", value: "tools-repository" },
  { label: "Social media", value: "social-media" },
  { label: "Recommended by someone", value: "recommendation" },
  { label: "Online article", value: "article" },
  { label: "Other", value: "other" },
];
