import { ReactNode, useEffect, useRef, useState } from "react";
import Card from "../../elements/Card";
import { PopupModal, PopupModalContent, PopupModalHeader } from "../../elements/PopupModal";
import useShareLinkModal, { ShareModalConfig } from "../../elements/ShareLinkModal";
import Button from "../../elements/Button";
import CoursableIcons from "../../../utils/CoursableIcons";
import InputField from "../../elements/InputField";
import useTooltipNotification from "../../elements/TooltipNotification";
import { FetchReferralDetails, FetchUserBalance, ReferralDetails } from "../../../backend/Referrals";
import { DisplayValue } from "./ProfileCard";
import Badge from "../../elements/Badge";
import { cn } from "../../../utils/UtilityMethods";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import { useAuth } from "../../../firebase/AuthContext";

const ReferralsCard = ({ className }: { className?: string }) => {
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);

  const [referralDetails, setReferral] = useState<ReferralDetails | undefined>(undefined);
  const [balance, setBalance] = useState<number>(0);
  const didFetch = useRef<boolean>(false);

  useEffect(() => {
    if (didFetch.current) return;

    didFetch.current = true;
    FetchReferralDetails().then(setReferral).catch(console.error);
    FetchUserBalance().then(setBalance).catch(console.error);
  }, []);

  return (
    <Card className={className} title="Referrals" description="Invite friends and get Plus for free">
      {referralDetails && referralDetails.totalSignedUpUsers > 0 && <DisplayValue label="Friends joined" value={<Badge variant="blue">{referralDetails.totalSignedUpUsers}</Badge>} />}
      {referralDetails && referralDetails.totalSubscribedUsers > 0 && <DisplayValue label="Rewards earned" value={<Badge variant="green">${(referralDetails?.totalSubscribedUsers ?? 0) * CoursablePlan.referralCredit}</Badge>} />}
      {currentUser && balance > 0 && <DisplayValue label="Remaining credit" value={<Badge variant="inverse">${balance}</Badge>} />}
      <Button onClick={() => setOpen(true)}>Invite a friend</Button>
      <ReferralPopupModal open={open} Close={() => setOpen(false)} referralDetails={referralDetails} />
    </Card>
  );
};
export default ReferralsCard;

const ReferralPopupModal = ({ open, referralDetails, Close }: { open: boolean; referralDetails: ReferralDetails | undefined; Close: () => void }) => {
  const referralLink = referralDetails?.id === undefined ? undefined : `${process.env.REACT_APP_CLIENT_URL}/?referralID=${referralDetails?.id}`;

  const shareModalConfig = (link?: string): ShareModalConfig => {
    return {
      dropdownLabel: <>Share link {CoursableIcons.PersonsFill()}</>,
      dropdownVariant: "default",
      dropdownSize: "lg",
      dropdownClassName: "w-full",
      showLink: false,
      title: "Share your invite link",
      socialMediaShareText: `Sign up for Coursable using my link to get free credit towards Plus!\n\n${link}`,
      link: referralLink,
      onOpen: CopyLink,
    };
  };

  function CopyLink() {
    if (!referralLink) return;

    navigator.clipboard.writeText(referralLink);
    ShowTooltipNotification(<>Copied {CoursableIcons.Copy()}</>);
  }

  const { ShareDropdown, SharePopup } = useShareLinkModal(shareModalConfig(referralLink));
  const { ShowTooltipNotification, TooltipNotificationEmitter } = useTooltipNotification({ emmiterClassName: "-inset-10" });

  return (
    <PopupModal className="w-full max-w-3xl" open={open} Close={Close} showCloseButton tapToClose>
      <PopupModalHeader dynamicType="font-size">
        <div className="w-full flex-centered flex-col">
          <div className="text-brand-500 flex-centered gap-1 text-sm md:text-base">{CoursableIcons.Gift("-mt-0.5")} Referrals</div>
          <div className="font-bold text-center">
            Invite friends and get free <span className="ml-1 text-brand-500">Plus</span>!
          </div>
        </div>
      </PopupModalHeader>
      <PopupModalContent>
        <div className="w-full flex items-center justify-start flex-col mt-4 gap-8 md:gap-12 text-center">
          <div className="w-full flex-centered flex-col gap-8 border-y py-12">
            <div className="text-lg font-semibold text-center">Share your referral link with friends</div>
            <div className="w-full max-w-lg flex-centered flex-col md:flex-row gap-4">
              {referralLink ? (
                <>
                  <InputField readOnly onClick={CopyLink} variant="ghost" size="lg" className="w-full text-sm" value={referralLink} />
                  <div className="relative">
                    {ShareDropdown}
                    {TooltipNotificationEmitter}
                  </div>
                </>
              ) : (
                <Button className="w-full animate-pulse" size="lg" variant="outline">
                  Loading
                </Button>
              )}
            </div>
          </div>
          <div className="w-full max-w-2xl flex-centered flex-col gap-8 relative">
            <div className="w-full text-center text-sm md:text-base mb-4">
              Earn ${CoursablePlan.referralCredit}* credit towards Coursable <span className="text-brand-500 font-semibold">{CoursablePlan.Plus.title}</span> for each referred friend, up to $
              {CoursablePlan.referralCredit * CoursablePlan.maxReferrals} total.
              <br />
              <span className="text-mini md:text-sm text-systemGray-400">
                * Equal to {Math.round(CoursablePlan.referralCredit / CoursablePlan.Plus.monthlyPrice)}-{Math.round(CoursablePlan.referralCredit / (CoursablePlan.Plus.annualMonthlyPrice * CoursablePlan.studentDiscount))} months for free,
                depending on your plan.
              </span>
            </div>
            <div className="w-full px-4 grid grid-cols-3 md:flex-centered md:flex-row gap-6">
              <Step label="Share a link" icon={CoursableIcons.Send()} />
              <Divider />
              <Step label="They sign up" icon={CoursableIcons.PersonFill()} />
              <Divider className="hidden md:flex" />
              <Step label="They upgrade to Plus for free" icon={CoursableIcons.GraduationCapFill()} />
              <Divider />
              <Step label={`You get Plus for free`} icon={CoursableIcons.GiftFill()} />
            </div>
          </div>
          <div className="w-full flex-centered flex-col gap-8 border-t py-8 md:py-12">
            <div className="w-full text-lg font-semibold text-center">Your rewards</div>
            <div className="w-full flex-centered gap-4">
              <div className="flex-centered flex-col gap-2">
                <div className="flex-centered rounded-full aspect-square bg-brand-50 text-brand-500 text-2xl font-bold p-4">{referralDetails?.totalSignedUpUsers}</div>
                <div className="text-sm md:text-base">Friends joined</div>
              </div>
              <div className="w-0.5 h-full bg-systemGray-300" />
              <div className="flex-centered flex-col gap-2">
                <div className="flex-centered rounded-full aspect-square bg-brand-50 text-brand-500 text-2xl font-bold p-4">${(referralDetails?.totalSubscribedUsers ?? 0) * CoursablePlan.referralCredit}</div>
                <div className="text-sm md:text-base">Rewards earned</div>
              </div>
            </div>
          </div>
        </div>
        {SharePopup}
      </PopupModalContent>
    </PopupModal>
  );
};

const Step = ({ label, icon }: { label: string; icon: ReactNode }) => {
  return (
    <div className="w-full max-w-full flex flex-col gap-4 relative mb-12 group">
      <div className="aspect-square rounded-full bg-brand-50 text-brand-500 text-4xl p-4 flex-centered group-hover:scale-105 group-hover:shadow-lg group-hover:shadow-brand-50 duration-300">{icon}</div>
      <div className="w-[150%] max-w-[150%] text-center absolute top-[calc(100%_+_1rem)] left-1/2 -translate-x-1/2 text-systemGray-500 group-hover:text-foreground duration-300 text-sm md:text-base">{label}</div>
    </div>
  );
};

const Divider = ({ className }: { className?: string }) => {
  const count = 5;
  return (
    <div className={cn("flex items-center justify-center text-xl text-brand-500 -translate-y-6 scale-80 md:scale-100", className)}>
      {Array.from({ length: count }).map((_, i) => (
        <span
          className="-mx-1.5"
          key={i}
          style={{
            opacity: (i + 1) / count,
          }}
        >
          {CoursableIcons.Chevron("right")}
        </span>
      ))}
    </div>
  );
};
