import { ProjectNote } from "../../../../../../backend/Projects/types";
import { Page, Text, Document, StyleSheet, View } from "@react-pdf/renderer";
import { generateJSON } from "@tiptap/core";
import { Style } from "@react-pdf/types";
import { JSONContent } from "@tiptap/react";
import { EditorExtensions } from "../NoteEditor";

const PDFFromNote = ({ note }: { note: ProjectNote }) => {
  const json = generateJSON(note.content, EditorExtensions) as JSONContent;

  return (
    <Document title={note.name + ".pdf"} author="Coursable.io" creator="Coursable.io">
      <Page style={styles.document} size="LETTER">
        {json.content?.map((content, index) => (
          <RenderText key={index} content={content} />
        ))}
      </Page>
      {/* <Page style={styles.document} size="LETTER">
        {json.content?.map((content, index) => (
          <Text style={{ fontSize: 12 }} key={index}>
            {textForContent(content)}
          </Text>
        ))}
      </Page> */}
    </Document>
  );
};

export default PDFFromNote;

const RenderText = ({ content, prefix }: { content: JSONContent; prefix?: string }) => {
  return content.type === "text" || content.type === "paragraph" ? (
    <Text style={getStyles(content)}>
      {content.text ?? null}
      {content.content?.map((child, index) => (
        <RenderText key={index} content={child} />
      ))}
    </Text>
  ) : (
    <View style={getStyles(content)}>
      {prefix ? <Text style={{ display: "none", transform: "translateX(-16pt)" }}>{prefix}</Text> : null}
      {content.text ? (
        <Text>
          {prefix ? prefix : null}
          {content.text}
        </Text>
      ) : null}
      {content.content?.map((child, index) => (
        <RenderText key={index} content={child} prefix={content.type === "orderedList" ? `${index + 1}.  ` : content.type === "bulletList" ? "•  " : undefined} />
      ))}
    </View>
  );
};

const textForContent = (content: JSONContent, depth: number = 0): string => {
  const spacing = `\n${Array.from({ length: depth * 4 }, () => "\u00A0").join("")}`;
  const subSpacing = `\n${Array.from({ length: depth * 8 }, () => "\u00A0").join("")}`;
  const styles = JSON.stringify(getStyles(content));
  return `${spacing}<div type={${content.type}}${styles === `{}` ? "" : ` styles=${styles}`}>${content.text ? subSpacing + content.text : ""}${
    content.content && content.content.length > 0 ? content.content.map((child) => textForContent(child, depth + 1)).join("") : ""
  }${spacing}</div>`;
};

const getStyles = (content: JSONContent): Style => {
  const marks = content.marks?.flatMap((mark) => [attributesToStyle(mark.attrs), markTypeToStyles(mark.type)]) ?? [];
  const style: Style = {
    ...typeToStyles(content.type),
    ...attributesToStyle(content.attrs),
    ...marks.reduce((acc: any, mark: any) => {
      Object.keys(mark).forEach((key) => {
        if (acc[key] && key !== "fontFamily") {
          acc[key] = `${acc[key]} ${mark[key]}`;
        } else {
          acc[key] = mark[key];
        }
      });
      return acc;
    }, {}),
  };
  return style;
};

const attributesToStyle = (attrs: JSONContent["attrs"]): Style => {
  // Lineheight is larger by 0.5 that the selected value by the user to make it look appropriate.

  const lineHeight = attrs?.lineHeight ? parseInt((attrs?.lineHeight as string).replace("%", "")) / 100 : 1.5;

  return {
    textAlign: attrs?.textAlign,
    lineHeight: lineHeight,
    fontSize: attrs?.fontSize,
  };
};

const markTypeToStyles = (type: string): Style => {
  if (type === "bold") {
    return { fontFamily: "Times-Bold" };
  } else if (type === "italic") {
    return { fontFamily: "Times-Italic" };
  } else if (type === "underline") {
    return { textDecoration: "underline" };
  } else if (type === "strike") {
    return { textDecoration: "line-through" };
  } else if (type === "code") {
    return {
      fontFamily: "Courier",
      backgroundColor: "#FBE9E9",
      color: "#D12424",
    };
  } else if (type === "highlight") {
    return {
      backgroundColor: "#FFFF00",
    };
  }

  return {};
};

const typeToStyles = (type?: string): Style => {
  if (type === "paragraph") {
    return {
      marginBottom: 12,
    };
  } else if (type === "orderedList" || type === "bulletList") {
    return {
      marginLeft: 32,
    };
  } else if (type === "listItem") {
    return {
      marginTop: -4,
    };
  } else if (type === "codeBlock") {
    return {
      backgroundColor: "#031C2B",
      padding: 12,
      fontFamily: "Courier",
      color: "#FFFFFF",
      borderRadius: 6,
    };
  }

  return {};
};

const styles = StyleSheet.create({
  document: {
    padding: "0.75in",
    fontSize: 12,
    fontFamily: "Times-Roman",
  },
});
