import { useEffect, useRef } from "react";

const useCancellablePromise = (cancelOnUnmount: boolean = true) => {
  const isCanceled = useRef<boolean>(false);

  function cancellablePromise<T>(promise: Promise<T>) {
    return new Promise<T>((resolve, reject) => {
      promise
        .then((val) => (isCanceled.current ? reject({ message: "This promise was canceled manually", isCanceled: isCanceled.current }) : resolve(val)))
        .catch((error) => (isCanceled.current ? reject({ message: "This promise was canceled manually", isCanceled: isCanceled.current }) : reject(error)));
    });
  }

  function CancelPromises() {
    isCanceled.current = true;
  }

  useEffect(() => {
    if (!cancelOnUnmount) return;

    isCanceled.current = false;
    return CancelPromises;
  }, []);

  return {
    cancellablePromise,
    CancelPromises,
  };
};

export default useCancellablePromise;
