import { useEffect, useRef, useState } from "react";
import { PopupModal, PopupModalContent, PopupModalHeader } from "../../elements/PopupModal";
import { TbChecklist } from "react-icons/tb";
import { IoCheckmark as RightIcon, IoClose as WrongIcon, IoCloseCircle as CloseIcon } from "react-icons/io5";
import Button from "../../elements/Button";

const QuizModal = ({ quiz, isOpen, Close }: { quiz: Course.Quiz; isOpen: boolean; Close: () => void }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [check, setCheck] = useState(true);

  const [completedQuestions, setCompletedQuestions] = useState<boolean[]>(quiz.multipleChoice.map(() => false));

  function Reset() {
    setCompletedQuestions(quiz.multipleChoice.map(() => false));
    setCheck(false);
    scrollRef.current?.scrollTo(0, 0);
  }

  useEffect(() => {
    Reset();
  }, [quiz, isOpen]);

  return (
    <PopupModal className="w-full max-w-3xl" open={isOpen} Close={Close}>
      <PopupModalHeader className="w-full flex flex-col gap-2 items-center">
        <TbChecklist className="text-4xl text-brand-500 shrink-0" />
        <div className="text-xl font-semibold">{quiz.title}</div>
        <div className="w-full flex items-end justify-between">
          <div className="text-systemGray-500 text-sm font-normal">
            Completed {completedQuestions.filter((c) => c).length}/{quiz.multipleChoice.length}
          </div>
          <Button onClick={() => (check ? Reset() : setCheck(true))} disabled={!completedQuestions.every((isCompleted) => isCompleted)} size="sm">
            {check ? "Retry" : "Check"}
          </Button>
        </div>
      </PopupModalHeader>
      <PopupModalContent className="">
        <div ref={scrollRef} className="w-full flex flex-col gap-10">
          {quiz.multipleChoice.map((question, index) => {
            return (
              <MultipleChoiceQuestion
                question={question}
                check={check}
                index={index}
                key={index}
                MarkCompleted={() =>
                  setCompletedQuestions((prev) => {
                    const newCompletedQuestions = [...prev];
                    newCompletedQuestions[index] = true;
                    return newCompletedQuestions;
                  })
                }
              />
            );
          })}
        </div>
      </PopupModalContent>
    </PopupModal>
  );
};

export default QuizModal;

const MultipleChoiceQuestion = ({ question, check, index, MarkCompleted }: { question: Course.Quiz.MultipleChoiceQuestion; check: boolean; index: number; MarkCompleted: () => void }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (selectedIndex !== null) MarkCompleted();
  }, [selectedIndex]);

  useEffect(() => {
    if (!check) setSelectedIndex(null);
  }, [check]);

  return (
    <div className="flex flex-col items-start gap-2 w-full px-6">
      <div className="text-left font-semibold flex justify-between items-start w-full">
        <div className="text-sm md:text-base">
          {index + 1}. {question.question}
        </div>
        {check && (selectedIndex === question.correctChoice ? <RightIcon className="ml-auto text-brandGreen-500 text-2xl shrink-0" /> : <WrongIcon className="ml-auto text-red-500 text-2xl shrink-0" />)}
      </div>
      <div className={`flex flex-col items-start w-full`}>
        {question.choices.map((choice, index) => {
          return <MultipleChoiceOption option={choice} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} correctIndex={question.correctChoice} revealAnswer={check} index={index} key={index} />;
        })}
      </div>
    </div>
  );
};

interface MultipleChoiceOptionProps {
  option: string;
  selectedIndex: number | null;
  setSelectedIndex: (value: number) => void;
  correctIndex: number;
  revealAnswer: boolean;
  index: number;
}
const MultipleChoiceOption = ({ option, selectedIndex, setSelectedIndex, correctIndex, revealAnswer, index }: MultipleChoiceOptionProps) => {
  const letter = ["a", "b", "c", "d", "e", "f", "g", "h", "i"][index];
  const isSelected = selectedIndex === index;

  let bgClasses = "border-transparent hover:bg-systemGray-100";
  if (isSelected) {
    bgClasses = "bg-systemGray-200";
    if (revealAnswer) bgClasses = correctIndex === index ? "bg-brandGreen-50 dark:bg-brandGreen-100 border-brandGreen-500" : "bg-red-100 dark:bg-red-950 border-red-500";
  }

  return (
    <button disabled={revealAnswer} onClick={() => setSelectedIndex(index)} className={`px-4 text-left w-full rounded-md py-1 flex items-center border ${bgClasses} duration-100`}>
      <div className="text-mini md:text-sm text-systemGray-500 mr-2">{letter}.</div>
      <div className="text-sm md:text-base">{option}</div>
    </button>
  );
};
