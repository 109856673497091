import { doc, getDoc, setDoc, getDocs, collection, deleteDoc } from "firebase/firestore";
import { auth, db } from "./FirebaseConfig";
import { EmailAuthProvider, GoogleAuthProvider, deleteUser, reauthenticateWithCredential, reauthenticateWithRedirect, sendPasswordResetEmail } from "firebase/auth";

export async function WriteCourseToDatabase(course: Course) {
  const user = auth.currentUser;
  if (!user) return;

  const courseRef = doc(db, "users", user.uid, "courses", course.id);
  await setDoc(courseRef, course).catch((error) => {
    console.error("Error writing course to databse: ", error);
  });
}

export async function DeleteCourseFromDatabase(course: Course) {
  const user = auth.currentUser;
  if (!user) return;

  const courseRef = doc(db, "users", user.uid, "courses", course.id);
  await deleteDoc(courseRef).catch((error) => {
    console.error("Error deleting course from database: ", error);
  });
}

export async function GetCoursesFromDatabase(): Promise<Course[]> {
  const user = auth.currentUser;
  if (!user) return [];

  const querySnapshot = await getDocs(collection(db, "users", user.uid, "courses"));
  let allCourses: Course[] = [];
  querySnapshot.forEach((doc) => {
    allCourses.push(doc.data() as Course);
  });
  allCourses.sort((a, b) => new Date(b.dateGenerated).getTime() - new Date(a.dateGenerated).getTime());
  return allCourses;
}

export async function GetCourseFromDatabase(courseID: string): Promise<Course | null> {
  const user = auth.currentUser;
  if (!user) return null;

  const docRef = await getDoc(doc(db, "users", user.uid, "courses", courseID));
  return docRef.data() as Course;
}

export async function GetStripeCustomerID(): Promise<string | null> {
  const user = auth.currentUser;
  if (!user) return null;

  const userDoc = await getDoc(doc(db, "users", user.uid));
  return userDoc.get("stripeCustomerID") ?? null;
}

export async function ReauthenticateUserWithPassword(password: string) {
  const user = auth.currentUser;
  if (!user) throw new Error("User is not logged in.");
  if (!user.email) throw new Error("User is not logged in with email.");

  await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email, password));
}

export async function ReauthenticateUserWithGoogle() {
  const user = auth.currentUser;
  if (!user) throw new Error("User is not logged in.");
  if (user.providerData[0].providerId !== "google.com") throw new Error("User is not logged in with Google.");

  const provider = new GoogleAuthProvider();
  await reauthenticateWithRedirect(user, provider);
}

export async function SendPasswordResetEmail(email: string) {
  await sendPasswordResetEmail(auth, email, {
    url: `${process.env.REACT_APP_CLIENT_URL}/app/courses`,
  });
}

export async function DeleteUser() {
  const user = auth.currentUser;
  if (!user) return;

  await deleteUser(user);
  await deleteDoc(doc(db, "users", user.uid));

  window.location.href = "/";
}

export async function GetEnterpriseAPIKeys(): Promise<(string | null)[]> {
  const user = auth.currentUser;
  if (!user) return [];

  const enterpriseDoc = await getDoc(doc(db, "enterpriseUsers", user.uid));
  const dev = enterpriseDoc.get("devAPIKey") ?? null;
  const prod = enterpriseDoc.get("prodAPIKey") ?? null;

  return [dev, prod];
}
