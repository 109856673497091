import { TextareaHTMLAttributes, useState } from "react";
import FillCircle from "./FillCircle";
import { VariantProps } from "class-variance-authority";
import { cn } from "../../utils/UtilityMethods";
import { inputVariants } from "./InputField";
import clsx from "clsx";

interface Props extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "size" | "value">, Omit<VariantProps<typeof inputVariants>, "size">, InputBoxProps {}

interface InputBoxProps {
  value?: string;
  size?: "sm" | "default" | null;
  onValueChange?: (v: string) => void;
  characterLimit?: number;
  containerClassName?: string;
}

const InputBox = ({ value, onChange, onValueChange, size, variant, className, containerClassName, characterLimit, ...props }: Props) => {
  const [animateError, setAnimateError] = useState<boolean>(false);

  function HandleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    onChange?.(e);
    if (characterLimit && e.target.value.length > characterLimit) {
      onValueChange?.(e.target.value.slice(0, characterLimit));
      return setAnimateError(true);
    }
    onValueChange?.(e.target.value);
  }

  return (
    <div className={`relative ${containerClassName}`}>
      <textarea
        {...props}
        className={cn("max-w-full transition-none h-auto outline-none placeholder:text-systemGray-500", inputVariants({ className, variant, size }))}
        style={{
          transition: "border-color 150ms, box-shadow 150ms",
        }}
        value={value}
        onChange={HandleChange}
      />
      {characterLimit !== undefined && value !== undefined && value.length > characterLimit * 0.8 && (
        <div
          className={clsx(`absolute top-full right-0 flex gap-1 items-center justify-end text-mini`, characterLimit === value.length ? "text-red-700" : "text-systemGray-500", animateError && "animate-error")}
          onAnimationEnd={() => setAnimateError(false)}
        >
          {value.length}/{characterLimit}
          <FillCircle size={12} fill={(100 * value.length) / characterLimit} fillColor={`${characterLimit === value.length ? "stroke-red-700" : "stroke-systemGray-500"}`} />
        </div>
      )}
    </div>
  );
};

export default InputBox;
