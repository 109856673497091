import { useEffect, useState } from "react";
import CoursableIcons from "../../../utils/CoursableIcons";
import Card from "../../elements/Card";
import { EmptyDashboardCard } from "./DashboardPage";
import Button from "../../elements/Button";
import date from "date-and-time";
import { GetCoursesFromDatabase } from "../../../firebase/FirestoreManager";
import FillCircle from "../../elements/FillCircle";
import { courseCompletedPrecentage } from "../Courses/DashboardSidemenu";
import { useNavigate } from "react-router-dom";
import { DashboardPlaceholderItem } from "./ProjectsCard";
import { cn } from "../../../utils/UtilityMethods";

const maxItems = 4;

const CoursesCard = ({ className }: { className?: string }) => {
  const [loading, setLoading] = useState(true);

  const [courses, setCourses] = useState<Course[]>([]);

  async function FetchProjects() {
    setLoading(true);
    const courses = await GetCoursesFromDatabase();
    setCourses(courses);
    setLoading(false);
  }

  useEffect(() => {
    FetchProjects();
  }, []);

  return (
    <Card title="Courses" description="Personalized AI generated courses" icon={CoursableIcons.GraduationCap()} className={className}>
      <div
        style={{
          height: `${3.5 * (maxItems + 1)}rem`,
        }}
        className="w-full flex flex-col"
      >
        {loading ? (
          <>
            <DashboardPlaceholderItem />
            <DashboardPlaceholderItem />
            <DashboardPlaceholderItem />
          </>
        ) : courses.length === 0 ? (
          <EmptyDashboardCard icon={CoursableIcons.GraduationCapFill()} text="You don't have any courses yet" cta="New course" to="/app/courses/generate" />
        ) : (
          courses.slice(0, maxItems).map((course, index) => <CourseItem key={index} course={course} />)
        )}
        <div className="w-full flex items-center justify-center gap-4 mt-auto">
          <Button to="/app/courses" className={cn("w-full", !loading && courses.length === 0 && "hidden")} size="sm" variant="outline">
            View all
          </Button>
          <Button to="/app/courses/generate" className={cn("w-full mt-auto", !loading && courses.length === 0 && "hidden")} size="sm" variant="default">
            New course
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default CoursesCard;

const CourseItem = ({ course }: { course: Course }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(`/app/courses`, { state: { preselectedCourseID: course.id } });
      }}
      variant="ghost"
      className="font-normal px-2 -mx-2 h-[3.5rem]"
    >
      <div className="w-full flex-started flex-col truncate">
        <div className="text-systemGray-400 text-xs shrink-0">{date.format(new Date(course.dateGenerated), "MMMM D, YYYY")}</div>
        <div className="max-w-full truncate">{course.title}</div>
      </div>
      <div className="">
        <FillCircle fill={courseCompletedPrecentage(course) * 100} size={20} strokePercentage={0.1} bgColor="stroke-systemGray-300" />
      </div>
    </Button>
  );
};
