import { ReactNode } from "react";
import { cn } from "../../utils/UtilityMethods";

const Card = ({ title, icon, description, children, className, headerClassName }: { title?: string; icon?: ReactNode; description?: string; children?: ReactNode; className?: string; headerClassName?: string }) => {
  const showHeader = title !== undefined || description !== undefined;
  return (
    <div className={cn("bg-background border border-systemGray-300 p-6 rounded-xl flex flex-col gap-4 shadow hover:shadow-lg duration-100", className)}>
      {showHeader && (
        <div className={cn("w-full flex items-baseline justify-between gap-2", headerClassName)}>
          <div className="w-full flex flex-col">
            {title && <div className="font-semibold text-foreground">{title}</div>}
            {description && <div className="text-systemGray-500 text-sm">{description}</div>}
          </div>
          {icon && <div className="text-systemGray-500 shrink-0 text-base">{icon}</div>}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
