import { useState } from "react";
import CoursableIcons from "../../../../utils/CoursableIcons";
import LoadingIndicator from "../../../elements/LoadingIndicator";
import dateFormat from "dateformat";
import Button from "../../../elements/Button";
import { ProjectDerivativeType, ProjectFlashcardSet, ProjectQuiz, ProjectSummary } from "../../../../backend/Projects/types";
import { PublishProjectDerivative, UnpublishProjectDerivative } from "../../../../backend/Projects/PublicProjects";
import { cn } from "../../../../utils/UtilityMethods";
import useTooltipNotification from "../../../elements/TooltipNotification";
import useShareLinkModal from "../../../elements/ShareLinkModal";

interface ShareModalProps {
  type: ProjectDerivativeType;
  derivative?: ProjectSummary | ProjectFlashcardSet | ProjectQuiz;
  projectID?: string;
  setPublicID?: (id: string | undefined) => void;
}

export const ShareProjectDerivative = ({ type, derivative, projectID, setPublicID }: ShareModalProps) => {
  const shared = !!derivative?.publicID;
  const getLink = (publicID: string) => `${process.env.REACT_APP_CLIENT_URL}/public/${type}/${publicID}`;

  const [loading, setLoading] = useState(false);

  const { ShowTooltipNotification, TooltipNotificationEmitter } = useTooltipNotification();
  const { ShareDropdown, SharePopup } = useShareLinkModal({
    title: `Share ${type}`,
    link: derivative?.publicID ? getLink(derivative.publicID) : undefined,
    socialMediaShareText: derivative?.publicID ? `Check out ${type === "flashcards" ? "these flashcards" : `this ${type}`} on Coursable: ${derivative.name}\n\n${getLink(derivative.publicID)}` : undefined,
    description: `Anyone with this link ${shared ? "can" : "will be able to"} view this summary.`,
    sharingOptionsContainerClassName: cn("duration-300", shared ? "h-[12.375rem] opacity-100" : "h-0 opacity-0 pointer-events-none pb-0 -mb-4 overflow-hidden"),
    showLink: shared,
    linkAreaChildren: TooltipNotificationEmitter,
    dropdownClassName: "@md:w-auto h-9 w-9",
    dropdownAutoClose: false,
    dropdownLabel: (
      <>
        <span className="hidden @md:inline">Share</span>
        {CoursableIcons.Share()}
      </>
    ),
    footer: (
      <Button
        onClick={() => {
          if (shared) StopSharing();
          else StartSharing();
        }}
        className={`relative w-full ${shared ? "mt-4" : ""}`}
      >
        {loading ? <LoadingIndicator className="w-4 h-4" /> : shared ? CoursableIcons.PersonsFillSlash() : CoursableIcons.PersonsFill()} {shared ? "Stop sharing" : "Create public link"}
      </Button>
    ),
  });

  async function StartSharing() {
    if (!setPublicID || !projectID || !derivative) return;

    setLoading(true);
    try {
      const publicID = await PublishProjectDerivative(type, projectID, derivative.id);
      setPublicID(publicID);

      setTimeout(() => {
        navigator.clipboard.writeText(getLink(publicID));
        ShowTooltipNotification(<>Link copied {CoursableIcons.Copy()}</>, { dir: "down" });
      }, 300);
    } catch (error) {}

    setLoading(false);
  }

  async function StopSharing() {
    if (!setPublicID || !projectID || !derivative || !derivative.publicID) return;

    setLoading(true);
    try {
      await UnpublishProjectDerivative(type, projectID, derivative.id);
      setPublicID(undefined);
    } catch (error) {}

    setLoading(false);
  }

  return { ShareDropdown, SharePopup };
};

export const DoneButton = ({ onClick, saving }: { onClick: () => void; saving: boolean }) => {
  return (
    <Button onClick={onClick}>
      {saving ? (
        <>
          <LoadingIndicator className="p-0.5 w-4 h-4" /> Saving
        </>
      ) : (
        <>{CoursableIcons.Check()} Done</>
      )}
    </Button>
  );
};

export const useWorkspaceStates = () => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const [renaming, setRenaming] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  return {
    deleting,
    setDeleting,
    renaming,
    setRenaming,
    editing,
    setEditing,
    saving,
    setSaving,
  };
};

export const formattedDateGenerated = (date: Date): string => {
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);

  if (date.getDate() === now.getDate()) {
    return dateFormat(date, `"Today at" HH:MM`);
  } else if (date.getDate() === yesterday.getDate()) {
    return dateFormat(date, `"Yesterday at" HH:MM`);
  } else if (date.getFullYear() === now.getFullYear()) {
    return dateFormat(date, `mmmm d "at" HH:MM`);
  } else {
    return dateFormat(date, "longDate");
  }
};
