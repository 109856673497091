import { Helmet } from "react-helmet-async";
import AppHeader from "./AppHeader";

const AppPageTemplate = ({ helmet, headerChildren, children }: { helmet: React.ReactNode; footerCTAClassNames?: string; headerChildren?: React.ReactNode; children: React.ReactNode }) => {
  return (
    <div className="w-screen h-screen flex flex-col">
      <Helmet>{helmet || ""}</Helmet>
      <AppHeader children={headerChildren} />
      <main className="w-full h-full overflow-auto">{children}</main>
    </div>
  );
};

export default AppPageTemplate;
