import { ProjectFile, Flashcard, FlashcardFeedback, MockProjectFlashcardSet, ProjectFlashcardSet, FlashcardDifficulty } from "./types";
import { GetIDToken, auth, db } from "../../firebase/FirebaseConfig";
import { WaitFor } from "../../utils/UtilityMethods";
import { ExtractServerResponse } from "../Shared";
import { DocumentData, DocumentSnapshot, collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";

export async function GenerateFlashcardSet(projectID: string, files: ProjectFile[], prompt: string, difficulties: FlashcardDifficulty[], flashcardSetID?: string): Promise<ProjectFlashcardSet> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  // await WaitFor(3);
  // return MockProjectFlashcardSet;

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/generate/flashcards`, {
    method: "POST",
    body: JSON.stringify({
      flashcardSetID,
      prompt,
      difficulties,
      files: files.map((file) => {
        return {
          fileID: file.id,
          openaiID: file.openaiID,
        };
      }),
    }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { flashcardSet } = (await ExtractServerResponse(response)).data;

  const cleanedSet: ProjectFlashcardSet = {
    ...flashcardSet,
    dateCreated: new Date(flashcardSet.dateCreated),
  };

  return cleanedSet;
}

export async function FetchFlashcardSets(projectID: string): Promise<ProjectFlashcardSet[]> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const flashcardsDocs = await getDocs(collection(db, `users/${currentUser.uid}/projects/${projectID}/flashcards`));

  const flashcards: ProjectFlashcardSet[] = flashcardsDocs.docs
    .map((doc) => {
      try {
        return flashcardSetFromDoc(doc);
      } catch (e) {
        return null;
      }
    })
    .filter((flashcardSet) => flashcardSet !== null) as ProjectFlashcardSet[];
  return flashcards;
}

export async function FetchFlashcardSet(projectID: string, flashcardSetID: string): Promise<ProjectFlashcardSet> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const flashcardsDoc = await getDoc(doc(db, `users/${currentUser.uid}/projects/${projectID}/flashcards/${flashcardSetID}`));

  return flashcardSetFromDoc(flashcardsDoc);
}

export async function SaveFlashcards(projectID: string, flashcardSet: ProjectFlashcardSet) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/flashcards/${flashcardSet.id}`);
  await setDoc(docRef, { flashcards: flashcardSet.flashcards }, { merge: true });
}

export async function DeleteFlashcardSet(projectID: string, flashcardSetID: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/flashcards/${flashcardSetID}`);
  await deleteDoc(docRef);
}

export async function RenameFlashcardSet(projectID: string, flashcardSetID: string, newName: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/flashcards/${flashcardSetID}`);
  await setDoc(docRef, { name: newName }, { merge: true });
}

export async function CheckFlashcardAnswer(projectID: string, flashcard: Flashcard, userAnswer: string): Promise<FlashcardFeedback> {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/flashcards/check-answer`, {
    method: "POST",
    body: JSON.stringify({
      flashcard,
      userAnswer,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const { feedback } = (await ExtractServerResponse(response)).data;
  return feedback;
}

const flashcardSetFromDoc = (doc: DocumentSnapshot<DocumentData>): ProjectFlashcardSet => {
  const data = doc.data();
  if (!data) throw new Error(`No flashcard set with ID ${doc.id} found.`);
  return {
    id: doc.id,
    name: data.name,
    dateCreated: data.dateCreated.toDate(),
    generatedFrom: data.generatedFrom,
    flashcards: data.flashcards,
    annotations: data.annotations,
    publicID: data.publicID,
  };
};
