import { createContext, useContext, useEffect, useRef, useState } from "react";
import useProjectSummaries from "./useProjectSummaries";
import { CoursableProject } from "../../../../backend/Projects/types";
import useProjectFlashcards from "./useProjectFlashcards";
import useProjectQuizzes from "./useProjectQuizzes";
import useProjectMessages from "./useProjectMessages";
import { useParams } from "react-router-dom";
import { FetchProject } from "../../../../backend/Projects/ProjectBase";
import { CheckIfOpenAIFilesUploaded } from "../../../../backend/Projects/ProjectFiles";
import useProjectFiles from "./useProjectFiles";
import useProjectChatInput from "./useProjectChatInput";
import useProjectNotes from "./useProjectNotes";

const ProjectManagerContext = createContext<{
  InitializeProject: () => Promise<void>;
  project: CoursableProject | undefined;
  Files: ReturnType<typeof useProjectFiles>;
  ChatInput: ReturnType<typeof useProjectChatInput>;
  Messages: ReturnType<typeof useProjectMessages>;
  Summaries: ReturnType<typeof useProjectSummaries>;
  Flashcards: ReturnType<typeof useProjectFlashcards>;
  Quizzes: ReturnType<typeof useProjectQuizzes>;
  Notes: ReturnType<typeof useProjectNotes>;
}>(null as any);

export function ProjectManagerProvider({ children }: { children: React.ReactNode }) {
  const { id } = useParams();

  const [project, setProject] = useState<CoursableProject | undefined>(undefined);

  const Files = useProjectFiles(project);
  const Messages = useProjectMessages(project, setProject);
  const ChatInput = useProjectChatInput(project, Messages);
  const Summaries = useProjectSummaries(project, setProject);
  const Flashcards = useProjectFlashcards(project, setProject);
  const Quizzes = useProjectQuizzes(project, setProject);
  const Notes = useProjectNotes(project);

  const checkingFileReupload = useRef<boolean>(false);
  const initializingProjectID = useRef<string | undefined>(undefined);

  async function InitializeProject() {
    if (!id) return;

    setProject(undefined);

    initializingProjectID.current = id;
    const project = await FetchProject(id);
    if (initializingProjectID.current !== id) return;

    setProject(project);

    if (!checkingFileReupload.current) {
      checkingFileReupload.current = true;
      await CheckIfOpenAIFilesUploaded(project);
    }
    await Promise.all([Files.Initialize(project), Messages.Initialize(project), Summaries.Initialize(project), Flashcards.Initialize(project), Quizzes.Initialize(project), Notes.Initialize(project)]);
  }

  useEffect(() => {
    return () => {
      checkingFileReupload.current = false;
    };
  }, [id]);

  return (
    <ProjectManagerContext.Provider
      value={{
        project,
        InitializeProject,
        Files,
        ChatInput,
        Messages,
        Summaries,
        Flashcards,
        Quizzes,
        Notes,
      }}
    >
      {children}
    </ProjectManagerContext.Provider>
  );
}

export const useProjectManager = () => {
  return useContext(ProjectManagerContext);
};
