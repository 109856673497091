import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProjectQuiz } from "../../../../../../backend/Projects/types";
import { Helmet } from "react-helmet-async";
import { TypeAnimation } from "react-type-animation";
import { PDFViewer } from "@react-pdf/renderer";
import PDFFromQuiz from "./PDFFromQuiz";
import { FetchQuiz } from "../../../../../../backend/Projects/ProjectQuizzes";
import { FetchPublicProjectQuiz } from "../../../../../../backend/Projects/PublicProjects";
import { pageMetatags } from "../../../../../../utils/MetatagsGenerator";

const ProjectQuizPDFPage = () => {
  const { publicID, projectID, quizID } = useParams();

  const [quiz, setQuiz] = useState<ProjectQuiz | null>(null);
  const [failed, setFailed] = useState<boolean>(false);

  async function GetQuiz() {
    if (!projectID || !quizID) return;

    try {
      const quiz = await FetchQuiz(projectID, quizID);
      setQuiz(quiz);
    } catch (error) {
      setFailed(true);
    }
  }

  async function GetPublicQuiz() {
    if (!publicID) return;

    try {
      const { derivative } = await FetchPublicProjectQuiz(publicID);
      setQuiz(derivative);
    } catch (error) {
      setFailed(true);
    }
  }

  useEffect(() => {
    if (publicID) GetPublicQuiz();
    else GetQuiz();
  }, []);

  const meta = (
    <Helmet>
      {pageMetatags({
        title: quiz?.name ?? "Quiz",
      })}
    </Helmet>
  );

  if (!quiz)
    return (
      <div className="w-screen h-screen bg-systemGray-200 text-systemGray-500 flex items-center justify-center">
        {meta}
        {failed ? <div>Failed to load quiz</div> : <TypeAnimation sequence={["Preparing PDF", 1000, ""]} repeat={Infinity} />}
      </div>
    );
  return (
    <>
      {meta}
      <PDFViewer className={`w-screen h-screen`}>
        <PDFFromQuiz quiz={quiz} />
      </PDFViewer>
    </>
  );
};

export default ProjectQuizPDFPage;
