import { InputHTMLAttributes } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../utils/UtilityMethods";
import { controlsStyles } from "./Button";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "size">, VariantProps<typeof inputVariants>, InputFieldProps {}

interface InputFieldProps {
  icon?: React.ReactNode;
  onValueChange?: (v: string) => void;
}

export const inputVariants = cva([controlsStyles.base, "font-normal flex items-center gap-2 border focus-within:border-brand-500 focus-within:shadow transition-all"], {
  variants: {
    variant: {
      outline: cn(controlsStyles.variants.outline, "active:bg-systemGray-100"),
      ghost: cn(controlsStyles.variants.ghost, "active:bg-systemGray-100 border-systemGray-300"),
    },
    size: {
      sm: cn(controlsStyles.size.sm, "px-1.5"),
      default: cn(controlsStyles.size.default, "px-3"),
      lg: cn(controlsStyles.size.lg, "px-3.5"),
    },
  },
  defaultVariants: {
    variant: "outline",
    size: "default",
  },
});

const InputField = ({ icon, onChange, onValueChange, disabled, variant, className, size, ...props }: Props) => {
  return (
    <div className={cn(inputVariants({ className, size, variant }), disabled && controlsStyles.disabled)}>
      <input
        {...props}
        className={`w-full bg-transparent outline-none placeholder:text-systemGray-500`}
        onChange={(e) => {
          onChange?.(e);
          onValueChange?.(e.target.value);
        }}
      />
      {icon && <div className={cn(variant === "outline" ? "text-systemGray-500" : "text-systemGray-400")}>{icon}</div>}
    </div>
  );
};

export default InputField;
