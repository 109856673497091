import { GetIDToken } from "../firebase/FirebaseConfig";
import { ExtractServerResponse } from "./Shared";

export async function EnterpriseUserResetKey(type: "dev" | "prod") {
  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/enterprise-user/reset-key/${type}`, {
    method: "PUT",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  await ExtractServerResponse(response);
}

export async function EnterpriseHealthCheck(): Promise<boolean> {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/`);
  return response.ok;
}
