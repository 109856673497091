import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProjectFlashcardSet } from "../../../../../../backend/Projects/types";
import { FetchFlashcardSet } from "../../../../../../backend/Projects/ProjectFlashcards";
import { Helmet } from "react-helmet-async";
import { TypeAnimation } from "react-type-animation";
import { PDFViewer } from "@react-pdf/renderer";
import PDFFromFlashcardSet from "./PDFFromFlashcardSet";
import { FetchPublicProjectFlashcardSet } from "../../../../../../backend/Projects/PublicProjects";
import { pageMetatags } from "../../../../../../utils/MetatagsGenerator";

const ProjectFlashcardsPDFPage = () => {
  const { publicID, projectID, flashcardSetID } = useParams();

  const [flashcardSet, setFlashcardSet] = useState<ProjectFlashcardSet | null>(null);
  const [failed, setFailed] = useState<boolean>(false);

  async function GetFlashcardSet() {
    if (!projectID || !flashcardSetID) return;

    try {
      const flashcardSet = await FetchFlashcardSet(projectID, flashcardSetID);
      setFlashcardSet(flashcardSet);
    } catch (error) {
      setFailed(true);
    }
  }

  async function GetPublicFlashcardSet() {
    if (!publicID) return;

    try {
      const { derivative } = await FetchPublicProjectFlashcardSet(publicID);
      setFlashcardSet(derivative);
    } catch (error) {
      setFailed(true);
    }
  }

  useEffect(() => {
    if (publicID) GetPublicFlashcardSet();
    else GetFlashcardSet();
  }, []);

  const meta = (
    <Helmet>
      {pageMetatags({
        title: flashcardSet?.name ?? "Flashcards",
      })}
    </Helmet>
  );

  if (!flashcardSet)
    return (
      <div className="w-screen h-screen bg-systemGray-200 text-systemGray-500 flex items-center justify-center">
        {meta}
        {failed ? <div>Failed to load flashcard set</div> : <TypeAnimation sequence={["Preparing PDF", 1000, ""]} repeat={Infinity} />}
      </div>
    );
  return (
    <>
      {meta}
      <PDFViewer className={`w-screen h-screen`}>
        <PDFFromFlashcardSet flashcardSet={flashcardSet} />
      </PDFViewer>
    </>
  );
};

export default ProjectFlashcardsPDFPage;
