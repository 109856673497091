import { useState } from "react";
import { AuthPageModalBackground, ContinueWithGoogleButton, SigninTypeDivider } from "./AuthShared";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthContext";
import LoadingIndicator from "../../elements/LoadingIndicator";
import { Helmet } from "react-helmet-async";
import CoursableLogo from "../../elements/CoursableLogo";
import Button from "../../elements/Button";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const SignupPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [accountExistsError, setAccountExistsError] = useState<boolean>(false);

  const [canSignup, setCanSignup] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);

  const { SignUp, SignInWithGoogle, currentUser } = useAuth();
  const navigate = useNavigate();

  async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!canSignup) return;

    setAccountExistsError(false);
    try {
      setIsSigningUp(true);
      await SignUp(name, email, password);
      navigate("/confirm-email");
      setIsSigningUp(false);
    } catch (error: any) {
      setIsSigningUp(false);
      if (error.code === "auth/email-already-in-use") setAccountExistsError(true);
      console.log(error);
    }
  }

  async function ContinueWithGoogle() {
    try {
      await SignInWithGoogle();
    } catch (error) {
      console.log(error);
    }
  }

  function OnNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);

    setNameError(e.target.value.length === 0 ? "Please enter your name." : "");

    setCanSignup(isValidName(e.target.value) && isValidEmail(email) && isValidPassword(password) && isValidConfirmPassword(confirmPassword));
  }

  function OnEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);

    setEmailError(e.target.value.length === 0 ? "Please enter your email." : "");

    setCanSignup(isValidName(name) && isValidEmail(e.target.value) && isValidPassword(password) && isValidConfirmPassword(confirmPassword));
  }

  function OnPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);

    setPasswordError(e.target.value.length === 0 ? "Please enter your password." : "");

    setCanSignup(isValidName(name) && isValidEmail(email) && isValidPassword(e.target.value) && isValidConfirmPassword(confirmPassword));
  }

  function OnConfirmPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setConfirmPassword(e.target.value);

    setConfirmPasswordError(e.target.value.length === 0 ? "Please confirm your password." : "");

    setCanSignup(isValidName(name) && isValidEmail(email) && isValidPassword(password) && isValidConfirmPassword(e.target.value));
  }

  function isValidName(name: string) {
    return name.length > 0;
  }
  function isValidEmail(email: string) {
    return /^\S+@\S+\.\S+$/.test(email);
  }
  function isValidPassword(password: string) {
    return password.length >= 8;
  }
  function isValidConfirmPassword(confirmPassword: string) {
    return confirmPassword === password;
  }

  const errorStyle = "text-sm text-red-500 mt-1";

  if (currentUser) {
    return <Navigate to="/app" />;
  }

  return (
    <AuthPageModalBackground>
      <Helmet>{pageMetatags({ title: "Sign Up", canonical: "/signup" })}</Helmet>
      <CoursableLogo size={10} />
      <h3 className="font-semibold text-center text-xl">
        Create a <span className="text-brand-500">Coursable</span> account 🚀
      </h3>
      <p className="text-sm text-systemGray-500 text-center -mt-2 mb-4">
        Already have an account?{" "}
        <Link to="/login" className="text-brand-500 hover:font-semibold">
          Sign in
        </Link>
      </p>
      <ContinueWithGoogleButton onClick={ContinueWithGoogle} />
      <SigninTypeDivider />
      {accountExistsError && (
        <div className="w-full rounded-md border bg-red-50 dark:bg-red-950/70 border-red-300 dark:border-red-700 text-mini px-2 py-1.5 text-red-500">
          An account with this email already exists. Would you like to{" "}
          <Link className="text-brandBlue-500 hover:text-brandBlue-600" to={"/login"}>
            login
          </Link>{" "}
          instead?
        </div>
      )}
      <form onSubmit={OnSubmit} className="flex flex-col gap-2 w-full">
        <label className="text-sm">
          Name
          <div className="mt-1">
            <input type="text" className="border outline-none focus:border-brand-500 duration-100 rounded-md w-full py-2 px-3" placeholder="Chalie Brown" value={name} onChange={OnNameChange} required />
          </div>
          {nameError && <div className={errorStyle}>{nameError}</div>}
        </label>
        <label className="text-sm">
          Email
          <div className="mt-1">
            <input type="email" className="border outline-none focus:border-brand-500 duration-100 rounded-md w-full py-2 px-3" placeholder="example@coursable.com" value={email} onChange={OnEmailChange} required />
          </div>
          {emailError && <div className={errorStyle}>{emailError}</div>}
        </label>
        <label className="text-sm">
          Password
          <div className="mt-1 peer">
            <input
              autoComplete="new-password"
              type="password"
              className="border outline-none focus:border-brand-500 duration-100 rounded-md w-full py-2 px-3"
              placeholder="Secure password"
              value={password}
              onChange={OnPasswordChange}
              required
            />
          </div>
          <PasswordValidator className={`${shouldHidePassRules(password) && "hidden peer-focus-within:block"}`} password={password} />
          {passwordError && <div className={errorStyle}>{passwordError}</div>}
        </label>
        <label className="text-sm">
          Confirm password
          <div className="mt-1 peer">
            <input
              autoComplete="new-password"
              type="password"
              className="border outline-none focus:border-brand-500 duration-100 rounded-md w-full py-2 px-3"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={OnConfirmPasswordChange}
              required
            />
          </div>
          <PasswordMatchValidator className={`${shouldHidePassMatch(password, confirmPassword) && "hidden peer-focus-within:block"}`} password={password} confirmPassword={confirmPassword} />
          {confirmPasswordError && <div className={errorStyle}>{confirmPasswordError}</div>}
        </label>
        <Button loading={isSigningUp} disabled={!canSignup || isSigningUp} type="submit" className="mt-4">
          Create account
        </Button>
      </form>
      <p className="mx-auto text-micro text-center text-systemGray-500 max-w-[20rem]">
        By creating an account, you agree to the Coursable{" "}
        <Link className="text-brandBlue-300 hover:text-brandBlue-500" to={"/terms"}>
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link className="text-brandBlue-300 hover:text-brandBlue-500" to={"/privacy"}>
          Privacy Policy
        </Link>
        .
      </p>
    </AuthPageModalBackground>
  );
};

const PasswordValidator = ({ password, className }: { password: string; className: string }) => {
  return (
    <ul className={`mt-2 ${className}`}>
      {passwordRules.map((rule, index) => {
        return (
          <li key={index} className="flex items-center gap-2 mx-2">
            <div className={`rounded-full h-1.5 w-1.5 ${rule.rule(password) ? "bg-green-500" : "bg-red-500"}`} />
            <div>{rule.label}</div>
          </li>
        );
      })}
    </ul>
  );
};

const PasswordMatchValidator = ({ password, confirmPassword, className }: { password: string; confirmPassword: string; className: string }) => {
  const match = password === confirmPassword;

  return (
    <ul className={`mt-2 ${className}`}>
      <li className="flex items-center gap-2 mx-2">
        <div className={`rounded-full h-1.5 w-1.5 ${match ? "bg-green-500" : "bg-red-500"}`} />
        <div>{match ? "Passwords match" : "Passwords don't match"}</div>
      </li>
    </ul>
  );
};

type PasswordRule = {
  label: string;
  rule: (password: string) => boolean;
};

const passwordRules: PasswordRule[] = [
  {
    label: "At least 8 characters",
    rule: (password: string) => password.length >= 8,
  },
  {
    label: "At least 1 number or special character",
    rule: (password: string) => /[0-9!?@#$%^&*<>]/.test(password),
  },
];

function shouldHidePassRules(password: string) {
  return passwordRules.every((rule) => rule.rule(password)) || password.length === 0;
}
function shouldHidePassMatch(password: string, confirmPassword: string) {
  return password === confirmPassword || confirmPassword.length === 0;
}

export default SignupPage;
