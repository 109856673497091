import { auth, GetIDToken } from "../../firebase/FirebaseConfig";
import { GALogPublishedProjectDerivative, GALogUnpublishedProjectDerivative } from "../../firebase/GoogleAnalytics";
import { ExtractServerResponse } from "../Shared";
import { ProjectDerivativeType, ProjectFlashcardSet, ProjectQuiz, ProjectSummary } from "./types";

export async function FetchPublicProjectSummary(publicID: string): Promise<{ derivative: ProjectSummary; ownerName?: string }> {
  return FetchPublicProjectDerivative("summary", publicID) as Promise<{ derivative: ProjectSummary; ownerName?: string }>;
}

export async function FetchPublicProjectFlashcardSet(publicID: string): Promise<{ derivative: ProjectFlashcardSet; ownerName?: string }> {
  return FetchPublicProjectDerivative("flashcards", publicID) as Promise<{ derivative: ProjectFlashcardSet; ownerName?: string }>;
}

export async function FetchPublicProjectQuiz(publicID: string): Promise<{ derivative: ProjectQuiz; ownerName?: string }> {
  return FetchPublicProjectDerivative("quiz", publicID) as Promise<{ derivative: ProjectQuiz; ownerName?: string }>;
}

async function FetchPublicProjectDerivative(type: ProjectDerivativeType, publicID: string): Promise<{ derivative: ProjectSummary | ProjectFlashcardSet | ProjectQuiz; ownerName?: string }> {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/public/${type}/${publicID}`, {
    method: "GET",
  });

  const { derivative, ownerName } = (await ExtractServerResponse(response)).data;

  return { derivative, ownerName };
}

export async function PublishProjectDerivative(type: ProjectDerivativeType, projectID: string, derivativeID: string): Promise<string> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/${type}/${derivativeID}/publish`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { publicID } = (await ExtractServerResponse(response)).data;

  GALogPublishedProjectDerivative(type);

  return publicID;
}

export async function UnpublishProjectDerivative(type: ProjectDerivativeType, projectID: string, derivativeID: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/${type}/${derivativeID}/unpublish`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  await ExtractServerResponse(response);

  GALogUnpublishedProjectDerivative(type);
}
