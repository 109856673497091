import { ProjectFile, MockProjectSummary, ProjectSummary } from "./types";
import { GetIDToken, auth, db } from "../../firebase/FirebaseConfig";
import { WaitFor } from "../../utils/UtilityMethods";
import { ExtractServerResponse } from "../Shared";
import { DocumentData, DocumentSnapshot, collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";

export async function GenerateSummary(projectID: string, files: ProjectFile[], prompt: string, summaryID?: string): Promise<ProjectSummary> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  // await WaitFor(3);
  // return MockProjectSummary;

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/generate/summary`, {
    method: "POST",
    body: JSON.stringify({
      summaryID,
      prompt,
      files: files.map((file) => {
        return {
          fileID: file.id,
          openaiID: file.openaiID,
        };
      }),
    }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { summary } = (await ExtractServerResponse(response)).data;

  const cleanedSumary: ProjectSummary = {
    ...summary,
    dateCreated: new Date(summary.dateCreated),
  };

  return cleanedSumary;
}

export async function FetchSummaries(projectID: string): Promise<ProjectSummary[]> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const summariesDocs = await getDocs(collection(db, `users/${currentUser.uid}/projects/${projectID}/summaries`));

  const summaries: ProjectSummary[] = summariesDocs.docs
    .map((doc) => {
      try {
        return summaryFromDoc(doc);
      } catch (e) {
        return null;
      }
    })
    .filter((summary) => summary !== null) as ProjectSummary[];
  return summaries;
}

export async function FetchSummary(projectID: string, summaryID: string): Promise<ProjectSummary> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const summaryDoc = await getDoc(doc(db, `users/${currentUser.uid}/projects/${projectID}/summaries/${summaryID}`));

  return summaryFromDoc(summaryDoc);
}

export async function SaveSummaryContent(projectID: string, summaryID: string, newContent: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/summaries/${summaryID}`);

  await setDoc(docRef, { content: newContent }, { merge: true });
}

export async function DeleteSummary(projectID: string, summaryID: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/summaries/${summaryID}`);
  await deleteDoc(docRef);
}

export async function RenameSummary(projectID: string, summaryID: string, newName: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/summaries/${summaryID}`);
  await setDoc(docRef, { name: newName }, { merge: true });
}

const summaryFromDoc = (doc: DocumentSnapshot<DocumentData>): ProjectSummary => {
  const data = doc.data();
  if (!data) throw new Error(`No summary with ID ${doc.id} found.`);
  return {
    id: doc.id,
    name: data.name,
    content: data.content,
    dateCreated: data.dateCreated.toDate(),
    generatedFrom: data.generatedFrom,
    annotations: data.annotations || [],
    publicID: data.publicID,
  };
};
