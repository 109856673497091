export const AuthBackground = ({ className }: { className?: string }) => {
  return (
    <svg className={className} id="visual" width="5000" height="5000" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" version="1.1">
      <g strokeWidth="1" strokeLinejoin="bevel">
        <path d="M2404 2481L2441 2312L2158 2281Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2158 2281L2175 2572L2404 2481Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2167 2099L2098 2160L2158 2281Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2158 2281L1903 2522L2175 2572Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2441 2312L2167 2099L2158 2281Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2175 2572L2286 2726L2404 2481Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2098 2160L1903 2522L2158 2281Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2175 2572L1925 2686L2286 2726Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2758 2436L2690 2208L2441 2312Z" fill="#641112" stroke="#641112"></path>
        <path d="M2441 2312L2572 1956L2167 2099Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2758 2436L2441 2312L2404 2481Z" fill="#681213" stroke="#681213"></path>
        <path d="M2286 2726L2592 2679L2404 2481Z" fill="#681213" stroke="#681213"></path>
        <path d="M2592 2679L2758 2436L2404 2481Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1903 2522L1925 2686L2175 2572Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2690 2208L2572 1956L2441 2312Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2572 1956L2212 1828L2167 2099Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2167 2099L1905 1941L2098 2160Z" fill="#711414" stroke="#711414"></path>
        <path d="M2098 2160L1712 2260L1903 2522Z" fill="#751415" stroke="#751415"></path>
        <path d="M2571 2901L2698 2765L2592 2679Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2592 2679L2698 2765L2758 2436Z" fill="#641112" stroke="#641112"></path>
        <path d="M2212 1828L1905 1941L2167 2099Z" fill="#681213" stroke="#681213"></path>
        <path d="M2027 2958L2211 2951L2286 2726Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2286 2726L2571 2901L2592 2679Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1905 1941L1712 2260L2098 2160Z" fill="#751415" stroke="#751415"></path>
        <path d="M1903 2522L1738 2693L1925 2686Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2211 2951L2571 2901L2286 2726Z" fill="#711414" stroke="#711414"></path>
        <path d="M1712 2260L1669 2506L1903 2522Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1925 2686L2027 2958L2286 2726Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1669 2506L1738 2693L1903 2522Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1738 2693L2027 2958L1925 2686Z" fill="#711414" stroke="#711414"></path>
        <path d="M3089 2299L2825 1975L2690 2208Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2690 2208L2825 1975L2572 1956Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2572 1956L2430 1665L2212 1828Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2971 2663L2964 2413L2758 2436Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2758 2436L2964 2413L2690 2208Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1690 1754L1662 2028L1905 1941Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1905 1941L1662 2028L1712 2260Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1712 2260L1480 2274L1669 2506Z" fill="#711414" stroke="#711414"></path>
        <path d="M2705 1734L2430 1665L2572 1956Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2212 1828L2014 1655L1905 1941Z" fill="#681213" stroke="#681213"></path>
        <path d="M2830 3064L2971 2663L2698 2765Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2698 2765L2971 2663L2758 2436Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2825 1975L2705 1734L2572 1956Z" fill="#681213" stroke="#681213"></path>
        <path d="M2170 1419L2014 1655L2212 1828Z" fill="#681213" stroke="#681213"></path>
        <path d="M3304 2582L3089 2299L2964 2413Z" fill="#681213" stroke="#681213"></path>
        <path d="M2964 2413L3089 2299L2690 2208Z" fill="#641112" stroke="#641112"></path>
        <path d="M2825 1975L2912 1818L2705 1734Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2027 2958L2296 3172L2211 2951Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2211 2951L2296 3172L2571 2901Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2571 2901L2830 3064L2698 2765Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1662 2028L1480 2274L1712 2260Z" fill="#751415" stroke="#751415"></path>
        <path d="M1669 2506L1501 2594L1738 2693Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1480 2274L1501 2594L1669 2506Z" fill="#751415" stroke="#751415"></path>
        <path d="M1738 2693L1755 3098L2027 2958Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2027 2958L1917 3279L2296 3172Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3088 1912L2912 1818L2825 1975Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2705 1734L2573 1583L2430 1665Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2170 1419L2001 1536L2014 1655Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2726 1562L2573 1583L2705 1734Z" fill="#681213" stroke="#681213"></path>
        <path d="M2296 3172L2537 3168L2571 2901Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2014 1655L1690 1754L1905 1941Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1662 2028L1403 2038L1480 2274Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2537 3168L2830 3064L2571 2901Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2170 1419L2212 1828L2430 1665Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2014 1655L1772 1510L1690 1754Z" fill="#641112" stroke="#641112"></path>
        <path d="M1214 2680L1477 2760L1501 2594Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1501 2594L1477 2760L1738 2693Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1477 2760L1755 3098L1738 2693Z" fill="#711414" stroke="#711414"></path>
        <path d="M2912 1818L2726 1562L2705 1734Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2542 1290L2170 1419L2430 1665Z" fill="#641112" stroke="#641112"></path>
        <path d="M3089 2299L3088 1912L2825 1975Z" fill="#711414" stroke="#711414"></path>
        <path d="M2912 1818L2998 1424L2726 1562Z" fill="#641112" stroke="#641112"></path>
        <path d="M1501 1748L1403 2038L1662 2028Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1480 2274L1244 2541L1501 2594Z" fill="#711414" stroke="#711414"></path>
        <path d="M2542 1290L2430 1665L2573 1583Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2789 3281L2931 3080L2830 3064Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2830 3064L2931 3080L2971 2663Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3304 2582L3287 2258L3089 2299Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1755 3098L1917 3279L2027 2958Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2564 3454L2789 3281L2537 3168Z" fill="#711414" stroke="#711414"></path>
        <path d="M3304 2582L2964 2413L2971 2663Z" fill="#681213" stroke="#681213"></path>
        <path d="M3089 2299L3287 2258L3088 1912Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2003 1253L1772 1510L2001 1536Z" fill="#751415" stroke="#751415"></path>
        <path d="M2001 1536L1772 1510L2014 1655Z" fill="#711414" stroke="#711414"></path>
        <path d="M1690 1754L1501 1748L1662 2028Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1542 1428L1501 1748L1690 1754Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1574 3167L1717 3206L1755 3098Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1755 3098L1717 3206L1917 3279Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1253 1985L1267 2226L1403 2038Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1403 2038L1267 2226L1480 2274Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3344 2847L3304 2582L2971 2663Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2537 3168L2789 3281L2830 3064Z" fill="#751415" stroke="#751415"></path>
        <path d="M3253 3042L3344 2847L2971 2663Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2564 3454L2537 3168L2296 3172Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3345 1978L3157 1776L3088 1912Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3088 1912L3157 1776L2912 1818Z" fill="#751415" stroke="#751415"></path>
        <path d="M1267 2226L1244 2541L1480 2274Z" fill="#751415" stroke="#751415"></path>
        <path d="M1477 2760L1458 3045L1755 3098Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1225 2967L1458 3045L1477 2760Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1458 3045L1574 3167L1755 3098Z" fill="#711414" stroke="#711414"></path>
        <path d="M1769 3554L2021 3425L1917 3279Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1769 3554L1917 3279L1717 3206Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1917 3279L2021 3425L2296 3172Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2021 3425L2242 3464L2296 3172Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2766 1229L2542 1290L2726 1562Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2726 1562L2542 1290L2573 1583Z" fill="#681213" stroke="#681213"></path>
        <path d="M2170 1419L2003 1253L2001 1536Z" fill="#711414" stroke="#711414"></path>
        <path d="M1324 1767L1253 1985L1403 2038Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1065 2684L1214 2680L1244 2541Z" fill="#751415" stroke="#751415"></path>
        <path d="M2242 3464L2564 3454L2296 3172Z" fill="#711414" stroke="#711414"></path>
        <path d="M1065 2684L1244 2541L1022 2552Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1244 2541L1214 2680L1501 2594Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3425 2241L3345 1978L3287 2258Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3287 2258L3345 1978L3088 1912Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2978 3432L2927 3308L2789 3281Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2789 3281L2927 3308L2931 3080Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1334 1464L1324 1767L1501 1748Z" fill="#681213" stroke="#681213"></path>
        <path d="M1501 1748L1324 1767L1403 2038Z" fill="#641112" stroke="#641112"></path>
        <path d="M3304 2582L3425 2241L3287 2258Z" fill="#751415" stroke="#751415"></path>
        <path d="M2236 1188L2003 1253L2170 1419Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1772 1510L1542 1428L1690 1754Z" fill="#681213" stroke="#681213"></path>
        <path d="M3253 3042L2971 2663L2931 3080Z" fill="#641112" stroke="#641112"></path>
        <path d="M3304 2582L3549 2555L3425 2241Z" fill="#681213" stroke="#681213"></path>
        <path d="M3223 3203L3253 3042L2931 3080Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3170 1519L2912 1818L3157 1776Z" fill="#751415" stroke="#751415"></path>
        <path d="M3170 1519L2998 1424L2912 1818Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2320 1077L2236 1188L2542 1290Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2542 1290L2236 1188L2170 1419Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2477 3778L2676 3504L2564 3454Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2564 3454L2676 3504L2789 3281Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3666 2591L3445 2699L3704 2680Z" fill="#751415" stroke="#751415"></path>
        <path d="M3344 2847L3445 2699L3304 2582Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1745 1235L1542 1428L1772 1510Z" fill="#681213" stroke="#681213"></path>
        <path d="M3548 1734L3170 1519L3157 1776Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1214 2680L1225 2967L1477 2760Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1222 3509L1585 3574L1574 3167Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2053 3699L2242 3464L2021 3425Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3019 1168L2766 1229L2998 1424Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2998 1424L2766 1229L2726 1562Z" fill="#681213" stroke="#681213"></path>
        <path d="M2927 3308L3223 3203L2931 3080Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3568 2957L3445 2699L3344 2847Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3200 3414L3223 3203L2927 3308Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1950 1081L1745 1235L2003 1253Z" fill="#641112" stroke="#641112"></path>
        <path d="M2003 1253L1745 1235L1772 1510Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2676 3504L2978 3432L2789 3281Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1022 2552L1244 2541L957 2277Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1214 2680L1065 2684L1225 2967Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3666 2591L3549 2555L3445 2699Z" fill="#681213" stroke="#681213"></path>
        <path d="M3445 2699L3549 2555L3304 2582Z" fill="#711414" stroke="#711414"></path>
        <path d="M3425 2241L3575 2085L3345 1978Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M957 2277L1267 2226L977 1956Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M957 2277L1244 2541L1267 2226Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2599 1094L2320 1077L2542 1290Z" fill="#641112" stroke="#641112"></path>
        <path d="M2236 1188L1950 1081L2003 1253Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2053 3699L2333 3660L2242 3464Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2242 3464L2333 3660L2564 3454Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2969 3724L3200 3414L2978 3432Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1769 3554L1717 3206L1585 3574Z" fill="#711414" stroke="#711414"></path>
        <path d="M1585 3574L1717 3206L1574 3167Z" fill="#681213" stroke="#681213"></path>
        <path d="M2654 917L2599 1094L2942 1009Z" fill="#641112" stroke="#641112"></path>
        <path d="M2766 1229L2599 1094L2542 1290Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3771 2231L3575 2085L3425 2241Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3345 1978L3548 1734L3157 1776Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1542 1428L1334 1464L1501 1748Z" fill="#681213" stroke="#681213"></path>
        <path d="M977 1956L1267 2226L1253 1985Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1432 1168L1334 1464L1542 1428Z" fill="#751415" stroke="#751415"></path>
        <path d="M2320 1077L1950 1081L2236 1188Z" fill="#751415" stroke="#751415"></path>
        <path d="M1745 1235L1432 1168L1542 1428Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M977 1956L1253 1985L1056 1663Z" fill="#711414" stroke="#711414"></path>
        <path d="M1769 3554L2053 3699L2021 3425Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1056 1663L1253 1985L1324 1767Z" fill="#711414" stroke="#711414"></path>
        <path d="M2969 3724L2978 3432L2676 3504Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2978 3432L3200 3414L2927 3308Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3568 2957L3344 2847L3253 3042Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3568 2957L3253 3042L3479 3204Z" fill="#711414" stroke="#711414"></path>
        <path d="M3549 2555L3771 2231L3425 2241Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1182 3222L1574 3167L1458 3045Z" fill="#751415" stroke="#751415"></path>
        <path d="M1769 3554L1709 3803L2053 3699Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M960 3058L1182 3222L1225 2967Z" fill="#681213" stroke="#681213"></path>
        <path d="M1225 2967L1182 3222L1458 3045Z" fill="#641112" stroke="#641112"></path>
        <path d="M960 3058L1225 2967L1065 2684Z" fill="#681213" stroke="#681213"></path>
        <path d="M3237 1212L2998 1424L3170 1519Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3237 1212L3019 1168L2998 1424Z" fill="#681213" stroke="#681213"></path>
        <path d="M3479 3204L3253 3042L3223 3203Z" fill="#711414" stroke="#711414"></path>
        <path d="M3663 2016L3345 1978L3575 2085Z" fill="#711414" stroke="#711414"></path>
        <path d="M3663 2016L3548 1734L3345 1978Z" fill="#681213" stroke="#681213"></path>
        <path d="M3771 2231L3663 2016L3575 2085Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2177 4054L2477 3778L2333 3660Z" fill="#711414" stroke="#711414"></path>
        <path d="M2333 3660L2477 3778L2564 3454Z" fill="#711414" stroke="#711414"></path>
        <path d="M1334 1464L1056 1663L1324 1767Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3568 2957L3704 2680L3445 2699Z" fill="#751415" stroke="#751415"></path>
        <path d="M3666 2591L3771 2231L3549 2555Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M3200 3414L3479 3204L3223 3203Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3568 2957L3781 3007L3704 2680Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1553 1065L1432 1168L1745 1235Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1334 1464L1020 1402L1056 1663Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3969 2171L3771 2231L4034 2432Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3832 1597L3565 1444L3548 1734Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1950 1081L1822 955L1745 1235Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1930 687L1822 955L1950 1081Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2263 685L1950 1081L2320 1077Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2446 774L2320 1077L2599 1094Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3565 1444L3237 1212L3170 1519Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2942 1009L2766 1229L3019 1168Z" fill="#681213" stroke="#681213"></path>
        <path d="M2942 1009L2599 1094L2766 1229Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1822 955L1553 1065L1745 1235Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M753 2940L1065 2684L666 2757Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M753 2940L960 3058L1065 2684Z" fill="#681213" stroke="#681213"></path>
        <path d="M1516 3784L1709 3803L1585 3574Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2477 3778L2790 3785L2676 3504Z" fill="#641112" stroke="#641112"></path>
        <path d="M3498 3553L3719 3193L3479 3204Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3239 1075L2942 1009L3019 1168Z" fill="#751415" stroke="#751415"></path>
        <path d="M2790 3785L2969 3724L2676 3504Z" fill="#641112" stroke="#641112"></path>
        <path d="M1253 1061L1318 1167L1432 1168Z" fill="#711414" stroke="#711414"></path>
        <path d="M1432 1168L1318 1167L1334 1464Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1222 3509L1574 3167L1182 3222Z" fill="#711414" stroke="#711414"></path>
        <path d="M1585 3574L1709 3803L1769 3554Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2053 3699L2177 4054L2333 3660Z" fill="#681213" stroke="#681213"></path>
        <path d="M2477 3778L2743 4070L2790 3785Z" fill="#681213" stroke="#681213"></path>
        <path d="M977 1956L750 2200L957 2277Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M957 2277L669 2438L1022 2552Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M728 2063L750 2200L977 1956Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M728 2063L977 1956L757 1747Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M957 3252L1222 3509L1182 3222Z" fill="#681213" stroke="#681213"></path>
        <path d="M3803 1760L3548 1734L3663 2016Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3548 1734L3565 1444L3170 1519Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M757 1747L977 1956L1056 1663Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1076 1320L1020 1402L1334 1464Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M960 3058L957 3252L1182 3222Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M756 3262L957 3252L960 3058Z" fill="#681213" stroke="#681213"></path>
        <path d="M2679 697L2446 774L2654 917Z" fill="#681213" stroke="#681213"></path>
        <path d="M2654 917L2446 774L2599 1094Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3535 1196L3239 1075L3237 1212Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3237 1212L3239 1075L3019 1168Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2942 1009L2971 797L2654 917Z" fill="#711414" stroke="#711414"></path>
        <path d="M1318 1167L1076 1320L1334 1464Z" fill="#751415" stroke="#751415"></path>
        <path d="M1341 3720L1516 3784L1585 3574Z" fill="#711414" stroke="#711414"></path>
        <path d="M3719 3193L3568 2957L3479 3204Z" fill="#711414" stroke="#711414"></path>
        <path d="M3719 3193L3781 3007L3568 2957Z" fill="#641112" stroke="#641112"></path>
        <path d="M4034 2432L3771 2231L3666 2591Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M562 2264L669 2438L750 2200Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M750 2200L669 2438L957 2277Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3905 1918L3803 1760L3663 2016Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M3905 1918L3663 2016L3771 2231Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M666 2757L1065 2684L1022 2552Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M3498 3553L3479 3204L3200 3414Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1222 3509L1341 3720L1585 3574Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M978 3716L1341 3720L1222 3509Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M809 1551L1056 1663L1020 1402Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M809 1551L757 1747L1056 1663Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M569 2062L562 2264L750 2200Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1318 1167L1253 1061L1076 1320Z" fill="#711414" stroke="#711414"></path>
        <path d="M769 1185L809 1551L1020 1402Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1553 1065L1253 1061L1432 1168Z" fill="#681213" stroke="#681213"></path>
        <path d="M1337 845L1253 1061L1553 1065Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1726 785L1553 1065L1822 955Z" fill="#681213" stroke="#681213"></path>
        <path d="M669 2438L666 2757L1022 2552Z" fill="#711414" stroke="#711414"></path>
        <path d="M3969 2171L3905 1918L3771 2231Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1930 687L1726 785L1822 955Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2457 510L2263 685L2446 774Z" fill="#711414" stroke="#711414"></path>
        <path d="M2446 774L2263 685L2320 1077Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4034 2432L3666 2591L3704 2680Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2036 4075L1709 3803L1791 4081Z" fill="#711414" stroke="#711414"></path>
        <path d="M2036 4075L2053 3699L1709 3803Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2036 4075L2177 4054L2053 3699Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2905 4047L3154 3837L2969 3724Z" fill="#711414" stroke="#711414"></path>
        <path d="M3154 3837L3200 3414L2969 3724Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3405 3762L3498 3553L3200 3414Z" fill="#681213" stroke="#681213"></path>
        <path d="M769 1185L1020 1402L1076 1320Z" fill="#681213" stroke="#681213"></path>
        <path d="M2177 4054L2457 4061L2477 3778Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2905 4047L2969 3724L2790 3785Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3565 1444L3535 1196L3237 1212Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M3705 1069L3535 1196L3841 1204Z" fill="#681213" stroke="#681213"></path>
        <path d="M3832 1597L3548 1734L3803 1760Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3207 780L2942 1009L3239 1075Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3207 780L2971 797L2942 1009Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2971 797L2679 697L2654 917Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2263 685L1930 687L1950 1081Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3939 1771L3803 1760L3905 1918Z" fill="#711414" stroke="#711414"></path>
        <path d="M3939 1771L3832 1597L3803 1760Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1791 4081L1709 3803L1429 3936Z" fill="#711414" stroke="#711414"></path>
        <path d="M2177 4054L2417 4242L2457 4061Z" fill="#751415" stroke="#751415"></path>
        <path d="M569 2062L728 2063L757 1747Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M569 2062L750 2200L728 2063Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M418 2673L519 2945L666 2757Z" fill="#711414" stroke="#711414"></path>
        <path d="M4064 2729L3704 2680L4016 2941Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4064 2729L4034 2432L3704 2680Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4307 1807L3939 1771L3905 1918Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M444 1714L569 2062L757 1747Z" fill="#641112" stroke="#641112"></path>
        <path d="M4289 2089L3905 1918L3969 2171Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2417 4242L2743 4070L2457 4061Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2457 4061L2743 4070L2477 3778Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M756 3262L981 3556L957 3252Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M957 3252L981 3556L1222 3509Z" fill="#751415" stroke="#751415"></path>
        <path d="M1429 3936L1709 3803L1516 3784Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M756 3262L960 3058L753 2940Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M519 2945L753 2940L666 2757Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M506 3228L756 3262L753 2940Z" fill="#711414" stroke="#711414"></path>
        <path d="M3226 4022L3405 3762L3154 3837Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3154 3837L3405 3762L3200 3414Z" fill="#751415" stroke="#751415"></path>
        <path d="M4157 2930L4016 2941L4050 3261Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1429 3936L1516 3784L1341 3720Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2290 4334L2417 4242L2177 4054Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2743 4070L2905 4047L2790 3785Z" fill="#751415" stroke="#751415"></path>
        <path d="M1096 3912L1429 3936L1341 3720Z" fill="#641112" stroke="#641112"></path>
        <path d="M1493 726L1553 1065L1726 785Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1493 726L1337 845L1553 1065Z" fill="#641112" stroke="#641112"></path>
        <path d="M1253 1061L998 965L1076 1320Z" fill="#681213" stroke="#681213"></path>
        <path d="M4016 2941L3781 3007L4050 3261Z" fill="#711414" stroke="#711414"></path>
        <path d="M4016 2941L3704 2680L3781 3007Z" fill="#681213" stroke="#681213"></path>
        <path d="M3717 3563L3719 3193L3498 3553Z" fill="#641112" stroke="#641112"></path>
        <path d="M1518 501L1493 726L1726 785Z" fill="#711414" stroke="#711414"></path>
        <path d="M3519 766L3207 780L3239 1075Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2971 797L2728 530L2679 697Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3686 3818L3717 3563L3498 3553Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M418 2673L666 2757L669 2438Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M412 2474L669 2438L562 2264Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2679 697L2457 510L2446 774Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2263 685L2059 493L1930 687Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1930 687L1815 497L1726 785Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2728 530L2457 510L2679 697Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1948 4254L2177 4054L2036 4075Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2743 4070L2931 4230L2905 4047Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2457 510L2232 490L2263 685Z" fill="#711414" stroke="#711414"></path>
        <path d="M730 3588L978 3716L981 3556Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M981 3556L978 3716L1222 3509Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M730 3588L981 3556L756 3262Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M296 2180L412 2474L562 2264Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2928 471L2728 530L2971 797Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2206 253L2059 493L2232 490Z" fill="#681213" stroke="#681213"></path>
        <path d="M2232 490L2059 493L2263 685Z" fill="#641112" stroke="#641112"></path>
        <path d="M412 2474L418 2673L669 2438Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M2905 4047L3226 4022L3154 3837Z" fill="#681213" stroke="#681213"></path>
        <path d="M3405 3762L3686 3818L3498 3553Z" fill="#681213" stroke="#681213"></path>
        <path d="M2931 4230L3226 4022L2905 4047Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1045 681L998 965L1337 845Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1337 845L998 965L1253 1061Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M498 1497L444 1714L757 1747Z" fill="#711414" stroke="#711414"></path>
        <path d="M1811 4335L1948 4254L1791 4081Z" fill="#751415" stroke="#751415"></path>
        <path d="M1791 4081L1948 4254L2036 4075Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1511 4180L1791 4081L1429 3936Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3535 1196L3580 923L3239 1075Z" fill="#711414" stroke="#711414"></path>
        <path d="M3841 1204L3535 1196L3565 1444Z" fill="#641112" stroke="#641112"></path>
        <path d="M3841 1204L3565 1444L3832 1597Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M998 965L769 1185L1076 1320Z" fill="#681213" stroke="#681213"></path>
        <path d="M569 2062L296 2180L562 2264Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1938 285L1815 497L2059 493Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2059 493L1815 497L1930 687Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1207 493L1045 681L1337 845Z" fill="#711414" stroke="#711414"></path>
        <path d="M3705 1069L3580 923L3535 1196Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3164 565L2928 471L2971 797Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4058 1439L3841 1204L3832 1597Z" fill="#751415" stroke="#751415"></path>
        <path d="M3726 821L3519 766L3580 923Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4050 3261L3781 3007L3719 3193Z" fill="#751415" stroke="#751415"></path>
        <path d="M4016 2941L4157 2930L4064 2729Z" fill="#711414" stroke="#711414"></path>
        <path d="M4269 2574L4276 2305L4034 2432Z" fill="#641112" stroke="#641112"></path>
        <path d="M4034 2432L4276 2305L3969 2171Z" fill="#681213" stroke="#681213"></path>
        <path d="M3939 1771L4058 1439L3832 1597Z" fill="#641112" stroke="#641112"></path>
        <path d="M978 3716L1096 3912L1341 3720Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M498 1497L757 1747L809 1551Z" fill="#711414" stroke="#711414"></path>
        <path d="M1948 4254L2290 4334L2177 4054Z" fill="#681213" stroke="#681213"></path>
        <path d="M2747 4309L2931 4230L2743 4070Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2747 4309L2743 4070L2417 4242Z" fill="#751415" stroke="#751415"></path>
        <path d="M4073 3519L4050 3261L3717 3563Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3717 3563L4050 3261L3719 3193Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1197 4086L1511 4180L1429 3936Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1948 4254L2161 4528L2290 4334Z" fill="#681213" stroke="#681213"></path>
        <path d="M978 3716L843 3789L1096 3912Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M524 3590L730 3588L756 3262Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M506 3228L753 2940L519 2945Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M243 2901L506 3228L519 2945Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3404 4270L3527 3922L3226 4022Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3226 4022L3527 3922L3405 3762Z" fill="#681213" stroke="#681213"></path>
        <path d="M4269 2574L4034 2432L4064 2729Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4335 2778L4269 2574L4064 2729Z" fill="#751415" stroke="#751415"></path>
        <path d="M2575 4433L2747 4309L2417 4242Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M538 1305L498 1497L809 1551Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4258 1560L4058 1439L3939 1771Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3164 565L2971 797L3207 780Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2497 171L2206 253L2457 510Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2206 253L1938 285L2059 493Z" fill="#641112" stroke="#641112"></path>
        <path d="M3506 594L3164 565L3207 780Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M177 1952L569 2062L444 1714Z" fill="#641112" stroke="#641112"></path>
        <path d="M177 1952L296 2180L569 2062Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M412 2474L255 2412L418 2673Z" fill="#751415" stroke="#751415"></path>
        <path d="M418 2673L243 2901L519 2945Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3527 3922L3686 3818L3405 3762Z" fill="#751415" stroke="#751415"></path>
        <path d="M4556 2214L4289 2089L4276 2305Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4276 2305L4289 2089L3969 2171Z" fill="#711414" stroke="#711414"></path>
        <path d="M3726 821L3580 923L3705 1069Z" fill="#681213" stroke="#681213"></path>
        <path d="M3580 923L3519 766L3239 1075Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3982 947L3705 1069L3841 1204Z" fill="#711414" stroke="#711414"></path>
        <path d="M1824 160L1518 501L1815 497Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1815 497L1518 501L1726 785Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M1511 4180L1811 4335L1791 4081Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M655 3903L843 3789L575 3832Z" fill="#751415" stroke="#751415"></path>
        <path d="M730 3588L843 3789L978 3716Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1096 3912L1197 4086L1429 3936Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M296 2180L255 2412L412 2474Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1045 4204L1197 4086L1096 3912Z" fill="#751415" stroke="#751415"></path>
        <path d="M1511 4180L1787 4464L1811 4335Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M998 965L717 1057L769 1185Z" fill="#681213" stroke="#681213"></path>
        <path d="M769 1185L538 1305L809 1551Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M836 803L717 1057L998 965Z" fill="#711414" stroke="#711414"></path>
        <path d="M717 1057L538 1305L769 1185Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M305 1589L304 1685L444 1714Z" fill="#711414" stroke="#711414"></path>
        <path d="M4189 1251L3993 1180L4058 1439Z" fill="#641112" stroke="#641112"></path>
        <path d="M4058 1439L3993 1180L3841 1204Z" fill="#681213" stroke="#681213"></path>
        <path d="M4406 3014L4335 2778L4157 2930Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4157 2930L4335 2778L4064 2729Z" fill="#711414" stroke="#711414"></path>
        <path d="M4269 2574L4513 2432L4276 2305Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2315 4663L2575 4433L2290 4334Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2290 4334L2575 4433L2417 4242Z" fill="#681213" stroke="#681213"></path>
        <path d="M2747 4309L2952 4422L2931 4230Z" fill="#681213" stroke="#681213"></path>
        <path d="M2931 4230L3309 4284L3226 4022Z" fill="#681213" stroke="#681213"></path>
        <path d="M4564 2026L4307 1807L4289 2089Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4289 2089L4307 1807L3905 1918Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1207 493L1337 845L1493 726Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M305 1589L444 1714L498 1497Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M0 2227L0 2477L255 2412Z" fill="#711414" stroke="#711414"></path>
        <path d="M0 2477L221 2755L255 2412Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M255 2412L221 2755L418 2673Z" fill="#751415" stroke="#751415"></path>
        <path d="M506 3228L524 3590L756 3262Z" fill="#641112" stroke="#641112"></path>
        <path d="M3982 947L3726 821L3705 1069Z" fill="#751415" stroke="#751415"></path>
        <path d="M3519 766L3506 594L3207 780Z" fill="#751415" stroke="#751415"></path>
        <path d="M2206 253L2232 490L2457 510Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1518 501L1207 493L1493 726Z" fill="#681213" stroke="#681213"></path>
        <path d="M2712 283L2457 510L2728 530Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M221 2755L243 2901L418 2673Z" fill="#711414" stroke="#711414"></path>
        <path d="M4079 3701L4073 3519L3717 3563Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4050 3261L4274 3176L4157 2930Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4307 1807L4258 1560L3939 1771Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2921 320L2712 283L2928 471Z" fill="#751415" stroke="#751415"></path>
        <path d="M2928 471L2712 283L2728 530Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2921 320L2928 471L3186 287Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M538 1305L305 1589L498 1497Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M304 1685L177 1952L444 1714Z" fill="#681213" stroke="#681213"></path>
        <path d="M188 1162L305 1589L538 1305Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1045 681L836 803L998 965Z" fill="#681213" stroke="#681213"></path>
        <path d="M717 1057L506 967L538 1305Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4073 3519L4274 3176L4050 3261Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3186 287L2928 471L3164 565Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M346 3349L524 3590L506 3228Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M843 3789L1045 4204L1096 3912Z" fill="#681213" stroke="#681213"></path>
        <path d="M3726 821L3506 594L3519 766Z" fill="#681213" stroke="#681213"></path>
        <path d="M0 1998L177 1952L0 1819Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1554 4593L1787 4464L1511 4180Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1811 4335L2082 4544L1948 4254Z" fill="#751415" stroke="#751415"></path>
        <path d="M2847 4501L2747 4309L2575 4433Z" fill="#641112" stroke="#641112"></path>
        <path d="M2847 4501L2952 4422L2747 4309Z" fill="#681213" stroke="#681213"></path>
        <path d="M3527 3922L3776 3973L3686 3818Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3686 3818L4079 3701L3717 3563Z" fill="#711414" stroke="#711414"></path>
        <path d="M2082 4544L2161 4528L1948 4254Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1419 278L1207 493L1518 501Z" fill="#751415" stroke="#751415"></path>
        <path d="M1045 681L1003 563L836 803Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3228 4437L3309 4284L2931 4230Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M243 2901L346 3349L506 3228Z" fill="#711414" stroke="#711414"></path>
        <path d="M1787 4464L2082 4544L1811 4335Z" fill="#711414" stroke="#711414"></path>
        <path d="M2708 4750L2847 4501L2575 4433Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3404 4270L3776 3973L3527 3922Z" fill="#751415" stroke="#751415"></path>
        <path d="M4258 1560L4189 1251L4058 1439Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3993 1180L3982 947L3841 1204Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3726 821L3688 490L3506 594Z" fill="#681213" stroke="#681213"></path>
        <path d="M3228 4437L3404 4270L3309 4284Z" fill="#681213" stroke="#681213"></path>
        <path d="M3309 4284L3404 4270L3226 4022Z" fill="#641112" stroke="#641112"></path>
        <path d="M2315 4663L2290 4334L2161 4528Z" fill="#751415" stroke="#751415"></path>
        <path d="M2952 4422L3228 4437L2931 4230Z" fill="#751415" stroke="#751415"></path>
        <path d="M2712 283L2497 171L2457 510Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2046 0L1824 160L1938 285Z" fill="#711414" stroke="#711414"></path>
        <path d="M2506 0L2497 171L2757 0Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4321 1078L3982 947L3993 1180Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4274 3176L4406 3014L4157 2930Z" fill="#751415" stroke="#751415"></path>
        <path d="M4572 2654L4513 2432L4269 2574Z" fill="#711414" stroke="#711414"></path>
        <path d="M4577 3196L4406 3014L4274 3176Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4162 3574L4274 3176L4073 3519Z" fill="#641112" stroke="#641112"></path>
        <path d="M1207 493L1003 563L1045 681Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M655 3903L1045 4204L843 3789Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1197 4086L1219 4349L1511 4180Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4572 2654L4269 2574L4335 2778Z" fill="#641112" stroke="#641112"></path>
        <path d="M4307 1807L4528 1786L4258 1560Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4599 1326L4321 1078L4189 1251Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4079 3701L4162 3574L4073 3519Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3776 3973L4079 3701L3686 3818Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M575 3832L730 3588L524 3590Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M575 3832L843 3789L730 3588Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1334 4580L1219 4349L1191 4663Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1045 4204L1219 4349L1197 4086Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1978 4693L2315 4663L2082 4544Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M479 3937L575 3832L336 3769Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2046 0L1938 285L2206 253Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1938 285L1824 160L1815 497Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1207 493L1000 277L1003 563Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1824 160L1419 278L1518 501Z" fill="#751415" stroke="#751415"></path>
        <path d="M3506 594L3186 287L3164 565Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2757 0L2497 171L2712 283Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3403 193L3186 287L3506 594Z" fill="#641112" stroke="#641112"></path>
        <path d="M4762 2302L4556 2214L4513 2432Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4513 2432L4556 2214L4276 2305Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M564 830L717 1057L836 803Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M564 830L506 967L717 1057Z" fill="#681213" stroke="#681213"></path>
        <path d="M0 1998L0 2227L177 1952Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3007 4731L3228 4437L2952 4422Z" fill="#681213" stroke="#681213"></path>
        <path d="M3404 4270L3843 4288L3776 3973Z" fill="#711414" stroke="#711414"></path>
        <path d="M0 2227L255 2412L296 2180Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M221 2755L0 2800L243 2901Z" fill="#711414" stroke="#711414"></path>
        <path d="M243 2901L0 3301L346 3349Z" fill="#711414" stroke="#711414"></path>
        <path d="M0 2227L296 2180L177 1952Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1978 4693L2082 4544L1787 4464Z" fill="#711414" stroke="#711414"></path>
        <path d="M2082 4544L2315 4663L2161 4528Z" fill="#641112" stroke="#641112"></path>
        <path d="M2847 4501L3007 4731L2952 4422Z" fill="#751415" stroke="#751415"></path>
        <path d="M177 1952L304 1685L0 1819Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4556 2214L4564 2026L4289 2089Z" fill="#711414" stroke="#711414"></path>
        <path d="M4661 2760L4335 2778L4406 3014Z" fill="#641112" stroke="#641112"></path>
        <path d="M4661 2760L4572 2654L4335 2778Z" fill="#641112" stroke="#641112"></path>
        <path d="M4556 2214L4762 2302L4564 2026Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4564 2026L4528 1786L4307 1807Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M672 599L564 830L836 803Z" fill="#681213" stroke="#681213"></path>
        <path d="M672 599L836 803L1003 563Z" fill="#711414" stroke="#711414"></path>
        <path d="M2315 4663L2513 4677L2575 4433Z" fill="#711414" stroke="#711414"></path>
        <path d="M0 1819L304 1685L0 1546Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M304 1685L305 1589L0 1546Z" fill="#751415" stroke="#751415"></path>
        <path d="M0 2477L0 2800L221 2755Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M346 3349L178 3440L524 3590Z" fill="#681213" stroke="#681213"></path>
        <path d="M4022 739L3726 821L3982 947Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4022 739L3688 490L3726 821Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1334 4580L1554 4593L1511 4180Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1830 4841L1978 4693L1787 4464Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M2660 5000L2708 4750L2513 4677Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M188 1162L538 1305L506 967Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M0 2800L0 2955L243 2901Z" fill="#681213" stroke="#681213"></path>
        <path d="M0 3301L178 3440L346 3349Z" fill="#641112" stroke="#641112"></path>
        <path d="M2497 171L2155 0L2206 253Z" fill="#681213" stroke="#681213"></path>
        <path d="M1232 0L1200 260L1419 278Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2506 0L2155 0L2497 171Z" fill="#751415" stroke="#751415"></path>
        <path d="M1564 0L1419 278L1824 160Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1419 278L1200 260L1207 493Z" fill="#751415" stroke="#751415"></path>
        <path d="M4316 806L4022 739L3982 947Z" fill="#711414" stroke="#711414"></path>
        <path d="M4321 1078L3993 1180L4189 1251Z" fill="#641112" stroke="#641112"></path>
        <path d="M2757 0L2921 320L3002 0Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2757 0L2712 283L2921 320Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2155 0L2046 0L2206 253Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M336 3769L575 3832L524 3590Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M575 3832L479 3937L655 3903Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M655 3903L837 4317L1045 4204Z" fill="#751415" stroke="#751415"></path>
        <path d="M1219 4349L1334 4580L1511 4180Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M703 301L672 599L1003 563Z" fill="#751415" stroke="#751415"></path>
        <path d="M235 958L188 1162L506 967Z" fill="#641112" stroke="#641112"></path>
        <path d="M4733 3019L4661 2760L4406 3014Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4826 2569L4762 2302L4513 2432Z" fill="#641112" stroke="#641112"></path>
        <path d="M4536 1435L4189 1251L4258 1560Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M178 3440L336 3769L524 3590Z" fill="#641112" stroke="#641112"></path>
        <path d="M3228 4437L3557 4416L3404 4270Z" fill="#681213" stroke="#681213"></path>
        <path d="M4295 3963L4277 3783L4079 3701Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M3319 4672L3557 4416L3228 4437Z" fill="#681213" stroke="#681213"></path>
        <path d="M1007 4452L1219 4349L1045 4204Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1554 4593L1830 4841L1787 4464Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3002 0L2921 320L3186 287Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2439 5000L2513 4677L2315 4663Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2513 4677L2708 4750L2575 4433Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M235 958L506 967L338 664Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2046 0L1769 0L1824 160Z" fill="#751415" stroke="#751415"></path>
        <path d="M4528 1786L4536 1435L4258 1560Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4764 1581L4536 1435L4528 1786Z" fill="#751415" stroke="#751415"></path>
        <path d="M4724 1908L4528 1786L4564 2026Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4584 3416L4577 3196L4274 3176Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4584 3416L4274 3176L4162 3574Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M4277 3783L4162 3574L4079 3701Z" fill="#641112" stroke="#641112"></path>
        <path d="M468 4218L837 4317L655 3903Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M188 1162L0 1546L305 1589Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4064 4078L4079 3701L3776 3973Z" fill="#711414" stroke="#711414"></path>
        <path d="M3812 322L3403 193L3688 490Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3688 490L3403 193L3506 594Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M837 4317L1007 4452L1045 4204Z" fill="#641112" stroke="#641112"></path>
        <path d="M1063 0L1000 277L1200 260Z" fill="#751415" stroke="#751415"></path>
        <path d="M1200 260L1000 277L1207 493Z" fill="#751415" stroke="#751415"></path>
        <path d="M3843 4288L4064 4078L3776 3973Z" fill="#711414" stroke="#711414"></path>
        <path d="M3557 4416L3843 4288L3404 4270Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M2708 4750L3007 4731L2847 4501Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M3557 4416L3689 4570L3843 4288Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4826 2569L4572 2654L4661 2760Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4826 2569L4513 2432L4572 2654Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3186 0L3002 0L3186 287Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M0 2955L0 3301L243 2901Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M178 3440L0 3689L336 3769Z" fill="#751415" stroke="#751415"></path>
        <path d="M4762 2302L4724 1908L4564 2026Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1769 0L1564 0L1824 160Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M338 664L564 830L672 599Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M338 664L506 967L564 830Z" fill="#641112" stroke="#641112"></path>
        <path d="M188 1162L0 1229L0 1546Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1506 4817L1830 4841L1554 4593Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M1978 4693L2168 5000L2315 4663Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2708 4750L3072 5000L3007 4731Z" fill="#641112" stroke="#641112"></path>
        <path d="M1506 4817L1554 4593L1334 4580Z" fill="#751415" stroke="#751415"></path>
        <path d="M4550 3667L4162 3574L4277 3783Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4550 3667L4584 3416L4162 3574Z" fill="#711414" stroke="#711414"></path>
        <path d="M4577 3196L4733 3019L4406 3014Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M3007 4731L3319 4672L3228 4437Z" fill="#711414" stroke="#711414"></path>
        <path d="M0 3301L0 3424L178 3440Z" fill="#641112" stroke="#641112"></path>
        <path d="M336 3769L250 3973L479 3937Z" fill="#751415" stroke="#751415"></path>
        <path d="M4773 3191L4733 3019L4577 3196Z" fill="#751415" stroke="#751415"></path>
        <path d="M5000 1919L4757 1807L4724 1908Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4724 1908L4757 1807L4528 1786Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3403 193L3186 0L3186 287Z" fill="#641112" stroke="#641112"></path>
        <path d="M3482 0L3186 0L3403 193Z" fill="#641112" stroke="#641112"></path>
        <path d="M4764 1581L4599 1326L4536 1435Z" fill="#681213" stroke="#681213"></path>
        <path d="M4536 1435L4599 1326L4189 1251Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4077 440L3688 490L4022 739Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M5000 2654L4826 2569L4661 2760Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4762 2302L5000 2232L4724 1908Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M985 4733L1191 4663L1007 4452Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1007 4452L1191 4663L1219 4349Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4316 806L3982 947L4321 1078Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4077 440L3812 322L3688 490Z" fill="#751415" stroke="#751415"></path>
        <path d="M3843 4288L4099 4174L4064 4078Z" fill="#641112" stroke="#641112"></path>
        <path d="M4064 4078L4295 3963L4079 3701Z" fill="#751415" stroke="#751415"></path>
        <path d="M4026 4467L4099 4174L3843 4288Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4564 962L4316 806L4321 1078Z" fill="#711414" stroke="#711414"></path>
        <path d="M0 1063L0 1229L188 1162Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4099 4174L4295 3963L4064 4078Z" fill="#751415" stroke="#751415"></path>
        <path d="M1191 4663L1506 4817L1334 4580Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M434 425L338 664L672 599Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M235 958L0 1063L188 1162Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1564 0L1232 0L1419 278Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M1000 277L703 301L1003 563Z" fill="#641112" stroke="#641112"></path>
        <path d="M5000 1727L4764 1581L4757 1807Z" fill="#751415" stroke="#751415"></path>
        <path d="M4757 1807L4764 1581L4528 1786Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4599 1326L4564 962L4321 1078Z" fill="#711414" stroke="#711414"></path>
        <path d="M0 3689L250 3973L336 3769Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M479 3937L468 4218L655 3903Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M837 4317L693 4527L1007 4452Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M250 3973L468 4218L479 3937Z" fill="#751415" stroke="#751415"></path>
        <path d="M4295 3963L4550 3667L4277 3783Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4584 3416L4773 3191L4577 3196Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M790 0L703 301L1000 277Z" fill="#641112" stroke="#641112"></path>
        <path d="M3319 4672L3689 4570L3557 4416Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4818 3522L4773 3191L4584 3416Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M5000 2654L5000 2502L4826 2569Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4222 470L4077 440L4022 739Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3812 322L3482 0L3403 193Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M2015 5000L1978 4693L1830 4841Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M2015 5000L2168 5000L1978 4693Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3007 4731L3072 5000L3319 4672Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3319 4672L3582 4796L3689 4570Z" fill="#711414" stroke="#711414"></path>
        <path d="M2168 5000L2439 5000L2315 4663Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M0 726L0 1063L235 958Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M0 3424L0 3689L178 3440Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1742 5000L2015 5000L1830 4841Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4077 440L3937 292L3812 322Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M3798 0L3482 0L3812 322Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M2439 5000L2660 5000L2513 4677Z" fill="#681213" stroke="#681213"></path>
        <path d="M1232 0L1063 0L1200 260Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4702 995L4564 962L4599 1326Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4316 806L4222 470L4022 739Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M1582 5000L1742 5000L1506 4817Z" fill="#681213" stroke="#681213"></path>
        <path d="M1506 4817L1742 5000L1830 4841Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M533 4505L693 4527L837 4317Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M533 4505L837 4317L468 4218Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M533 4505L468 4218L194 4276Z" fill="#751415" stroke="#751415"></path>
        <path d="M703 301L434 425L672 599Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M338 664L0 726L235 958Z" fill="#711414" stroke="#711414"></path>
        <path d="M577 269L434 425L703 301Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M1217 5000L1506 4817L1191 4663Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M767 4719L985 4733L1007 4452Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4077 440L4263 291L3937 292Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M3937 292L3798 0L3812 322Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4495 735L4222 470L4316 806Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M790 0L577 269L703 301Z" fill="#641112" stroke="#641112"></path>
        <path d="M5000 2654L4661 2760L5000 2971Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4826 2569L5000 2502L4762 2302Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M5000 2502L5000 2232L4762 2302Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4764 1581L4780 1274L4599 1326Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M5000 1451L4780 1274L4764 1581Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4155 4530L4026 4467L4025 4717Z" fill="#751415" stroke="#751415"></path>
        <path d="M3689 4570L4026 4467L3843 4288Z" fill="#711414" stroke="#711414"></path>
        <path d="M4099 4174L4336 4195L4295 3963Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4295 3963L4485 4060L4550 3667Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M5000 2971L4661 2760L4733 3019Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M1217 5000L1582 5000L1506 4817Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4155 4530L4336 4195L4099 4174Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M5000 3328L5000 2971L4773 3191Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4834 833L4495 735L4564 962Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4564 962L4495 735L4316 806Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M5000 2232L5000 1919L4724 1908Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M2660 5000L3072 5000L2708 4750Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3423 5000L3582 4796L3319 4672Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M164 283L297 456L434 425Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M434 425L297 456L338 664Z" fill="#751415" stroke="#751415"></path>
        <path d="M194 4276L468 4218L250 3973Z" fill="#641112" stroke="#641112"></path>
        <path d="M693 4527L767 4719L1007 4452Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4336 4195L4485 4060L4295 3963Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4773 3191L5000 2971L4733 3019Z" fill="#681213" stroke="#681213"></path>
        <path d="M5000 1009L4702 995L4780 1274Z" fill="#751415" stroke="#751415"></path>
        <path d="M4780 1274L4702 995L4599 1326Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4818 3522L4584 3416L4550 3667Z" fill="#711414" stroke="#711414"></path>
        <path d="M4485 4060L4680 3785L4550 3667Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4680 3785L4818 3522L4550 3667Z" fill="#641112" stroke="#641112"></path>
        <path d="M5000 1919L5000 1727L4757 1807Z" fill="#711414" stroke="#711414"></path>
        <path d="M560 4817L767 4719L693 4527Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M985 4733L1217 5000L1191 4663Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M1063 0L790 0L1000 277Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M577 269L164 283L434 425Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4263 291L4077 440L4222 470Z" fill="#711414" stroke="#711414"></path>
        <path d="M4088 0L3798 0L3937 292Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M0 447L0 726L297 456Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M297 456L0 726L338 664Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3072 5000L3310 5000L3319 4672Z" fill="#641112" stroke="#641112"></path>
        <path d="M4025 4717L4026 4467L3689 4570Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M0 4093L194 4276L250 3973Z" fill="#641112" stroke="#641112"></path>
        <path d="M847 5000L985 4733L767 4719Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M922 5000L1217 5000L985 4733Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3800 4837L3689 4570L3582 4796Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M4026 4467L4155 4530L4099 4174Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4547 4313L4656 4080L4485 4060Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4485 4060L4656 4080L4680 3785Z" fill="#711414" stroke="#711414"></path>
        <path d="M5000 1727L5000 1451L4764 1581Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M4448 298L4263 291L4222 470Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M4448 298L4222 470L4545 404Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M4834 833L4564 962L4702 995Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M3310 5000L3423 5000L3319 4672Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M0 3689L0 4093L250 3973Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4818 3522L5000 3328L4773 3191Z" fill="#641112" stroke="#641112"></path>
        <path d="M5000 3515L5000 3328L4818 3522Z" fill="#681213" stroke="#681213"></path>
        <path d="M3679 5000L3800 4837L3582 4796Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4547 4313L4485 4060L4336 4195Z" fill="#751415" stroke="#751415"></path>
        <path d="M5000 3734L5000 3515L4818 3522Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M3800 4837L4025 4717L3689 4570Z" fill="#681213" stroke="#681213"></path>
        <path d="M4448 4552L4547 4313L4336 4195Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M5000 1451L5000 1240L4780 1274Z" fill="#751415" stroke="#751415"></path>
        <path d="M5000 3734L4818 3522L4680 3785Z" fill="#641112" stroke="#641112"></path>
        <path d="M560 4817L693 4527L533 4505Z" fill="#641112" stroke="#641112"></path>
        <path d="M847 5000L922 5000L985 4733Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M560 4817L533 4505L160 4593Z" fill="#681213" stroke="#681213"></path>
        <path d="M5000 1009L4834 833L4702 995Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4205 0L4088 0L4263 291Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4263 291L4088 0L3937 292Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M3423 5000L3679 5000L3582 4796Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M3800 4837L3904 5000L4025 4717Z" fill="#751415" stroke="#751415"></path>
        <path d="M4448 4552L4336 4195L4155 4530Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M5000 3934L5000 3734L4680 3785Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4448 4552L4155 4530L4178 4813Z" fill="#711414" stroke="#711414"></path>
        <path d="M412 0L164 283L577 269Z" fill="#681213" stroke="#681213"></path>
        <path d="M0 4093L0 4271L194 4276Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M4545 404L4222 470L4495 735Z" fill="#751415" stroke="#751415"></path>
        <path d="M4788 497L4545 404L4495 735Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M164 283L0 447L297 456Z" fill="#681213" stroke="#681213"></path>
        <path d="M160 4593L533 4505L194 4276Z" fill="#711414" stroke="#711414"></path>
        <path d="M560 4817L847 5000L767 4719Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M790 0L412 0L577 269Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M164 283L0 302L0 447Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M5000 1240L5000 1009L4780 1274Z" fill="#751415" stroke="#751415"></path>
        <path d="M4834 833L4788 497L4495 735Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M5000 3934L4680 3785L4656 4080Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4472 0L4263 291L4448 298Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4472 0L4205 0L4263 291Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4178 4813L4155 4530L4025 4717Z" fill="#681213" stroke="#681213"></path>
        <path d="M4827 4233L5000 3934L4656 4080Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M0 4271L160 4593L194 4276Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M560 4817L581 5000L847 5000Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M0 0L0 302L164 283Z" fill="#711414" stroke="#711414"></path>
        <path d="M3679 5000L3904 5000L3800 4837Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M3904 5000L4178 4813L4025 4717Z" fill="#711414" stroke="#711414"></path>
        <path d="M5000 736L4788 497L4834 833Z" fill="#751415" stroke="#751415"></path>
        <path d="M4545 404L4665 275L4448 298Z" fill="#711414" stroke="#711414"></path>
        <path d="M4827 4233L4656 4080L4547 4313Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M175 5000L581 5000L560 4817Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4761 4506L4827 4233L4547 4313Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M4761 4506L4547 4313L4448 4552Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M5000 1009L5000 736L4834 833Z" fill="#751415" stroke="#751415"></path>
        <path d="M5000 236L4665 275L4788 497Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4788 497L4665 275L4545 404Z" fill="#7e1616" stroke="#7e1616"></path>
        <path d="M0 4271L0 4581L160 4593Z" fill="#751415" stroke="#751415"></path>
        <path d="M160 4593L175 4804L560 4817Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M412 0L158 0L164 283Z" fill="#641112" stroke="#641112"></path>
        <path d="M4665 275L4472 0L4448 298Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M4707 0L4472 0L4665 275Z" fill="#681213" stroke="#681213"></path>
        <path d="M0 4581L175 4804L160 4593Z" fill="#641112" stroke="#641112"></path>
        <path d="M3904 5000L4188 5000L4178 4813Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4178 4813L4580 4800L4448 4552Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M4580 4800L4761 4506L4448 4552Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M4827 4233L5000 4290L5000 3934Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M158 0L0 0L164 283Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M5000 4469L5000 4290L4827 4233Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4443 5000L4580 4800L4178 4813Z" fill="#570f0f" stroke="#570f0f"></path>
        <path d="M4761 4506L5000 4469L4827 4233Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M5000 736L5000 411L4788 497Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M0 4581L0 4843L175 4804Z" fill="#641112" stroke="#641112"></path>
        <path d="M175 4804L175 5000L560 4817Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M0 4843L175 5000L175 4804Z" fill="#5b1010" stroke="#5b1010"></path>
        <path d="M5000 236L4707 0L4665 275Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4188 5000L4443 5000L4178 4813Z" fill="#530e0e" stroke="#530e0e"></path>
        <path d="M5000 4662L5000 4469L4761 4506Z" fill="#5f1011" stroke="#5f1011"></path>
        <path d="M4839 4721L4761 4506L4580 4800Z" fill="#641112" stroke="#641112"></path>
        <path d="M5000 411L5000 236L4788 497Z" fill="#641112" stroke="#641112"></path>
        <path d="M4769 5000L4839 4721L4580 4800Z" fill="#711414" stroke="#711414"></path>
        <path d="M0 4843L0 5000L175 5000Z" fill="#7a1515" stroke="#7a1515"></path>
        <path d="M4839 4721L5000 4662L4761 4506Z" fill="#751415" stroke="#751415"></path>
        <path d="M5000 5000L5000 4662L4839 4721Z" fill="#681213" stroke="#681213"></path>
        <path d="M5000 236L5000 0L4707 0Z" fill="#6c1314" stroke="#6c1314"></path>
        <path d="M4443 5000L4769 5000L4580 4800Z" fill="#711414" stroke="#711414"></path>
        <path d="M4769 5000L5000 5000L4839 4721Z" fill="#5f1011" stroke="#5f1011"></path>
      </g>
    </svg>
  );
};
