import { useCallback, useEffect, useRef, useState } from "react";
import { IoReorderThree as ReorderIcon } from "react-icons/io5";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { FiPlus } from "react-icons/fi";
import InputField from "../../elements/InputField";
import CoursableIcons from "../../../utils/CoursableIcons";

interface DragItem {
  topic: Course.Topic;
  index: number;
}

interface CourseEditingSidemenuProps {
  course: Course;
  setCourse: (c: Course) => void;
  selectedTopicIndex: number | null;
  setSelectedTopicIndex: (n: number | null) => void;
}

const CourseEditingSidemenu = ({ course, setCourse, selectedTopicIndex, setSelectedTopicIndex }: CourseEditingSidemenuProps) => {
  const [topics, setTopics] = useState(course.topics);

  function UpdateCourseTitle(value: string) {
    const newCourse = { ...course };
    newCourse.title = value;
    setCourse(newCourse);
  }

  function UpdateTopics(newTopics: Course.Topic[]) {
    const newCourse = { ...course };
    newCourse.topics = newTopics;
    setCourse(newCourse);
  }

  function AddTopic() {
    const newTopic: Course.Topic = {
      title: "",
      content: "",
      description: "",
      isCompleted: false,
      materials: [],
    };
    const newTopics = [...topics, newTopic];
    UpdateTopics(newTopics);
    setTimeout(() => {
      setSelectedTopicIndex(newTopics.length - 1);
    }, 100);
  }

  const moveTopic = useCallback((dragIndex: number, hoverIndex: number) => {
    setTopics((prevTopic) => {
      const newTopics = [...prevTopic];
      const moveTopic = newTopics[dragIndex];
      newTopics.splice(dragIndex, 1);
      newTopics.splice(hoverIndex, 0, moveTopic);
      return newTopics;
    });
  }, []);

  useEffect(() => {
    setTopics(course.topics);
  }, [course]);

  return (
    <div onClick={() => setSelectedTopicIndex(null)} className="flex flex-col gap-4 w-full border-r p-4 overflow-auto">
      <InputField className="font-semibold text-lg" size="lg" value={course.title} onValueChange={UpdateCourseTitle} placeholder="Course title" icon={CoursableIcons.Edit()} />
      <div className="flex flex-col gap-2 w-full">
        {topics.map((topic, index) => (
          <SidemenuTopic key={index} topic={topic} index={index} selectedIndex={selectedTopicIndex} setSelectedIndex={setSelectedTopicIndex} moveTopic={moveTopic} UpdateTopics={() => UpdateTopics(topics)} />
        ))}
        <button onClick={AddTopic} className="flex gap-1 items-center px-2 text-systemGray-500 hover:text-foreground active:text-systemGray-600 duration-100">
          <FiPlus />
          Add topic
        </button>
      </div>
    </div>
  );
};

export default CourseEditingSidemenu;

interface SidemenuTopicProps {
  topic: Course.Topic;
  index: number;
  selectedIndex: number | null;
  setSelectedIndex: (n: number | null) => void;
  moveTopic: (dragIndex: number, hoverIndex: number) => void;
  UpdateTopics: () => void;
}

const SidemenuTopic = ({ topic, index, selectedIndex, setSelectedIndex, moveTopic, UpdateTopics }: SidemenuTopicProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "topic",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveTopic(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      UpdateTopics();
    },
  });

  const [{ isDragging, originalDragging }, drag] = useDrag({
    type: "topic",
    item: () => {
      setSelectedIndex(null);
      return { topic, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.getItem()?.index === index,
      originalDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const isSelected = index === selectedIndex;

  function SelectTopic(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    setSelectedIndex(index);
  }

  return (
    <button
      ref={ref}
      data-handler-id={handlerId}
      onClick={SelectTopic}
      className={`flex gap-2 items-center rounded-lg border p-2 ${
        isDragging ? "border-dashed border-systemGray-500 opacity-50" : isSelected ? "bg-brand-25 dark:bg-brand-100 border-brand-500 shadow" : `border-transparent ${!originalDragging && "hover:bg-systemGray-100"}`
      } duration-100`}
    >
      <div className="flex flex-col gap-0 items-start justify-center w-full">
        <div className="text-brand-500 text-sm">Topic {index + 1}</div>
        <div className={`text-lg text-left font-semibold ${topic.title === "" && "text-systemGray-400"}`}>{topic.title || `Empty title`}</div>
      </div>
      <ReorderIcon className="text-systemGray-300 text-3xl" />
    </button>
  );
};
