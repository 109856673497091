import React, { ButtonHTMLAttributes, HTMLAttributes, useEffect } from "react";
import { ReactNode } from "react";
import { cn } from "../../utils/UtilityMethods";
import { cva, VariantProps } from "class-variance-authority";

interface SegmentedPickerProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof pickerVariants> {
  onSelectSegment?: (i: number) => void;
  disabled?: boolean;
}

export const pickerVariants = cva("grid gap-2 rounded-md transition-colors shrink-0 group relative", {
  variants: {
    variant: {
      default: "bg-brand-500 shadow-lg shadow-brand-500/20 dark:shadow-brand-500/30",
      secondary: "bg-systemGray-300 shadow-lg shadow-systemGray-500/20",
      outline: "border border-systemGray-300 bg-systemGray-100 shadow-sm hover:shadow ",
      inverse: "bg-brand-50 dark:bg-brand-100/80",
      ghost: "bg-transparent hover:bg-systemGray-100",
    },
    size: {
      sm: "text-sm h-8 p-1",
      default: "text-sm h-9 p-1",
      lg: "text-base h-10 p-1.5",
    },
  },
  defaultVariants: {
    variant: "outline",
    size: "default",
  },
});

export const SegmentedPicker = ({ children, variant, size, onSelectSegment, disabled, className }: SegmentedPickerProps) => {
  const [selected, setSelected] = React.useState<number>(0);

  const nChildren = React.Children.count(children);

  const renderedChildren = React.Children.map(children, (child, index) => {
    return React.cloneElement(child as any, {
      selected: selected === index,
      onClick: () => setSelected(index),
      variant: variant ?? "outline",
      size: size ?? "default",
    });
  });

  useEffect(() => {
    onSelectSegment?.(selected);
  }, [selected]);

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${nChildren}, 1fr)`,
      }}
      className={cn(pickerVariants({ variant, size, className }), disabled && "opacity-50 pointer-events-none shadow-none")}
    >
      {renderedChildren ?? <SegmentedPickerItem disabled>Empty</SegmentedPickerItem>}
      {nChildren > 0 && (
        <div
          style={{
            width: `calc((100% - 0.5rem - ${(nChildren - 1) * 0.5}rem) / ${nChildren})`,
            transform: `translateX(calc(${selected * 100}% + ${selected * 0.5}rem))`,
          }}
          className={cn(
            "absolute top-1 bottom-1 left-1 rounded z-0 shadow duration-300 opacity-100 bg-background",
            variant === "inverse" && "bg-brand-500",
            variant === "ghost" && "bg-systemGray-100 shadow-none group-hover:shadow group-hover:bg-background"
          )}
        />
      )}
    </div>
  );
};

interface SegmentedPickerItemProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof pickerVariants> {
  selected?: boolean;
  children?: ReactNode;
  className?: string;
  onSelect?: () => void;
}

export const SegmentedPickerItem = ({ selected, children, variant, size, className, onSelect, ...props }: SegmentedPickerItemProps) => {
  useEffect(() => {
    if (selected) onSelect?.();
  }, [selected]);

  return (
    <button
      className={cn(
        "text-foreground stroke-foreground inline-flex items-center justify-center gap-2 whitespace-nowrap px-4 h-full font-medium transition-colors z-1 disabled:opacity-50 disabled:pointer-events-none select-none",
        variant === "default" && !selected && "text-white stroke-white",
        variant === "inverse" && (selected ? "text-white stroke-white" : "text-brand-500 stroke-brand-500"),
        !selected && "hover:opacity-80",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};
