import { useEffect, useState } from "react";
import { ProjectQuiz } from "../../../backend/Projects/types";
import { useNotifications } from "../../../utils/NotificationsContext";
import Button from "../../elements/Button";
import { cn } from "../../../utils/UtilityMethods";
import CoursableIcons from "../../../utils/CoursableIcons";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { ContextMenuButton, ContextMenuDivider } from "../../elements/DropdownMenu/ContextMenu";
import exportFromJSON from "export-from-json";
import { PublicProjectsPageTemplate, PublicProjectsHeader, PublicProjectsPageState } from "./Shared";
import { QuizView } from "../Projects/Workspace/Quizzes/ProjectQuizzes";
import ProjectQuizzesPractice from "../Projects/Workspace/Quizzes/ProjectQuizzesPractice";
import { useParams } from "react-router-dom";
import { FetchPublicProjectQuiz } from "../../../backend/Projects/PublicProjects";

const PublicProjectsQuizPage = () => {
  const { publicID } = useParams<{ publicID: string }>();
  const [state, setState] = useState<PublicProjectsPageState>("loading");

  const [owner, setOwner] = useState<string | null>(null);
  const [quiz, setQuiz] = useState<ProjectQuiz | null>(null);
  const [revealAll, setRevealAll] = useState(false);
  const [practice, setPractice] = useState(false);

  const pageTitle = quiz ? quiz.name : undefined;
  const pageDescription = quiz ? `Quiz generated in Coursable by ${owner}. Chat with AI about your existing study materials to pull insights, generate flashcards, answer questions, and much more.` : undefined;

  async function LoadQuiz() {
    if (!publicID) return;

    setState("loading");
    try {
      const { derivative, ownerName } = await FetchPublicProjectQuiz(publicID);
      setQuiz(derivative);
      setOwner(ownerName ?? null);
      setState("loaded");
    } catch (error: any) {
      if (error.code === 404) setState("not-found");
      else setState("error");
    }
  }

  useEffect(() => {
    LoadQuiz();
  }, []);

  return (
    <PublicProjectsPageTemplate
      type="quiz"
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      state={state}
      header={quiz && <QuizHeader quiz={quiz} owner={owner} revealAll={revealAll} setRevealAll={setRevealAll} OpenPractice={() => setPractice(true)} />}
      content={
        quiz && (
          <div className="w-full max-w-5xl">
            <QuizView quiz={quiz} reveal={revealAll} />
          </div>
        )
      }
      popup={quiz && <ProjectQuizzesPractice quiz={quiz} isOpen={practice} setIsOpen={setPractice} />}
    />
  );
};

export default PublicProjectsQuizPage;

const QuizHeader = ({ quiz, revealAll, setRevealAll, OpenPractice, owner }: { quiz: ProjectQuiz; revealAll: boolean; setRevealAll: (b: boolean) => void; OpenPractice: () => void; owner: string | null }) => {
  function Download(as: "json" | "xml") {
    const fileName = quiz.name;
    const exportType = as;

    const dataToDownload = {
      name: quiz.name,
      flashcards: quiz.multipleChoice,
    };

    exportFromJSON({ data: dataToDownload, fileName, exportType });
  }

  return (
    <PublicProjectsHeader
      type="quiz"
      derivative={quiz}
      header={`Quiz${owner !== null ? ` generated by ${owner}` : ""}`}
      title={quiz.name}
      buttons={
        <>
          <Dropdown
            label={
              <>
                {CoursableIcons.Download()} <span className="hidden @md:inline">Download</span>
              </>
            }
            chevron={false}
            className="@md:w-auto h-9 w-9"
            variant="outline"
          >
            <ContextMenuButton label="PDF" icon={CoursableIcons.PdfDoc("text-red-500")} onClick={() => window.open(`/public/quiz/${quiz.publicID}/pdf`, "_blank")} />
            <ContextMenuDivider />
            <ContextMenuButton label="JSON" icon={CoursableIcons.JsonDoc("text-purple-500")} onClick={() => Download("json")} />
            <ContextMenuButton label="XML" icon={CoursableIcons.XmlDoc("text-orange-500")} onClick={() => Download("xml")} />
          </Dropdown>
          <Button onClick={() => setRevealAll(!revealAll)} variant="outline" className="relative @md:w-auto h-9 w-9">
            {revealAll ? CoursableIcons.EyeSlash() : CoursableIcons.Eye()}
            <span className={cn("hidden @md:inline duration-100", !revealAll ? "opacity-100 scale-100" : "opacity-0 scale-0")}>Reveal</span>
            <span className={cn("hidden @md:inline duration-100 absolute right-6", revealAll ? "opacity-100 scale-100" : "opacity-0 scale-0")}>Hide</span>
          </Button>
          <Button className="@md:w-auto h-9 w-9" onClick={OpenPractice}>
            {CoursableIcons.Flashcard()} <span className="hidden @md:inline">Practice</span>
          </Button>
        </>
      }
    />
  );
};
