import { useEffect, useRef, useState } from "react";
import { CoursableProject, FlashcardDifficulty, ProjectFile, ProjectFlashcardSet } from "../../../../backend/Projects/types";
import { Unsubscribe } from "firebase/firestore";
import { DeleteFlashcardSet, FetchFlashcardSets, GenerateFlashcardSet, RenameFlashcardSet, SaveFlashcards } from "../../../../backend/Projects/ProjectFlashcards";
import { SubscribeToProjectChanges } from "../../../../backend/Projects/ProjectBase";

const useProjectFlashcards = (project: CoursableProject | undefined, setProject: React.Dispatch<React.SetStateAction<CoursableProject | undefined>>) => {
  const [flashcards, setFlashcards] = useState<ProjectFlashcardSet[]>([]);

  const flashcardsGenerationListener = useRef<Unsubscribe | undefined>(undefined);
  const initializingProjectID = useRef<string | undefined>(undefined);

  async function Initialize(project: CoursableProject) {
    setFlashcards([]);

    initializingProjectID.current = project.id;
    const flashcards = await FetchFlashcardSets(project.id);
    if (initializingProjectID.current !== project.id) return;

    setFlashcards(flashcards);

    if (project.isGeneratingFlashcards) {
      flashcardsGenerationListener.current?.();
      flashcardsGenerationListener.current = SubscribeToProjectChanges(project.id, async (project) => {
        if (project.isGeneratingFlashcards) return;
        flashcardsGenerationListener.current?.();
        setProject(project);
        const flashcards = await FetchFlashcardSets(project.id);
        setFlashcards(flashcards);
      });
    }
  }

  async function Generate(files: ProjectFile[], prompt: string, difficulties: FlashcardDifficulty[], flashcardSetID?: string) {
    if (!project) throw new Error("Project not set");

    SetIsGenerating(true);
    const flashcardSet = await GenerateFlashcardSet(project.id, files, prompt, difficulties, flashcardSetID);
    const newArray = flashcards.some((s) => s.id === flashcardSet.id) ? flashcards.map((s) => (s.id === flashcardSet.id ? flashcardSet : s)) : [flashcardSet, ...flashcards];
    setFlashcards(newArray);
    SetIsGenerating(false);
    return flashcardSet;
  }

  async function SaveName(flashcardSetID: string, name: string) {
    if (!project) return;

    await RenameFlashcardSet(project.id, flashcardSetID, name);
    setFlashcards((prev) => prev.map((s) => (s.id === flashcardSetID ? { ...s, name } : s)));
  }

  async function SaveSet(flashcardSet: ProjectFlashcardSet) {
    if (!project) return;

    setFlashcards((prev) => prev.map((s) => (s.id === flashcardSet.id ? flashcardSet : s)));
    await SaveFlashcards(project.id, flashcardSet);
  }

  async function Delete(flashcardSetID: string) {
    if (!project) return;

    await DeleteFlashcardSet(project.id, flashcardSetID);
    setFlashcards((prev) => prev.filter((s) => s.id !== flashcardSetID));
  }

  function SetPublicID(flashcardSetID: string, publicID: string | undefined) {
    setFlashcards((prev) => prev.map((s) => (s.id === flashcardSetID ? { ...s, publicID } : s)));
  }

  function SetIsGenerating(isGenerating: boolean) {
    setProject((prev) => prev && { ...prev, isGeneratingFlashcards: isGenerating });
  }

  useEffect(() => {
    return () => {
      flashcardsGenerationListener.current?.();
    };
  }, [project?.id]);

  return { flashcards, Initialize, Generate, SaveName, SaveSet, Delete, SetPublicID, SetIsGenerating };
};

export default useProjectFlashcards;
