import { useState } from "react";
import { Flashcard } from "../../../../../backend/Projects/types";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { cn } from "../../../../../utils/UtilityMethods";
import Badge from "../../../../elements/Badge";
import "../../../../../utils/StringExtensions";

const flashcards: FlashcardWithDifficulty[] = [
  {
    question: "What measures inflation rate?",
    answer: "Consumer Price Index",
    difficulty: "medium",
  },
  {
    question: "What encodes genetic information?",
    answer: "DNA",
    difficulty: "easy",
  },
  {
    question: `Who wrote "Pride and Prejudice"?`,
    answer: "Jane Austen",
    difficulty: "medium",
  },
  {
    question: "Who painted the Mona Lisa?",
    answer: "Leonardo da Vinci",
    difficulty: "easy",
  },
  {
    question: "Define gross domestic product.",
    answer: "Total value of goods, services produced",
    difficulty: "hard",
  },
];

const FlashcardsGraphic = () => {
  const [index, setIndex] = useState<number>(Math.round(Math.random() * (flashcards.length - 1)));
  const [power, setPower] = useState<number>(0.9);

  const getCoeff = (i: number) => Math.pow(flashcards.length + 1 - i, power);

  return (
    <div className="w-full h-full flex justify-end py-24 md:py-0">
      <div
        onMouseEnter={() => {
          setPower(1.4);
        }}
        onMouseLeave={() => {
          setPower(0.9);
        }}
        className="w-full md:w-5/6 h-full flex relative"
      >
        {flashcards.map((flashcard, i) => (
          <FlashcardView
            style={{
              transform: `rotate(${getCoeff(i) * 10 - 10}deg)`,
            }}
            className={"absolute inset-0 w-auto shadow-md active:inset-2"}
            key={i}
            flashcard={flashcard}
            OnClick={() => setIndex(i)}
            index={flashcards.length - i}
            highlighted={power > 1}
          />
        ))}
        <FlashcardView flashcard={flashcards[index]} index={flashcards.length - index} highlighted />
      </div>
    </div>
  );
};

export default FlashcardsGraphic;

interface FlashcardWithDifficulty extends Flashcard {
  difficulty: "easy" | "medium" | "hard";
}

interface FlashcardViewProps {
  style?: React.CSSProperties;
  flashcard: FlashcardWithDifficulty;
  index: number;
  highlighted: boolean;
  className?: string;
  OnClick?: () => void;
}

const FlashcardView = ({ style, flashcard, className, highlighted, OnClick, index }: FlashcardViewProps) => {
  const [reveal, setReveal] = useState<boolean>(false);

  function Reveal(b: boolean) {
    OnClick?.();
    setReveal(b);
  }

  return (
    <div
      style={style}
      onClick={() => Reveal(!reveal)}
      className={cn(`w-full h-48 bg-background flex-centered rounded-2xl p-4 @md:p-10 shadow-lg-c border-2 border-systemGray-200 hover:border-brand-500 duration-300 group cursor-pointer relative`, className)}
    >
      <div className={cn("text-lg md:text-xl ease-out-back select-none duration-300 text-center text-foreground", reveal && "-translate-y-10 opacity-30 scale-60")}>{flashcard.question}</div>
      <div className={cn("absolute bottom-8 text-systemGray-300 flex-centered text-sm gap-1 duration-300 select-none", reveal && "opacity-0")}>{CoursableIcons.Eye("text-base")} Click</div>
      <div className={cn("absolute flex-centered font-semibold top-4 bottom-0 left-0 right-0 text-xl md:text-2xl text-center ease-out-back select-none duration-300 text-foreground", reveal ? "scale-100 opacity-100" : "scale-90 opacity-0")}>
        {flashcard.answer}
      </div>
      <Badge className={cn("absolute bottom-2 right-2 opacity-50", highlighted && "opacity-100")} variant="outline">
        {index}
      </Badge>
      <Badge variant={flashcard.difficulty === "easy" ? "green" : flashcard.difficulty === "medium" ? "yellow" : "inverse"} className={cn("absolute top-2 left-2 opacity-50", highlighted && "opacity-100")}>
        {flashcard.difficulty.capitalize()}
      </Badge>
    </div>
  );
};
