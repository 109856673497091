import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import ProjectChat from "./Chat/ProjectChat";
import { useAuth } from "../../../firebase/AuthContext";
import { useNotifications } from "../../../utils/NotificationsContext";
import useResizeHorizontal from "../../../utils/Hooks/useResizeHorizontal";
import WorkspaceView from "./Workspace/WorkspaceView";
import { CollapseButton, ProjectsContext } from "./ProjectsPage";
import NoProjectView from "./NoProjectView";
import LoadingIndicator from "../../elements/LoadingIndicator";
import CoursableIcons from "../../../utils/CoursableIcons";
import Button from "../../elements/Button";
import CreativitySelector from "./CreativitySelector";
import { useProjectManager } from "./Hooks/useProjectManager";
import { Flashcard } from "../../../backend/Projects/types";
import { MessageAttachment, useAttachments } from "./Chat/MessageAttachments";
import { cn } from "../../../utils/UtilityMethods";

const ProjectView = () => {
  const { id } = useParams();

  const projectsContext = useContext(ProjectsContext);
  const { currentUser } = useAuth();
  const { sendError } = useNotifications();
  const navigate = useNavigate();

  const [collapsedWorkspace, setCollapsedWorkspace] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);
  const loadingProjectID = useRef<string | undefined>(undefined);

  const { InitializeProject, Messages } = useProjectManager();

  const { rightWidth, setRightWidth, DragHandle, isResizing } = useResizeHorizontal();

  async function Initialize() {
    setLoading(true);
    try {
      loadingProjectID.current = id;
      await InitializeProject();
      if (loadingProjectID.current !== id) return;
    } catch (error: any) {
      if (error.name === "AbortError" || error.isCanceled) return;
      console.log(error);
      sendError();
    }
    setLoading(false);
  }

  function MobileBackClick() {
    if (!collapsedWorkspace) return setCollapsedWorkspace(true);
    navigate("..");
  }

  useEffect(() => {
    Initialize();
  }, [id]);

  useEffect(() => {
    setCollapsedWorkspace(projectsContext.isMobile || projectsContext.pageLayout.workspaceCollapsed);
    if (projectsContext.pageLayout.workspaceWidth) {
      setRightWidth(projectsContext.pageLayout.workspaceWidth);
    } else if (projectsContext.pageLayout.workspaceRatio) {
      setRightWidth(projectsContext.pageLayout.workspaceRatio * window.innerWidth);
    }
  }, [projectsContext.pageLayout]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (!id) {
    return <NoProjectView />;
  }

  return (
    <div
      key={id}
      style={{
        gridTemplateColumns: `auto ${collapsedWorkspace ? `0px 0px` : `2px clamp(20%, ${rightWidth}px, 80%)`}`,
        transition: isResizing ? "" : "grid-template-columns 300ms",
      }}
      className={`w-full ${projectsContext.isMobile ? "flex flex-col" : "grid"} overflow-y-auto overflow-x-clip justify-items-center bg-systemGray-100 relative`}
    >
      {projectsContext.isMobile && (
        <div className={`w-full flex items-center justify-between px-2 py-1 text-sm ${collapsedWorkspace ? "bg-transparent" : "bg-background"}`}>
          <button onClick={MobileBackClick} className="flex-centered gap-1 text-brand-500 hover:text-brand-600 active:text-brand-400 duration-100">
            {CoursableIcons.Chevron("left")} {collapsedWorkspace ? "Projects" : "Back"}
          </button>
          {collapsedWorkspace && (
            <button onClick={() => setCollapsedWorkspace(false)} className="flex-centered gap-1 text-brand-500 hover:text-brand-600 active:text-brand-400 duration-100">
              Workspace {CoursableIcons.Chevron("right")}
            </button>
          )}
        </div>
      )}
      {(!projectsContext.isMobile || collapsedWorkspace) && (
        <div className="flex flex-col items-center overflow-auto w-full h-full relative group">
          {!projectsContext.isMobile && <CollapseButton collapsed={collapsedWorkspace} setCollapsed={setCollapsedWorkspace} collapseDir="right" className={`absolute top-1/2 -translate-y-1/2 right-0`} />}
          <div className="flex flex-col h-full max-w-5xl overflow-auto w-full px-4 pb-4">
            {loading ? (
              <div className="w-full h-full flex items-center justify-center gap-2 text-systemGray-400 animate-pulse">
                <LoadingIndicator className="stroke-systemGray-400" /> Loading Project
              </div>
            ) : (
              <>
                <ProjectChat />
                <Chatbox />
              </>
            )}
          </div>
          <Button disabled={Messages.resettingChat} onClick={Messages.ResetChat} variant="outline" size="sm" className="absolute top-4 right-4 opacity-50 group-hover:opacity-100">
            Reset {CoursableIcons.Reset(Messages.resettingChat ? "animate-spin direction-reverse" : "")}
          </Button>
          <CreativitySelector className="absolute top-4" onChange={(level) => (Messages.creativityLevel.current = level)} />
        </div>
      )}
      {projectsContext.isMobile ? <></> : collapsedWorkspace ? <div /> : DragHandle}
      {(!projectsContext.isMobile || !collapsedWorkspace) && <WorkspaceView collapsed={collapsedWorkspace} />}
    </div>
  );
};

export default ProjectView;

const Chatbox = () => {
  const { project, Messages, ChatInput } = useProjectManager();
  const generating = project?.isGenerating ?? false;
  const streaming = !!Messages.streamingMessageID;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [hover, setHover] = useState<boolean>(false);

  const focusClasses = `focus-within:border-brand-500 focus-within:shadow-xl-c ${generating && !streaming && "animate-pulse"}`;
  const bgClasses = {
    default: `border-systemGray-300 shadow-lg-c ${focusClasses}`,
    hover: `border-brand-300 shadow-lg-c ${focusClasses}`,
  };

  useAutosizeTextArea(textareaRef.current, ChatInput.input);

  function HandleInput(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === "Enter" && !e.shiftKey && !generating) {
      e.preventDefault();
      ChatInput.Send();
    }
  }

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={`bg-background w-full flex-started flex-col p-4 rounded-xl border transition-colors ${hover ? bgClasses.hover : bgClasses.default} @container`}>
      <MessageAttachments attachments={ChatInput.attachments} />
      <div className="w-full flex-centered gap-2">
        <textarea
          onKeyDown={HandleInput}
          ref={textareaRef}
          className="w-full h-6 resize-none outline-none peer bg-transparent text-sm @sm:text-base placeholder:text-systemGray-500"
          placeholder="Ask AI something..."
          value={ChatInput.input}
          onChange={(e) => ChatInput.setInput(e.target.value)}
        />
        <button
          disabled={ChatInput.input.length === 0 && !streaming}
          onClick={() => {
            if (streaming) Messages.StopStream();
            else ChatInput.Send();
          }}
          className="text-foreground hover:text-brand-500 active:text-brand-400 peer-focus:hover:text-brand-500 peer-focus:active:text-brand-400 duration-100 disabled:opacity-50 disabled:pointer-events-none"
        >
          {streaming ? CoursableIcons.Stop("text-xl") : CoursableIcons.Send("text-xl")}
        </button>
      </div>
    </div>
  );
};

const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
  const maxHeight = 200;
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      if (scrollHeight > maxHeight) {
        textAreaRef.style.overflowY = "scroll";
        textAreaRef.style.height = maxHeight + "px";
      } else {
        textAreaRef.style.overflowY = "hidden";
        textAreaRef.style.height = scrollHeight + "px";
      }
    }
  }, [textAreaRef, value]);
};

const MessageAttachments = ({ attachments }: { attachments: MessageAttachment[] }) => {
  const { ChatInput } = useProjectManager();
  if (attachments.length === 0) return null;

  return (
    <div className="flex gap-2 pb-4 flex-wrap">
      {attachments.map((attachment, index) => (
        <Attachment key={index} attachment={attachment} className="max-w-[250px]" Remove={() => ChatInput.RemoveAttachment(index)} />
      ))}
    </div>
  );
};

const Attachment = ({ attachment, className, Remove }: { attachment: MessageAttachment; className?: string; Remove: () => void }) => {
  return (
    <div className={cn("flex-centered gap-2 bg-background rounded-md border px-2 py-1 shadow group/attachment select-none", className)}>
      {attachment.type === "Flashcard" ? CoursableIcons.Flashcard("text-brand-500 text-sm") : CoursableIcons.Quizzes("text-brand-500 text-sm")}
      <div className="truncate text-systemGray-700 text-mini">{attachment.type === "Flashcard" ? attachment.content.question : attachment.content.question}</div>
      <button onClick={Remove} className="text-sm text-systemGray-500 hover:text-foreground active:text-systemGray-700 duration-100 opacity-0 w-0 group-hover/attachment:opacity-100 group-hover/attachment:w-4">
        {CoursableIcons.Xmark()}
      </button>
    </div>
  );
};
