import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { useAuth } from "../../../firebase/AuthContext";
import { CoursablePlan, CoursablePlanInterval } from "../../../utils/CoursablePlan";
import { useNotifications } from "../../../utils/NotificationsContext";
import { BuyPlan, CoursableTrialStatus, FetchTrialStatus, OpenStripePortal } from "../../../backend/Payment";
import CoursableIcons from "../../../utils/CoursableIcons";
import HomePageTemplate from "../../page-elements/HomePageTemplate";
import Switch from "../../elements/Switch";
import Button from "../../elements/Button";
import Badge from "../../elements/Badge";
import { cn } from "../../../utils/UtilityMethods";
import "../../../utils/StringExtensions";
import { FetchUserBalance } from "../../../backend/Referrals";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const PricingPage = () => {
  const [cycle, setCycle] = useState<CoursablePlanInterval>("quarter");
  const [studentDiscount, setStudentDiscount] = useState(false);

  const { currentUser } = useAuth();
  const [isEduEmail, setIsEduEmail] = useState<boolean | undefined>(undefined);

  function CheckEDU() {
    if (!currentUser || !currentUser.email) return setIsEduEmail(undefined);

    const emailDomain = currentUser.email.split("@")[1];
    const isEDU = emailDomain === undefined ? false : emailDomain.endsWith(".edu") || emailDomain.includes(".edu.");
    setIsEduEmail(isEDU);
    setStudentDiscount(isEDU);
  }

  useEffect(() => {
    CheckEDU();
  }, []);

  return (
    <HomePageTemplate
      helmet={pageMetatags({
        title: "Pricing",
        description: "Coursable Plus for learners of every kind. Free trial and student discounts. An affordable plan designed to cultiave knowledge and foster learners of the digital era. Cancel any time, no questions asked.",
        canonical: "/pricing",
      })}
    >
      <div className="h-full w-full flex flex-col max-w-7xl items-center my-10 mx-auto px-4 gap-[5rem]">
        <TitleView />
        <Switches cycle={cycle} setCycle={setCycle} studentDiscount={studentDiscount} setStudentDiscount={setStudentDiscount} isEduEmail={isEduEmail} />
        <PricingCardsView cycle={cycle} studentDiscount={studentDiscount} />
        <FAQSection />
      </div>
    </HomePageTemplate>
  );
};

export default PricingPage;

const TitleView = () => {
  return (
    <div className="flex flex-col items-center gap-4 text-center">
      <h1 className="text-brand-500 font-semibold text-xl">Pricing</h1>
      <h3 className="text-4xl font-bold text-foreground">
        Coursable{" "}
        <span
          style={{
            textShadow: "0px 5px 12px rgba(209, 36, 36, 0.2)",
          }}
          className="text-brand-500"
        >
          Plus
        </span>{" "}
        for learners of every kind
      </h3>
      <p className="text-systemGray-500 max-w-xl text-lg mt-2">An affordable plan designed to cultiave knowledge and foster learners of the digital era. Cancel any time, no questions asked.</p>
    </div>
  );
};

const Switches = ({
  cycle,
  setCycle,
  studentDiscount,
  setStudentDiscount,
  isEduEmail,
}: {
  cycle: CoursablePlanInterval;
  setCycle: (cycle: CoursablePlanInterval) => void;
  studentDiscount: boolean;
  setStudentDiscount: (studentDiscount: boolean) => void;
  isEduEmail: boolean | undefined;
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 items-center justify-items-center gap-4 w-full">
      <div className="hidden md:block" />
      <CycleSwitch cycle={cycle} setCycle={setCycle} />
      <StudentDiscountSwitch studentDiscount={studentDiscount} setStudentDiscount={setStudentDiscount} isEduEmail={isEduEmail} />
    </div>
  );
};

export const CycleSwitch = ({ cycle, setCycle, showDiscount = true }: { cycle: CoursablePlanInterval; setCycle: (cycle: CoursablePlanInterval) => void; showDiscount?: boolean }) => {
  return (
    <div className="grid grid-cols-3 gap-1 p-1 rounded-full text-center ring-1 ring-systemGray-300 select-none cursor-pointer text-sm relative">
      <style>@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');</style>
      <CycleButton cycle="month" selectedCycle={cycle} setSelectedCycle={setCycle} />
      <CycleButton cycle="quarter" selectedCycle={cycle} setSelectedCycle={setCycle} />
      <CycleButton cycle="year" selectedCycle={cycle} setSelectedCycle={setCycle} />
      <div
        style={{
          width: "calc((100% - 1rem) / 3)",
          left: `calc(${cycle === "month" ? "0% + 0.25rem" : cycle === "quarter" ? "33.333% + 0.2rem" : "66.666% + 0.15rem"})`,
        }}
        className={cn("bg-brand-500 absolute top-1 bottom-1 rounded-full z-0 duration-300 shadow-md shadow-brand-100")}
      />
      {showDiscount && (
        <>
          <CurlyArrow className="absolute h-10 right-6 bottom-9 scale-x-50 -scale-y-50 rotate-[150deg] md:-right-14 md:-bottom-0 md:rotate-[-10deg] md:-scale-y-100 md:scale-x-100 pointer-events-none fill-systemGray-800" />
          <div className="absolute -right-[1.8rem] -top-11 rotate-[-20deg] md:-right-[5.4rem] md:-top-9 text-foreground pointer-events-none text-lg md:text-xl font-[Satisfy]">
            Save <span className="text-brand-500">40%</span>
          </div>
        </>
      )}
    </div>
  );
};

const CycleButton = ({ cycle, selectedCycle, setSelectedCycle }: { cycle: CoursablePlanInterval; selectedCycle: CoursablePlanInterval; setSelectedCycle: (cycle: CoursablePlanInterval) => void }) => {
  return (
    <button className={cn("py-1 px-4 rounded-full z-1 transition-colors", cycle === selectedCycle ? "text-background" : "text-foreground hover:bg-systemGray-200 active:bg-systemGray-100")} onClick={() => setSelectedCycle(cycle)}>
      {cycle === "quarter" ? "Semester" : cycle?.capitalize()}
    </button>
  );
};

const StudentDiscountSwitch = ({ studentDiscount, setStudentDiscount, isEduEmail }: { studentDiscount: boolean; setStudentDiscount: (studentDiscount: boolean) => void; isEduEmail: boolean | undefined }) => {
  const { sendInfo } = useNotifications();

  function Toggle(isOn: boolean) {
    if (isEduEmail === undefined) {
      return setStudentDiscount(isOn);
    }

    if (isEduEmail) {
      return sendInfo("Student discount is applied!", ".edu accounts get 50% off automatically.");
    } else {
      return sendInfo("Students get 50% off.", "Register with an .edu email to get the discount automatically or contact us at support@coursable.io with proof of enrollement.");
    }
  }

  return (
    <div onClick={() => Toggle(!studentDiscount)} className="w-full flex items-center justify-center md:justify-end gap-2 px-4 text-systemGray-500 hover:text-systemGray-600 mt-2 md:mt-0 cursor-pointer select-none group relative">
      <span className={`${studentDiscount && "text-foreground"} duration-100 text-base`}>Student discount</span>
      <Switch on={studentDiscount} className="order-last md:order-none" />
      <div
        className={`hidden md:block right-4 min-w-[300px] absolute bottom-full opacity-0 group-hover:opacity-100 text-sm bg-systemGray-100 text-systemGray-700 border rounded-lg px-2 py-1 z-10 mb-2 shadow-md duration-100 pointer-events-none`}
      >
        Students automatically get 50% off all plans! Learn more in the FAQ below.
      </div>
      <CurlyArrow className="hidden md:block absolute h-10 right-[1.5rem] bottom-[-2.3rem] scale-60 rotate-[130deg] pointer-events-none fill-systemGray-800" />
      <div className="translate-y-1 order-1 md:absolute md:right-[3.7rem] md:bottom-[-1.7rem] md:rotate-[-10deg] text-foreground pointer-events-none text-lg font-[Satisfy]">
        <span className="text-brand-500">50%</span>
      </div>
    </div>
  );
};

const PricingCardsView = ({ cycle, studentDiscount }: { cycle: CoursablePlanInterval; studentDiscount: boolean }) => {
  const { currentUser } = useAuth();
  const isLoggedIn = currentUser !== null;

  const userPlan = isLoggedIn ? CoursablePlan.get(currentUser?.customClaims.plan.tier) : undefined;

  const [trialStatus, setTrialStatus] = useState<CoursableTrialStatus>("not-eligible");
  const [balance, setBalance] = useState<number | undefined>(undefined);

  useEffect(() => {
    FetchTrialStatus().then(setTrialStatus).catch(console.error);
    FetchUserBalance().then(setBalance).catch(console.error);
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full md:w-3/4 px-4">
      <PricingCard plan={CoursablePlan.Free} description="Great for most leaners" cycle={cycle} studentDiscount={studentDiscount} isLoggedIn={isLoggedIn} userPlan={userPlan} />
      <PricingCard
        className="order-first md:order-none"
        plan={CoursablePlan.Plus}
        description="For those with unlimited curiousity"
        cycle={cycle}
        studentDiscount={studentDiscount}
        isLoggedIn={isLoggedIn}
        userPlan={userPlan}
        userBalance={balance}
        trialStatus={trialStatus}
      />
    </div>
  );
};

interface PricingCardProps {
  plan: CoursablePlan;
  description: string;
  cycle: CoursablePlanInterval;
  studentDiscount: boolean;
  isLoggedIn: boolean;
  userPlan?: CoursablePlan;
  className?: string;
  userBalance?: number;
  trialStatus?: CoursableTrialStatus;
}
const PricingCard = ({ plan, description, cycle, studentDiscount, isLoggedIn, userPlan, className, trialStatus, userBalance }: PricingCardProps) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { sendError } = useNotifications();

  const isPlus = plan.code === "plus";
  const features = useMemo(() => plan.allFeatures(), [plan]);

  function CTAClick() {
    if (!isLoggedIn || !userPlan) return navigate("/signup");

    if (userPlan === CoursablePlan.Free) {
      BuyPlus(cycle);
    } else {
      RedirectToStripe();
    }
  }

  async function BuyPlus(interval: CoursablePlanInterval) {
    setLoading(true);
    try {
      await BuyPlan(CoursablePlan.Plus, interval);
    } catch (error) {
      sendError("Failed to subscribe to plan", "Please try again later.");
      setLoading(false);
      console.log(error);
    }
  }

  async function RedirectToStripe() {
    setLoading(true);
    try {
      await OpenStripePortal();
    } catch (error: any) {
      if (userPlan === CoursablePlan.Plus && error.type === "no_customer") {
        sendError("Your account is managed by a third party", "Please contact your administrator for assistance.");
      } else {
        sendError("Failed to open customer portal", "Please try again later.");
        console.log(error);
      }
      setLoading(false);
    }
  }

  return (
    <div
      className={cn(`flex flex-col gap-4 w-full bg-background dark:bg-systemGray-100 border-2 p-8 rounded-3xl ${isPlus ? "border-brand-500 shadow-xl-c shadow-brand-100 dark:shadow-brand-300" : "border-systemGray-300"} relative`, className)}
    >
      <h2 className="font-semibold text-xl md:text-2xl text-foreground">{plan.title}</h2>
      <p className="text-base md:text-lg text-foreground">{description}</p>
      <p className="flex items-baseline justify-start text-3xl md:text-4xl font-semibold text-foreground">
        {(cycle === "month" ? plan.monthlyPrice : cycle === "quarter" ? plan.quaterlyMonthlyPrice : plan.annualMonthlyPrice) * (studentDiscount ? CoursablePlan.studentDiscount : 1) === 0 ? (
          `Free`
        ) : (
          <>
            <div>$</div>
            <div className="h-10 overflow-clip">
              <div
                className={cn(
                  "flex-started flex-col duration-500 ease-out-back",
                  cycle === "month" && "",
                  cycle === "quarter" && (studentDiscount ? "translate-y-[-33.33%]" : "translate-y-[-40%]"),
                  cycle === "year" && (studentDiscount ? "translate-y-[-66.66%]" : "translate-y-[-80%]")
                )}
              >
                <div>{plan.monthlyPrice * (studentDiscount ? CoursablePlan.studentDiscount : 1)}</div>
                {!studentDiscount && <div>{(plan.monthlyPrice + plan.quaterlyMonthlyPrice) / 2}</div>}
                <div>{plan.quaterlyMonthlyPrice * (studentDiscount ? CoursablePlan.studentDiscount : 1)}</div>
                {!studentDiscount && <div>{(plan.quaterlyMonthlyPrice + plan.annualMonthlyPrice) / 2}</div>}
                <div>{plan.annualMonthlyPrice * (studentDiscount ? CoursablePlan.studentDiscount : 1)}</div>
              </div>
            </div>
            <div className={cn("text-sm text-systemGray-500 duration-300", cycle === "month" || studentDiscount ? "ml-1" : "-ml-2 md:-ml-3")}>/ month</div>
          </>
        )}
      </p>
      {userPlan === plan ? (
        <Button className="mt-2 pointer-events-none shadow-none bg-background" size="lg" variant="outline">
          Current plan
        </Button>
      ) : (
        <Button variant={plan === CoursablePlan.Free ? "inverse" : "default"} loading={loading} disabled={loading} onClick={CTAClick} className="mt-2" size="lg">
          {isLoggedIn ? (plan === CoursablePlan.Free ? "Downgrade" : "Upgrade Now") : "Start Now"}
        </Button>
      )}
      {features.map((category, index) => (
        <div className={`w-full flex flex-col items-start justify-start gap-2 relative`} key={index}>
          <div className="w-full border-b text-systemGray-500 text-sm py-1 flex items-center justify-start gap-1">
            {category.category == "Courses" ? CoursableIcons.GraduationCap() : CoursableIcons.Project()}
            {category.category}
          </div>
          {category.features.map((feature, index) => (
            <li key={`${index} ${index}`} className="flex items-center gap-2 text-foreground">
              {CoursableIcons.Check(isPlus ? "text-brand-500" : "text-brand-300")}
              <p>{feature}</p>
            </li>
          ))}
        </div>
      ))}
      {isPlus &&
        (userBalance !== undefined && userBalance > 0 ? (
          <Badge variant="inverse" className="absolute top-8 right-6 text-sm whitespace-pre-wrap">
            ${userBalance} credit for free
            <div className="absolute bottom-full left-1.5 text-brandBlue-500 bg-clip-text font-normal mb-0.5">{CoursableIcons.Gift("inline mb-1 -mr-0.5")} Referral reward</div>
          </Badge>
        ) : (
          trialStatus === "eligible" && (
            <Badge variant="inverse" className="absolute top-6 right-6 text-sm">
              <span className="z-1">{CoursablePlan.trialDays} day free trial</span>
            </Badge>
          )
        ))}
    </div>
  );
};

const FAQSection = () => {
  return (
    <div className="flex flex-col items-center max-w-5xl mt-10">
      <h2 className="text-2xl md:text-3xl font-semibold text-center text-foreground">Frequently asked questions</h2>
      <p className="text-center text-base md:text-lg my-4 mb-8 text-systemGray-500">
        Can't find what you are looking for? Reach out to our{" "}
        <a href="mailto:support@coursable.io" className="text-brand-400 font-semibold hover:text-brand-300">
          support
        </a>{" "}
        team.
      </p>
      {faq.map((faq, index) => (
        <div className="w-full" key={index}>
          <div className="border-b bg-systemGray-300 w-full" />
          <FAQCard question={faq.question} answer={faq.answer} />
        </div>
      ))}
    </div>
  );
};

const FAQCard = ({ question, answer }: { question: string; answer: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`w-full py-4 text-left`}>
      <button onClick={() => setIsOpen((prev) => !prev)} className="flex w-full justify-between items-center">
        <h5 className="text-base md:text-xl font-semibold text-left text-foreground">{question}</h5>
        <FaChevronDown className={`fill-systemGray-500 ${!isOpen && "rotate-90"} duration-300`} />
      </button>
      <div className={`w-full grid ${isOpen ? "grid-rows-[1fr] mt-2" : "grid-rows-[0fr] mt-0"} duration-300`}>
        <div className="text-sm md:text-base overflow-hidden text-foreground">{answer}</div>
      </div>
    </div>
  );
};

const CurlyArrow = ({ className }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 324.67 252.81">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M317,252.81h0a7.63,7.63,0,0,1-7.62-7.66c.08-24.69-.74-50.63-11.43-72.51-15.4-31.51-41.55-55.89-71.48-68.22-.23,1.16-.47,2.32-.73,3.47-8.54,36.48-34.85,65.93-68.7,76.87-21.15,6.83-49.37,7.75-65.14-10.16-9.9-11.23-15-32.55,3.17-55.65C115.72,92.72,151,78.34,189.46,80.43a127.84,127.84,0,0,1,22.83,3.37,68.29,68.29,0,0,0-11.62-31.86,84.89,84.89,0,0,0-56.42-35.63c-31.82-5-65.68,8-88.37,33.91A7.64,7.64,0,1,1,44.4,40.14C70.5,10.37,109.66-4.55,146.6,1.22c26.81,4.2,51.75,20,66.73,42.18a82.93,82.93,0,0,1,14.51,45.18c35.19,13.14,66.08,41,83.85,77.36,12.13,24.83,13.07,52.77,13,79.26A7.62,7.62,0,0,1,317,252.81ZM182,95.5c-30.87,0-58.55,12-74.95,32.9-7.73,9.82-13.35,25.18-3.71,36.11,10.9,12.37,33.2,10.81,49,5.72,28.78-9.3,51.2-34.52,58.51-65.81.38-1.66.7-3.31,1-5a113.75,113.75,0,0,0-23.21-3.76C186.42,95.57,184.23,95.5,182,95.5Z" />
          <polygon points="82.03 55.63 43.53 9.95 0 88.1 82.03 55.63" />
        </g>
      </g>
    </svg>
  );
};

const CodeHighlight = ({ children }: { children: string }) => {
  return <span className="px-1 font-semibold rounded-md text-brand-500 border bg-brand-50 border-brand-200">{children}</span>;
};

const LinkHighlight = ({ children, to }: { children: string; to: string }) => {
  return (
    <Link className="text-brand-500 font-semibold hover:text-brand-400 duration-100" to={to}>
      {children}
    </Link>
  );
};

const faq = [
  {
    question: "Do you offer student discounts?",
    answer: (
      <>
        Yes, we offer a 50% discount for all students!
        <ul className="flex flex-col gap-2 mt-4 pl-6 list-disc list-outside">
          <li>
            If you have an <CodeHighlight>.edu</CodeHighlight> student email, use it to register a Coursable account and the discount will be applied automatically at checkout.
          </li>
          <li>
            If you do not have an <CodeHighlight>.edu</CodeHighlight> student email, then contact us at <LinkHighlight to={"mailto:support@coursable.io"}>support@coursable.io</LinkHighlight> with any proof of your enrollement (student ID,
            transcript, classes schedule, enrollement verification letter, etc), and we will apply the discount to your account manually.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "What materials can I upload to projects?",
    answer: "Projects support a wide range of files (PDF, Word, PowerPoint, Text or Markdown) in addition to online content, like YouTube videos, websites, articles, and more.",
  },
  {
    question: "Can I share flashcards, quizzes, and summaries generated by Projects?",
    answer: "Yes! You can export all the generated materials from Projects as beautiful PDFs to share with your friends, classmates, or even your social media followers.",
  },
  {
    question: "Can I trust AI to give me the best course?",
    answer:
      "AI is known to be finicky with detailed information. That is why we only rely on our AI to create the course outline, which you can always adjust to your liking. Afterwards, the actual study material come from trusted sources on the internet, so you will always be learning from real humans.",
  },
  {
    question: "What are course 'personalizations'?",
    answer:
      "Personalizations allow you to tailor the generated course specifically for you. Want to dive deeper into a particular subject or remove a topic that you already know? Personalize the course with your request and our AI will try to adjust it to your liking.",
  },
  {
    question: "Can I cancel my subscription?",
    answer: (
      <>
        To cancel an active subscription, sign in to your account and go to your account <LinkHighlight to="/settings/plans">Settings</LinkHighlight>. From there, open <LinkHighlight to="/settings/plans">Plans and billing</LinkHighlight>{" "}
        where you can see and cancel your subscription.
      </>
    ),
  },
];
