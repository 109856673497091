import { useState } from "react";
import { CoursableProject } from "../../../../backend/Projects/types";
import useProjectMessages from "./useProjectMessages";
import { MessageAttachment } from "../Chat/MessageAttachments";

const useProjectChatInput = (project: CoursableProject | undefined, Messages: ReturnType<typeof useProjectMessages>) => {
  const [input, setInput] = useState<string>("");
  const [attachments, setAttachments] = useState<MessageAttachment[]>([]);

  function Send() {
    const streaming = !!Messages.streamingMessageID;
    if (input.length === 0 || !project || project.isGenerating || streaming) return;

    Messages.SendMessage(input, attachments);
    setInput("");
    setAttachments([]);
  }

  function AddAttachment(attachment: MessageAttachment) {
    setAttachments((prev) => [...prev, attachment]);
  }

  function RemoveAttachment(index: number) {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  }

  return { input, attachments, setInput, Send, AddAttachment, RemoveAttachment };
};

export default useProjectChatInput;
