import { Dispatch, useRef, useState } from "react";
import CoursableIcons from "../../../utils/CoursableIcons";
import { ContextMenu } from "./ContextMenu";
import { VariantProps } from "class-variance-authority";
import Button, { buttonVariants } from "../Button";
import { cn } from "../../../utils/UtilityMethods";

interface Props extends VariantProps<typeof buttonVariants> {
  label?: string | React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  direction?: "top" | "bottom" | "left" | "right";
  align?: "start" | "center" | "end";
  containerClassName?: string;
  autoClose?: boolean;
  closeOnClick?: boolean;
  children?: React.ReactNode;
  chevron?: boolean;
  open?: boolean;
  setOpen?: Dispatch<React.SetStateAction<boolean>>;
}

const Dropdown = ({ label, direction = "bottom", align = "end", className, disabled, variant, containerClassName, loading, autoClose, closeOnClick, size, children, chevron = true, open, setOpen }: Props) => {
  const mouseIn = useRef(false);
  const [isOpen, setIsOpen] = useState(false);

  const openStyles = {
    default: "bg-brand-500/80",
    secondary: "bg-systemGray-300/80",
    outline: "shadow dark:shadow-systemGray-300",
    ghost: "bg-systemGray-100",
    link: "underline",
    inverse: "bg-brand-100/80 dark:bg-brand-200/60",
  };

  return (
    <div className={cn("relative", containerClassName)}>
      <Button
        loading={loading}
        disabled={disabled}
        className={cn("max-w-full relative z-1", className, (open ?? isOpen) && variant && openStyles[variant])}
        variant={variant ?? "outline"}
        size={size}
        onClick={(e) => {
          e.stopPropagation();
          if (setOpen !== undefined) return setOpen(!open);
          return setIsOpen(!isOpen);
        }}
      >
        {typeof label === "string" ? <span className="truncate">{label}</span> : label}
        {chevron && CoursableIcons.Chevron("down", cn("text-sm text-systemGray-400", variant === "default" && "text-brand-200 dark:text-brand-800", variant === "inverse" && "text-brand-300", label !== undefined && "ml-2"))}
      </Button>
      <ContextMenu
        isOpen={open ?? isOpen}
        setIsOpen={setOpen ?? setIsOpen}
        autoClose={autoClose}
        closeOnClick={closeOnClick}
        direction={direction}
        align={align}
        onMouseEnter={() => (mouseIn.current = true)}
        onMouseLeave={() => (mouseIn.current = false)}
      >
        {children}
      </ContextMenu>
      {(autoClose === undefined || autoClose) && (
        <div
          onMouseLeave={() => {
            setTimeout(() => {
              if (mouseIn.current) return;

              if (setOpen !== undefined) return setOpen(false);
              return setIsOpen(false);
            }, 100);
          }}
          className="absolute -inset-4 z-0"
        />
      )}
    </div>
  );
};

export default Dropdown;
