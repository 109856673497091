import Button from "../elements/Button";
import HomePageTemplate from "../page-elements/HomePageTemplate";
import { pageMetatags } from "../../utils/MetatagsGenerator";

const PageNotFound = () => {
  return (
    <HomePageTemplate helmet={pageMetatags({ title: "Not found" })}>
      <div className="h-full w-full flex flex-col items-center justify-center gap-8 py-16">
        <h1 className="text-[8rem] font-semibold">404</h1>
        <h3 className="text-3xl">Oops, nothing here. You dun goofed 😠</h3>
        <Button to="/" size="lg">
          Go home
        </Button>
      </div>
    </HomePageTemplate>
  );
};

export default PageNotFound;
