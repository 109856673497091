import { ReactNode, useEffect, useRef, useState } from "react";
import { AssistantMessage, CoursableProject, ProjectNote } from "../../../../backend/Projects/types";
import { CreateNewNote, DeleteNote, FetchNotes, SaveNoteContent, SaveNoteName, StopAIWritingStream, StreamAIWriting } from "../../../../backend/Projects/ProjectNotes";
import { useNotifications } from "../../../../utils/NotificationsContext";

const useProjectNotes = (project: CoursableProject | undefined) => {
  const [notes, setNotes] = useState<ProjectNote[]>([]);

  const [writingToolStyle, setWritingToolStype] = useState<AIWritingToolStyle | null>(null);
  const [writingTool, setWritingTool] = useState<AIWritingTool | null>(null);
  const [writingToolGenerating, setWritingToolGenerating] = useState<boolean>(false);
  const [writingToolContent, setWritingToolContent] = useState<string>("");
  const writingToolContext = useRef<string | undefined>(undefined);

  const initializingProjectID = useRef<string | undefined>(undefined);
  const creatingNewNote = useRef<boolean>(false);

  const writingToolGenerationThreadID = useRef<string | undefined>(undefined);
  const writingToolGeneratingAbortController = useRef<AbortController | undefined>(undefined);

  const { sendError } = useNotifications();

  async function Initialize(project: CoursableProject) {
    setNotes([]);

    initializingProjectID.current = project.id;
    const fetchedNotes = await FetchNotes(project.id);
    if (initializingProjectID.current !== project.id) return;

    if (fetchedNotes.length === 0 && !creatingNewNote.current) {
      creatingNewNote.current = true;
      const newNote = await CreateNewNote(project.id);
      fetchedNotes.push(newNote);
    }

    setNotes(fetchedNotes);
  }

  async function SaveContent(noteID: string, content: string) {
    if (!project) return;

    await SaveNoteContent(project.id, noteID, content);
    setNotes((prev) => prev.map((n) => (n.id === noteID ? { ...n, lastUpdated: new Date(), content } : n)));
  }

  async function SaveName(noteID: string, name: string) {
    if (!project) return;

    await SaveNoteName(project.id, noteID, name);
    setNotes((prev) => prev.map((n) => (n.id === noteID ? { ...n, lastUpdated: new Date(), name } : n)));
  }

  async function Delete(noteID: string) {
    if (!project) return;

    await DeleteNote(project.id, noteID);
    const newNotes = notes.filter((n) => n.id !== noteID);
    if (newNotes.length === 0) {
      const note = await CreateNewNote(project.id);
      newNotes.push(note);
    }
    setNotes(newNotes);
  }

  async function SelectWritingTool(tool: AIWritingTool, context?: string) {
    if (!project) return;

    setWritingToolContent("");
    setWritingTool(tool);
    writingToolContext.current = context;
    if (tool.requireUserPrompt) return;

    await UseWritingTool(tool);
  }

  async function UseWritingTool(tool: AIWritingTool, userPrompt?: string) {
    if (!project) return;

    setWritingToolGenerating(true);

    try {
      writingToolGeneratingAbortController.current?.abort();
      writingToolGeneratingAbortController.current = new AbortController();

      await StreamAIWriting(
        project.id,
        OnStreamStart,
        OnStream,
        OnStreamEnd,
        tool.prompt ?? userPrompt,
        tool.prompt === undefined ? undefined : userPrompt,
        writingToolContext.current || undefined,
        tool.useMaterials,
        writingToolStyle?.name,
        writingToolGeneratingAbortController.current
      );
    } catch (error: any) {
      if (error.name === "AbortError") return;

      sendError("Failed to use writing tool.", "Please try again later.");
    }
  }

  async function CancelWritingTool() {
    if (!project || !writingTool || !writingToolGenerating) return;

    try {
      if (writingToolGenerationThreadID.current) {
        await StopAIWritingStream(project.id, writingToolGenerationThreadID.current);
      } else {
        writingToolGeneratingAbortController.current?.abort();
        setWritingToolGenerating(false);
      }
    } catch (error: any) {
      if (error.name === "AbortError") return;

      sendError("Failed to stop the generation.", "This should not happen. Please try again.");
    }
  }

  function OnStreamStart(m: AssistantMessage) {
    writingToolGenerationThreadID.current = m.threadID;
    setWritingToolGenerating(true);
  }
  function OnStreamEnd() {
    setWritingToolGenerating(false);
    writingToolGenerationThreadID.current = undefined;
  }
  function OnStream(content: string) {
    const citationRegex = /(【.*?】)/g;
    const removeCitations = content.replaceAll(citationRegex, "");
    setWritingToolContent(removeCitations);
  }

  function DeselectWritingTool() {
    setWritingTool(null);
    setWritingToolContent("");
    setWritingToolGenerating(false);
    writingToolContext.current = undefined;
    CancelWritingTool();
  }

  function SetWritingToolStyle(style: AIWritingToolStyle | null) {
    setWritingToolStype(style);
  }

  useEffect(() => {
    return () => {
      writingToolContext.current = undefined;
      creatingNewNote.current = false;
      writingToolGenerationThreadID.current = undefined;
      writingToolGeneratingAbortController.current?.abort();
    };
  }, [project?.id]);

  return {
    notes,
    writingToolStyle,
    writingTool,
    writingToolGenerating,
    writingToolContent,
    writingToolContext: writingToolContext.current,
    Initialize,
    SaveName,
    SaveContent,
    Delete,
    SetWritingToolStyle,
    SelectWritingTool,
    UseWritingTool,
    CancelWritingTool,
    DeselectWritingTool,
  };
};

export default useProjectNotes;

export interface AIWritingToolStyle {
  name: AIWritingStyleName;
  icon: ReactNode;
  color: string;
}

export type AIWritingStyleName = "Academic" | "Professional" | "Casual" | "Friendly" | "Simple";

export interface AIWritingTool {
  label: string;
  icon: ReactNode;
  prompt?: string;
  requireUserPrompt?: boolean;
  userPromptPlaceholder?: string;
  useMaterials?: boolean;
  replaceSelection?: boolean;
}
