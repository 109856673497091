import { useEffect, useState } from "react";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { PopupModal, PopupModalContent, PopupModalHeader } from "../../../../elements/PopupModal";
import { useNotifications } from "../../../../../utils/NotificationsContext";
import CTAButton from "../../../../elements/CTAButton";
import LoadingIndicator from "../../../../elements/LoadingIndicator";
import { ServerResponse } from "../../../../../backend/Shared";
import { PlanBadge } from "../../../../page-elements/AppHeader";
import { useProjectManager } from "../../Hooks/useProjectManager";
import useMaterialUpload from "../../Hooks/useMaterialUpload";
import { useAuth } from "../../../../../firebase/AuthContext";
import { CoursablePlan } from "../../../../../utils/CoursablePlan";

const AddMaterialsPopup = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (b: boolean) => void }) => {
  const { project, Files } = useProjectManager();

  const { sendError } = useNotifications();
  const { files, links, ClearMaterials, MaterialUploadSection } = useMaterialUpload({ open: isOpen });
  const { currentUser } = useAuth();
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);

  const [loading, setLoading] = useState<boolean>(false);

  async function StartUploading() {
    setLoading(true);
    try {
      await Files.Upload(
        files,
        links.map((l) => l.url)
      );
      setIsOpen(false);
    } catch (error: any) {
      if (error instanceof ServerResponse && error.type === "invalid_file_format") {
        sendError("Looks like there is something wrong with your file 😢", "Please try again with a different document.");
      } else if (error instanceof ServerResponse && error.type === "failed_to_extract_content") {
        sendError("Link is unavailable.", "Failed to extract content from your provided link. Please try again with a different url.");
      } else {
        sendError("Oops, something went wrong uploading your files. Please try again later.");
        console.log(error);
      }
    }
    setLoading(false);
  }
  useEffect(() => {
    if (isOpen) {
      ClearMaterials();
    }
  }, [isOpen]);

  return (
    <PopupModal flex-centered flex-col gap-2 pb-8 open={isOpen} showCloseButton={!loading} Close={() => setIsOpen(false)} className="w-full max-w-2xl relative">
      <PopupModalHeader dynamicType="scale" dynamicScaleOrigin="center" className="flex-centered flex-col gap-2 text-xl md:text-2xl font-semibold">
        <span className="text-2xl md:text-3xl text-brand-500">{CoursableIcons.Project()}</span>
        {project?.name}
      </PopupModalHeader>
      <PopupModalContent className="w-full flex items-center justify-start flex-col gap-4 md:gap-8">
        <div className="w-full border-b py-2 text-sm md:text-base">Add new materials</div>
        <MaterialUploadSection />
        <CTAButton disabled={loading || files.length + links.length === 0} onClick={StartUploading}>
          {loading && <LoadingIndicator className="stroke-white" />}Add Materials
        </CTAButton>
      </PopupModalContent>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:gap-2 text-systemGray-500 text-micro md:text-mini absolute top-4 md:top-6 left-4 md:left-6">
        <PlanBadge />
        {plan.materialsPerProject === null ? (
          <>Unlimited materials</>
        ) : (
          <>
            {files.length + links.length + Files.files.length}/{plan.materialsPerProject} materials
          </>
        )}
      </div>
    </PopupModal>
  );
};

export default AddMaterialsPopup;
