import { useNavigate, useParams } from "react-router-dom";
import CourseEditingSidemenu from "./CourseEditingSidemenu";
import { useEffect, useState } from "react";
import { GetCourseFromDatabase, WriteCourseToDatabase } from "../../../firebase/FirestoreManager";
import CourseEditingTopicView from "./CourseEditingTopicView";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNotifications } from "../../../utils/NotificationsContext";
import { BackButton } from "../Courses/CoursesDashboardPage";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import { useAuth } from "../../../firebase/AuthContext";
import { GAEvent, GALogEvent } from "../../../firebase/GoogleAnalytics";
import AppPageTemplate from "../../page-elements/AppPageTemplate";
import Button from "../../elements/Button";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const CourseEditingPage = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  const [course, setCourse] = useState<Course | undefined>(undefined);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState<number | null>(null);

  const [verticalLayout, setVerticalLayout] = useState<boolean>(false);

  async function FetchCourse() {
    if (!id) return;

    const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);
    if (plan !== CoursablePlan.Plus) {
      return navigate("/app/courses");
    }

    const course = await GetCourseFromDatabase(id);
    setCourse(course ?? undefined);
  }

  useEffect(() => {
    FetchCourse();

    function CheckWidth() {
      if (window.innerWidth < 768) setVerticalLayout(true);
      else setVerticalLayout(false);
    }
    window.addEventListener("resize", CheckWidth);
    CheckWidth();
    return () => {
      window.removeEventListener("resize", CheckWidth);
    };
  }, []);

  return (
    <AppPageTemplate helmet={pageMetatags({ title: "Edit Course" })}>
      <div className="flex-centered h-full w-full flex-col bg-background relative overflow-auto">
        {course && <Controls course={course} />}
        {verticalLayout ? (
          <VerticalLayout course={course} setCourse={setCourse} selectedTopicIndex={selectedTopicIndex} setSelectedTopicIndex={setSelectedTopicIndex} />
        ) : (
          <LandscapeLayout course={course} setCourse={setCourse} selectedTopicIndex={selectedTopicIndex} setSelectedTopicIndex={setSelectedTopicIndex} />
        )}
      </div>
    </AppPageTemplate>
  );
};

export default CourseEditingPage;

interface LayoutProps {
  course: Course | undefined;
  setCourse: (c: Course | undefined) => void;
  selectedTopicIndex: number | null;
  setSelectedTopicIndex: (index: number | null) => void;
}

const LandscapeLayout = ({ course, setCourse, selectedTopicIndex, setSelectedTopicIndex }: LayoutProps) => {
  return (
    <div className={`w-full grow grid grid-cols-[1fr_2fr] xl:grid-cols-[600px_3fr] overflow-auto`}>
      {course ? (
        <>
          <DndProvider backend={HTML5Backend}>
            <CourseEditingSidemenu course={course} setCourse={setCourse} selectedTopicIndex={selectedTopicIndex} setSelectedTopicIndex={setSelectedTopicIndex} />{" "}
          </DndProvider>
          {selectedTopicIndex === null ? (
            <div className="flex flex-col gap-2 items-center justify-center text-systemGray-400">
              <div className="text-2xl">😉</div>
              <div className="text-lg">No topic is selected</div>
            </div>
          ) : (
            <CourseEditingTopicView course={course} setCourse={setCourse} index={selectedTopicIndex} setSelectedTopicIndex={setSelectedTopicIndex} />
          )}
        </>
      ) : (
        <LoadingPlaceholder />
      )}
    </div>
  );
};

const VerticalLayout = ({ course, setCourse, selectedTopicIndex, setSelectedTopicIndex }: LayoutProps) => {
  return (
    <div className="w-full flex overflow-auto">
      {course ? (
        selectedTopicIndex === null ? (
          <DndProvider backend={HTML5Backend}>
            <CourseEditingSidemenu course={course} setCourse={setCourse} selectedTopicIndex={selectedTopicIndex} setSelectedTopicIndex={setSelectedTopicIndex} />{" "}
          </DndProvider>
        ) : (
          <div className="w-full overflow-auto">
            <BackButton onClick={() => setSelectedTopicIndex(null)} />
            <CourseEditingTopicView course={course} setCourse={setCourse} index={selectedTopicIndex} setSelectedTopicIndex={setSelectedTopicIndex} />
          </div>
        )
      ) : (
        <LoadingPlaceholder />
      )}
    </div>
  );
};

const Controls = ({ course }: { course: Course }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { sendError } = useNotifications();

  async function Save() {
    if (!course) return;

    setLoading(true);
    try {
      await WriteCourseToDatabase(course);
      GALogEvent(GAEvent.Course.Editing.saved);
      navigate("/app/courses");
    } catch (error) {
      console.log(error);
      sendError("Oops, something went wrong. Please try again later.");
    }
    setLoading(false);
  }

  function Cancel() {
    GALogEvent(GAEvent.Course.Editing.cancelled);
    navigate("/app/courses");
  }

  return (
    <div className="w-full flex justify-between gap-4 items-center border-b p-4">
      <div className="text-base md:text-xl px-2">
        Editing <span className="font-semibold">{course.title || "Empty title"}</span>
      </div>
      <div className="flex items-center gap-4">
        <Button disabled={loading} onClick={Cancel} variant="secondary">
          Cancel
        </Button>
        <Button loading={loading} disabled={loading} onClick={Save}>
          Save
        </Button>
      </div>
    </div>
  );
};

const LoadingPlaceholder = () => {
  return (
    <div className="w-full flex flex-col gap-2 items-start justify-start animate-pulse p-4">
      <div className="bg-systemGray-200 rounded-md h-6 w-3/4" />
      <div className="bg-systemGray-200 rounded-md h-6 w-2/4" />
      <div className="bg-systemGray-200 rounded-md h-6 w-2/4" />
    </div>
  );
};
