import { cn } from "../../../../../utils/UtilityMethods";
import { CSSProperties, ReactNode, useState } from "react";
import { FaHighlighter, FaAlignJustify, FaAlignCenter, FaAlignLeft, FaAlignRight, FaBold, FaCode, FaItalic, FaStrikethrough, FaUnderline, FaArrowRotateLeft, FaArrowRotateRight, FaListOl, FaListUl } from "react-icons/fa6";
import { IoCode } from "react-icons/io5";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { ContextMenu } from "../../../../elements/DropdownMenu/ContextMenu";
import { useCurrentEditor } from "@tiptap/react";
import { TbLineHeight } from "react-icons/tb";
import React from "react";
import { AIToolsControls } from "./AIToolsControls";
import { AIWritingTool, AIWritingToolStyle } from "../../Hooks/useProjectNotes";

interface ControlsProps {
  OnToolClick?: (tool: AIWritingTool) => void;
  aiToolGroups?: string[];
  writingToolStyle: AIWritingToolStyle | null;
  setWritingToolStyle: (style: AIWritingToolStyle | null) => void;
  hideFont?: boolean;
}

export const Controls = ({ OnToolClick, aiToolGroups, writingToolStyle, hideFont, setWritingToolStyle }: ControlsProps) => {
  const { editor } = useCurrentEditor();

  return (
    <div className="flex items-center justify-start gap-1 p-1 flex-wrap">
      <AIToolsControls OnToolClick={OnToolClick} toolGroups={aiToolGroups} writingToolStyle={writingToolStyle} setWritingToolStyle={setWritingToolStyle} />
      <Divider />
      <ControlsButton onClick={() => editor?.commands.undo()} disabled={!editor?.can().undo()}>
        <FaArrowRotateLeft />
      </ControlsButton>
      <ControlsButton onClick={() => editor?.commands.redo()} disabled={!editor?.can().redo()}>
        <FaArrowRotateRight />
      </ControlsButton>
      <Divider />
      <FontSize />
      <Divider />
      {hideFont === false && (
        <>
          <Font />
          <Divider />
        </>
      )}
      <ControlsButton active={editor?.isActive("bold")} onClick={() => editor?.commands.toggleBold()}>
        <FaBold />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("italic")} onClick={() => editor?.commands.toggleItalic()}>
        <FaItalic />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("underline")} onClick={() => editor?.commands.toggleUnderline()}>
        <FaUnderline />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("strike")} onClick={() => editor?.commands.toggleStrike()}>
        <FaStrikethrough />
      </ControlsButton>
      <Divider />
      <ControlsButton active={editor?.isActive("highlight")} onClick={() => editor?.commands.toggleHighlight()}>
        <FaHighlighter />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("code")} onClick={() => editor?.commands.toggleCode()}>
        <IoCode />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("codeBlock")} onClick={() => editor?.commands.toggleCodeBlock()}>
        <FaCode />
      </ControlsButton>
      <Divider />
      <Alignment />
      <LineHeight />
      <ControlsButton active={editor?.isActive("orderedList")} onClick={() => editor?.commands.toggleOrderedList()}>
        <FaListOl />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("bulletList")} onClick={() => editor?.commands.toggleBulletList()}>
        <FaListUl />
      </ControlsButton>
    </div>
  );
};

export const BubbleControls = ({ OnToolClick, aiToolGroups, writingToolStyle, setWritingToolStyle }: ControlsProps) => {
  const { editor } = useCurrentEditor();
  return (
    <div className="flex items-center justify-start gap-1 p-1">
      <AIToolsControls OnToolClick={OnToolClick} toolGroups={aiToolGroups} writingToolStyle={writingToolStyle} setWritingToolStyle={setWritingToolStyle} />
      <Divider />
      <ControlsButton active={editor?.isActive("bold")} onClick={() => editor?.commands.toggleBold()}>
        <FaBold />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("italic")} onClick={() => editor?.commands.toggleItalic()}>
        <FaItalic />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("underline")} onClick={() => editor?.commands.toggleUnderline()}>
        <FaUnderline />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("strike")} onClick={() => editor?.commands.toggleStrike()}>
        <FaStrikethrough />
      </ControlsButton>
      <Divider />
      <ControlsButton active={editor?.isActive("highlight")} onClick={() => editor?.commands.toggleHighlight()}>
        <FaHighlighter />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("code")} onClick={() => editor?.commands.toggleCode()}>
        <IoCode />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("codeBlock")} onClick={() => editor?.commands.toggleCodeBlock()}>
        <FaCode />
      </ControlsButton>
      <Divider />
      <Alignment />
      <ControlsButton active={editor?.isActive("orderedList")} onClick={() => editor?.commands.toggleOrderedList()}>
        <FaListOl />
      </ControlsButton>
      <ControlsButton active={editor?.isActive("bulletList")} onClick={() => editor?.commands.toggleBulletList()}>
        <FaListUl />
      </ControlsButton>
    </div>
  );
};

const Font = () => {
  const { editor } = useCurrentEditor();
  const currentFont = editor?.getAttributes("textStyle")?.fontFamily || undefined;

  const fonts = ["Arial", "Courier New", "Georgia", "Times New Roman", "Verdana"];
  return (
    <ControlsSubmenu
      icon={
        <>
          <span className="font-medium max-w-[70px] truncate">{currentFont || "Font"}</span> {CoursableIcons.Chevron("down", "ml-2 text-mini text-systemGray-400")}
        </>
      }
      className="w-auto px-2"
      menuClassName="flex-col"
    >
      {fonts.map((font, index) => (
        <ControlsButton
          style={{
            fontFamily: font,
          }}
          key={index}
          active={font === currentFont}
          onClick={() => editor?.commands.setFontFamily(font)}
          className="w-auto px-2 text-left justify-start"
        >
          {font}
        </ControlsButton>
      ))}
    </ControlsSubmenu>
  );
};

const FontSize = () => {
  const { editor } = useCurrentEditor();
  const currentSize: string | undefined = (editor?.getAttributes("textStyle")?.fontSize as string | undefined)?.replace("pt", "") || "12";

  const fontSize = [6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 60, 72];

  return (
    <ControlsSubmenu
      icon={
        <>
          <span className="font-medium max-w-[70px] truncate">{currentSize}</span> {CoursableIcons.Chevron("down", "ml-2 text-mini text-systemGray-400")}
        </>
      }
      className="w-auto px-2"
      menuClassName="flex-col"
    >
      {fontSize.map((size, index) => (
        <ControlsButton key={index} active={`${size}` === currentSize} onClick={() => editor?.commands.setFontSize(`${size}pt`)} className="w-auto px-2 text-left justify-start">
          {size}
        </ControlsButton>
      ))}
    </ControlsSubmenu>
  );
};

const Alignment = () => {
  const { editor } = useCurrentEditor();
  return (
    <ControlsSubmenu icon={editor?.isActive({ textAlign: "left" }) ? <FaAlignLeft /> : editor?.isActive({ textAlign: "center" }) ? <FaAlignCenter /> : editor?.isActive({ textAlign: "right" }) ? <FaAlignRight /> : <FaAlignJustify />}>
      <ControlsButton active={editor?.isActive({ textAlign: "left" })} onClick={() => editor?.commands.setTextAlign("left")}>
        <FaAlignLeft />
      </ControlsButton>
      <ControlsButton active={editor?.isActive({ textAlign: "center" })} onClick={() => editor?.commands.setTextAlign("center")}>
        <FaAlignCenter />
      </ControlsButton>
      <ControlsButton active={editor?.isActive({ textAlign: "right" })} onClick={() => editor?.commands.setTextAlign("right")}>
        <FaAlignRight />
      </ControlsButton>
      <ControlsButton active={editor?.isActive({ textAlign: "justify" })} onClick={() => editor?.commands.setTextAlign("justify")}>
        <FaAlignJustify />
      </ControlsButton>
    </ControlsSubmenu>
  );
};

const LineHeight = () => {
  const { editor } = useCurrentEditor();
  const lineHeights = [1, 1.15, 1.5, 2];

  return (
    <ControlsSubmenu icon={<TbLineHeight className="scale-125" />} menuClassName="flex-col">
      {lineHeights.map((height, index) => (
        <ControlsButton key={index} active={editor?.isActive("bulletList")} onClick={() => editor?.commands.setLineHeight(`${Math.round((height + 0.5) * 100).toFixed()}%`)} className="w-auto px-2 text-left justify-start">
          {height === 1 ? "Single" : height === 2 ? "Double" : `${height}`}
        </ControlsButton>
      ))}
    </ControlsSubmenu>
  );
};

interface ControlsButtonProps {
  active?: boolean;
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  activeClassName?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ControlsButton = ({ children, active, onClick, className, style, disabled, onMouseEnter, onMouseLeave, activeClassName }: ControlsButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(
        "text-sm w-7 h-7 rounded-md bg-background transition-colors flex items-center justify-center shrink-0 whitespace-nowrap disabled:opacity-50 disabled:pointer-events-none",
        active ? "bg-brand-50 active:bg-brand-100 text-brand-500" : "text-systemGray-700 hover:bg-systemGray-100 active:bg-systemGray-200",
        active && activeClassName,
        className
      )}
      style={style}
    >
      {children}
    </button>
  );
};

interface ControlsSubmenuProps {
  children: ReactNode;
  icon: ReactNode;
  stopPropagation?: boolean;
  contextMenuStyle?: CSSProperties;
  style?: CSSProperties;
  className?: string;
  menuClassName?: string;
  hover?: boolean;
  direction?: "left" | "right" | "bottom" | "top";
  align?: "center" | "end" | "start";
  activeClassName?: string;
  activeStyles?: CSSProperties;
  disabled?: boolean;
}

export const ControlsSubmenu = ({ children, icon, stopPropagation, contextMenuStyle, style, className, menuClassName, hover, align = "start", direction, activeClassName, activeStyles, disabled }: ControlsSubmenuProps) => {
  const [open, setOpen] = useState(false);

  return (
    <ControlsButton
      disabled={disabled}
      style={{
        ...style,
        ...(open && activeStyles),
      }}
      onMouseEnter={() => {
        if (!hover) return;
        setOpen(true);
      }}
      onMouseLeave={() => {
        if (!hover) return;
        setOpen(false);
      }}
      active={open}
      onClick={(e) => {
        if (stopPropagation === true) e.stopPropagation();
        setOpen(!open);
      }}
      className={cn("relative", className)}
      activeClassName={activeClassName}
    >
      {icon}
      <ContextMenu menuStyle={contextMenuStyle} isOpen={open} setIsOpen={setOpen} align={align} direction={direction} menuClassName={cn("p-1 flex flex-row gap-1 dark:bg-background", menuClassName)}>
        {children}
      </ContextMenu>
    </ControlsButton>
  );
};

const Divider = () => {
  return <div className="w-0.5 bg-systemGray-200 h-6" />;
};
