import { useState } from "react";
import { ProjectLinkInfo } from "../../../../backend/Projects/ProjectLinks";
import CoursableIcons from "../../../../utils/CoursableIcons";
import { cn, getFileExtension } from "../../../../utils/UtilityMethods";

export const MaterialPreviewsGrid = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(12rem, 1fr))`,
      }}
      className="w-full grid gap-2"
    >
      {children}
    </div>
  );
};

const MaterialPreview = ({ name, icon, RemoveMaterial }: { name: string; icon: React.ReactNode; RemoveMaterial: () => void }) => {
  return (
    <div className={`flex items-center justify-start gap-2 p-1 border rounded-md duration-100 text-sm bg-systemGray-100 hover:shadow relative group cursor-pointer select-none`}>
      {icon}
      <div className="w-full text-start truncate break-all">{name}</div>
      <button
        onClick={RemoveMaterial}
        className="text-base pr-1 pl-2 bg-gradient-to-r from-transparent to-20% to-systemGray-100 text-red-600 hover:text-red-700 active:text-red-500 duration-100 absolute top-0 bottom-0 -right-0 md:opacity-0 group-hover:opacity-100"
      >
        {CoursableIcons.Delete()}
      </button>
    </div>
  );
};

export const FilePreview = ({ file, RemoveFile }: { file: File; RemoveFile: (file: File) => void }) => {
  const extension = getFileExtension(file.name);

  const removeExtension = (v: string) => {
    if (!extension) return v;
    return v.slice(0, -extension.length - 1);
  };

  const icon = fileExtensionIcon(extension);

  return <MaterialPreview name={removeExtension(file.name)} icon={<div className={`text-base`}>{icon}</div>} RemoveMaterial={() => RemoveFile(file)} />;
};

export const fileExtensionIcon = (extension: string | undefined) => {
  if (extension === "pdf") return CoursableIcons.PdfDoc("text-red-600");
  if (extension === "doc" || extension === "docx") return CoursableIcons.DocxDoc("text-blue-600");
  if (extension === "ppt" || extension === "pptx") return CoursableIcons.PptxDoc("text-orange-600");
  if (extension === "txt") return CoursableIcons.TxtDoc("text-black dark:text-systemGray-700");
  if (extension === "md") return CoursableIcons.MdDoc("text-cyan-600");
  return CoursableIcons.Doc();
};

export const LinkPreview = ({ info, Remove }: { info: ProjectLinkInfo; Remove: () => void }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);

  const fileExtension = info.type === "file" ? getFileExtension(info.title) : undefined;
  const fileIcon = fileExtension ? fileExtensionIcon(fileExtension) : undefined;

  return (
    <MaterialPreview
      name={info.title}
      icon={
        fileIcon ?? (
          <div className={cn("min-h-full relative rounded-full", info.type === "youtube" ? "aspect-video" : "aspect-square")}>
            <div className={`w-full animate-pulse absolute inset-0 bg-systemGray-300 rounded z-0`} />
            {info.previewImageURL ? (
              errorLoadingImage ? (
                <div className="absolute inset-0 w-full bg-systemGray-200 flex-centered rounded">{CoursableIcons.Text()}</div>
              ) : (
                <img
                  src={info.previewImageURL}
                  onLoad={() => setImageLoaded(true)}
                  onError={() => setErrorLoadingImage(true)}
                  className={`w-full ${imageLoaded ? "opacity-100" : "opacity-0"} absolute inset-0 object-cover duration-300 rounded z-1`}
                />
              )
            ) : (
              <div className="w-full absolute inset-0 border rounded z-1 flex-centered bg-systemGray-200">{CoursableIcons.Text()}</div>
            )}
          </div>
        )
      }
      RemoveMaterial={Remove}
    />
  );
};
