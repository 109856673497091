import { Dispatch, useEffect, useRef, useState } from "react";
import { CoursableProject, ProjectFile, ProjectSummary } from "../../../../backend/Projects/types";
import { DeleteSummary, FetchSummaries, GenerateSummary, RenameSummary, SaveSummaryContent } from "../../../../backend/Projects/ProjectSummaries";
import { Unsubscribe } from "firebase/firestore";
import { SubscribeToProjectChanges } from "../../../../backend/Projects/ProjectBase";

const useProjectSummaries = (project: CoursableProject | undefined, setProject: Dispatch<React.SetStateAction<CoursableProject | undefined>>) => {
  const [summaries, setSummaries] = useState<ProjectSummary[]>([]);

  const summaryGenerationListener = useRef<Unsubscribe | undefined>(undefined);
  const initializingProjectID = useRef<string | undefined>(undefined);

  async function Initialize(project: CoursableProject) {
    setSummaries([]);

    initializingProjectID.current = project.id;
    const summaries = await FetchSummaries(project.id);
    if (initializingProjectID.current !== project.id) return;

    setSummaries(summaries);

    if (project.isGeneratingSummary) {
      summaryGenerationListener.current?.();
      summaryGenerationListener.current = SubscribeToProjectChanges(project.id, async (project) => {
        if (project.isGeneratingSummary) return;
        summaryGenerationListener.current?.();
        setProject(project);
        const summaries = await FetchSummaries(project.id);
        setSummaries(summaries);
      });
    }
  }

  async function Generate(selectedFiles: ProjectFile[], prompt: string, summaryID?: string): Promise<ProjectSummary> {
    if (!project) throw new Error("Project not set");

    SetIsGenerating(true);
    const summary = await GenerateSummary(project.id, selectedFiles, prompt, summaryID);
    const newArray = summaries.some((s) => s.id === summary.id) ? summaries.map((s) => (s.id === summary.id ? summary : s)) : [summary, ...summaries];
    setSummaries(newArray);
    SetIsGenerating(false);
    return summary;
  }

  async function SaveName(summaryID: string, name: string) {
    if (!project) return;

    await RenameSummary(project.id, summaryID, name);
    setSummaries((prev) => prev.map((s) => (s.id === summaryID ? { ...s, name } : s)));
  }

  async function SaveContent(summaryID: string, content: string) {
    if (!project) return;

    await SaveSummaryContent(project.id, summaryID, content);
    setSummaries((prev) => prev.map((s) => (s.id === summaryID ? { ...s, content } : s)));
  }

  async function Delete(summaryID: string) {
    if (!project) return;

    await DeleteSummary(project.id, summaryID);
    setSummaries((prev) => prev.filter((s) => s.id !== summaryID));
  }

  function SetIsGenerating(isGenerating: boolean) {
    setProject((prev) => prev && { ...prev, isGeneratingSummary: isGenerating });
  }

  function SetPublicID(summaryID: string, publicID: string | undefined) {
    setSummaries((prev) => prev.map((s) => (s.id === summaryID ? { ...s, publicID } : s)));
  }

  useEffect(() => {
    return () => {
      summaryGenerationListener.current?.();
    };
  }, [project?.id]);

  return { summaries, Initialize, Generate, SaveName, SaveContent, Delete, SetIsGenerating, SetPublicID };
};

export default useProjectSummaries;
