import { collection, deleteDoc, deleteField, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, setDoc } from "firebase/firestore";
import { auth, db, GetIDToken } from "../../firebase/FirebaseConfig";
import { AssistantMessage, ProjectNote } from "./types";
import { nanoid } from "nanoid";
import { ExtractServerResponse } from "../Shared";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { assistantMessageFromData } from "./ProjectMessages";
import { AIWritingStyleName } from "../../components/pages/Projects/Hooks/useProjectNotes";

export async function FetchNotes(projectID: string): Promise<ProjectNote[]> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const notesDocs = await getDocs(collection(db, `users/${currentUser.uid}/projects/${projectID}/notes`));

  const notes: ProjectNote[] = notesDocs.docs
    .map((doc) => {
      try {
        return noteFromDoc(doc);
      } catch (e) {
        return null;
      }
    })
    .filter((note) => note !== null) as ProjectNote[];
  return notes;
}

export async function FetchNote(projectID: string, noteID: string): Promise<ProjectNote> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const noteDoc = await getDoc(doc(db, `users/${currentUser.uid}/projects/${projectID}/notes/${noteID}`));

  return noteFromDoc(noteDoc);
}

export async function CreateNewNote(projectID: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const note: ProjectNote = {
    id: nanoid(),
    name: "Untitled Note",
    dateCreated: new Date(),
    lastUpdated: new Date(),
    content: "",
  };
  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/notes/${note.id}`);
  await setDoc(docRef, note, { merge: true });

  return note;
}

export async function SaveNoteName(projectID: string, noteID: string, name: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/notes/${noteID}`);
  await setDoc(
    docRef,
    {
      lastUpdated: new Date(),
      name: name,
    },
    { merge: true }
  );
}

export async function SaveNoteContent(projectID: string, noteID: string, content?: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/notes/${noteID}`);
  await setDoc(
    docRef,
    {
      lastUpdated: new Date(),
      content: content ?? deleteField(),
    },
    { merge: true }
  );
}

export async function DeleteNote(projectID: string, noteID: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/notes/${noteID}`);
  await deleteDoc(docRef);
}

const noteFromDoc = (doc: DocumentSnapshot<DocumentData>): ProjectNote => {
  const data = doc.data();
  if (!data) throw new Error(`No note with ID ${doc.id} found.`);
  return {
    id: doc.id,
    name: data.name,
    dateCreated: data.dateCreated.toDate(),
    lastUpdated: data.lastUpdated.toDate(),
    content: data.content,
  };
};

export async function StreamAIWriting(
  projectID: string,
  onMessageCreated: (m: AssistantMessage) => void,
  onStreamMessage: (message: string) => void,
  onStreamEnd: () => void,
  prompt?: string,
  userPrompt?: string,
  context?: string,
  useMaterials?: boolean,
  writingStyle?: AIWritingStyleName,
  abortController?: AbortController
) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/writing/prepare-stream`, {
    method: "POST",
    body: JSON.stringify({
      prompt,
      userPrompt,
      context,
      useMaterials,
    }),
    signal: abortController?.signal,
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { streamThreadID } = (await ExtractServerResponse(response)).data;

  await fetchEventSource(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/writing/${streamThreadID}/stream`, {
    method: "POST",
    body: JSON.stringify({
      writingStyle,
    }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    signal: abortController?.signal,
    openWhenHidden: true,
    async onopen(response) {
      if (response.ok && response.headers.get("content-type") === EventStreamContentType) return;
      throw new Error("Failed to open stream.");
    },
    onmessage(msg) {
      if (msg.event === "message_created") {
        onMessageCreated(assistantMessageFromData(JSON.parse(msg.data)));
      } else if (msg.event === "message_snapshot") {
        onStreamMessage(JSON.parse(msg.data));
      } else if (msg.event === "stream_end") {
        abortController?.abort();
        onStreamEnd();
      } else if (msg.event === "stream_error") {
        throw new Error(msg.data);
      }
    },
    onclose() {
      onStreamEnd();
    },
    onerror(err) {
      onStreamEnd();
      throw err;
    },
  });
}

export async function StopAIWritingStream(projectID: string, streamThreadID: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/writing/${streamThreadID}/cancel`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  await ExtractServerResponse(response);
}
