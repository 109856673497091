import CoursableIcons from "../../../utils/CoursableIcons";
import Button from "../../elements/Button";
import { useAuth } from "../../../firebase/AuthContext";
import { Navigate } from "react-router-dom";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { ContextMenuButton, ContextMenuDivider, ContextMenuLabel, ContextMenuSubmenu } from "../../elements/DropdownMenu/ContextMenu";
import CTAButton from "../../elements/CTAButton";
import InputField from "../../elements/InputField";
import InputBox from "../../elements/InputBox";
import { useState } from "react";
import { Notification, useNotifications } from "../../../utils/NotificationsContext";
import Switch from "../../elements/Switch";
import Checkbox from "../../elements/Checkbox";
import Badge from "../../elements/Badge";
import AppPageTemplate from "../../page-elements/AppPageTemplate";
import useTooltipNotification from "../../elements/TooltipNotification";
import CreativitySelector from "../Projects/CreativitySelector";
import { SegmentedPicker, SegmentedPickerItem } from "../../elements/SegmentedPicker";
import { PopupModal, PopupModalContent, PopupModalFooter, PopupModalHeader } from "../../elements/PopupModal";

const UIDesignPage = () => {
  const { currentUser } = useAuth();

  const { TooltipNotificationEmitter, ShowTooltipNotification } = useTooltipNotification();

  if (currentUser?.customClaims.role !== "admin") {
    return <Navigate to="/not-found" />;
  }

  return (
    <AppPageTemplate helmet={<meta name="robots" content="noindex" />}>
      <div className="w-full max-h-full flex flex-col items-center justify-start gap-8 overflow-auto py-16">
        <CreativitySelector />
        <Notifications />
        <div className="flex-centered flex-col">
          <InputsPreview size="lg" />
          <InputsPreview size="default" />
          <InputsPreview size="sm" />
        </div>
        <div className="w-full flex items-start justify-center">
          <div className="flex flex-col items-start justify-start">
            <ButtonVariants variant="default" />
            <ButtonVariants variant="inverse" />
            <ButtonVariants variant="secondary" />
            <ButtonVariants variant="outline" />
            <ButtonVariants variant="ghost" />
            <ButtonVariants variant="link" />
          </div>
          <div className="flex flex-col items-start justify-start">
            <DropdownVariants variant="default" />
            <DropdownVariants variant="inverse" />
            <DropdownVariants variant="secondary" />
            <DropdownVariants variant="outline" />
            <DropdownVariants variant="ghost" />
            <DropdownVariants variant="link" />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start">
          <PickerVariants variant="default" />
          <PickerVariants variant="inverse" />
          <PickerVariants variant="secondary" />
          <PickerVariants variant="outline" />
          <PickerVariants variant="ghost" />
        </div>
        <div className="flex flex-col items-start justify-start gap-4">
          <div className="flex flex-col items-start justify-start">
            <InputFieldVariants variant="outline" />
            <InputFieldVariants variant="ghost" />
          </div>
        </div>
        <div className="flex items-center justify-start gap-4">
          <SwitchVariants />
          <Badge variant="default">Badge</Badge>
          <Badge variant="green">Badge</Badge>
          <Badge variant="blue">Badge</Badge>
          <Badge variant="yellow">Badge</Badge>
          <Badge variant="inverse">Badge</Badge>
          <Badge variant="secondary">Badge</Badge>
          <Badge variant="outline">Badge</Badge>
        </div>
        <div className="flex flex-col items-start justify-start gap-4">
          <CheckmarkVariants variant="outline" />
          <CheckmarkVariants variant="dynamic" />
          <CheckmarkVariants variant="ghost" />
          <CheckmarkVariants variant="bare" />
        </div>
        <div className="flex flex-col items-start justify-start gap-4">
          <div className="flex flex-col items-start justify-start">
            <InputBoxVariants variant="outline" />
            <InputBoxVariants variant="ghost" />
          </div>
        </div>
        <div className="flex-centered gap-4">
          <CTAButton>Call to Action</CTAButton>
          <Button variant="ghost" className="relative" onClick={() => ShowTooltipNotification(<>This is a tooltip notification {CoursableIcons.Copy()}</>)}>
            Tooltip notification
            {TooltipNotificationEmitter}
          </Button>
        </div>
        <PopupModalView />
      </div>
    </AppPageTemplate>
  );
};

export default UIDesignPage;

const ButtonVariants = ({ variant }: { variant?: "default" | "link" | "secondary" | "inverse" | "outline" | "ghost" | null | undefined }) => {
  return (
    <div className="flex-centered gap-4 p-4">
      {["sm", "default", "lg", "icon"].map((size: any) => (
        <Button variant={variant} size={size}>
          {size === "icon" ? CoursableIcons.Delete() : "Button"}
        </Button>
      ))}
      <Button variant={variant} disabled>
        Button
      </Button>
    </div>
  );
};
const DropdownVariants = ({ variant }: { variant?: "default" | "link" | "secondary" | "inverse" | "outline" | "ghost" | null | undefined }) => {
  return (
    <div className="flex gap-4 items-center justify-center p-4">
      {["sm", "default", "lg", "icon"].map((size: any) => (
        <Dropdown variant={variant} size={size} label={size === "icon" ? undefined : "Dropdown"}>
          <ContextMenuLabel label="Dropdown label" />
          <ContextMenuDivider />
          <ContextMenuButton icon={CoursableIcons.Project()} label="Button" />
          <ContextMenuButton icon={CoursableIcons.Text()} label="Next button" />
          <ContextMenuButton icon={CoursableIcons.ChatBubble()} label="Another" />
          <ContextMenuSubmenu label="Submenu">
            <ContextMenuButton icon={CoursableIcons.Project()} label="Button" />
            <ContextMenuButton icon={CoursableIcons.Text()} label="Next button" />
            <ContextMenuButton icon={CoursableIcons.ChatBubble()} label="Another" />
          </ContextMenuSubmenu>
          <ContextMenuDivider />
          <ContextMenuButton icon={CoursableIcons.Delete()} destructive label="Destructive" />
        </Dropdown>
      ))}
      <Dropdown disabled variant={variant} label={"Dropdown"}></Dropdown>
    </div>
  );
};

const InputFieldVariants = ({ variant }: { variant?: "outline" | "ghost" | null | undefined }) => {
  return (
    <div className="flex gap-4 items-center justify-center p-4">
      {["sm", "default", "lg"].map((size: any) => (
        <InputField className="w-[200px]" variant={variant} size={size} placeholder="Input field" />
      ))}
      <InputField className="w-[200px]" variant={variant} icon={CoursableIcons.Edit()} placeholder="Input field" />
      <InputField className="w-[200px]" variant={variant} size="sm" icon={CoursableIcons.Edit()} placeholder="Input field" />
      <InputField className="w-[200px]" variant={variant} disabled placeholder="Input field" />
    </div>
  );
};

const SwitchVariants = () => {
  const [on, setOn] = useState<boolean>(false);
  return (
    <div className="flex gap-4 items-center justify-center p-4">
      <Switch on={on} setOn={setOn} />
      <Switch on={on} setOn={setOn} size="sm" />
    </div>
  );
};

const CheckmarkVariants = ({ variant }: { variant?: "outline" | "ghost" | "bare" | "dynamic" }) => {
  const [on, setOn] = useState<boolean>(false);
  return (
    <div className="flex gap-4 items-center justify-center p-4">
      <Checkbox checked={on} variant={variant} setChecked={setOn} size="sm" />
      <Checkbox checked={on} variant={variant} setChecked={setOn} />
      <Checkbox checked={on} variant={variant} setChecked={setOn} size="lg" />
      <Checkbox checked={on} variant={variant} setChecked={setOn} size="sm" label="Subscribe to our mailing list." />
      <Checkbox checked={on} variant={variant} setChecked={setOn} label="Subscribe to our mailing list." />
      <Checkbox checked={on} variant={variant} setChecked={setOn} size="lg" label="Subscribe to our mailing list." />
    </div>
  );
};

const InputBoxVariants = ({ variant }: { variant?: "outline" | "ghost" | null | undefined }) => {
  const [value, setValue] = useState<string>("");

  return (
    <div className="flex gap-4 items-center justify-center p-4">
      {["sm", "default"].map((size: any) => (
        <InputBox className="w-[200px]" value={value} onValueChange={setValue} variant={variant} size={size} characterLimit={50} placeholder="Input box" />
      ))}
      <InputBox className="w-[200px]" value={value} onValueChange={setValue} variant={variant} disabled characterLimit={50} placeholder="Input box" />
    </div>
  );
};

const PickerVariants = ({ variant }: { variant?: "default" | "secondary" | "inverse" | "outline" | "ghost" | null | undefined }) => {
  return (
    <div className="flex-centered gap-4 p-4">
      {["sm", "default", "lg"].map((size: any) => (
        <SegmentedPicker size={size} variant={variant}>
          <SegmentedPickerItem>{CoursableIcons.GraduationCapFill()} Focused</SegmentedPickerItem>
          <SegmentedPickerItem>{CoursableIcons.SparklesFill()} Regular</SegmentedPickerItem>
          <SegmentedPickerItem disabled>{CoursableIcons.Signature()} Creative</SegmentedPickerItem>
        </SegmentedPicker>
      ))}
      <SegmentedPicker variant={variant} disabled>
        <SegmentedPickerItem>{CoursableIcons.GraduationCapFill()} Focused</SegmentedPickerItem>
        <SegmentedPickerItem>{CoursableIcons.SparklesFill()} Regular</SegmentedPickerItem>
        <SegmentedPickerItem disabled>{CoursableIcons.Signature()} Creative</SegmentedPickerItem>
      </SegmentedPicker>
    </div>
  );
};

const InputsPreview = ({ size }: { size: "sm" | "default" | "lg" }) => {
  return (
    <div className="flex-centered gap-4 p-4">
      <Button size={size} variant="outline">
        Button
      </Button>
      <Dropdown size={size} label="Dropdown">
        <ContextMenuLabel label="Dropdown label" />
        <ContextMenuDivider />
        <ContextMenuButton onClick={() => {}} icon={CoursableIcons.Project()} label="Button" />
        <ContextMenuButton onClick={() => {}} icon={CoursableIcons.Text()} label="Next button" />
        <ContextMenuButton onClick={() => {}} icon={CoursableIcons.ChatBubble()} label="Another" />
        <ContextMenuDivider />
        <ContextMenuButton onClick={() => {}} icon={CoursableIcons.Delete()} destructive label="Destructive" />
      </Dropdown>
      <Checkbox size={size} />
      <InputField size={size} placeholder="Input field" />
      <SegmentedPicker size={size} variant="outline">
        <SegmentedPickerItem>{CoursableIcons.GraduationCapFill()} Focused</SegmentedPickerItem>
        <SegmentedPickerItem>{CoursableIcons.SparklesFill()} Regular</SegmentedPickerItem>
        <SegmentedPickerItem disabled>{CoursableIcons.Signature()} Creative</SegmentedPickerItem>
      </SegmentedPicker>
    </div>
  );
};

const Notifications = () => {
  const { sendInfo, sendError, sendSuccess, sendWarning } = useNotifications();
  return (
    <div className="grid grid-cols-2 gap-4">
      <div onClick={() => sendInfo("Info notification", "Click me and see info notification in action.")}>
        <Notification title="Info notification" description="Click me and see info notification in action." type="info" />
      </div>
      <div onClick={() => sendSuccess("Info notification", "Click me and see success notification in action.")}>
        <Notification title="Success notification" description="Click me and see success notification in action." type="success" />
      </div>
      <div onClick={() => sendWarning("Info notification", "Click me and see warning notification in action.")}>
        <Notification title="Warning notification" description="Click me and see warning notification in action." type="warning" />
      </div>
      <div onClick={() => sendError("Info notification", "Click me and see error notification in action.")}>
        <Notification title="Error notification" description="Click me and see error notification in action." type="error" />
      </div>
    </div>
  );
};

const PopupModalView = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className="w-full flex-centered">
      <Button variant="outline" onClick={() => setOpen(true)}>
        Open debug popup modal
      </Button>
      <PopupModal open={open} Close={() => setOpen(false)} tapToClose className="w-full max-w-5xl">
        <PopupModalHeader containerClassName="bg-red-50">
          <div className="bg-red-100">Header</div>
        </PopupModalHeader>
        <PopupModalContent className="w-full bg-blue-50">
          <div className="h-[2000px] bg-blue-100">Content</div>
        </PopupModalContent>
        <PopupModalFooter className="bg-green-50">
          <div className="bg-green-100">Footer</div>
        </PopupModalFooter>
      </PopupModal>
    </div>
  );
};
