import { useEffect, useState } from "react";
import Dropdown from "./DropdownMenu/Dropdown";
import CoursableIcons from "../../utils/CoursableIcons";
import { EmailShareButton, FacebookShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import Button, { buttonVariants, controlsStyles } from "./Button";
import { cn } from "../../utils/UtilityMethods";
import InputField from "./InputField";
import { PopupModal } from "./PopupModal";
import useResizeListener from "../../utils/Hooks/useResizeListener";

export interface ShareModalConfig {
  link?: string;
  linkAreaChildren?: React.ReactNode;
  socialMediaShareText?: string;
  title?: string;
  description?: string;
  footer?: React.ReactNode;
  showSharingOptions?: boolean;
  showLink?: boolean;
  sharingOptionsContainerClassName?: string;
  dropdownAutoClose?: boolean;
  dropdownLabel?: React.ReactNode;
  dropdownClassName?: string;
  dropdownVariant?: keyof typeof controlsStyles.variants;
  dropdownSize?: keyof typeof controlsStyles.size;
  onOpen?: () => void;
}

const useShareLinkModal = ({ dropdownLabel, dropdownVariant, dropdownSize, dropdownClassName, dropdownAutoClose, onOpen, showSharingOptions = true, showLink = true, ...props }: ShareModalConfig) => {
  const [open, setOpen] = useState(false);
  const [usePopup, setUsePopup] = useState(false);

  useEffect(() => {
    if (open) onOpen?.();
  }, [open]);

  useEffect(() => {
    const handleResize = () => {
      setUsePopup(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const SharePopup = usePopup && (
    <PopupModal open={open} tapToClose={true} Close={() => setOpen(false)}>
      <ShareModal {...props} showSharingOptions={showSharingOptions} showLink={showLink} />
    </PopupModal>
  );

  const ShareDropdown = (
    <Dropdown
      open={open}
      setOpen={setOpen}
      label={
        dropdownLabel ?? (
          <>
            Share
            {CoursableIcons.Share()}
          </>
        )
      }
      autoClose={dropdownAutoClose}
      closeOnClick={false}
      chevron={false}
      variant={dropdownVariant}
      className={dropdownClassName}
      size={dropdownSize}
    >
      {!usePopup && (
        <div className="w-[24rem] relative">
          <ShareModal {...props} showSharingOptions={showSharingOptions} showLink={showLink} />
          <button onClick={() => setOpen(!open)} className="absolute top-4 right-4 text-systemGray-500 hover:text-foreground active:text-systemGray-700 transition-colors">
            {CoursableIcons.Xmark()}
          </button>
        </div>
      )}
    </Dropdown>
  );

  return { SharePopup, ShareDropdown };
};

export default useShareLinkModal;

const ShareModal = ({ link, socialMediaShareText, title, description, showSharingOptions = true, showLink = true, footer, linkAreaChildren, sharingOptionsContainerClassName }: ShareModalConfig) => {
  const [copyLinkSuccess, setCopyLinkSuccess] = useState(false);
  const { width } = useResizeListener();
  const md = width >= 768;

  function CopyLink() {
    navigator.clipboard.writeText(link ?? "");
    setCopyLinkSuccess(true);
    setTimeout(() => setCopyLinkSuccess(false), 2000);
  }

  return (
    <div className="w-full flex flex-col items-start gap-4 p-4 relative">
      <div className="font-semibold text-lg">{title ?? "Share"}</div>
      {description && <div className="text-systemGray-500 text-sm md:text-base">{description}</div>}
      {showLink && (
        <div className={cn("w-full flex-centered gap-4 relative", showSharingOptions && "mb-4")}>
          <InputField className="w-full" variant="ghost" size={md ? "default" : "sm"} value={link} readOnly />
          <Button onClick={CopyLink} size={md ? "icon" : "iconSm"} className="relative">
            {CoursableIcons.Copy(cn("duration-300 ease-out-back", !copyLinkSuccess ? "opacity-100 scale-100" : "opacity-0 scale-0"))}
            {CoursableIcons.Check(cn("absolute duration-300 ease-out-back", copyLinkSuccess ? "opacity-100 scale-100" : "opacity-0 scale-0"))}
          </Button>
          {linkAreaChildren}
        </div>
      )}
      {showSharingOptions && (
        <div className={cn("w-full flex flex-col gap-4", footer && "border-b pb-4", sharingOptionsContainerClassName)}>
          <div className="pb-1 text-left w-full border-b text-systemGray-500 text-sm">Share via</div>
          <div className={cn("grid grid-cols-2 gap-4")}>
            <EmailShareButton url={socialMediaShareText ?? link ?? ""}>
              <div className={cn(buttonVariants({ variant: "outline", className: "w-full", size: `${md ? "default" : "sm"}` }))}>{CoursableIcons.EnvelopeFill()} Email</div>
            </EmailShareButton>
            <WhatsappShareButton url={socialMediaShareText ?? link ?? ""}>
              <div className={cn(buttonVariants({ variant: "outline", className: "w-full", size: `${md ? "default" : "sm"}` }))}>{CoursableIcons.WhatsAppLogo()} WhatsApp</div>
            </WhatsappShareButton>
            <TelegramShareButton url={socialMediaShareText ?? link ?? ""}>
              <div className={cn(buttonVariants({ variant: "outline", className: "w-full", size: `${md ? "default" : "sm"}` }))}>{CoursableIcons.TelegramLogo()} Telegram</div>
            </TelegramShareButton>
            <TwitterShareButton url={socialMediaShareText ?? link ?? ""}>
              <div className={cn(buttonVariants({ variant: "outline", className: "w-full", size: `${md ? "default" : "sm"}` }))}>{CoursableIcons.TwitterLogo()} X (Twitter)</div>
            </TwitterShareButton>
            <FacebookShareButton url={link ?? ""}>
              <div className={cn(buttonVariants({ variant: "outline", className: "w-full", size: `${md ? "default" : "sm"}` }))}>{CoursableIcons.FacebookLogo()} Facebook</div>
            </FacebookShareButton>
            <RedditShareButton title={socialMediaShareText ?? link ?? ""} url={link ?? ""}>
              <div className={cn(buttonVariants({ variant: "outline", className: "w-full", size: `${md ? "default" : "sm"}` }))}>{CoursableIcons.RedditLogo()} Reddit</div>
            </RedditShareButton>
          </div>
        </div>
      )}
      {footer && footer}
    </div>
  );
};
