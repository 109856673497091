import { useEffect, useState } from "react";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { MultipleChoiceQuestion } from "../../../../../backend/Projects/types";
import Badge from "../../../../elements/Badge";
import "../../../../../utils/StringExtensions";
import { cn, WaitFor } from "../../../../../utils/UtilityMethods";
import Button from "../../../../elements/Button";
import LoadingIndicator from "../../../../elements/LoadingIndicator";
import { HomePageMaterialPreview } from "./SummariesGraphic";

const QuizzesGraphic = () => {
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const question = questions[questionIndex];

  const [generating, setGenerating] = useState(false);

  async function StartGenerating() {
    setGenerating(true);
    await WaitFor(1 + Math.random());
    setQuestionIndex((prev) => (prev + 1) % questions.length);
    setGenerating(false);
  }

  return (
    <div className="w-full md:w-[90%] h-full flex flex-col gap-4 justify-start select-none">
      <div className="text-sm text-systemGray-400 border-b border-systemGray-200 w-full font-light -mb-2">Source materials</div>
      <div className="w-full flex items-center justify-start flex-wrap gap-2">
        <HomePageMaterialPreview name="Lecture 4/29" icon={CoursableIcons.PptxDoc("text-red-500")} />
        <HomePageMaterialPreview name="Exam prep recording" icon={CoursableIcons.YouTube("text-red-500")} />
        <HomePageMaterialPreview name="Last exam" icon={CoursableIcons.DocxDoc("text-blue-500")} />
      </div>
      <Question question={question} />
      <div className="w-full flex justify-end">
        <Button onClick={StartGenerating} variant="inverse" size="sm" className={cn("rounded-full relative sm:hidden", generating && "pointer-events-none animate-pulse")}>
          {generating ? <LoadingIndicator className="w-4 h-4" /> : CoursableIcons.SparklesFill()}
          {generating ? "Generating" : "Next"}
        </Button>
        <Button onClick={StartGenerating} variant="inverse" className={cn("rounded-full relative hidden sm:inline-flex", generating && "pointer-events-none animate-pulse")}>
          {generating ? <LoadingIndicator className="w-4 h-4" /> : CoursableIcons.SparklesFill()}
          {generating ? "Generating" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default QuizzesGraphic;

const Question = ({ question }: { question: MultipleChoiceQuestionWithDifficulty }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const optionLetter = (index: number): string => {
    return ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"][index].toUpperCase();
  };

  useEffect(() => {
    setSelectedIndex(null);
  }, [question]);

  return (
    <div className="flex flex-col justify-between gap-2 duration-300 w-full bg-background p-4 rounded-xl shadow-md relative">
      <div className="w-full flex items-baseline justify-between gap-2">
        <div className="h-12 w-full font-semibold text-sm md:text-base line-clamp-2 text-left text-foreground">{question.question}</div>
        <Badge variant={question.difficulty === "easy" ? "green" : question.difficulty === "medium" ? "yellow" : "inverse"}>{question.difficulty.capitalize()}</Badge>
      </div>
      <div className="w-full flex flex-col gap-1">
        {question.choices.map((text, index) => {
          return <Option key={index} Select={() => setSelectedIndex(index)} choice={text} isCorrect={question.correctChoice === index} isSelected={selectedIndex === index} optionLetter={optionLetter(index)} />;
        })}
      </div>
    </div>
  );
};

const Option = ({ choice, optionLetter, isCorrect, isSelected, Select }: { choice: string; optionLetter: string; isCorrect: boolean; isSelected: boolean; Select: () => void }) => {
  const [reveal, setReveal] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setReveal(isSelected);
    }, 150);
  }, [isSelected]);

  return (
    <button
      onClick={() => Select()}
      className={`flex items-start justify-start gap-2 md:gap-4 border border-transparent hover:bg-systemGray-100 hover:border-systemGray-300 active:bg-systemGray-200 hover:shadow duration-100 rounded-xl p-1`}
    >
      <div
        style={{
          transform: isSelected ? "rotateY(180deg)" : "rotateY(0deg)",
          transition: "transform 0.3s linear",
        }}
        className={`h-6 w-6 md:h-7 md:w-7 flex-centered aspect-square ${
          reveal ? (isCorrect ? "bg-green-200 dark:bg-green-950 border-green-500 text-green-500" : "bg-red-200 dark:bg-red-950 border-red-500 text-red-500") : "bg-systemGray-200 text-systemGray-500"
        } border border-systemGray-200 text-xs rounded-full`}
      >
        {reveal ? (isCorrect ? CoursableIcons.Check("-scale-x-100") : CoursableIcons.Xmark()) : optionLetter}
      </div>
      <div className={`text-foreground text-sm md:text-base text-left mr-2 translate-y-0.5 ${reveal ? (isCorrect ? "text-green-500" : "text-red-500") : "text-black"}`}>{choice}</div>
    </button>
  );
};

interface MultipleChoiceQuestionWithDifficulty extends MultipleChoiceQuestion {
  difficulty: "easy" | "medium" | "hard";
}
const questions: MultipleChoiceQuestionWithDifficulty[] = [
  {
    question: "Which organelle is known as the powerhouse of the cell?",
    choices: ["Mitochondria", "Endoplasmic reticulum", "Golgi apparatus", "Nucleus"],
    correctChoice: 0,
    difficulty: "easy",
  },
  {
    question: "What type of bond links amino acids in a protein?",
    choices: ["Hydrogen bond", "Ionic bond", "Peptide bond", "Covalent bond"],
    correctChoice: 2,
    difficulty: "hard",
  },
  {
    question: "Which enzyme is responsible for unwinding DNA?",
    choices: ["Polymerase", "Helicase", "Ligase", "Topoisomerase"],
    correctChoice: 1,
    difficulty: "medium",
  },
  {
    question: "Which compound is responsible for carrying oxygen in the blood?",
    choices: ["Glucose", "Platelets", "Plasma", "Hemoglobin"],
    correctChoice: 3,
    difficulty: "medium",
  },
  {
    question: "What is the process by which plants make their food?",
    choices: ["Respiration", "Photosynthesis", "Digestion", "Fermentation"],
    correctChoice: 1,
    difficulty: "easy",
  },
];
