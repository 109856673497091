import { useEffect, useRef, useState } from "react";

const useDidScroll = <T extends HTMLElement>(onScroll?: (values: IOnScroll) => void) => {
  const scrollRef = useRef<T | null>(null);
  const [didScroll, setDidScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollRef.current) return setDidScroll(false);
      onScroll?.({
        scrollTop: scrollRef.current.scrollTop,
        scrollHeight: scrollRef.current.scrollHeight,
        scrollProgress: scrollRef.current.scrollTop / (scrollRef.current.scrollHeight - scrollRef.current.clientHeight),
      });
      setDidScroll(scrollRef.current.scrollTop > 0);
    };

    scrollRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { scrollRef, didScroll };
};

export default useDidScroll;

export interface IOnScroll {
  scrollTop: number;
  scrollHeight: number;
  scrollProgress: number;
}
