import { TypeAnimation } from "react-type-animation";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import CoursableLogo from "../../../../elements/CoursableLogo";
import { useState } from "react";
import { Document, Video } from "../HeroGraphic";

const ChatGraphic = () => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [answer, setAnswer] = useState("");

  function ShowAnswer(index: number) {
    setShowAnswer(true);
    setAnswer(answers[index]);
  }
  function HideAnswer() {
    setShowAnswer(false);
  }

  const questions = [`Please summarize this paper.`, `Who are the authors?`, `Can you pull key insights from this article?`, "Write a short report on each point."];
  const answers = [
    `The document is a review article titled "Apple phytochemicals and their health benefits" published in the Nutrition Journal. It discusses the health benefits of apples, their phytochemical composition, and their impact on chronic diseases such as cardiovascular disease, cancer, asthma, and diabetes.`,
    `Looks like this paper was written by John Doe and Rick Anderson in May 2004.`,
    `Sure! There are several key insights on the health benefits of apples and their phytochemicals: 1) Apples contain a large concentration of flavonoids as well as a variety of other phytochemicals, with the concentration of these phytochemicals varying between apple peels and apple flesh.`,
    `Report on Apple Phytochemicals: Apples are rich in phytochemicals including a large concentration of flavonoids and a variety of other phytochemicals. The concentration of these phytochemicals varies greatly between the apple peels and the apple flesh.`,
  ];

  return (
    <div className="w-full flex justify-end">
      <div className="w-full md:w-5/6 flex flex-col select-none">
        <div className="w-full h-32 flex items-start justify-center overflow-clip relative">
          <Video className="w-56 absolute bg-background right-10 top-10 shadow" />
          <Document image="/Images/Home/Projects/Doc1.png" className="w-40 -translate-x-20 shadow" />
        </div>
        <div className="w-full flex items-center justify-center gap-1 rounded-xl shadow-lg py-3 px-4 border border-systemGray-200 bg-background z-[1]">
          <div className="text-systemGray-400 font-light text-sm md:text-base">Ask:</div>
          <TypeAnimation
            className="w-full text-sm md:text-base line-clamp-1 text-foreground"
            repeat={Infinity}
            speed={60}
            deletionSpeed={80}
            sequence={questions.map((q, i) => [q, 300, () => ShowAnswer(i), 5000, () => HideAnswer()]).flat()}
          />
          {CoursableIcons.Send("text-systemGray-400")}
        </div>
        <div className="w-full flex p-4 overflow-y-clip z-0 relative">
          <div className="w-full h-20 bg-transparent" />
          <div className={`absolute left-4 right-4 flex gap-2 px-3 p-2 bg-background rounded-lg shadow ${showAnswer ? "opacity-100 top-4" : "-top-16 opacity-0"} duration-500 ease-out-back`}>
            <CoursableLogo size={8} />
            <div className="w-full flex flex-col text-mini md:text-sm text-foreground">
              <div className="font-semibold">Coursable Assistant</div>
              <div className="line-clamp-2">{answer}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatGraphic;
