import { EditorExtensions } from "../../Projects/Workspace/Notes/NoteEditor";
import { BubbleMenu, EditorProvider, useCurrentEditor } from "@tiptap/react";
import { BubbleControls, Controls } from "../../Projects/Workspace/Notes/EditorControls";
import { cn } from "../../../../utils/UtilityMethods";
import { AIWritingTool, AIWritingToolStyle } from "../../Projects/Hooks/useProjectNotes";
import { useRef, useState } from "react";
import Button from "../../../elements/Button";
import CoursableIcons from "../../../../utils/CoursableIcons";

const DemoTextEditor = () => {
  const [writingToolStyle, setWritingToolStyle] = useState<AIWritingToolStyle | null>(null);
  const [writingTool, setWritingTool] = useState<AIWritingTool | null>(null);

  const [showTool, setShowTool] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  function OnToolClick(tool: AIWritingTool) {
    setWritingTool(tool);
    setShowTool(true);

    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setShowTool(false);
    }, 5000);
  }

  function CloseTool() {
    if (timeout.current) clearTimeout(timeout.current);
    setShowTool(false);
  }

  return (
    <EditorProvider
      content={`<p class="leading-normal mb-4">Try our rich<strong> AI writing workspace</strong> that knows your <code class="bg-brand-50 text-brand-500 rounded px-1">uploaded materials</code> to help you <s>be more creative</s> write better.</p>`}
      extensions={EditorExtensions}
      editorProps={{
        attributes: {
          class: "focus:outline-none bg-background dark:bg-systemGray-100 p-8 pb-20 cursor-text w-full rounded-xl shadow-xl-c dark:shadow-systemGray-200 border-2 border-brand-100 dark:border-brand-200",
        },
      }}
      enableInputRules={false}
      enablePasteRules={false}
      slotBefore={
        <div className="flex-centered flex-col bg-background -mx-8 relative">
          <Controls aiToolGroups={["Your materials"]} OnToolClick={(tool) => OnToolClick(tool)} writingToolStyle={writingToolStyle} setWritingToolStyle={setWritingToolStyle} hideFont />
          <div className="h-8 bg-background w-full" />
          <div className={cn("absolute left-8 right-8 flex-centered z-1 duration-300 ease-out-back", showTool ? "opacity-100 top-12 scale-100" : "opacity-0 top-0 pointer-events-none scale-95")}>
            <AIToolDemoView tool={writingTool} Close={CloseTool} />
          </div>
        </div>
      }
    >
      <BubbleMenu tippyOptions={{ duration: 100 }} editor={null} className={cn("min-w-min duration-200 relative")}>
        <div className={cn("flex-centered w-full shadow-lg rounded-lg border bg-background duration-200", showTool ? "opacity-0" : "opacity-100")}>
          <BubbleControls aiToolGroups={["General writing"]} OnToolClick={(tool) => OnToolClick(tool)} writingToolStyle={writingToolStyle} setWritingToolStyle={setWritingToolStyle} />
        </div>
      </BubbleMenu>
    </EditorProvider>
  );
};

export default DemoTextEditor;

const AIToolDemoView = ({ tool, Close }: { tool: AIWritingTool | null; Close: () => void }) => {
  return (
    <div className="bg-background p-4 rounded-lg border shadow-md flex-centered">
      <div className="text-brand-500 text-lg mr-2">{CoursableIcons.SparklesFill()}</div>
      Start using
      <div className="bg-brand-25 text-brand-500 font-medium flex-centered gap-1 mx-1 rounded-md border border-brand-200 px-2">
        {tool?.icon}
        {tool?.label}
      </div>
      now
      <Button onClick={Close} className="ml-14 text-systemGray-400 hover:text-foreground" size="iconSm" variant="ghost">
        {CoursableIcons.Xmark()}
      </Button>
      <Button to="/app/projects" className="ml-2" size="sm">
        Try Projects Now
      </Button>
    </div>
  );
};
