import { auth, GetIDToken } from "../firebase/FirebaseConfig";
import { GAEvent, GALogBeginCheckout, GALogEvent } from "../firebase/GoogleAnalytics";
import { CoursablePlan, CoursablePlanInterval } from "../utils/CoursablePlan";
import { ExtractServerResponse } from "./Shared";

export async function BuyPlan(plan: CoursablePlan, interval: CoursablePlanInterval) {
  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/buy/${plan.code}/${interval}`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  const { url } = (await ExtractServerResponse(response)).data;

  GALogBeginCheckout(plan, interval);

  window.location.href = url;
}

export async function OpenStripePortal() {
  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/stripe/portal`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  const { url } = (await ExtractServerResponse(response)).data;

  GALogEvent(GAEvent.Sales.stripePortalOpened);

  window.location.href = url;
}

export async function FetchTrialStatus(): Promise<CoursableTrialStatus> {
  if (!auth.currentUser) return "eligible";

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/trial/status`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  const { trialStatus } = (await ExtractServerResponse(response)).data;

  return trialStatus;
}

export type CoursableTrialStatus = "eligible" | "not-eligible" | "active" | "ended";

export async function ResetPlanLimit() {
  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/plan/reset`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  await ExtractServerResponse(response);
}
