import { ReactNode, useEffect, useState } from "react";
import { cn } from "../../utils/UtilityMethods";

const useTooltipNotification = ({ emmiterClassName }: { emmiterClassName?: string } = {}) => {
  const [tooltips, setTooltips] = useState<{ id: string; content: React.ReactNode; dir?: "up" | "down"; className?: string; duration?: number }[]>([]);

  function ShowTooltipNotification(content: React.ReactNode, { dir, className, duration }: { dir?: "up" | "down"; className?: string; duration?: number } = { dir: "up" }) {
    const id = Math.random().toString(36).substring(7);
    setTooltips((prev) => [...prev, { id, dir, content, className, duration }]);
  }

  const TooltipNotificationEmitter = (
    <div className={cn("pointer-events-none absolute inset-0", emmiterClassName)}>
      {tooltips.map((tooltip) => (
        <TooltipNotificationView
          key={tooltip.id}
          duration={tooltip.duration}
          className={tooltip.className}
          dir={tooltip.dir}
          Die={() => {
            setTooltips((prev) => prev.filter((tt) => tooltip.id !== tt.id));
          }}
        >
          {tooltip.content}
        </TooltipNotificationView>
      ))}
    </div>
  );

  return { TooltipNotificationEmitter, ShowTooltipNotification };
};

export default useTooltipNotification;

const TooltipNotificationView = ({ children, dir, duration, className, Die }: { dir?: "up" | "down"; children?: ReactNode; duration?: number; className?: string; Die: () => void }) => {
  const [hide, setHide] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);

    setTimeout(() => {
      setHide(true);
      setTimeout(() => {
        Die();
      }, 300);
    }, (duration ?? calculateDuration(children)) * 1000);
  }, []);

  return (
    <div
      className={cn(
        "flex-centered gap-1 text-foreground absolute left-1/2 -translate-x-1/2 pointer-events-none text-sm px-2 py-1 bg-systemGray-200/50 backdrop-blur-lg rounded-md border shadow-md dark:shadow-systemGray-200 duration-300 ease-out",
        dir === "up" ? "bottom-1/2" : "top-1/2",
        show ? cn("opacity-100", dir === "up" ? "-translate-y-8" : "translate-y-8") : " opacity-0",
        hide && "opacity-0",
        className
      )}
    >
      {children}
    </div>
  );
};

const calculateDuration = (text: string | ReactNode): number => {
  const wordsPerMin = 200.0; // Average read speed in English is 220, we reduce it to 200 for safety.
  const wordsPerSec = wordsPerMin / 60.0;

  let nWords = 0;
  if (typeof text === "string") {
    nWords = text.split(" ").length;
  } else {
    const valueOf = text?.valueOf() as any;
    if (!valueOf) return 3;
    const children = valueOf.props.children as ReactNode[];
    if (!children || children.length === 0) return 3;

    nWords = children.reduce((acc: number, child: ReactNode) => {
      if (typeof child === "string") {
        return acc + child.split(" ").length;
      } else {
        return acc;
      }
    }, 0);
  }

  return 1.5 + nWords / wordsPerSec; //We also add little time for safety and easiness.
};
