const Circle = ({ size, strokePercentage, color, pct }: { size: number; strokePercentage: number; color: string; pct: number }) => {
  const stroke = size * strokePercentage;
  const r = (size - stroke) / 2;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - (pct ?? 100)) * circ) / 100;
  return (
    <circle
      className={`${strokePct === circ ? "hidden" : ""} ${color}`}
      r={r}
      cx={size / 2}
      cy={size / 2}
      fill="transparent"
      strokeWidth={`${stroke}px`}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const FillCircle = ({ fill, size = 200, strokePercentage = 0.1, fillColor = "stroke-brand-500", bgColor = "stroke-systemGray-200" }: { fill: number; strokePercentage?: number; size?: number; bgColor?: string; fillColor?: string }) => {
  const cleanPercentage = (percentage: number) => {
    const tooLow = !Number.isFinite(+percentage) || percentage < 0;
    const tooHigh = percentage > 100;
    return tooLow ? 0 : tooHigh ? 100 : +percentage;
  };

  const pct = cleanPercentage(fill);

  return (
    <svg width={size} height={size}>
      <g transform={`rotate(-90 ${`${size / 2} ${size / 2}`})`}>
        <Circle size={size} strokePercentage={strokePercentage} pct={100} color={bgColor} />
        <Circle size={size} strokePercentage={strokePercentage} pct={pct} color={fillColor} />
      </g>
    </svg>
  );
};

export default FillCircle;
