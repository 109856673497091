import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../utils/UtilityMethods";
import { ButtonHTMLAttributes } from "react";
import LoadingIndicator from "./LoadingIndicator";
import { Link } from "react-router-dom";

interface Props extends ButtonHTMLAttributes<HTMLElement>, VariantProps<typeof buttonVariants> {
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  to?: string;
  loading?: boolean;
}

export const controlsStyles = {
  base: "text-foreground stroke-foreground rounded-md font-medium text-base transition-colors disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none select-none",
  disabled: "pointer-events-none opacity-50 shadow-none",
  variants: {
    default: "text-white stroke-white bg-brand-500 hover:bg-brand-500/80 active:bg-brand-500 shadow-lg shadow-brand-500/20 dark:shadow-brand-500/30 focus-visible:ring-brand-700",
    secondary: "bg-systemGray-300 hover:bg-systemGray-300/80 active:bg-systemGray-300 shadow-lg shadow-systemGray-500/20 focus-visible:ring-systemGray-500",
    outline: "border border-systemGray-300 bg-systemGray-100 active:bg-systemGray-200 shadow-sm hover:shadow dark:hover:shadow-systemGray-300 focus-visible:ring-systemGray-300",
    ghost: "bg-transparent hover:bg-systemGray-100 active:bg-systemGray-200 focus-visible:ring-systemGray-200",
    link: "underline-offset-4 hover:underline active:opacity-80 focus-visible:ring-systemGray-200",
    inverse: "bg-brand-50 dark:bg-brand-100/80 text-brand-500 stroke-brand-500 hover:bg-brand-100/80 dark:hover:bg-brand-200/60 active:bg-brand-100/60 dark:active:bg-brand-200/50 focus-visible:ring-brand-500",
  },
  size: {
    sm: "h-8 px-3 text-sm",
    default: "h-9 px-4 py-2",
    lg: "h-10 px-8",
    icon: "h-9 w-9 shrink-0",
    iconSm: "h-8 w-8 shrink-0",
  },
};

export const buttonVariants = cva(["inline-flex items-center justify-center gap-2 whitespace-nowrap focus-visible:outline-none focus-visible:ring-2", controlsStyles.base], {
  variants: {
    variant: controlsStyles.variants,
    size: controlsStyles.size,
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

const Button = ({ className, to, onClick, size, variant, children, loading, ...props }: Props) => {
  const content = (
    <>
      {loading && <LoadingIndicator className={cn("w-4 h-4", size === "sm" && "w-3 h-3")} />}
      {loading && size === "icon" ? null : children}
    </>
  );

  return to === undefined ? (
    <button {...props} onClick={(e) => onClick?.(e)} className={cn(buttonVariants({ className, size, variant }))}>
      {content}
    </button>
  ) : (
    <Link to={to} {...props} onClick={(e) => onClick?.(e)} className={cn(buttonVariants({ className, size, variant }))}>
      {content}
    </Link>
  );
};

export default Button;
