import { GetIDToken } from "../firebase/FirebaseConfig";
import { ExtractServerResponse } from "./Shared";

export async function DuplicateCourseToDatabase(course: Course) {
  const idToken = await GetIDToken();

  const params = new URLSearchParams();
  params.append("ID", course.id);

  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/course/duplicate?${params}`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  await ExtractServerResponse(response);
}
