import Button from "../../../../elements/Button";

const ProjectInfoBlock = ({ side, header, icon, title, description, Media }: { side: "left" | "right"; header: string; icon: React.ReactNode; title: string; description: string; Media: React.ReactNode }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-12 sm:gap-4 justify-between items-center w-full px-4 md:px-0">
      {side === "right" && <div className={`hidden sm:block w-full sm:w-1/2`}>{Media}</div>}
      <div className={`w-full sm:w-1/2 flex flex-col text-center items-center ${side === "left" ? "sm:text-left sm:items-start" : "sm:text-right sm:items-end"}`}>
        <h4 className="text-brand-500 flex items-center justify-start gap-2 mb-2">
          {side === "right" && header}
          <div className="text-xl">{icon}</div>
          {side === "left" && header}
        </h4>
        <h3 className={`font-semibold text-xl sm:text-2xl mb-6 text-foreground`}>{title}</h3>
        <p className="text-systemGray-500 sm:max-w-sm text-sm sm:text-base sm:mb-8">{description}</p>
        <div className={`w-full block sm:hidden py-16`}>{Media}</div>
        <Button to={"/app/projects/new"} variant="default">
          Try {header.toLowerCase()} for free
        </Button>
      </div>
      {side === "left" && <div className={`hidden sm:block w-full sm:w-1/2`}>{Media}</div>}
    </div>
  );
};

export default ProjectInfoBlock;
