import { type ClassValue, clsx } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const extendedTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      shadow: [{ shadow: ["md-c", "lg-c", "xl-c", "2xl-c"] }],
      "font-size": [{ text: ["mini", "micro"] }],
      animate: [{ animate: ["error", "float", "cornerShake"] }],
      h: [{ h: ["screen"] }],
      w: [{ w: ["screen"] }],
      scale: [{ scale: ["60", "80", "101", "102"] }],
      z: [{ z: ["1", "2", "3"] }],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return extendedTwMerge(clsx(inputs));
}

export async function WaitFor(seconds: number) {
  await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export const isValidCourseStructure = (object: any): Boolean => {
  const isValidCourseStructureTopic = (topic: CourseStructure.Topic): Boolean => {
    return topic.title !== undefined && topic.description !== undefined && topic.search_term !== undefined;
  };

  return object.id !== undefined && object.title !== undefined && object.topics !== undefined && object.topics.every(isValidCourseStructureTopic);
};

export const getFileExtension = (filename: string): string | undefined => {
  const fname = filename.toLowerCase();
  return fname.slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1) || undefined;
};

function isFirestoreTimestamp(obj: any) {
  return obj.hasOwnProperty("_seconds") && obj.hasOwnProperty("_nanoseconds");
}

function firestoreTimestampToDate(timestamp: { _seconds: number; _nanoseconds: number }) {
  return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
}
export function convertTimestampsToDates(obj: any) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (isFirestoreTimestamp(value)) {
        obj[key] = firestoreTimestampToDate(value);
      } else if (typeof value === "object" && value !== null) {
        convertTimestampsToDates(value);
      }
    }
  }
}

export const hexToHsb = (hex: string) => {
  let r = parseInt(hex.substring(1, 3), 16) / 255;
  let g = parseInt(hex.substring(3, 5), 16) / 255;
  let b = parseInt(hex.substring(5, 7), 16) / 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0,
    s,
    v = max; // Initialize h to 0

  let d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max !== min) {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return [h, s, v];
};
