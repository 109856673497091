import { useEffect, useRef, useState } from "react";
import { Flashcard, FlashcardDifficulty, MultipleChoiceQuestion } from "../../../../backend/Projects/types";
import CoursableIcons from "../../../../utils/CoursableIcons";
import "../../../../utils/StringExtensions";
import { cn } from "../../../../utils/UtilityMethods";
import OnOffTransition from "../../../elements/OnOffTransition";

const MessageAttachments = ({ attachments }: { attachments: MessageAttachment[] }) => {
  if (attachments.length === 0) return null;

  return (
    <div className="flex gap-2 pt-2 pb-4 flex-wrap">
      {attachments.map((attachment, index) => {
        switch (attachment.type) {
          case "Flashcard":
            return <FlashcardAttachment key={index} flashcard={attachment.content} />;
          case "MultipleChoiceQuestion":
            return <MultipleChoiceQuestionAttachment key={index} question={attachment.content} />;
          default:
            return null;
        }
      })}
    </div>
  );
};

export default MessageAttachments;

const FlashcardAttachment = ({ flashcard, className }: { flashcard: Flashcard; className?: string }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  function UpdateHeight() {
    if (!ref.current) return;

    setHeight(ref.current.offsetHeight);
  }

  useEffect(() => {
    UpdateHeight();
  }, [showAnswer]);

  return (
    <button
      onTransitionEnd={(e) => {
        if (e.propertyName === "max-width") UpdateHeight();
      }}
      onClick={() => setShowAnswer(!showAnswer)}
      className={cn("text-left flex-started flex-col bg-background rounded-lg border p-2 shadow relative group/flashcard max-w-[300px] hover:max-w-[450px] duration-200 ease-in", className)}
    >
      <div className="w-full flex items-center justify-start gap-1">
        {CoursableIcons.Flashcard("text-brand-500 text-sm")}
        <div className="text-mini text-brand-500 whitespace-pre-wrap">Flashcard |</div>
        <OnOffTransition className="inline text-mini text-brand-500" scale={false} trigger={!showAnswer} onElement="Question" offElement="Answer" horizontalAlign="left" />
      </div>
      <div
        style={{
          height: ref.current ? `${height}px` : "auto",
        }}
        className="duration-200 overflow-hidden ease-out"
      >
        <div ref={ref} className={cn("text-systemGray-700 group-hover/flashcard:text-foreground text-sm line-clamp-2 group-hover/flashcard:line-clamp-none")}>
          {showAnswer ? flashcard.answer : flashcard.question}
        </div>
      </div>
      <div className="absolute top-2 right-2 text-sm text-systemGray-500 hover:text-foreground active:text-systemGray-700 duration-100 opacity-0 group-hover/flashcard:opacity-100">
        <OnOffTransition trigger={showAnswer} onElement={CoursableIcons.Eye()} offElement={CoursableIcons.EyeSlash()} />
      </div>
    </button>
  );
};

const MultipleChoiceQuestionAttachment = ({ question, className }: { question: MultipleChoiceQuestion; className?: string }) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  function UpdateHeight() {
    if (!ref.current) return;

    setHeight(ref.current.offsetHeight);
  }

  useEffect(() => {
    UpdateHeight();
  }, []);

  return (
    <div
      onTransitionEnd={(e) => {
        if (e.propertyName === "max-width") UpdateHeight();
      }}
      className={cn("text-left flex-started flex-col bg-background rounded-lg border p-2 shadow relative group/multipleChoiceQuestion max-w-[300px] hover:max-w-[450px] duration-200 ease-in", className)}
    >
      <div className="w-full flex items-center justify-start gap-1">
        {CoursableIcons.Quizzes("text-brand-500 text-sm")}
        <div className="text-mini text-brand-500 whitespace-pre-wrap">Quiz | Multiple choice question</div>
      </div>
      <div
        style={{
          height: ref.current ? `${height}px` : "auto",
        }}
        className="duration-200 overflow-hidden ease-out"
      >
        <div ref={ref} className={cn("text-systemGray-700 group-hover/multipleChoiceQuestion:text-foreground text-sm line-clamp-2 group-hover/multipleChoiceQuestion:line-clamp-none")}>
          <div>{question.question}</div>
          <div className="hidden group-hover/multipleChoiceQuestion:flex flex-col ml-4 pt-1">
            {question.choices.map((choice, index) => (
              <div key={index} className={index === question.correctChoice - 1 ? "text-brandGreen-500 font-semibold" : "text-systemGray-500"}>
                {alphabet[index]}. {choice}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const useAttachments = (message: string): { cleanMessage: string; attachments: MessageAttachment[] } => {
  const { cleanMessage, rawAttachments } = ParseAttachments(message);

  const attachments: MessageAttachment[] = rawAttachments
    .map((raw) => {
      switch (raw.type) {
        case "Flashcard":
          return { type: "Flashcard", content: rawToFlashcard(raw.content) };
        case "MultipleChoiceQuestion":
          return { type: "MultipleChoiceQuestion", content: rawToMultipleChoiceQuestion(raw.content) };
        default:
          return null;
      }
    })
    .filter((f) => f !== null) as MessageAttachment[];

  return { cleanMessage, attachments };
};

function ParseAttachments(message: string): IRawAttachments {
  const regex = /\[ATTACHMENT - [\s\S]*?\]/g;
  if (!regex.test(message)) return { cleanMessage: message, rawAttachments: [] };

  const rawAttachments = (message.match(regex) as string[])
    .map((raw) => {
      const parts = raw.replace("[ATTACHMENT - ", "").replace("]", "").trim().split("\n");
      if (parts.length === 0) return null;

      const attachmentType = parts[0];
      if (!attachmentTypes.includes(attachmentType)) return null;

      const content = parts.slice(1).join("\n");
      return {
        type: attachmentType,
        content,
      };
    })
    .filter((f) => f !== null) as { type: AttachmentType; content: string }[];

  const cleanMessage = message.replace(regex, "");

  return { cleanMessage, rawAttachments };
}

interface IRawAttachments {
  cleanMessage: string;
  rawAttachments: { type: AttachmentType; content: string }[];
}

const rawToFlashcard = (raw: string): Flashcard | null => {
  const parts = raw.split("\n");

  const question = parts
    .find((p) => p.startsWith("Question:"))
    ?.replace("Question:", "")
    .trim();
  const answer = parts
    .find((p) => p.startsWith("Answer:"))
    ?.replace("Answer:", "")
    .trim();
  const difficulty = parts
    .find((p) => p.startsWith("Difficulty:"))
    ?.replace("Difficulty:", "")
    .trim();

  if (!question || !answer || !difficulty) return null;

  return {
    question,
    answer,
    difficulty: difficulty as FlashcardDifficulty,
  };
};

const rawToMultipleChoiceQuestion = (raw: string): MultipleChoiceQuestion | null => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const parts = raw.split("\n");

  const question = parts
    .find((p) => p.startsWith("Question:"))
    ?.replace("Question:", "")
    .trim();
  const choices = parts.filter((p) => p.startsWith("Option ")).map((p) => p.replace(/Option\s[A-Za-z]\./g, "").trim());
  const correctAnswer = parts
    .find((p) => p.startsWith("Correct answer:"))
    ?.replace("Correct answer:", "")
    .replace("Option ", "")
    .trim();

  if (!question || !choices || choices.length === 0 || !correctAnswer) return null;

  return {
    question,
    choices,
    correctChoice: alphabet.indexOf(correctAnswer) + 1,
  };
};

export function AttachAttachment(message: string, attachment: MessageAttachment): string {
  switch (attachment.type) {
    case "Flashcard":
      return AttachFlashcard(message, attachment.content);
    case "MultipleChoiceQuestion":
      return AttachMultipleChoiceQuestion(message, attachment.content);
  }
}

function AttachFlashcard(message: string, flashcard: Flashcard): string {
  const attachment = `[ATTACHMENT - Flashcard
Question: ${flashcard.question}
Answer: ${flashcard.answer}
Difficulty: ${flashcard.difficulty}
]`;

  return `${attachment}\n\n${message}`;
}

function AttachMultipleChoiceQuestion(message: string, question: MultipleChoiceQuestion): string {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const attachment = `[ATTACHMENT - MultipleChoiceQuestion
Question: ${question.question}
${question.choices.map((option, i) => `Option ${alphabet[i]}. ${option}`).join("\n")}
Correct answer: Option ${alphabet[question.correctChoice - 1]}
]`;

  return `${attachment}\n\n${message}`;
}

const attachmentTypes = ["Flashcard", "MultipleChoiceQuestion"];
type AttachmentType = (typeof attachmentTypes)[number];

export type MessageAttachment = FlashcardAttachment | MultipleChoiceQuestionAttachment;

interface FlashcardAttachment {
  type: "Flashcard";
  content: Flashcard;
}

interface MultipleChoiceQuestionAttachment {
  type: "MultipleChoiceQuestion";
  content: MultipleChoiceQuestion;
}
