// Generated with https://app.haikei.app/

import { useTheme } from "../../../utils/ThemeContext";

export const LargeWaves = ({ className }: { className?: string }) => {
  const { appliedTheme } = useTheme();
  const dark = appliedTheme === "dark";

  return (
    <svg className={className} id="visual" width="5000" height="200" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <path
        d="M0 99L39.7 89.5C79.3 80 158.7 61 238 59.2C317.3 57.3 396.7 72.7 476 82.2C555.3 91.7 634.7 95.3 714 91.3C793.3 87.3 872.7 75.7 952 74.7C1031.3 73.7 1110.7 83.3 1190.2 88.8C1269.7 94.3 1349.3 95.7 1428.8 88C1508.3 80.3 1587.7 63.7 1667 54.2C1746.3 44.7 1825.7 42.3 1905 49.5C1984.3 56.7 2063.7 73.3 2143 74.3C2222.3 75.3 2301.7 60.7 2381 52.2C2460.3 43.7 2539.7 41.3 2619 48.5C2698.3 55.7 2777.7 72.3 2857 72.3C2936.3 72.3 3015.7 55.7 3095 56.2C3174.3 56.7 3253.7 74.3 3333 79.8C3412.3 85.3 3491.7 78.7 3571.2 75.3C3650.7 72 3730.3 72 3809.8 73.8C3889.3 75.7 3968.7 79.3 4048 79.7C4127.3 80 4206.7 77 4286 79C4365.3 81 4444.7 88 4524 90.5C4603.3 93 4682.7 91 4762 80.8C4841.3 70.7 4920.7 52.3 4960.3 43.2L5000 34L5000 201L4960.3 201C4920.7 201 4841.3 201 4762 201C4682.7 201 4603.3 201 4524 201C4444.7 201 4365.3 201 4286 201C4206.7 201 4127.3 201 4048 201C3968.7 201 3889.3 201 3809.8 201C3730.3 201 3650.7 201 3571.2 201C3491.7 201 3412.3 201 3333 201C3253.7 201 3174.3 201 3095 201C3015.7 201 2936.3 201 2857 201C2777.7 201 2698.3 201 2619 201C2539.7 201 2460.3 201 2381 201C2301.7 201 2222.3 201 2143 201C2063.7 201 1984.3 201 1905 201C1825.7 201 1746.3 201 1667 201C1587.7 201 1508.3 201 1428.8 201C1349.3 201 1269.7 201 1190.2 201C1110.7 201 1031.3 201 952 201C872.7 201 793.3 201 714 201C634.7 201 555.3 201 476 201C396.7 201 317.3 201 238 201C158.7 201 79.3 201 39.7 201L0 201Z"
        fill={dark ? "#2c0707" : "#efa4a4"}
      ></path>
      <path
        d="M0 99L39.7 98C79.3 97 158.7 95 238 94.2C317.3 93.3 396.7 93.7 476 96.7C555.3 99.7 634.7 105.3 714 106.7C793.3 108 872.7 105 952 104.8C1031.3 104.7 1110.7 107.3 1190.2 102.5C1269.7 97.7 1349.3 85.3 1428.8 85.2C1508.3 85 1587.7 97 1667 98.8C1746.3 100.7 1825.7 92.3 1905 88.3C1984.3 84.3 2063.7 84.7 2143 85.7C2222.3 86.7 2301.7 88.3 2381 88.2C2460.3 88 2539.7 86 2619 88.2C2698.3 90.3 2777.7 96.7 2857 100.8C2936.3 105 3015.7 107 3095 108.7C3174.3 110.3 3253.7 111.7 3333 110.5C3412.3 109.3 3491.7 105.7 3571.2 105C3650.7 104.3 3730.3 106.7 3809.8 101.7C3889.3 96.7 3968.7 84.3 4048 79.7C4127.3 75 4206.7 78 4286 76.7C4365.3 75.3 4444.7 69.7 4524 70.5C4603.3 71.3 4682.7 78.7 4762 82.3C4841.3 86 4920.7 86 4960.3 86L5000 86L5000 201L4960.3 201C4920.7 201 4841.3 201 4762 201C4682.7 201 4603.3 201 4524 201C4444.7 201 4365.3 201 4286 201C4206.7 201 4127.3 201 4048 201C3968.7 201 3889.3 201 3809.8 201C3730.3 201 3650.7 201 3571.2 201C3491.7 201 3412.3 201 3333 201C3253.7 201 3174.3 201 3095 201C3015.7 201 2936.3 201 2857 201C2777.7 201 2698.3 201 2619 201C2539.7 201 2460.3 201 2381 201C2301.7 201 2222.3 201 2143 201C2063.7 201 1984.3 201 1905 201C1825.7 201 1746.3 201 1667 201C1587.7 201 1508.3 201 1428.8 201C1349.3 201 1269.7 201 1190.2 201C1110.7 201 1031.3 201 952 201C872.7 201 793.3 201 714 201C634.7 201 555.3 201 476 201C396.7 201 317.3 201 238 201C158.7 201 79.3 201 39.7 201L0 201Z"
        fill={dark ? "#3f0b0f" : "#d5817e"}
      ></path>
      <path
        d="M0 113L39.7 116C79.3 119 158.7 125 238 124.3C317.3 123.7 396.7 116.3 476 109.8C555.3 103.3 634.7 97.7 714 102.8C793.3 108 872.7 124 952 130.8C1031.3 137.7 1110.7 135.3 1190.2 129.8C1269.7 124.3 1349.3 115.7 1428.8 111.7C1508.3 107.7 1587.7 108.3 1667 113.8C1746.3 119.3 1825.7 129.7 1905 127.2C1984.3 124.7 2063.7 109.3 2143 102.7C2222.3 96 2301.7 98 2381 104.3C2460.3 110.7 2539.7 121.3 2619 123.3C2698.3 125.3 2777.7 118.7 2857 114.3C2936.3 110 3015.7 108 3095 109C3174.3 110 3253.7 114 3333 116.8C3412.3 119.7 3491.7 121.3 3571.2 123C3650.7 124.7 3730.3 126.3 3809.8 127.8C3889.3 129.3 3968.7 130.7 4048 129.3C4127.3 128 4206.7 124 4286 120.5C4365.3 117 4444.7 114 4524 112.3C4603.3 110.7 4682.7 110.3 4762 108.8C4841.3 107.3 4920.7 104.7 4960.3 103.3L5000 102L5000 201L4960.3 201C4920.7 201 4841.3 201 4762 201C4682.7 201 4603.3 201 4524 201C4444.7 201 4365.3 201 4286 201C4206.7 201 4127.3 201 4048 201C3968.7 201 3889.3 201 3809.8 201C3730.3 201 3650.7 201 3571.2 201C3491.7 201 3412.3 201 3333 201C3253.7 201 3174.3 201 3095 201C3015.7 201 2936.3 201 2857 201C2777.7 201 2698.3 201 2619 201C2539.7 201 2460.3 201 2381 201C2301.7 201 2222.3 201 2143 201C2063.7 201 1984.3 201 1905 201C1825.7 201 1746.3 201 1667 201C1587.7 201 1508.3 201 1428.8 201C1349.3 201 1269.7 201 1190.2 201C1110.7 201 1031.3 201 952 201C872.7 201 793.3 201 714 201C634.7 201 555.3 201 476 201C396.7 201 317.3 201 238 201C158.7 201 79.3 201 39.7 201L0 201Z"
        fill={dark ? "#540e13" : "#b95e5a"}
      ></path>
      <path
        d="M0 121L39.7 126C79.3 131 158.7 141 238 144.3C317.3 147.7 396.7 144.3 476 144.5C555.3 144.7 634.7 148.3 714 149.3C793.3 150.3 872.7 148.7 952 144.2C1031.3 139.7 1110.7 132.3 1190.2 132C1269.7 131.7 1349.3 138.3 1428.8 141.3C1508.3 144.3 1587.7 143.7 1667 144C1746.3 144.3 1825.7 145.7 1905 148C1984.3 150.3 2063.7 153.7 2143 151.5C2222.3 149.3 2301.7 141.7 2381 140.5C2460.3 139.3 2539.7 144.7 2619 148.3C2698.3 152 2777.7 154 2857 150.7C2936.3 147.3 3015.7 138.7 3095 134.8C3174.3 131 3253.7 132 3333 135.5C3412.3 139 3491.7 145 3571.2 144.2C3650.7 143.3 3730.3 135.7 3809.8 133.3C3889.3 131 3968.7 134 4048 136.8C4127.3 139.7 4206.7 142.3 4286 143.2C4365.3 144 4444.7 143 4524 143.5C4603.3 144 4682.7 146 4762 147.3C4841.3 148.7 4920.7 149.3 4960.3 149.7L5000 150L5000 201L4960.3 201C4920.7 201 4841.3 201 4762 201C4682.7 201 4603.3 201 4524 201C4444.7 201 4365.3 201 4286 201C4206.7 201 4127.3 201 4048 201C3968.7 201 3889.3 201 3809.8 201C3730.3 201 3650.7 201 3571.2 201C3491.7 201 3412.3 201 3333 201C3253.7 201 3174.3 201 3095 201C3015.7 201 2936.3 201 2857 201C2777.7 201 2698.3 201 2619 201C2539.7 201 2460.3 201 2381 201C2301.7 201 2222.3 201 2143 201C2063.7 201 1984.3 201 1905 201C1825.7 201 1746.3 201 1667 201C1587.7 201 1508.3 201 1428.8 201C1349.3 201 1269.7 201 1190.2 201C1110.7 201 1031.3 201 952 201C872.7 201 793.3 201 714 201C634.7 201 555.3 201 476 201C396.7 201 317.3 201 238 201C158.7 201 79.3 201 39.7 201L0 201Z"
        fill={dark ? "#691215" : "#9c3c37"}
      ></path>
      <path
        d="M0 155L39.7 156.7C79.3 158.3 158.7 161.7 238 161.8C317.3 162 396.7 159 476 157.8C555.3 156.7 634.7 157.3 714 157.5C793.3 157.7 872.7 157.3 952 156.7C1031.3 156 1110.7 155 1190.2 156.5C1269.7 158 1349.3 162 1428.8 164.2C1508.3 166.3 1587.7 166.7 1667 166.8C1746.3 167 1825.7 167 1905 166.7C1984.3 166.3 2063.7 165.7 2143 166.3C2222.3 167 2301.7 169 2381 169.7C2460.3 170.3 2539.7 169.7 2619 166.2C2698.3 162.7 2777.7 156.3 2857 157C2936.3 157.7 3015.7 165.3 3095 170.5C3174.3 175.7 3253.7 178.3 3333 175.8C3412.3 173.3 3491.7 165.7 3571.2 162.3C3650.7 159 3730.3 160 3809.8 163.2C3889.3 166.3 3968.7 171.7 4048 170.7C4127.3 169.7 4206.7 162.3 4286 160.7C4365.3 159 4444.7 163 4524 162.5C4603.3 162 4682.7 157 4762 156.7C4841.3 156.3 4920.7 160.7 4960.3 162.8L5000 165L5000 201L4960.3 201C4920.7 201 4841.3 201 4762 201C4682.7 201 4603.3 201 4524 201C4444.7 201 4365.3 201 4286 201C4206.7 201 4127.3 201 4048 201C3968.7 201 3889.3 201 3809.8 201C3730.3 201 3650.7 201 3571.2 201C3491.7 201 3412.3 201 3333 201C3253.7 201 3174.3 201 3095 201C3015.7 201 2936.3 201 2857 201C2777.7 201 2698.3 201 2619 201C2539.7 201 2460.3 201 2381 201C2301.7 201 2222.3 201 2143 201C2063.7 201 1984.3 201 1905 201C1825.7 201 1746.3 201 1667 201C1587.7 201 1508.3 201 1428.8 201C1349.3 201 1269.7 201 1190.2 201C1110.7 201 1031.3 201 952 201C872.7 201 793.3 201 714 201C634.7 201 555.3 201 476 201C396.7 201 317.3 201 238 201C158.7 201 79.3 201 39.7 201L0 201Z"
        fill="#7e1616"
      ></path>
    </svg>
  );
};

export const SmallWaves = ({ className }: { className?: string }) => {
  const { appliedTheme } = useTheme();
  const dark = appliedTheme === "dark";

  return (
    <svg id="visual" className={className} width="5000" height="100" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <path
        d="M0 82L37.8 76.8C75.7 71.7 151.3 61.3 227.2 55.8C303 50.3 379 49.7 454.8 53.5C530.7 57.3 606.3 65.7 682 67.3C757.7 69 833.3 64 909 60.2C984.7 56.3 1060.3 53.7 1136.2 56.5C1212 59.3 1288 67.7 1363.8 67.8C1439.7 68 1515.3 60 1591 58.2C1666.7 56.3 1742.3 60.7 1818 62.7C1893.7 64.7 1969.3 64.3 2045.2 65.5C2121 66.7 2197 69.3 2272.8 70.8C2348.7 72.3 2424.3 72.7 2500 71.7C2575.7 70.7 2651.3 68.3 2727.2 65C2803 61.7 2879 57.3 2954.8 56.2C3030.7 55 3106.3 57 3182 59.7C3257.7 62.3 3333.3 65.7 3409 65.3C3484.7 65 3560.3 61 3636.2 61.5C3712 62 3788 67 3863.8 71.2C3939.7 75.3 4015.3 78.7 4091 75.3C4166.7 72 4242.3 62 4318 58C4393.7 54 4469.3 56 4545.2 55.5C4621 55 4697 52 4772.8 52C4848.7 52 4924.3 55 4962.2 56.5L5000 58L5000 0L4962.2 0C4924.3 0 4848.7 0 4772.8 0C4697 0 4621 0 4545.2 0C4469.3 0 4393.7 0 4318 0C4242.3 0 4166.7 0 4091 0C4015.3 0 3939.7 0 3863.8 0C3788 0 3712 0 3636.2 0C3560.3 0 3484.7 0 3409 0C3333.3 0 3257.7 0 3182 0C3106.3 0 3030.7 0 2954.8 0C2879 0 2803 0 2727.2 0C2651.3 0 2575.7 0 2500 0C2424.3 0 2348.7 0 2272.8 0C2197 0 2121 0 2045.2 0C1969.3 0 1893.7 0 1818 0C1742.3 0 1666.7 0 1591 0C1515.3 0 1439.7 0 1363.8 0C1288 0 1212 0 1136.2 0C1060.3 0 984.7 0 909 0C833.3 0 757.7 0 682 0C606.3 0 530.7 0 454.8 0C379 0 303 0 227.2 0C151.3 0 75.7 0 37.8 0L0 0Z"
        fill={dark ? "#2c0707" : "#efa4a4"}
      ></path>
      <path
        d="M0 66L37.8 64.7C75.7 63.3 151.3 60.7 227.2 60.2C303 59.7 379 61.3 454.8 58.7C530.7 56 606.3 49 682 47.2C757.7 45.3 833.3 48.7 909 49.2C984.7 49.7 1060.3 47.3 1136.2 49C1212 50.7 1288 56.3 1363.8 59.7C1439.7 63 1515.3 64 1591 64C1666.7 64 1742.3 63 1818 59.5C1893.7 56 1969.3 50 2045.2 49.5C2121 49 2197 54 2272.8 56.2C2348.7 58.3 2424.3 57.7 2500 58.8C2575.7 60 2651.3 63 2727.2 60C2803 57 2879 48 2954.8 46.7C3030.7 45.3 3106.3 51.7 3182 52.8C3257.7 54 3333.3 50 3409 48C3484.7 46 3560.3 46 3636.2 47.3C3712 48.7 3788 51.3 3863.8 50.5C3939.7 49.7 4015.3 45.3 4091 43.8C4166.7 42.3 4242.3 43.7 4318 45.2C4393.7 46.7 4469.3 48.3 4545.2 48.8C4621 49.3 4697 48.7 4772.8 51C4848.7 53.3 4924.3 58.7 4962.2 61.3L5000 64L5000 0L4962.2 0C4924.3 0 4848.7 0 4772.8 0C4697 0 4621 0 4545.2 0C4469.3 0 4393.7 0 4318 0C4242.3 0 4166.7 0 4091 0C4015.3 0 3939.7 0 3863.8 0C3788 0 3712 0 3636.2 0C3560.3 0 3484.7 0 3409 0C3333.3 0 3257.7 0 3182 0C3106.3 0 3030.7 0 2954.8 0C2879 0 2803 0 2727.2 0C2651.3 0 2575.7 0 2500 0C2424.3 0 2348.7 0 2272.8 0C2197 0 2121 0 2045.2 0C1969.3 0 1893.7 0 1818 0C1742.3 0 1666.7 0 1591 0C1515.3 0 1439.7 0 1363.8 0C1288 0 1212 0 1136.2 0C1060.3 0 984.7 0 909 0C833.3 0 757.7 0 682 0C606.3 0 530.7 0 454.8 0C379 0 303 0 227.2 0C151.3 0 75.7 0 37.8 0L0 0Z"
        fill={dark ? "#3f0b0f" : "#d5817e"}
      ></path>
      <path
        d="M0 46L37.8 43.2C75.7 40.3 151.3 34.7 227.2 35C303 35.3 379 41.7 454.8 44.3C530.7 47 606.3 46 682 46.7C757.7 47.3 833.3 49.7 909 47C984.7 44.3 1060.3 36.7 1136.2 35C1212 33.3 1288 37.7 1363.8 38.8C1439.7 40 1515.3 38 1591 39.7C1666.7 41.3 1742.3 46.7 1818 47.5C1893.7 48.3 1969.3 44.7 2045.2 42.2C2121 39.7 2197 38.3 2272.8 37.7C2348.7 37 2424.3 37 2500 36.8C2575.7 36.7 2651.3 36.3 2727.2 36.2C2803 36 2879 36 2954.8 38.5C3030.7 41 3106.3 46 3182 46C3257.7 46 3333.3 41 3409 37.2C3484.7 33.3 3560.3 30.7 3636.2 31.5C3712 32.3 3788 36.7 3863.8 39.5C3939.7 42.3 4015.3 43.7 4091 42C4166.7 40.3 4242.3 35.7 4318 36.7C4393.7 37.7 4469.3 44.3 4545.2 45C4621 45.7 4697 40.3 4772.8 36.7C4848.7 33 4924.3 31 4962.2 30L5000 29L5000 0L4962.2 0C4924.3 0 4848.7 0 4772.8 0C4697 0 4621 0 4545.2 0C4469.3 0 4393.7 0 4318 0C4242.3 0 4166.7 0 4091 0C4015.3 0 3939.7 0 3863.8 0C3788 0 3712 0 3636.2 0C3560.3 0 3484.7 0 3409 0C3333.3 0 3257.7 0 3182 0C3106.3 0 3030.7 0 2954.8 0C2879 0 2803 0 2727.2 0C2651.3 0 2575.7 0 2500 0C2424.3 0 2348.7 0 2272.8 0C2197 0 2121 0 2045.2 0C1969.3 0 1893.7 0 1818 0C1742.3 0 1666.7 0 1591 0C1515.3 0 1439.7 0 1363.8 0C1288 0 1212 0 1136.2 0C1060.3 0 984.7 0 909 0C833.3 0 757.7 0 682 0C606.3 0 530.7 0 454.8 0C379 0 303 0 227.2 0C151.3 0 75.7 0 37.8 0L0 0Z"
        fill={dark ? "#540e13" : "#b95e5a"}
      ></path>
      <path
        d="M0 35L37.8 33.3C75.7 31.7 151.3 28.3 227.2 25.7C303 23 379 21 454.8 21.5C530.7 22 606.3 25 682 25.2C757.7 25.3 833.3 22.7 909 24C984.7 25.3 1060.3 30.7 1136.2 32.3C1212 34 1288 32 1363.8 32.3C1439.7 32.7 1515.3 35.3 1591 34.5C1666.7 33.7 1742.3 29.3 1818 28.5C1893.7 27.7 1969.3 30.3 2045.2 32C2121 33.7 2197 34.3 2272.8 34.3C2348.7 34.3 2424.3 33.7 2500 34.2C2575.7 34.7 2651.3 36.3 2727.2 35.7C2803 35 2879 32 2954.8 32C3030.7 32 3106.3 35 3182 34C3257.7 33 3333.3 28 3409 27C3484.7 26 3560.3 29 3636.2 29.7C3712 30.3 3788 28.7 3863.8 26.8C3939.7 25 4015.3 23 4091 22.3C4166.7 21.7 4242.3 22.3 4318 21.8C4393.7 21.3 4469.3 19.7 4545.2 20.7C4621 21.7 4697 25.3 4772.8 27.2C4848.7 29 4924.3 29 4962.2 29L5000 29L5000 0L4962.2 0C4924.3 0 4848.7 0 4772.8 0C4697 0 4621 0 4545.2 0C4469.3 0 4393.7 0 4318 0C4242.3 0 4166.7 0 4091 0C4015.3 0 3939.7 0 3863.8 0C3788 0 3712 0 3636.2 0C3560.3 0 3484.7 0 3409 0C3333.3 0 3257.7 0 3182 0C3106.3 0 3030.7 0 2954.8 0C2879 0 2803 0 2727.2 0C2651.3 0 2575.7 0 2500 0C2424.3 0 2348.7 0 2272.8 0C2197 0 2121 0 2045.2 0C1969.3 0 1893.7 0 1818 0C1742.3 0 1666.7 0 1591 0C1515.3 0 1439.7 0 1363.8 0C1288 0 1212 0 1136.2 0C1060.3 0 984.7 0 909 0C833.3 0 757.7 0 682 0C606.3 0 530.7 0 454.8 0C379 0 303 0 227.2 0C151.3 0 75.7 0 37.8 0L0 0Z"
        fill={dark ? "#691215" : "#9c3c37"}
      ></path>
      <path
        d="M0 12L37.8 11.7C75.7 11.3 151.3 10.7 227.2 10.7C303 10.7 379 11.3 454.8 11.2C530.7 11 606.3 10 682 9.3C757.7 8.7 833.3 8.3 909 9.5C984.7 10.7 1060.3 13.3 1136.2 13.7C1212 14 1288 12 1363.8 13C1439.7 14 1515.3 18 1591 19.8C1666.7 21.7 1742.3 21.3 1818 21.5C1893.7 21.7 1969.3 22.3 2045.2 20.8C2121 19.3 2197 15.7 2272.8 15.7C2348.7 15.7 2424.3 19.3 2500 20.5C2575.7 21.7 2651.3 20.3 2727.2 18.7C2803 17 2879 15 2954.8 15C3030.7 15 3106.3 17 3182 16.2C3257.7 15.3 3333.3 11.7 3409 11.2C3484.7 10.7 3560.3 13.3 3636.2 15.3C3712 17.3 3788 18.7 3863.8 17.8C3939.7 17 4015.3 14 4091 14.2C4166.7 14.3 4242.3 17.7 4318 19.3C4393.7 21 4469.3 21 4545.2 19.7C4621 18.3 4697 15.7 4772.8 13.7C4848.7 11.7 4924.3 10.3 4962.2 9.7L5000 9L5000 0L4962.2 0C4924.3 0 4848.7 0 4772.8 0C4697 0 4621 0 4545.2 0C4469.3 0 4393.7 0 4318 0C4242.3 0 4166.7 0 4091 0C4015.3 0 3939.7 0 3863.8 0C3788 0 3712 0 3636.2 0C3560.3 0 3484.7 0 3409 0C3333.3 0 3257.7 0 3182 0C3106.3 0 3030.7 0 2954.8 0C2879 0 2803 0 2727.2 0C2651.3 0 2575.7 0 2500 0C2424.3 0 2348.7 0 2272.8 0C2197 0 2121 0 2045.2 0C1969.3 0 1893.7 0 1818 0C1742.3 0 1666.7 0 1591 0C1515.3 0 1439.7 0 1363.8 0C1288 0 1212 0 1136.2 0C1060.3 0 984.7 0 909 0C833.3 0 757.7 0 682 0C606.3 0 530.7 0 454.8 0C379 0 303 0 227.2 0C151.3 0 75.7 0 37.8 0L0 0Z"
        fill="#7e1616"
      ></path>
    </svg>
  );
};
