import CoursableIcons from "../../../../utils/CoursableIcons";
import CoursableLogo from "../../../elements/CoursableLogo";
import CTAButton from "../../../elements/CTAButton";
import { PopupModal, PopupModalContent, PopupModalFooter, PopupModalHeader } from "../../../elements/PopupModal";
import { useEffect, useState } from "react";
import { useNotifications } from "../../../../utils/NotificationsContext";
import LoadingIndicator from "../../../elements/LoadingIndicator";
import { useAuth } from "../../../../firebase/AuthContext";
import { CoursablePlan } from "../../../../utils/CoursablePlan";
import { ServerResponse } from "../../../../backend/Shared";
import { CreateNewProject } from "../../../../backend/Projects/ProjectBase";
import { PlanBadge } from "../../../page-elements/AppHeader";
import InputField from "../../../elements/InputField";
import { useNavigate } from "react-router-dom";
import useMaterialUpload from "../Hooks/useMaterialUpload";

interface NewProjectModalProps {
  isOpen: boolean;
  Close: () => void;
}

const NewProjectModal = ({ isOpen, Close }: NewProjectModalProps) => {
  const [loading, setLoading] = useState(false);
  const { sendError } = useNotifications();

  const [name, setName] = useState("");
  const { files, links, ClearMaterials, MaterialUploadSection } = useMaterialUpload({ open: isOpen });

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);

  async function Start() {
    setLoading(true);
    try {
      const project = await CreateNewProject(
        files,
        links.map((l) => l.url),
        name || undefined
      );
      navigate(`/app/projects/${project.id}`, {
        state: { isNewProject: true },
      });
      Close();
    } catch (error: any) {
      if (error instanceof ServerResponse && error.type === "invalid_file_format") {
        sendError("Looks like there is something wrong with your file 😢", "Please try again with a different document.");
      } else if (error instanceof ServerResponse && error.type === "failed_to_extract_content") {
        sendError("Link is unavailable.", "Failed to extract content from your provided link. Please try again with a different url.");
      } else {
        sendError("Oops, something went wrong. Please try again.");
        console.log(error);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!isOpen) return;
    ClearMaterials();
    setName("");
  }, [isOpen]);

  return (
    <PopupModal open={isOpen} Close={Close} className="w-full max-w-2xl relative">
      <PopupModalHeader dynamicScaleOrigin="center" dynamicType="scale" className="flex-centered flex-col gap-4">
        <CoursableLogo size={8} className="hidden sm:block" />
        <CoursableLogo size={6} className="block sm:hidden" />
        <div className="text-xl md:text-2xl font-semibold">
          Coursable <span className="text-brand-500">Projects</span>
        </div>
        <div className="text-center text-sm md:text-base font-normal w-full text-systemGray-500">Ask Coursable anything about your documents and videos.</div>
      </PopupModalHeader>
      <PopupModalContent className="flex items-start justify-start flex-col gap-4 md:gap-8">
        <div className="w-full flex-centered flex-col md:flex-row gap-4 md:gap-12 border-y py-4">
          <div className="flex-centered flex-col gap-4">
            <FeatureBlock label="Generate comprehensive summaries" icon={CoursableIcons.Text()} />
            <FeatureBlock label="Test yourself with AI quizzes" icon={CoursableIcons.Quizzes()} />
          </div>
          <div className="flex-centered flex-col gap-4">
            <FeatureBlock label="Study with AI flashcards" icon={CoursableIcons.Flashcard()} />
            <FeatureBlock label="Share results with anyone" icon={CoursableIcons.People()} />
          </div>
        </div>
        <div className={`w-full flex flex-col ${loading && "pointer-events-none opacity-50"} duration-300`}>
          <Label title="Name" description="Give your project a name" />
          <InputField value={name} onValueChange={setName} placeholder="Exam practice" className="mb-8" />
          <MaterialsLabel plan={plan} />
          <MaterialUploadSection />
        </div>
      </PopupModalContent>
      <PopupModalFooter className="flex-centered">
        <CTAButton disabled={loading || files.length + links.length === 0} onClick={Start}>
          {loading && <LoadingIndicator className="stroke-white" />}Start New Project
        </CTAButton>
      </PopupModalFooter>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:gap-2 text-systemGray-500 text-micro md:text-mini absolute top-4 md:top-6 left-4 md:left-6">
        <PlanBadge />
        {plan.projectsLimitMonthly === null ? (
          <>Unlimited projects</>
        ) : (
          <>
            {currentUser?.customClaims.stats?.projectsCreatedCurrentCycle ?? 0}/{plan.projectsLimitMonthly} projects created
          </>
        )}
      </div>
    </PopupModal>
  );
};

export default NewProjectModal;

const FeatureBlock = ({ label, icon }: { label: string; icon: React.ReactNode }) => {
  return (
    <div className="w-auto max-w-full md:w-full flex items-center justify-start gap-2">
      <div className="text-sm md:text-base text-brand-500 shrink-0">{icon}</div>
      <div className="text-mini md:text-sm w-full truncate">{label}</div>
    </div>
  );
};

const Label = ({ title, description }: { title: string; description: React.ReactNode }) => {
  return (
    <div className="w-full flex items-center justify-between gap-2 text-sm md:text-base mb-4">
      <div className="text-semibold text-left">{title}</div>
      <div className="text-systemGray-400 text-mini md:text-sm text-right">{description}</div>
    </div>
  );
};

const MaterialsLabel = ({ plan }: { plan: CoursablePlan }) => {
  return (
    <Label
      title="Materials"
      description={
        plan.materialsPerProject !== null && (
          <>
            Add up to {plan.materialsPerProject} materials with your <span className={`${plan === CoursablePlan.Free ? "text-brandBlue-300" : "text-brand-300"}`}>{plan.title}</span> plan
          </>
        )
      }
    />
  );
};
