import { useCallback, useEffect, useRef, useState } from "react";
import { cn } from "../UtilityMethods";

interface Tab {
  label: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
}

const useTabs = (tabs: Tab[]) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const tabBarRef = useRef<HTMLDivElement>(null);
  const isSmall = useRef<boolean>(false);
  const [{ sliderLeft, sliderWidth }, setSlider] = useState({ sliderLeft: 0, sliderWidth: 0 });

  const SelectTab = useCallback((index: number) => {
    setSelectedTab(index);
    if (!tabBarRef.current) return;

    const children = tabBarRef.current.childNodes;
    children.forEach((child) => {
      (child as HTMLButtonElement).style.fontWeight = "400";
    });
    const selectedChild = tabBarRef.current?.childNodes[index] as HTMLButtonElement;
    if (!selectedChild) return;

    selectedChild.style.fontWeight = "600";
    const { offsetLeft, offsetWidth } = selectedChild;
    setSlider({ sliderLeft: offsetLeft, sliderWidth: offsetWidth });
  }, []);

  function HandleResize() {
    if (!tabBarRef.current) return;

    const rootFontSizePx = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const clientWidthRem = tabBarRef.current?.clientWidth / rootFontSizePx;

    if (isSmall.current && clientWidthRem >= 32) {
      isSmall.current = false;
      SelectTab(selectedTab);
      return;
    }
    if (!isSmall.current && clientWidthRem < 32) {
      isSmall.current = true;
      SelectTab(selectedTab);
      return;
    }
  }

  useEffect(() => {
    SelectTab(selectedTab);
  }, [tabBarRef.current]);

  useEffect(() => {
    if (!tabBarRef.current) return;

    const resizeObserver = new ResizeObserver(HandleResize);
    if (tabBarRef.current) resizeObserver.observe(tabBarRef.current);

    return () => {
      if (tabBarRef.current) resizeObserver.unobserve(tabBarRef.current);
      isSmall.current = false;
    };
  }, [tabBarRef.current, selectedTab]);

  const TabsBar = (className?: string) => {
    return (
      <div className={`flex flex-col gap-1 relative ${className} @container`}>
        <div ref={tabBarRef} className="w-full flex items-center justify-start gap-4 border-b pb-2">
          {tabs.map((tab, index) => (
            <TabButton key={index} index={index} label={tab.label} icon={tab.icon} selectedTab={selectedTab} SelectTab={SelectTab} />
          ))}
        </div>
        <div
          style={{
            width: `calc(${sliderWidth}px)`,
            left: `calc(${sliderLeft}px)`,
          }}
          className="bg-brand-500 h-0.5 duration-300 absolute bottom-0"
        />
      </div>
    );
  };

  const TabView = (className?: string) => {
    return <div className={className}>{tabs[selectedTab].content}</div>;
  };

  return { selectedTab, TabsBar, TabView };
};

export default useTabs;

const TabButton = ({ label, icon, selectedTab, index, SelectTab }: { label: string; icon?: React.ReactNode; index: number; selectedTab: number; SelectTab: (i: number) => void }) => {
  const isSelected = index === selectedTab;

  return (
    <button
      onClick={() => SelectTab(index)}
      className={cn(
        "flex items-center justify-start gap-2 transition-colors px-3 py-1 rounded-md",
        isSelected ? "stroke-brand-500 text-brand-500 font-semibold" : "stroke-foreground text-foreground hover:bg-systemGray-100 active:bg-systemGray-200"
      )}
    >
      {icon && <span className="text-xl @[32rem]:text-base">{icon}</span>}
      <span className="hidden @[32rem]:block">{label}</span>
    </button>
  );
};
