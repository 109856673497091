import { useState } from "react";
import FollowMouseBlock from "../../../../elements/FollowMouseBlock";
import { TypeAnimation } from "react-type-animation";

const CourseGenerationBlock = () => {
  const output1 = `{
  title: "Project Management 101",
  description: "Learn the basics of project management ...
  topics: [
    {
      title: "Introduction to Project Management",
      description: "Learn the basic principles and ...
      materials: [...],
    },
...`;

  const output2 = `{
  title: "Employee KPI Management",
  description: "Learn how to manage employee KPIs ...
  topics: [
    {
      title: "Understanding Key Performance ...",
      description: "In this topic, you will learn ...
      materials: [...],
    },
...`;

  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  return (
    <div className="w-full flex items-center justify-center sm:justify-end select-none">
      <FollowMouseBlock sensitivity={0.2}>
        <div className="hover:bg-systemGray-50 rounded-2xl hover:shadow-lg p-4 w-full flex flex-col gap-1 duration-300 hover:scale-[1.02]">
          <div className="text-sm font-semibold text-brand-500 pl-2">prompt</div>
          <div className="bg-systemGray-200 rounded-md border border-systemGray-300 w-full text-foreground font-mono px-2 text-sm sm:text-base">
            <TypeAnimation speed={60} deletionSpeed={80} repeat={Infinity} sequence={["project management for beginners", () => setShowFirst(true), 5000, "setting KPIs for small business", () => setShowSecond(true), 5000]} />
          </div>
          <div className="text-systemGray-400 text-sm mt-2 pl-2">AI output</div>
          <div className="bg-systemGray-200 rounded-md w-full text-systemGray-600 font-mono px-2 py-1 whitespace-pre-wrap text-mini relative">
            <div className="opacity-0 !font-mono">{output1}</div>
            {showFirst && <TypeAnimation cursor={false} className="absolute left-2 right-2 top-1 bottom-1" speed={99} deletionSpeed={99} sequence={[200, output1, 3500, "", () => setShowFirst(false)]} />}
            {showSecond && <TypeAnimation cursor={false} className="absolute left-2 right-2 top-1 bottom-1" speed={99} deletionSpeed={99} sequence={[200, output2, 3500, "", () => setShowSecond(false)]} />}
          </div>
        </div>
      </FollowMouseBlock>
    </div>
  );
};

export default CourseGenerationBlock;
