import { ReactNode, useState } from "react";
import CoursableIcons from "../../../utils/CoursableIcons";
import Card from "../../elements/Card";
import { PlanBadge } from "../../page-elements/AppHeader";
import Button from "../../elements/Button";
import { useAuth } from "../../../firebase/AuthContext";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import "../../../utils/StringExtensions";
import date from "date-and-time";
import { useNavigate } from "react-router-dom";
import { OpenStripePortal } from "../../../backend/Payment";
import { useNotifications } from "../../../utils/NotificationsContext";

const PlanCard = ({ className }: { className?: string }) => {
  const { sendError } = useNotifications();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [loadingCustomerPortal, setLoadingCustomerPortal] = useState(false);

  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);

  async function ClickManagePlan() {
    if (plan === CoursablePlan.Free) return navigate("/pricing");

    setLoadingCustomerPortal(true);
    try {
      await OpenStripePortal();
    } catch (error: any) {
      sendError("Oops, something went wrong", "Please try again later or contact us at support@coursable.io.");
      setLoadingCustomerPortal(false);
      console.error(error);
    }
  }

  return (
    <Card className={className} title="Profile" description="Your profile and subscription" icon={CoursableIcons.Wallet()}>
      <div className="w-full flex flex-col items-end justify-start gap-4 my-2">
        <DisplayValue label="Plan" value={<PlanBadge />} />
        {currentUser?.customClaims.plan.cycle && <DisplayValue label="Cycle" value={currentUser?.customClaims.plan.cycle === "quarter" ? "Semester" : currentUser?.customClaims.plan.cycle?.capitalize() + "ly"} />}
        {currentUser?.customClaims.plan.trialEndDate ? (
          <DisplayValue label="Trial until" value={date.format(currentUser?.customClaims.plan.trialEndDate, "MMMM D, YYYY")} />
        ) : (
          currentUser?.customClaims.plan.nextCycleDate && <DisplayValue label="Next payment" value={date.format(currentUser?.customClaims.plan.nextCycleDate, "MMMM D, YYYY")} />
        )}
        {currentUser?.displayName && <DisplayValue label="Name" value={currentUser?.displayName} />}
      </div>
      <div className="w-full flex-centered gap-4">
        <Button disabled={loadingCustomerPortal} className="w-full" to="/app/settings" size="sm" variant="outline">
          Settings
        </Button>
        <Button disabled={loadingCustomerPortal} loading={loadingCustomerPortal} className="w-full" onClick={ClickManagePlan} size="sm" variant={plan === CoursablePlan.Free ? "default" : "outline"}>
          {plan === CoursablePlan.Free ? "Upgrade" : "Manage plan"}
        </Button>
      </div>
    </Card>
  );
};

export default PlanCard;

export const DisplayValue = ({ label, value }: { label: string; value?: string | ReactNode }) => {
  return (
    <div className="w-full flex items-center justify-between gap-2 text-sm">
      <div className="text-systemGray-500">{label}</div>
      {value === undefined ? <div className="text-systemGray-400 text-sm">None</div> : value}
    </div>
  );
};
