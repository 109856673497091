import LogRocket from "logrocket";
import { User } from "firebase/auth";

export function ConfigureLogRocket() {
  const logRocketID = process.env.REACT_APP_LOG_ROCKET_ID;
  if (!logRocketID) return;
  LogRocket.init(logRocketID);
}

export function LogRocketIdentifyUser(user: User) {
  LogRocket.identify(user.uid, {
    name: user.displayName || "No name",
    email: user.email || "No email",
  });
}
