import DashboardCourseView from "./DashboardCourseView";
import DashboardSidemenu from "./DashboardSidemenu";
import { useEffect, useState } from "react";
import DashboardTopicView from "./DashboardTopicView";
import { GetCoursesFromDatabase, WriteCourseToDatabase } from "../../../firebase/FirestoreManager";
import { IoChevronBack } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import AppPageTemplate from "../../page-elements/AppPageTemplate";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const CoursesDashboardPage = () => {
  const [verticalLayout, setVerticalLayout] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<Course[]>([]);

  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<Course.Topic | null>(null);

  const { state } = useLocation() as { state: { preselectedCourseID: string } };

  async function FetchAllCourses() {
    setIsLoading(true);
    setSelectedCourse(null);

    try {
      const allCourses = await GetCoursesFromDatabase();
      allCourses.sort((a: Course, b: Course) => {
        const aDate = new Date(a.dateGenerated);
        const bDate = new Date(b.dateGenerated);
        return bDate.getTime() - aDate.getTime();
      });
      setCourses(allCourses);
      if (state?.preselectedCourseID) {
        const preselectedCourse = allCourses.find((c) => c.id === state?.preselectedCourseID) ?? null;
        setSelectedCourse(preselectedCourse);
        setSelectedTopic(preselectedCourse?.topics[0] ?? null);
      }
    } catch (error) {
      console.log(error);
      return;
    }

    setIsLoading(false);
  }

  useEffect(() => {
    FetchAllCourses();

    function CheckWidth() {
      if (window.innerWidth < 768) setVerticalLayout(true);
      else setVerticalLayout(false);
    }
    window.addEventListener("resize", CheckWidth);
    CheckWidth();
    return () => {
      window.removeEventListener("resize", CheckWidth);
    };
  }, []);

  function UpdateCourse(course: Course) {
    const courseIndex = courses.findIndex((c) => c.id === course.id);
    const newCourses = [...courses];
    newCourses[courseIndex] = course;

    setCourses(newCourses);
    setSelectedCourse(course);

    try {
      WriteCourseToDatabase(course);
    } catch (error) {
      console.log(error);
    }
  }

  function ClickBack() {
    if (selectedTopic) return setSelectedTopic(null);
    if (selectedCourse) return setSelectedCourse(null);
  }

  return (
    <AppPageTemplate
      helmet={pageMetatags({
        title: "Courses",
        description:
          "Learn something new with personalized AI courses and approved study materials from the web. Coursable will generate a custom study guide, look up online learning materials, evaluate their quality, and provide you with an optimal course to get started.",
        ogImage: "/Images/OpenGraph/Courses Promo.png",
        canonical: "/app/courses",
      })}
    >
      <div className="h-full w-full flex flex-col bg-background">
        {!verticalLayout ? (
          <div className={`w-full grow grid ${selectedCourse ? "grid-cols-[1fr_2fr_1fr] xl:grid-cols-[400px_2fr_1.5fr]" : "grid-cols-[1fr_2fr] xl:grid-cols-[400px_3fr]"} overflow-auto`}>
            <DashboardSidemenu isLoading={isLoading} courses={courses} selectedCourse={selectedCourse} selectCourse={setSelectedCourse} selectTopic={setSelectedTopic} />
            <DashboardCourseView course={selectedCourse} ReloadCourses={FetchAllCourses} selectedTopic={selectedTopic} selectTopic={setSelectedTopic} key={selectedCourse?.id} />
            {selectedCourse && <DashboardTopicView topic={selectedTopic} course={selectedCourse} updateCourse={UpdateCourse} key={selectedTopic && selectedCourse.topics.indexOf(selectedTopic)} />}
          </div>
        ) : (
          <div className={`w-full grow flex relative overflow-auto`}>
            <div className={`w-full h-full flex ${selectedCourse || selectedTopic ? "hidden" : "block"}`}>
              <DashboardSidemenu isLoading={isLoading} courses={courses} selectedCourse={selectedCourse} selectCourse={setSelectedCourse} selectTopic={setSelectedTopic} />
            </div>
            <div className={`absolute flex flex-col w-full inset-0 ${selectedCourse && !selectedTopic ? "block" : "hidden"}`}>
              <BackButton onClick={ClickBack} />
              <DashboardCourseView course={selectedCourse} ReloadCourses={FetchAllCourses} selectedTopic={selectedTopic} selectTopic={setSelectedTopic} key={selectedCourse?.id} />
            </div>
            <div className={`absolute flex flex-col w-full inset-0 ${selectedCourse && selectedTopic ? "block" : "hidden"}`}>
              <BackButton onClick={ClickBack} />
              {selectedCourse && <DashboardTopicView topic={selectedTopic} course={selectedCourse} updateCourse={UpdateCourse} key={selectedTopic && selectedCourse.topics.indexOf(selectedTopic)} />}
            </div>
          </div>
        )}
      </div>
    </AppPageTemplate>
  );
};

export default CoursesDashboardPage;

export const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button onClick={onClick} className="px-2 pt-2 text-brand-500 font-semibold flex items-center hover:text-brand-600 duration-100 w-full bg-background">
      <IoChevronBack />
      <span>Back</span>
    </button>
  );
};
