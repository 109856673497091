import { useEffect, useState } from "react";
import HomePageTemplate from "../../page-elements/HomePageTemplate";
import MarkdownViewWithIDs from "./MarkdownViewWithIDs";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const PrivacyPage = () => {
  const [privacy, setPrivacy] = useState<string>("");

  const privacyMd = require("./Privacy.md");

  useEffect(() => {
    fetch(privacyMd)
      .then((response) => response.text())
      .then((text) => setPrivacy(text))
      .catch(console.error);
  }, []);

  return (
    <HomePageTemplate
      helmet={pageMetatags({
        title: "Privacy Policy",
        canonical: "/privacy",
      })}
    >
      <div className="w-full flex items-start justify-center py-8 overflow-auto px-4">
        <MarkdownViewWithIDs>{privacy}</MarkdownViewWithIDs>
      </div>
    </HomePageTemplate>
  );
};

export default PrivacyPage;
