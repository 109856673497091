import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../firebase/AuthContext";

const PrivateRoute = ({ route }: { route: React.ReactNode }) => {
  const { currentUser } = useAuth();

  const isVerified = currentUser?.emailVerified;
  const didFinishRegistration = currentUser?.didFinishRegistration;

  return currentUser ? isVerified ? didFinishRegistration ? <>{route}</> : <Navigate to="/complete-registration" /> : <Navigate to="/confirm-email" /> : <Navigate to="/login" />;
};

export default PrivateRoute;
