import Explainer from "../../elements/Explainer";
import "../../../utils/StringExtensions";
import MaterialsList from "./CourseEditingMaterialsList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PopupModal, PopupModalContent, PopupModalFooter, PopupModalHeader } from "../../elements/PopupModal";
import { useState } from "react";
import CTAButton from "../../elements/CTAButton";
import LoadingIndicator from "../../elements/LoadingIndicator";
import { useNotifications } from "../../../utils/NotificationsContext";
import { GenerateTopicContent, GenerateTopicDescription, GenerateTopicFindMaterials, GenerateTopicFull } from "../../../backend/CourseEditor";
import { ServerResponse } from "../../../backend/Shared";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { ContextMenuButton, ContextMenuDivider } from "../../elements/DropdownMenu/ContextMenu";
import CoursableIcons from "../../../utils/CoursableIcons";
import { ContextMenuDeleteConfirmation } from "../../elements/DropdownMenu/ContextMenuConfirmation";
import Checkbox from "../../elements/Checkbox";
import InputField from "../../elements/InputField";
import InputBox from "../../elements/InputBox";
import AIButton from "../../elements/AIButton";

const CourseEditingTopicView = ({ course, setCourse, index, setSelectedTopicIndex }: { course: Course; setCourse: (c: Course) => void; index: number; setSelectedTopicIndex: (n: number | null) => void }) => {
  const topic: Course.Topic = course.topics[index];

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isGenerationPopupOpen, setGenerationPopupOpen] = useState(false);

  function UpdateStringField(value: string, field: string) {
    const newCourse = { ...course };
    newCourse.topics[index][field] = value;
    setCourse(newCourse);
  }

  function UpdateMaterials(newMaterials: Course.Topic.Material[]) {
    const newCourse = { ...course };
    newCourse.topics[index].materials = newMaterials;
    setCourse(newCourse);
  }

  function DeleteTopic() {
    const newCourse = { ...course };
    newCourse.topics.splice(index, 1);
    setSelectedTopicIndex(null);
    setCourse(newCourse);
  }

  function DuplicateTopic() {
    const newTopic = { ...topic };
    newTopic.title += " (copy)";

    const newCourse = { ...course };
    newCourse.topics.splice(index + 1, 0, newTopic);
    setCourse(newCourse);
    setSelectedTopicIndex(index + 1);
  }

  async function GenerateEverything(unique: boolean, details: string) {
    const excludeURLs: string[] = unique ? course.topics.map((t: Course.Topic) => t.materials.map((m: Course.Topic.Material) => m.url)).flat() : [];

    const newTopic = await GenerateTopicFull(courseStructure(), index, excludeURLs, details);
    const newCourse = { ...course };
    newCourse.topics[index] = newTopic;
    setCourse(newCourse);
  }
  async function GenerateDescription(details: string) {
    const newDescription = await GenerateTopicDescription(courseStructure(), index, details);
    UpdateStringField(newDescription, "description");
  }
  async function GenerateContent(details: string) {
    const newConent = await GenerateTopicContent(courseStructure(), index, details);
    UpdateStringField(newConent, "content");
  }
  async function GenerateMaterials(unique: boolean, details: string) {
    const excludeURLs: string[] = unique ? course.topics.map((t: Course.Topic) => t.materials.map((m: Course.Topic.Material) => m.url)).flat() : [];
    const newMaterials: Course.Topic.Material[] = await GenerateTopicFindMaterials(courseStructure(), index, excludeURLs, details);

    UpdateMaterials(newMaterials);
  }

  const courseStructure = (): CourseStructure => {
    return {
      id: course.id,
      title: course.title,
      topics: course.topics.map((t) => {
        return {
          title: t.title,
          description: t.description,
          search_term: "",
        };
      }),
    };
  };

  async function Generate(type: "everything" | "description" | "content" | "materials", uniqueMaterials: boolean, details: string) {
    switch (type) {
      case "everything":
        await GenerateEverything(uniqueMaterials, details);
        break;
      case "description":
        await GenerateDescription(details);
        break;
      case "content":
        await GenerateContent(details);
        break;
      case "materials":
        await GenerateMaterials(uniqueMaterials, details);
        break;
    }
  }

  return (
    <div className="w-full flex flex-col gap-4 p-4 overflow-auto bg-background">
      <div className="flex items-center justify-center gap-4">
        <div className="text-brand-500 px-2 font-semibold w-full">Topic {index + 1}</div>
        <div className="relative">
          <Dropdown label="Actions">
            <ContextMenuButton label="Duplicate" icon={CoursableIcons.Duplicate()} onClick={DuplicateTopic} />
            <ContextMenuDivider />
            <ContextMenuButton label="Delete" icon={CoursableIcons.Delete()} onClick={() => setIsDeletePopupOpen(true)} destructive />
          </Dropdown>
          <ContextMenuDeleteConfirmation label="Are you sure you want to delete this topic?" width={180} isOpen={isDeletePopupOpen} setIsOpen={() => setIsDeletePopupOpen(false)} onDelete={DeleteTopic} />
        </div>
        <GenerateWithAI isEnabled={topic.title.length >= 3} onClick={() => setGenerationPopupOpen(true)} />
      </div>
      <Label>Title</Label>
      <InputField size="lg" className="text-lg font-semibold" value={topic.title} onValueChange={(v) => UpdateStringField(v, "title")} placeholder="Topic title" icon={CoursableIcons.Edit()} />
      <Label>Description</Label>
      <InputBox value={topic.description} onValueChange={(v) => UpdateStringField(v, "description")} className="w-full h-24" placeholder="Topic description" />
      <Label>Content</Label>
      <InputBox value={topic.content} onValueChange={(v) => UpdateStringField(v, "content")} className="w-full h-[30rem]" placeholder="Topic content" />
      <DndProvider backend={HTML5Backend}>
        <MaterialsList topic={topic} UpdateMaterials={UpdateMaterials} />
      </DndProvider>
      <GenerationModal topic={topic} isOpen={isGenerationPopupOpen} Close={() => setGenerationPopupOpen(false)} Generate={Generate} />
    </div>
  );
};

export default CourseEditingTopicView;

const Label = ({ children }: { children: string }) => {
  return <div className="text-systemGray-500 text-sm px-3 -mb-3">{children}</div>;
};

const GenerateWithAI = ({ onClick, isEnabled }: { onClick: () => void; isEnabled: boolean }) => {
  const { sendInfo } = useNotifications();

  function Click() {
    if (isEnabled) {
      onClick();
    } else {
      sendInfo("Please add a short title to this topic first.");
    }
  }
  return <AIButton onClick={Click}>Generate with AI</AIButton>;
};

interface GenerationModalProps {
  topic: Course.Topic;
  isOpen: boolean;
  Close: () => void;
  Generate: (type: "everything" | "description" | "content" | "materials", uniqueMaterials: boolean, details: string) => void;
}

const GenerationModal = ({ topic, isOpen, Close, Generate }: GenerationModalProps) => {
  const [type, setType] = useState<"everything" | "description" | "content" | "materials">("everything");
  const [uniqueMaterials, setUniqueMaterials] = useState(true);
  const [details, setDetails] = useState("");

  const [loading, setLoading] = useState(false);

  const { sendError } = useNotifications();

  async function ClickGenerate() {
    setLoading(true);
    try {
      await Generate(type, uniqueMaterials, details);
      Close();
    } catch (error) {
      if (error instanceof ServerResponse && error.code === 404) {
        sendError(error.message);
      } else {
        sendError("Oops, something went wrong. Please try again later.");
        console.log(error);
      }
    }
    setLoading(false);
  }

  return (
    <PopupModal open={isOpen} tapToClose={!loading} showCloseButton={!loading} Close={Close} className="w-full max-w-xl">
      <PopupModalHeader className="text-center flex flex-col gap-2 items-center justify-center w-full">
        <div className="text-brand-500 font-semibold flex-centered gap-2"> {CoursableIcons.SparklesFill("text-brand-500")}Generate with AI</div>
        <div className="text-center font-semibold scale-75">{topic.title}</div>
      </PopupModalHeader>
      <PopupModalContent className="w-full flex flex-col items-center justify-start gap-4">
        <div className="w-full text-center text-systemGray-500">Coursable will use the topic's title and information from other topics in this course to understand the context and generate appropriate results.</div>
        <div className={`w-full flex flex-col gap-4 ${loading && "opacity-50 pointer-events-none"}`}>
          <div className="w-full flex items-center gap-2">
            <div>Generate</div>
            <Dropdown label={type.capitalize()}>
              <ContextMenuButton label="Everything" onClick={() => setType("everything")} />
              <ContextMenuDivider />
              <ContextMenuButton label="Description" onClick={() => setType("description")} />
              <ContextMenuButton label="Content" onClick={() => setType("content")} />
              <ContextMenuButton label="Materials" onClick={() => setType("materials")} />
            </Dropdown>
          </div>
          {(type === "materials" || type === "everything") && (
            <div className="w-full flex items-center gap-2">
              <button className="text-left" onClick={() => setUniqueMaterials(!uniqueMaterials)}>
                Unique materials
              </button>
              <Checkbox variant="bare" checked={uniqueMaterials} setChecked={(b) => setUniqueMaterials(b)} />
              <Explainer minWidth={300}>Skip materials that are already included in other topics.</Explainer>
            </div>
          )}
          <div>Details</div>
          <InputBox className="w-full h-16" containerClassName="w-full" placeholder="Ask AI for specific details or leave empty" value={details} onValueChange={(v) => setDetails(v)} />
        </div>
      </PopupModalContent>
      <PopupModalFooter className="flex flex-col gap-2 w-full items-center justify-center">
        <CTAButton onClick={ClickGenerate} disabled={loading} className="!text-base">
          {loading ? <LoadingIndicator className="w-4 h-4" /> : CoursableIcons.SparklesFill("text-lg w-4 h-4")}
          Generate
        </CTAButton>
        <div className="text-mini text-systemGray-500">Current {type === "everything" ? "description, content, and materials" : type} will be substituted.</div>
      </PopupModalFooter>
    </PopupModal>
  );
};
