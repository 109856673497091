import { useEffect, useState } from "react";
import { ProjectFile } from "../../../../../backend/Projects/types";
import { FetchWebArticleInfo, WebArticleInfo } from "../../../../../backend/Projects/ProjectLinks";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { cn } from "../../../../../utils/UtilityMethods";
import LoadingIndicator from "../../../../elements/LoadingIndicator";

const WebArticleViewer = ({ file }: { file: ProjectFile }) => {
  const [info, setInfo] = useState<WebArticleInfo | undefined>(undefined);
  const [showFavicon, setShowFavicon] = useState(false);

  async function FetchInfo() {
    if (!file.sourceUrl) return;

    try {
      const info = await FetchWebArticleInfo(file.sourceUrl);
      setInfo(info);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setInfo(undefined);
    setShowFavicon(false);
    FetchInfo();
  }, [file]);

  return (
    <div className="w-full p-4 flex flex-col overflow-auto">
      {info ? (
        <div className="w-full flex-started flex-col gap-2">
          <div className="w-8 h-8 flex-centered bg-systemGray-100 relative rounded-md shrink-0">
            {CoursableIcons.Text("text-lg")}
            <img onLoad={() => setShowFavicon(true)} src={info.faviconUrl} className={cn("absolute inset-0 rounded-md opacity-0 bg-systemGray-100", showFavicon && "opacity-100")} />
          </div>
          <div className="w-full font-semibold">{info.title}</div>
          {info.author && <div className="text-systemGray-500 text-sm">Authors: {info.author}</div>}
          <a href={info.url} target="_blank" className="text-sm pt-4 text-systemGray-400 hover:underline hover:text-foreground">
            {info.url}
          </a>
        </div>
      ) : (
        <div className="w-full flex items-center justify-start gap-2 text-systemGray-500">
          <LoadingIndicator className="stroke-systemGray-400 w-4 h-4" /> Loading
        </div>
      )}
    </div>
  );
};

export default WebArticleViewer;
