import { CSSProperties, Dispatch, ReactNode } from "react";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { ControlsButton, ControlsSubmenu } from "./EditorControls";
import { ContextMenuDivider } from "../../../../elements/DropdownMenu/ContextMenu";
import React from "react";
import Button from "../../../../elements/Button";
import LoadingIndicator from "../../../../elements/LoadingIndicator";
import { useProjectManager } from "../../Hooks/useProjectManager";
import { useCurrentEditor } from "@tiptap/react";
import MarkdownView from "../../../../elements/MarkdownView";
import { cn } from "../../../../../utils/UtilityMethods";
import { AIToolGroup, writingToolGroups, writingToolStyles } from "./AITools";
import { AIWritingTool, AIWritingToolStyle } from "../../Hooks/useProjectNotes";
import InputBox from "../../../../elements/InputBox";
import * as marked from "marked";

interface AIToolsControlsProps {
  writingToolStyle?: AIWritingToolStyle | null;
  setWritingToolStyle?: (style: AIWritingToolStyle | null) => void;
  OnToolClick?: (tool: AIWritingTool) => void;
  toolGroups?: string[];
}

export const AIToolsControls = ({ writingToolStyle, setWritingToolStyle, OnToolClick, toolGroups }: AIToolsControlsProps) => {
  const { editor } = useCurrentEditor();

  let currentSelection: undefined | string = undefined;
  if (editor) {
    const { from, to } = editor?.state.selection;
    currentSelection = editor.state.doc.textBetween(from, to, "");
  }

  const groupsToRender = writingToolGroups.filter((group) => group.label && toolGroups?.includes(group.label));

  return (
    <ControlsSubmenu
      className="w-auto px-2"
      menuClassName="flex-col min-w-[250px]"
      activeStyles={{
        backgroundColor: writingToolStyle ? `${writingToolStyle.color}1a` : undefined,
      }}
      style={{
        color: writingToolStyle ? writingToolStyle.color : "hsl(var(--brand-500))",
        transition: "color 0.2s",
      }}
      contextMenuStyle={{
        color: writingToolStyle ? writingToolStyle.color : undefined,
        borderColor: writingToolStyle ? `${writingToolStyle.color}80` : undefined,
        transition: "border-color 0.2s",
      }}
      icon={
        <>
          {CoursableIcons.SparklesFill("mr-1 scale-110")} <span className="font-medium">AI Tools</span> {CoursableIcons.Chevron("down", "ml-2 text-mini opacity-50")}
        </>
      }
    >
      <Label>{CoursableIcons.Sparkles()} Smart tools to help you write</Label>
      <SelectWritingToolStyle writingToolStyle={writingToolStyle} setWritingToolStyle={setWritingToolStyle} />
      <Divider />
      {groupsToRender.map((group, index) => (
        <div key={index} className="w-full flex items-stretch justify-start flex-col gap-1 relative">
          {group.label && (
            <Label className="z-2">
              {group.label} <span className="ml-auto">{group.icon}</span>
            </Label>
          )}
          {group.tools.map((tool, index) =>
            (tool as any).tools === undefined ? (
              <WritingToolButton OnToolClick={OnToolClick} disabled={group.requiresSelection && currentSelection?.length === 0} key={index} tool={tool} writingToolStyle={writingToolStyle ?? null} />
            ) : (
              <ControlsSubmenu
                key={index}
                icon={
                  <>
                    <WritingToolButtonView tool={tool} writingToolStyle={writingToolStyle ?? null} />
                    {CoursableIcons.Chevron("right", "ml-auto scale-75 text-systemGray-300")}
                  </>
                }
                disabled={group.requiresSelection && currentSelection?.length === 0}
                hover
                direction="right"
                align="start"
                menuClassName="flex-col"
                className="w-auto px-2 text-left justify-start gap-2"
                activeClassName="bg-systemGray-100 active:bg-systemGray-200 text-foreground"
              >
                {(tool as AIToolGroup).tools.map((subTool, index) => (
                  <WritingToolButton OnToolClick={OnToolClick} disabled={group.requiresSelection && currentSelection?.length === 0} key={index} tool={subTool} writingToolStyle={writingToolStyle ?? null} />
                ))}
              </ControlsSubmenu>
            )
          )}
          {index !== groupsToRender.length - 1 && <Divider />}
          {group.requiresSelection && currentSelection?.length === 0 && (
            <div className="absolute flex items-center justify-start pt-16 flex-col gap-2 inset-0 z-1 px-4 whitespace-normal text-foreground pointer-events-none cursor-default bg-gradient-to-b from-background/80 from-30% to-background/10">
              {CoursableIcons.Sparkles("text-lg")}
              <div>Select text you would like to edit.</div>
            </div>
          )}
        </div>
      ))}
    </ControlsSubmenu>
  );
};

export const AIToolView = ({ End }: { End: () => void }) => {
  const { Notes } = useProjectManager();
  const { editor } = useCurrentEditor();

  const [userInput, setUserInput] = React.useState<string>("");

  function AcceptToolOutput() {
    if (!Notes.writingToolContent) return;

    const htmlFromMarkdown = parseOAImarkdown(Notes.writingToolContent);
    if (Notes.writingTool?.replaceSelection === false) {
      editor?.commands.insertContentAt(editor?.state.doc.content.size - 1, htmlFromMarkdown, { updateSelection: true });
    } else {
      editor?.commands.insertContent(htmlFromMarkdown, { updateSelection: true });
    }
    End();
  }

  if (!Notes.writingTool) return null;

  return (
    <div
      style={{
        borderColor: Notes.writingToolStyle ? `${Notes.writingToolStyle.color}80` : "hsl(var(--brand-500) / 50%)",
      }}
      className="flex flex-col bg-background dark:bg-systemGray-100 rounded-lg shadow-lg dark:shadow-systemGray-200 border"
    >
      <div className="flex justify-between items-center gap-2 p-4">
        <div className="w-full flex items-center justify-start gap-2">
          <span className="shrink-0">{Notes.writingTool.icon}</span>
          <span className="font-semibold shrink-0">{Notes.writingTool.label}</span>
        </div>
        {Notes.writingToolGenerating ? (
          <Button onClick={Notes.writingTool.requireUserPrompt ? Notes.CancelWritingTool : End} className="h-8 group relative" variant="ghost">
            <span className="opacity-100 group-hover:opacity-0 duration-200 flex-centered gap-2 text-systemGray-400">{CoursableIcons.Sparkles()} Generating</span>
            <span className="opacity-0 group-hover:opacity-100 duration-200 flex-centered gap-2 absolute left-8">
              {CoursableIcons.Xmark()} {Notes.writingTool.requireUserPrompt ? "Stop" : "Cancel"}
            </span>
            <LoadingIndicator type="dots" className="stroke-transparent fill-systemGray-400 group-hover:fill-foreground h-4 w-4 ml-1" />
          </Button>
        ) : Notes.writingTool.requireUserPrompt ? (
          <div className="flex gap-2">
            <Button onClick={End} className="hover:bg-systemGray-200 active:bg-systemGray-200/80" variant="ghost" size="iconSm">
              {CoursableIcons.Xmark()}
            </Button>
            {Notes.writingToolContent.length === 0 ? (
              <Button size="sm" onClick={() => Notes.UseWritingTool(Notes.writingTool!, userInput)} disabled={Notes.writingToolGenerating || (Notes.writingTool.requireUserPrompt && userInput.length <= 2)}>
                {CoursableIcons.Sparkles()} Generate
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => Notes.UseWritingTool(Notes.writingTool!, userInput)}
                  disabled={Notes.writingTool.requireUserPrompt && userInput.length <= 2}
                  className="hover:bg-systemGray-200 active:bg-systemGray-200/80"
                  variant="ghost"
                  size="iconSm"
                >
                  {CoursableIcons.Reset()}
                </Button>
                <Button onClick={AcceptToolOutput} variant="default" size="iconSm">
                  {CoursableIcons.Check()}
                </Button>
              </>
            )}
          </div>
        ) : (
          <div className="flex gap-2">
            <Button onClick={End} className="hover:bg-systemGray-200 active:bg-systemGray-200/80" variant="ghost" size="iconSm">
              {CoursableIcons.Xmark()}
            </Button>
            <Button onClick={() => Notes.UseWritingTool(Notes.writingTool!, userInput)} className="hover:bg-systemGray-200 active:bg-systemGray-200/80" variant="ghost" size="iconSm">
              {CoursableIcons.Reset()}
            </Button>
            <Button onClick={AcceptToolOutput} variant="default" size="iconSm">
              {CoursableIcons.Check()}
            </Button>
          </div>
        )}
      </div>
      {Notes.writingToolContext && (
        <div className="w-full px-4 pb-4">
          <div
            style={{
              borderColor: Notes.writingToolStyle ? `${Notes.writingToolStyle.color}80` : undefined,
            }}
            className="text-sm text-systemGray-500 border-l-2 pl-2 line-clamp-2"
          >
            {Notes.writingToolContext}
          </div>
        </div>
      )}
      {Notes.writingTool.requireUserPrompt && (
        <div className="w-full flex-centered gap-2 px-4 pb-4">
          <InputBox value={userInput} onValueChange={setUserInput} disabled={Notes.writingToolGenerating} placeholder={Notes.writingTool.userPromptPlaceholder ?? "Input anything"} containerClassName="w-full" className="w-full h-32" />
        </div>
      )}
      {Notes.writingToolContent && (
        <div className="w-full border-t p-4 max-h-[300px] overflow-auto flex-started flex-col-reverse">
          <MarkdownView size="sm">{Notes.writingToolContent}</MarkdownView>
        </div>
      )}
    </div>
  );
};

const WritingToolButton = ({ tool, disabled, OnToolClick, writingToolStyle }: { tool: AIWritingTool; disabled?: boolean; OnToolClick?: (tool: AIWritingTool) => void; writingToolStyle: AIWritingToolStyle | null }) => {
  return (
    <ControlsButton disabled={disabled} onClick={() => OnToolClick?.(tool)} className="w-auto px-2 text-left justify-start gap-2">
      <WritingToolButtonView tool={tool} writingToolStyle={writingToolStyle} />
    </ControlsButton>
  );
};

const WritingToolButtonView = ({ tool, writingToolStyle }: { tool: AIWritingTool; writingToolStyle: AIWritingToolStyle | null }) => {
  return (
    <>
      <span
        style={{
          color: writingToolStyle ? writingToolStyle.color : "hsl(var(--brand-500))",
          transition: "border-color 0.2s",
        }}
      >
        {tool.icon}
      </span>
      {tool.label}
    </>
  );
};

const SelectWritingToolStyle = ({ writingToolStyle, setWritingToolStyle }: { writingToolStyle?: AIWritingToolStyle | null; setWritingToolStyle?: (style: AIWritingToolStyle | null) => void }) => {
  return (
    <ControlsSubmenu
      icon={
        <>
          <span className="truncate">Writing style</span>
          <span className={cn("ml-auto flex-centered gap-1", writingToolStyle === null ? "text-systemGray-400" : "text-foreground")}>
            {writingToolStyle === null || writingToolStyle === undefined ? (
              "None"
            ) : (
              <>
                <span
                  className="mr-0.5"
                  style={{
                    color: writingToolStyle.color,
                  }}
                >
                  {writingToolStyle.icon}
                </span>
                {writingToolStyle.name}
              </>
            )}
          </span>
          {CoursableIcons.Chevron("down", "scale-75 text-systemGray-300")}
        </>
      }
      direction="bottom"
      align="end"
      menuClassName="flex-col min-w-[180px]"
      className="w-full px-2 text-left justify-start gap-2 z-3"
      activeClassName="bg-systemGray-100 active:bg-systemGray-200 text-foreground"
      stopPropagation
    >
      <ControlsButton
        onClick={() => {
          setWritingToolStyle?.(null);
        }}
        className="w-auto px-2 text-left justify-start gap-2"
      >
        None {writingToolStyle === null && <span className="ml-auto">{CoursableIcons.Check()}</span>}
      </ControlsButton>
      <Divider />
      {writingToolStyles.map((style, index) => (
        <ControlsButton
          key={index}
          onClick={(e) => {
            setWritingToolStyle?.(style);
          }}
          className="w-auto px-2 text-left justify-start gap-2"
        >
          <span style={{ color: style.color }}>{style.icon}</span> {style.name} {writingToolStyle === style && <span className="ml-auto">{CoursableIcons.Check()}</span>}
        </ControlsButton>
      ))}
    </ControlsSubmenu>
  );
};

const Divider = () => {
  return <ContextMenuDivider className="h-[1px]" />;
};
const Label = ({ style, children, className }: { style?: CSSProperties; children?: ReactNode; className?: string }) => {
  return (
    <div style={style} className={cn("w-full text-left text-sm text-systemGray-400 px-2 py-1 cursor-auto flex items-center justify-start gap-1", className)}>
      {children}
    </div>
  );
};

const parseOAImarkdown = (markdown: string) => {
  let parsedHtml = marked.parse(markdown, { breaks: true }) as string;
  parsedHtml = parsedHtml
    .replaceAll(`<h1>`, `<p><span style="font-size: ${28}pt"><strong>`)
    .replaceAll(`</h1>`, `</strong></span></p>`)
    .replaceAll(`<h2>`, `<p><span style="font-size: ${24}pt"><strong>`)
    .replaceAll(`</h2>`, `</strong></span></p>`)
    .replaceAll(`<h3>`, `<p><span style="font-size: ${20}pt"><strong>`)
    .replaceAll(`</h3>`, `</strong></span></p>`)
    .replaceAll(`<h4>`, `<p><span style="font-size: ${16}pt"><strong>`)
    .replaceAll(`</h4>`, `</strong></span></p>`)
    .replaceAll(`<h5>`, `<p><span style="font-size: ${12}pt"><strong>`)
    .replaceAll(`</h5>`, `</strong></span></p>`)
    .replaceAll(`<h6>`, `<p><span style="font-size: ${10}pt">`)
    .replaceAll(`</h6>`, `</span></p>`);

  return parsedHtml;
};
