import { useEffect, useRef, useState } from "react";
import CoursableIcons from "../../../utils/CoursableIcons";
import { cn } from "../../../utils/UtilityMethods";
import FollowMouseBlock from "../../elements/FollowMouseBlock";
import "../../../utils/StringExtensions";
import { AssistantCreativityLevel } from "../../../backend/Projects/types";

const CreativitySelector = ({ className, onChange }: { className?: string; onChange?: (l: AssistantCreativityLevel) => void }) => {
  const [creativityLevel, setCreativityLevel] = useState<AssistantCreativityLevel>("regular");
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipLevel, setTooltipLevel] = useState<AssistantCreativityLevel>("regular");
  const [tooltipLeft, setTooltipLeft] = useState<number>(2.5);

  const tooltipTimer = useRef<NodeJS.Timeout | null>(null);

  function StartTooltipTimer() {
    tooltipTimer.current && clearTimeout(tooltipTimer.current);
    tooltipTimer.current = setTimeout(() => {
      setShowTooltip(true);
    }, 1000);
  }
  function EndTooltipTimer() {
    tooltipTimer.current && clearTimeout(tooltipTimer.current);
  }

  function OnMouseEnterButton(level: AssistantCreativityLevel) {
    SwitchTooltip(level);
    if (!showTooltip) StartTooltipTimer();
  }

  function OnMouseLeaveButton() {
    EndTooltipTimer();
  }

  function SwitchTooltip(level: AssistantCreativityLevel) {
    setTooltipLevel(level);
    setTooltipLeft(level === "regular" ? 2.5 : level === "focused" ? -4 : 9.5);
  }

  function SelectCreativityLevel(level: AssistantCreativityLevel) {
    setCreativityLevel(level);
  }

  useEffect(() => {
    onChange?.(creativityLevel);
  }, [creativityLevel]);

  return (
    <FollowMouseBlock sensitivity={0.15} className={cn("z-10", className)}>
      <div className="w-[19rem] flex-centered relative">
        <div
          style={{
            gridTemplateColumns: "fit-content(100%) fit-content(100%) fit-content(100%)",
          }}
          onMouseEnter={() => setExpanded(true)}
          onMouseLeave={() => {
            setExpanded(false);
            setShowTooltip(false);
          }}
          className={cn("bg-systemGray-200 p-1 grid border border-systemGray-300 shadow-lg duration-300", expanded ? "gap-1 rounded-lg" : "gap-0 rounded-3xl")}
        >
          <CreativityLevelButton
            level="focused"
            selectedLevel={creativityLevel}
            expanded={expanded}
            onClick={() => SelectCreativityLevel("focused")}
            onMouseEnter={() => OnMouseEnterButton("focused")}
            onMouseLeave={() => OnMouseLeaveButton()}
          />
          <CreativityLevelButton
            level="regular"
            selectedLevel={creativityLevel}
            expanded={expanded}
            onClick={() => SelectCreativityLevel("regular")}
            onMouseEnter={() => OnMouseEnterButton("regular")}
            onMouseLeave={() => OnMouseLeaveButton()}
          />
          <CreativityLevelButton
            level="creative"
            selectedLevel={creativityLevel}
            expanded={expanded}
            onClick={() => SelectCreativityLevel("creative")}
            onMouseEnter={() => OnMouseEnterButton("creative")}
            onMouseLeave={() => OnMouseLeaveButton()}
          />
        </div>
        <div
          style={{
            height: "calc(100% - 0.5rem)",
            left: !expanded || creativityLevel === "regular" ? "6.5rem" : creativityLevel === "focused" ? "0.25rem" : "12.75rem",
          }}
          className={cn("absolute w-24 bg-background shadow top-1 z-0 duration-300 ease-out pointer-events-none", expanded ? "rounded-md" : "rounded-2xl")}
        />
        <CreativityLevelTooltip
          style={{
            left: `${tooltipLeft}rem`,
          }}
          level={tooltipLevel}
          className={cn("absolute top-[calc(100%_+_0.5rem)] w-56 -left-8", showTooltip && "opacity-100")}
        />
      </div>
    </FollowMouseBlock>
  );
};

export default CreativitySelector;

interface CreativityLevelButtonProps {
  level: AssistantCreativityLevel;
  selectedLevel: AssistantCreativityLevel;
  expanded: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  className?: string;
}

const CreativityLevelButton = ({ level, selectedLevel, expanded, onClick, onMouseEnter, onMouseLeave, className }: CreativityLevelButtonProps) => {
  const selected = level === selectedLevel;

  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={{
        transition: "width 0.3s, opacity 0.1s",
      }}
      className={cn(
        "py-0.5 text-sm font-medium text-foreground active:text-foreground/80 rounded-md flex-centered gap-2 overflow-clip z-1",
        expanded || selected ? "w-24" : "w-0 -mx-1",
        selected ? "opacity-100" : "opacity-75 hover:opacity-100",
        className
      )}
    >
      {creativityLevelConfig[level].icon}
      {level.capitalize()}
    </button>
  );
};

const CreativityLevelTooltip = ({ level, style, className }: { level: AssistantCreativityLevel; className?: string; style?: React.CSSProperties | undefined }) => {
  return (
    <div style={style} className={cn("flex-started flex-col gap-1 text-sm bg-background p-2 rounded-md shadow-lg border opacity-0 duration-300 pointer-events-none", creativityLevelConfig[level].tooltipStyles, className)}>
      <span className="text-base mb-0.5">{creativityLevelConfig[level].icon}</span>
      <div className="font-semibold">{creativityLevelConfig[level].tooltip.split("\n")[0]}</div>
      <div className="text-systemGray-500">{creativityLevelConfig[level].tooltip.split("\n")[1]}</div>
    </div>
  );
};

const creativityLevelConfig = {
  regular: {
    icon: CoursableIcons.SparklesFill("text-brand-500"),
    tooltipStyles: "border-brand-200 shadow-brand-100",
    tooltip: "Balanced and versatile\nGreat for most daily tasks.",
  },
  focused: {
    icon: CoursableIcons.GraduationCapFill("text-brandBlue-500"),
    tooltipStyles: "border-brandBlue-200 shadow-brandBlue-100",
    tooltip: "Strict and to the point\nIdeal for pulling information, solving problems, and working with documents.",
  },
  creative: {
    icon: CoursableIcons.Signature("text-purple-500"),
    tooltipStyles: "border-purple-300 dark:border-purple-950 shadow-purple-200 dark:shadow-purple-950/50",
    tooltip: "Imaginative and freeform\nBest for brainstorming ideas, writing essays, and generating content.",
  },
};
