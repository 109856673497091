import { useEffect, useState } from "react";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { DocumentViewer } from "react-documents";
import { ProjectFile } from "../../../../../backend/Projects/types";
import { getFileExtension } from "../../../../../utils/UtilityMethods";
import { useNotifications } from "../../../../../utils/NotificationsContext";
import LoadingIndicator from "../../../../elements/LoadingIndicator";
import Dropdown from "../../../../elements/DropdownMenu/Dropdown";
import { ContextMenu, ContextMenuButton, ContextMenuDivider, ContextMenuLabel } from "../../../../elements/DropdownMenu/ContextMenu";
import { extractYoutubeVideoID, isURLYouTubeVideo } from "../../../../../backend/Projects/ProjectLinks";
import YoutubeViewer from "./YoutubeViewer";
import { useAuth } from "../../../../../firebase/AuthContext";
import { CoursablePlan } from "../../../../../utils/CoursablePlan";
import Button from "../../../../elements/Button";
import InputField from "../../../../elements/InputField";
import WebArticleViewer from "./WebArticleViewer";
import { useProjectManager } from "../../Hooks/useProjectManager";

interface MaterialsViewerProps {
  selectedFile: ProjectFile;
  shown: boolean;
  peek: boolean;
  filesGridRef: React.RefObject<HTMLDivElement> | null;
  SelectFile: (id: string) => void;
  OpenAddFiles: () => void;
}

const MaterialsViewer = ({ shown, peek, filesGridRef, selectedFile, SelectFile, OpenAddFiles }: MaterialsViewerProps) => {
  const { Files } = useProjectManager();
  const { currentUser } = useAuth();
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);

  return (
    <div className={`flex flex-col w-full`}>
      <div className={`font-semibold px-4 duration-100 ${shown ? "py-4" : "py-2"} ${shown || peek ? "text-foreground" : "text-systemGray-500"} ${shown ? "text-xl md:text-2xl" : "text-base"}`}>Materials</div>
      <div className="w-full flex flex-col items-start justify-center border-y">
        <div
          ref={filesGridRef}
          style={{
            gridTemplateColumns: `repeat(auto-fill, minmax(${shown ? "12rem" : "10rem"}, 1fr))`,
          }}
          className={`w-full grid gap-2 px-4 ${shown ? "py-4" : "py-2"}`}
        >
          {Files.files.map((file, index) => (
            <FilePreview key={index} file={file} shown={shown} selectedID={selectedFile.id} SelectFile={SelectFile} />
          ))}
          {!plan.materialsPerProject ||
            (Files.files.length < plan.materialsPerProject && (
              <button onClick={OpenAddFiles} className="px-2 text-sm text-systemGray-500 hover:text-foreground active:text-systemGray-700 duration-100 flex items-center odd:justify-start even:justify-end gap-1">
                {CoursableIcons.Plus()} Add materials
              </button>
            ))}
        </div>
      </div>
      {selectedFile && <FileView shown={shown} file={selectedFile} canDelete={Files.files.length > 1} />}
    </div>
  );
};

export default MaterialsViewer;

const FilePreview = ({ file, shown, selectedID, SelectFile }: { file: ProjectFile; shown: boolean; selectedID: string | undefined; SelectFile: (v: string) => void }) => {
  const isSelected = selectedID === file.id;
  const extension = getFileExtension(file.name);

  const removeExtension = (v: string) => {
    if (!extension) return v;
    return v.slice(0, -extension.length - 1);
  };

  let icon;
  if (extension === "pdf") icon = CoursableIcons.PdfDoc("text-red-600");
  else if (extension === "doc" || extension === "docx") icon = CoursableIcons.DocxDoc("text-blue-600");
  else if (extension === "pptx") icon = CoursableIcons.PptxDoc("text-orange-600");
  else if (extension === "txt") icon = CoursableIcons.TxtDoc("text-black dark:text-systemGray-700");
  else if (extension === "md") icon = CoursableIcons.MdDoc("text-cyan-600");
  else if (file.sourceUrl && isURLYouTubeVideo(file.sourceUrl)) icon = CoursableIcons.YouTube("text-red-600");
  else icon = CoursableIcons.Doc();

  return (
    <button
      onClick={() => SelectFile(file.id)}
      className={`flex items-center justify-start gap-1 p-1 border rounded-md duration-100 ${isSelected ? "bg-brand-25 dark:bg-brand-100 border-brand-500 shadow" : "hover:bg-systemGray-200 active:bg-systemGray-300 border-transparent"} ${
        shown ? "text-sm" : "text-mini"
      }`}
    >
      <div className={`text-base ${shown ? "aspect-square" : "aspect-[3/1]"}`}>{icon}</div>
      <div className="text-center truncate break-all">{removeExtension(file.name)}</div>
    </button>
  );
};

const FileHeader = ({ file, canDelete }: { file: ProjectFile; canDelete: boolean }) => {
  const { Files } = useProjectManager();
  const extension = getFileExtension(file.name);

  const removeExtension = (v: string) => {
    if (!extension) return v;
    return v.slice(0, -extension.length - 1);
  };

  const [fileName, setFileName] = useState<string>(removeExtension(file.name));
  const [renamingFile, setRenamingFile] = useState<boolean>(false);
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const { sendError } = useNotifications();

  useEffect(() => {
    setFileName(removeExtension(file.name));
  }, [file]);

  async function SaveNewFileName() {
    setRenamingFile(false);

    const oldName = removeExtension(file.name);
    if (fileName === oldName) return;
    if (!/\S/.test(fileName)) return setFileName(removeExtension(file.name)); //Test if its empty or only whitespace

    try {
      const extension = getFileExtension(file.name);
      const newName = fileName + (extension ? "." + extension : "");
      Files.SaveName(file, newName);
    } catch (error) {
      console.error(error);
      sendError();
    }
  }

  async function ClickDeleteFile() {
    setConfirmingDelete(false);
    try {
      await Files.Delete(file);
    } catch (error) {
      console.error(error);
      sendError();
    }
  }

  return (
    <div className="p-4 w-full flex gap-2 items-center justify-between border-b">
      {renamingFile ? (
        <>
          <InputField className="w-full" icon={CoursableIcons.Edit()} value={fileName} onValueChange={setFileName} />
          <Button onClick={SaveNewFileName}>
            {CoursableIcons.Check()}
            Done
          </Button>
        </>
      ) : (
        <>
          <div className="w-full font-semibold text-sm md:text-base">{fileName}</div>
          <div className="flex gap-2 items-center justify-end relative">
            <Dropdown label="Actions">
              <ContextMenuButton label="Rename" icon={CoursableIcons.Edit()} onClick={() => setRenamingFile(true)} />
              {canDelete && (
                <>
                  <ContextMenuDivider />
                  <ContextMenuButton label="Delete" icon={CoursableIcons.Delete()} onClick={() => setConfirmingDelete(true)} destructive />
                </>
              )}
            </Dropdown>
            <ContextMenu autoClose={false} isOpen={confirmingDelete} setIsOpen={setConfirmingDelete}>
              <ContextMenuLabel label="Are you sure you want to delete this file?" width={180} />
              <ContextMenuDivider />
              <ContextMenuButton label="Cancel" icon={CoursableIcons.Xmark()} onClick={() => setConfirmingDelete(false)} />
              <ContextMenuButton label="Delete" icon={CoursableIcons.Delete()} onClick={ClickDeleteFile} destructive />
            </ContextMenu>
          </div>
        </>
      )}
    </div>
  );
};

const FileView = ({ shown, file, canDelete }: { shown: boolean; file: ProjectFile; canDelete: boolean }) => {
  const youtubeID = file.sourceUrl ? (isURLYouTubeVideo(file.sourceUrl) ? extractYoutubeVideoID(file.sourceUrl) : null) : null;
  const isWebArticle = file.sourceUrl ? !youtubeID : false;

  return (
    <div className="w-full h-full flex flex-col overflow-auto">
      <FileHeader file={file} canDelete={canDelete} />
      {youtubeID ? (
        shown && <YoutubeViewer file={file} videoID={youtubeID} />
      ) : isWebArticle ? (
        <WebArticleViewer file={file} />
      ) : (
        <div className="flex h-full w-full bg-systemGray-300 relative ">
          <div className="flex flex-col items-center gap-2 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 absolute z-0">
            <LoadingIndicator className="stroke-systemGray-400" />
            <div className="text-systemGray-500 text-sm truncate">Loading preview</div>
          </div>
          {shown && <DocumentViewer className="bg-transparent z-10" url={file.downloadUrl} />}
          {/* <iframe className="w-full z-10" src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent("https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx")}`}></iframe> */}
        </div>
      )}
    </div>
  );
};
