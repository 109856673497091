import { AuthPageModalBackground } from "../Auth/AuthShared";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cookies from "../../../utils/CookiesManager";
import Button from "../../elements/Button";
import InputField from "../../elements/InputField";

const DevLoginPage = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [animateError, setAnimateError] = useState(false);

  function TryUnlock() {
    if (password === process.env.REACT_APP_LOCK_PASSWORD) {
      cookies.set("devLoggedIn", "true");
      setError("");
      navigate("/");
    } else {
      setPassword("");
      setAnimateError(true);
      setError("Incorrect password");
    }
  }

  return (
    <AuthPageModalBackground homeButton={false}>
      <h3 className="font-semibold text-center text-xl mb-4">Welcome back! 👋</h3>
      <InputField variant="ghost" placeholder="Enter your password" type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
      {error && (
        <p onAnimationEnd={() => setAnimateError(false)} className={`${animateError && "animate-error"} text-red-500 text-center -my-2 text-sm`}>
          {error}
        </p>
      )}
      <Button onClick={TryUnlock} className="mt-4">
        Unlock
      </Button>
    </AuthPageModalBackground>
  );
};

export default DevLoginPage;
