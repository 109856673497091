// Session Storage Manager

class SSManager {
  static set<T>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static get<T>(key: string): T | null {
    const value = sessionStorage.getItem(key);
    if (value === null) return null;
    return JSON.parse(value);
  }
}

export default SSManager;
