import CoursableIcons from "../../utils/CoursableIcons";
import { cn } from "../../utils/UtilityMethods";
import Button from "./Button";
import LoadingIndicator from "./LoadingIndicator";
import ShineEffect from "./ShineEffect/ShineEffect";

const AIButton = ({ generating, children, onClick, className }: { generating?: boolean; children: React.ReactNode; onClick?: () => void; className?: string }) => {
  return (
    <Button onClick={onClick} className={cn(`rounded-full shrink-0 border-2 border-brand-200 relative ${!!generating && "animate-pulse pointer-events-none"}`, className)}>
      {!!generating ? <LoadingIndicator className="w-4 h-4" /> : CoursableIcons.SparklesFill("text-lg w-4 h-4")}
      {children}
      <ShineEffect color="rgb(255, 255, 255, 0.1)" className="rounded-full" />
    </Button>
  );
};

export default AIButton;
