import { useState, useEffect } from "react";
import { useLocation, useNavigate, Location } from "react-router-dom";
import { useAuth } from "../../firebase/AuthContext";
import CoursableLogo from "../elements/CoursableLogo";
import CoursableIcons from "../../utils/CoursableIcons";
import { ContextMenu, ContextMenuButton, ContextMenuDivider } from "../elements/DropdownMenu/ContextMenu";
import { HeaderBanner } from "../../backend/Website";
import MarkdownView from "../elements/MarkdownView";
import { useHeaderBanner } from "./HeaderBannerProvider";
import { cn, hexToHsb } from "../../utils/UtilityMethods";
import Button from "../elements/Button";
import { ThemeToggle } from "./AppHeader";
import { Link } from "react-router-dom";

const Header = ({ scrollBehaviour = "hover" }: { scrollBehaviour?: "shadow" | "hover" }) => {
  const { headerBanner } = useHeaderBanner();
  const [smallSize, setSmallSize] = useState(false);

  const [didScroll, setDidScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const location: Location = useLocation();

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollHandler = () => {
      setDidScroll(window.scrollY > 1);
      setIsOpen(false);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [didScroll]);

  useEffect(() => {
    const resizeHandler = () => {
      setSmallSize(window.innerWidth < 768);
    };
    window.addEventListener("resize", resizeHandler);

    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <header className={`sticky top-0 duration-300 shrink-0 z-10 w-full`}>
      {headerBanner && <Banner banner={headerBanner} />}
      <div className={cn(`flex-centered h-20 pt-4 px-4`, scrollBehaviour === "shadow" && "duration-100 bg-background h-24 pb-4", didScroll && scrollBehaviour === "shadow" && "shadow")}>
        <div
          className={cn(
            "w-full max-w-7xl flex items-center h-full gap-4 rounded-2xl duration-200 border border-transparent px-0",
            didScroll && scrollBehaviour === "hover" && "px-4 border-background backdrop-blur-2xl bg-systemGray-300/50 shadow-xl dark:shadow-systemGray-100"
          )}
        >
          <CoursableLogo className="mr-auto" onClick={() => navigate("/?appRedirect=false")} wide size={smallSize ? 6 : 8} />
          <div className="hidden h-full lg:flex-centered">
            <ThemeToggle />
          </div>
          <NavBarButtons location={location} className="hidden lg:block" />
          <button onClick={() => setIsOpen((prev) => !prev)} className="block lg:hidden ">
            {CoursableIcons.Menu("w-6 h-6 cursor-pointer")}
          </button>
          {currentUser ? (
            <UserProfilePanel />
          ) : (
            <>
              <SignInButton className="hidden lg:block" />
              <SignUpButton />
            </>
          )}
        </div>
        <div
          className={cn(
            "flex flex-col gap-4 text-xl py-4 items-center bg-systemGray-200/50 rounded-2xl backdrop-blur-2xl absolute left-4 right-4 top-[5.5rem] border border-background z-10 shadow-xl lg:hidden duration-300 origin-top",
            isOpen ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"
          )}
        >
          {!currentUser && <SignInButton />}
          <NavBarButtons location={location} />
          <div className="absolute top-2 right-4">
            <ThemeToggle />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

const NavBarButtons = ({ location, className }: { location: Location; className?: string }) => {
  return (
    <div className="flex-centered flex-col gap-2 md:gap-0 md:flex-row">
      <NavbarButton className={className} to="/" location={location}>
        Home
      </NavbarButton>
      <NavbarButton className={className} to="/projects" location={location}>
        Projects
      </NavbarButton>
      <NavbarButton className={className} to="/courses" location={location}>
        Courses
      </NavbarButton>
      <NavbarButton className={className} to="/pricing" location={location}>
        Pricing
      </NavbarButton>
      <NavbarButton className={className} to="/press" location={location}>
        Press
      </NavbarButton>
    </div>
  );
};

const SignInButton = ({ className }: { className?: string }) => {
  return (
    <Link to="/login" className={`${className} text-base text-brand-500 hover:text-brand-600 duration-100 font-semibold shrink-0`}>
      Sign in
    </Link>
  );
};

const SignUpButton = () => {
  return (
    <Button to="/signup" className="shrink-0 text-base">
      <span className="inline lg:hidden">Get Started</span>
      <span className="hidden lg:inline">Get Started Free</span>
    </Button>
  );
};

const NavbarButton = ({ children, to, location, className }: { children?: React.ReactNode; to: string; location: Location; className?: string }) => {
  return (
    <Link
      to={to}
      className={`text-base shrink-0 hover:bg-systemGray-100 active:bg-systemGray-200 px-2 py-1 rounded-md ${
        location.pathname === to || location.pathname === `${to}/` ? "text-brand-500 hover:text-brand-600" : "text-systemGray-700 hover:text-foreground"
      } transition-colors font-semibold ${className}`}
    >
      {children}
    </Link>
  );
};

export const UserProfilePanel = ({ className }: { className?: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { currentUser } = useAuth();

  const { SignOut } = useAuth();
  const navigate = useNavigate();

  async function HandleSignOut() {
    try {
      await SignOut();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={`flex my-auto gap-2 p-0.5 items-baseline border border-transparent ${isOpen ? "bg-systemGray-100" : "hover:bg-systemGray-100"} rounded-full ${className} cursor-pointer select-none relative duration-100`}
      >
        <div className="flex rounded-full text-base md:text-lg font-bold h-9 md:h-10 w-9 md:w-10 text-white bg-gradient-to-t from-brand-500 to-brand-400 shadow-md shadow-brand-500/30">
          <div className="m-auto">{currentUser?.displayName?.slice(0, 2).toUpperCase()}</div>
        </div>
        <div className="font-semibold -mb-1 hidden lg:block text-foreground">{currentUser?.displayName}</div>
        {CoursableIcons.Chevron("down", "text-sm translate-y-0.5 mr-1 text-systemGray-500")}
        <ContextMenu isOpen={isOpen} setIsOpen={setIsOpen} className="min-w-[140px]">
          <ContextMenuButton label="Dashboard" icon={CoursableIcons.Home()} onClick={() => navigate("/app")} />
          <ContextMenuButton label="Projects" icon={CoursableIcons.Project()} onClick={() => navigate("/app/projects")} />
          <ContextMenuButton label="Courses" icon={CoursableIcons.GraduationCap()} onClick={() => navigate("/app/courses")} />
          <ContextMenuButton label="Settings" icon={CoursableIcons.Gear()} onClick={() => navigate("/app/settings")} />
          <ContextMenuDivider />
          <ContextMenuButton label="Log out" icon={CoursableIcons.Logout()} destructive onClick={HandleSignOut} />
        </ContextMenu>
      </div>
    </>
  );
};

export const Banner = ({ banner }: { banner: HeaderBanner }) => {
  const floatToHexOpacity = (float: number) => {
    const hex = Math.round(float * 255).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  const calcLightMode = (color: string | undefined) => {
    if (!color) return false;
    const hsb = hexToHsb(color);
    return hsb[2] > 0.8;
  };

  const [lightMode, setLightMode] = useState(calcLightMode(banner.backgroundColor));

  useEffect(() => {
    setLightMode(calcLightMode(banner.backgroundColor));
  }, [banner.backgroundColor]);

  return (
    <div
      style={{
        backgroundColor: `${banner.backgroundColor ?? "#A51C1C"}${floatToHexOpacity(banner.backgroundOpacity ?? 0.9)}`,
      }}
      className="flex-centered backdrop-blur-2xl p-2"
    >
      <MarkdownView size="sm" inverseTheme={!lightMode} className={`${lightMode ? "" : "text-white prose-a:text-white"} font-light text-center max-w-5xl`}>
        {banner.text}
      </MarkdownView>
    </div>
  );
};
