import Checkbox from "../../../../elements/Checkbox";
import Dropdown from "../../../../elements/DropdownMenu/Dropdown";
import "../../../../../utils/StringExtensions";
import { FlashcardDifficulty } from "../../../../../backend/Projects/types";
import { Dispatch } from "react";
import { DifficultyBadge } from "./ProjectFlashcards";

interface DifficultySelectorProps {
  generating: boolean;
  selectedDifficulties: FlashcardDifficulty[];
  setSelectedDifficulties: Dispatch<React.SetStateAction<FlashcardDifficulty[]>>;
  className?: string;
}

const DifficultySelector = ({ generating, selectedDifficulties, setSelectedDifficulties, className }: DifficultySelectorProps) => {
  function ToggleDifficulty(difficulty: FlashcardDifficulty, selected: boolean) {
    if (selected) {
      if (!selectedDifficulties.some((option) => option === difficulty)) setSelectedDifficulties((prev) => [...prev, difficulty]);
      else if (isAllSelected) setSelectedDifficulties([difficulty]);
    } else {
      if (selectedDifficulties.length > 1) setSelectedDifficulties(selectedDifficulties.filter((option) => difficulty !== option));
    }
  }

  const isOneSelected = selectedDifficulties.length === 1;
  const isAllSelected = selectedDifficulties.length === allDifficulties.length;

  return (
    <Dropdown
      className={`${generating && "pointer-events-none animate-pulse"} ${className}`}
      closeOnClick={false}
      label={isOneSelected ? <DifficultyBadge difficulty={selectedDifficulties[0]} /> : isAllSelected ? <DifficultyBadge difficulty="any" /> : selectedDifficulties.map((d, index) => <DifficultyBadge short key={index} difficulty={d} />)}
    >
      <div className="w-[300px] flex flex-col justify-center gap-1 p-2">
        <div className="text-sm text-systemGray-500">Difficulty for the generated flashcards.</div>
        <div className="w-full h-[1px] bg-systemGray-200" />
        <Checkbox
          label={<DifficultyBadge difficulty="any" className="mr-auto" />}
          size="sm"
          variant="dynamic"
          checked={isAllSelected}
          setChecked={() => {
            setSelectedDifficulties(allDifficulties);
          }}
          className="my-2"
        />
        <div className="w-full flex items-center gap-2 mb-2">
          <div className="w-full h-[1px] bg-systemGray-200" />
          <div className="text-mini text-systemGray-500 -my-2">or</div>
          <div className="w-full h-[1px] bg-systemGray-200" />
        </div>
        {allDifficulties.map((option, key) => {
          return (
            <Checkbox
              key={key}
              label={<DifficultyBadge className="mr-auto" difficulty={option} />}
              size="sm"
              variant="dynamic"
              checked={selectedDifficulties.includes(option) && selectedDifficulties.length !== allDifficulties.length}
              setChecked={(selected) => {
                ToggleDifficulty(option, selected);
              }}
            />
          );
        })}
      </div>
    </Dropdown>
  );
};

export default DifficultySelector;

const allDifficulties: FlashcardDifficulty[] = ["easy", "medium", "hard"];
