import { useNavigate } from "react-router-dom";
import CTAButton from "../../elements/CTAButton";
import { useState } from "react";
import "../../../utils/StringExtensions";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import { useAuth } from "../../../firebase/AuthContext";
import { UpgradePopup } from "../../elements/UpgradePopup";
import { GAEvent, GALogEvent } from "../../../firebase/GoogleAnalytics";

interface Props {
  isLoading: boolean;
  courses: Course[];
  selectedCourse: Course | null;
  selectCourse: (course: Course | null) => void;
  selectTopic: (topic: Course.Topic | null) => void;
}

const DashboardSidemenu = ({ isLoading, courses, selectedCourse, selectCourse, selectTopic }: Props) => {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);

  function ClickNewCourse() {
    if (!currentUser) return;

    if (plan.courseLimitMonthly && (currentUser.customClaims.stats?.coursesGeneratedCurrentCycle ?? 0) >= plan.courseLimitMonthly) {
      GALogEvent(GAEvent.Sales.upgradePopupShown, {
        source: "course_count_limit",
      });
      return setShowUpgradePopup(true);
    }

    return navigate("/app/courses/generate");
  }

  return (
    <div
      onClick={(e) => {
        selectCourse(null);
        selectTopic(null);
      }}
      className="flex flex-col gap-2 w-full px-2 md:px-4 py-4 overflow-auto md:max-w-sm"
    >
      <div className="flex gap-2 justify-between items-center">
        <div className="text-3xl font-semibold mb-2 ml-2 md:ml-4">Courses</div>
      </div>
      {isLoading ? (
        <>
          <LoadingPlaceholder />
          <LoadingPlaceholder />
          <LoadingPlaceholder />
        </>
      ) : courses.length === 0 ? (
        <div className="mx-2 md:mx-4 mt-auto text-center text-systemGray-500">
          You don't have any courses yet. Click on{" "}
          <button onClick={ClickNewCourse} className="font-semibold text-brand-500 hover:text-brand-600 duration-100">
            New Course
          </button>{" "}
          below to begin.
        </div>
      ) : (
        courses.map((course, index) => <CourseSidemenuItem key={index} course={course} isSelected={course === selectedCourse} selectCourse={selectCourse} selectTopic={selectTopic} />)
      )}

      <CTAButton disabled={!currentUser} className="mt-auto mx-2 md:mx-0 text-base md:text-base" onClick={ClickNewCourse}>
        New Course
      </CTAButton>
      <UpgradePopup
        isOpen={showUpgradePopup}
        Close={() => setShowUpgradePopup(false)}
        highlightCourses
        header={
          <>
            You have generated {currentUser?.customClaims.stats?.coursesGeneratedCurrentCycle ?? 0} out of {plan.courseLimitMonthly} courses available this month with the <span className={`font-bold text-brandBlue-500`}>{plan.title}</span>{" "}
            plan.
          </>
        }
      />
    </div>
  );
};

export default DashboardSidemenu;

interface CourseSidemenuItemProps {
  course: Course;
  isSelected: boolean;
  selectCourse: (course: Course) => void;
  selectTopic: (topic: Course.Topic | null) => void;
}

const CourseSidemenuItem = ({ course, isSelected, selectCourse, selectTopic }: CourseSidemenuItemProps) => {
  const completedPercentage = courseCompletedPrecentage(course);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        selectCourse(course);
        selectTopic(null);
      }}
      className={`rounded-lg px-2 md:px-4 py-2 cursor-pointer border-2 duration-100 ${isSelected ? "bg-brand-25 dark:bg-brand-100 border-brand-500 shadow" : "border-transparent hover:bg-systemGray-100"}`}
    >
      <div className="flex flex-col gap-1 text-systemGray-500 text-sm">
        <div className="font-semibold text-lg text-foreground">{course.title}</div>
        <div className="flex items-center">
          <div className="w-full">Completion: {Math.round(completedPercentage * 100)}%</div>
          <div className="rounded-full w-1/2 h-2 bg-systemGray-200 overflow-hidden">
            <div
              style={{
                width: `${Math.round(completedPercentage * 100)}%`,
              }}
              className={`h-full rounded-r-full bg-gradient-to-r from-brand-500 to-brand-300 duration-300`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingPlaceholder = () => {
  const randomWidth = 30 + Math.random() * 20;
  return (
    <div className="bg-systemGray-200 p-4 rounded-lg animate-pulse flex flex-col gap-2">
      <div className="rounded-md h-5 bg-systemGray-300 w-full" />
      <div
        style={{
          width: `${randomWidth}%`,
        }}
        className={`rounded h-3 bg-systemGray-300`}
      />
    </div>
  );
};

export const courseCompletedPrecentage = (course: Course) =>
  course.topics.reduce((topicSum: number, topic: Course.Topic) => {
    if (topic.isCompleted) return topicSum + 1;
    if (topic.materials.length === 0) return topicSum;

    const materials = topic.materials.reduce((materialSum: number, material: Course.Topic.Material) => {
      return materialSum + (material.isCompleted ? 1 : 0);
    }, 0);

    return topicSum + materials / topic.materials.length;
  }, 0) / course.topics.length;
