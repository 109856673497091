import { useEffect, useRef, useState } from "react";
import CourseStructureTopicView from "./CourseStructureTopicView";
import { GAEvent, GALogEvent } from "../../../firebase/GoogleAnalytics";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import { PersonalizeCourseStructure } from "../../../backend/CourseGeneration";
import { useAuth } from "../../../firebase/AuthContext";
import { PlanBadge } from "../../page-elements/AppHeader";
import Button from "../../elements/Button";
import InputBox from "../../elements/InputBox";
import { useNotifications } from "../../../utils/NotificationsContext";
import { UpgradePopup } from "../../elements/UpgradePopup";

interface Props {
  courseStructure: CourseStructure;
  setCourseStructure: (courseStructure: CourseStructure) => void;
  ConfirmCourse: () => void;
}

const PersonalizeCourseStructureView = ({ courseStructure, setCourseStructure, ConfirmCourse }: Props) => {
  const originalCourseStructure = useRef<CourseStructure>(courseStructure);
  const [updatedTopicIDs, setUpdatedTopicIDs] = useState<number[]>([]);

  const { currentUser } = useAuth();
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);
  const maxPersonalizations = plan.personalizationsLimit;

  const nPersonalizations = useRef<number>(0);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);

  const [isGeneratingPersonalization, setIsGeneratingPersonalization] = useState<boolean>(false);

  const [nStepsShown, setNStepsShown] = useState<number>(-1);
  const [isPersonalizationSettingsShown, setIsPersonalizationSettingsShown] = useState<boolean>(false);

  const { sendError } = useNotifications();

  useEffect(() => {
    const interval = setInterval(() => {
      if (nStepsShown >= courseStructure.topics.length) {
        clearInterval(interval);
        setIsPersonalizationSettingsShown(true);
        return;
      }

      setNStepsShown((prev) => prev + 1);
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [courseStructure, nStepsShown]);

  useEffect(() => {
    setNStepsShown(-1);
  }, [courseStructure]);

  async function Personalize(desiredComplexity: number, prompt: string) {
    if (maxPersonalizations && nPersonalizations.current >= maxPersonalizations) {
      GALogEvent(GAEvent.Sales.upgradePopupShown, {
        source: "courses_personalizations_limit",
      });
      setShowUpgradePopup(true);
      return;
    }

    setIsGeneratingPersonalization(true);
    try {
      GALogEvent(GAEvent.Course.Structure.personalize, { prompt, desiredComplexity });
      const newCourseStructure = await PersonalizeCourseStructure(originalCourseStructure.current, desiredComplexity, prompt);
      const oldCourseStructure = courseStructure;
      const updatedIDs: number[] = [];
      for (let i = 0; i < newCourseStructure.topics.length; i++) {
        if (i >= oldCourseStructure.topics.length) {
          updatedIDs.push(i);
          continue;
        }
        const newTopic = newCourseStructure.topics[i];
        if (oldCourseStructure.topics.some((oldTopic) => oldTopic.title === newTopic.title || oldTopic.description === newTopic.description)) continue;
        updatedIDs.push(i);
      }

      setUpdatedTopicIDs(updatedIDs);
      setCourseStructure(newCourseStructure);
      nPersonalizations.current++;
    } catch (error) {
      console.log(error);
      sendError("Oops, something went wrong! Please try again.");
    }
    setIsGeneratingPersonalization(false);
  }

  return (
    <div className="bg-background flex flex-col w-full h-full max-w-7xl border-x overflow-auto">
      <div className="flex items-center gap-2 md:gap-4 shadow bg-background/50 backdrop-blur p-4 text-sm md:text-base">
        <div className="text-xl md:text-3xl font-bold flex-grow">{courseStructure.title.charAt(0).toUpperCase() + courseStructure.title.slice(1)}</div>
        <Button disabled={isGeneratingPersonalization} variant="secondary" to="/app/courses">
          Cancel
        </Button>
        <Button disabled={isGeneratingPersonalization} onClick={ConfirmCourse}>
          Confirm
        </Button>
      </div>
      <div className="flex flex-col lg:grid lg:grid-cols-2 h-full overflow-auto">
        <PeronalizationControlsView
          isShown={isPersonalizationSettingsShown}
          isGeneratingPersonalization={isGeneratingPersonalization}
          Personalize={Personalize}
          nPersonalizations={nPersonalizations.current}
          maxPersonalizations={maxPersonalizations}
        />
        <div className="flex flex-col gap-4 lg:overflow-auto p-4">
          {courseStructure.topics.map((topic, index) => (
            <CourseStructureTopicView key={index} index={index + 1} topic={topic} didUpdate={updatedTopicIDs.includes(index)} className={`shrink-0 ${index <= nStepsShown ? "opacity-100 scale-100" : "opacity-0 scale-105"}`} />
          ))}
        </div>
      </div>
      <UpgradePopup
        isOpen={showUpgradePopup}
        Close={() => setShowUpgradePopup(false)}
        highlightCourses
        header={
          <>
            You've reached the limit of course personalizations with the <span className={`font-semibold text-brandBlue-500`}>{plan.title}</span> plan.
          </>
        }
      />
    </div>
  );
};

export default PersonalizeCourseStructureView;

interface PersonalizationControlsProps {
  isShown: boolean;
  isGeneratingPersonalization: boolean;
  Personalize: (desiredComplexity: number, prompt: string) => void;
  nPersonalizations: number;
  maxPersonalizations: number | null;
}
const PeronalizationControlsView = ({ isShown, isGeneratingPersonalization, Personalize, nPersonalizations, maxPersonalizations }: PersonalizationControlsProps) => {
  const [prompt, setPrompt] = useState<string>("");
  const [desiredComplexity, setDesiredComplexity] = useState<number>(3);

  useEffect(() => {
    if (!isGeneratingPersonalization) {
      setPrompt("");
    }
  }, [isGeneratingPersonalization]);

  return (
    <div
      className={`p-4 mb-auto w-full flex flex-col gap-6 md:gap-8 ${
        isShown ? `duration-1000 max-h-[1000px] ${isGeneratingPersonalization ? "pointer-events-none duration-0 opacity-50" : "opacity-100"}` : "max-h-0 opacity-0"
      } transition-all ease-linear`}
    >
      <div className="flex gap-2 items-center">
        <PlanBadge />
        <div className="text-mini text-systemGray-500">
          {maxPersonalizations === null ? "Unlimited " : `${nPersonalizations}/${maxPersonalizations} `}
          personalizations
        </div>
      </div>
      <div className="text-sm md:text-base">
        Here is your full generated course. Ask AI to make changes to personalize it exactly for your needs. Once you are done, click <span className="font-semibold text-brand-500">Confirm</span> in the top right corner and get to it!
      </div>
      <TitleLabel title="Changes" />
      <InputBox className="w-full h-16" value={prompt} onValueChange={setPrompt} placeholder="If you have any desired changes for the course outline, type them out here." characterLimit={500} />
      <TitleLabel title="Complexity" />
      <DesiredComplexitySlider value={desiredComplexity} onChange={setDesiredComplexity} />
      <Button
        disabled={isGeneratingPersonalization}
        className="m-auto mb-8 text-base"
        onClick={() => {
          Personalize(desiredComplexity, prompt);
        }}
        loading={isGeneratingPersonalization}
      >
        Personalize
      </Button>
    </div>
  );
};

const TitleLabel = ({ title }: { title: string }) => {
  return <div className="text-base md:text-lg font-semibold -mb-3 md:-mb-4">{title}</div>;
};

const DesiredComplexitySlider = ({ value, onChange }: { value: number; onChange: (value: number) => void }) => {
  const sliderThumnStyle = `
    [&::-webkit-slider-thumb]:appearance-none
    [&::-webkit-slider-thumb]:h-8
    [&::-webkit-slider-thumb]:w-5
    [&::-webkit-slider-thumb]:bg-transparent
    [&::-webkit-slider-thumb]:cursor-pointer
  `;

  const handleStyle = "h-5 w-2 rounded-full duration-300";
  const handlePos = `${(value - 1) * 25}%`;

  const coeffs = [1, 1.5, 1.75, 2, 2.5];
  const handleMarginLeft = `${-0.375 * coeffs[value - 1]}rem`;

  return (
    <div className="w-full flex flex-col gap-4 relative">
      <input className={`w-full [-webkit-appearance:none] outline-none h-3 bg-systemGray-200 rounded-full ${sliderThumnStyle}`} type="range" min={1} max={5} value={value} onChange={(e) => onChange(parseInt(e.target.value))} />
      <div
        style={{
          left: handlePos,
          marginLeft: handleMarginLeft,
        }}
        className={`bg-brand-500 h-8 w-5 rounded-full absolute -top-2.5 pointer-events-none shadow-md shadow-brand-200`}
      ></div>
      <div className="flex justify-between items-center">
        <div className={`${handleStyle} ${value === 1 ? "bg-brand-200" : "bg-systemGray-200"}`} />
        <div className={`${handleStyle} ${value === 2 ? "bg-brand-200" : "bg-systemGray-200"}`} />
        <div className={`${handleStyle} ${value === 3 ? "bg-brand-300" : "bg-systemGray-200"}`} />
        <div className={`${handleStyle} ${value === 4 ? "bg-brand-200" : "bg-systemGray-200"}`} />
        <div className={`${handleStyle} ${value === 5 ? "bg-brand-200" : "bg-systemGray-200"}`} />
      </div>
      <div className="grid grid-cols-3 w-[100%] -mt-2">
        <div className={`font-semibold text-sm md:text-base text-left duration-300 ${value === 2 ? "text-brand-300" : value === 1 ? "text-brand-500" : "text-systemGray-500"}`}>More basic</div>
        <div className={`font-semibold text-sm md:text-base text-center duration-300 ${value === 3 ? "text-brand-500" : "text-systemGray-500"}`}>Standard</div>
        <div className={`font-semibold text-sm md:text-base text-right duration-300 ${value === 4 ? "text-brand-300" : value === 5 ? "text-brand-500" : "text-systemGray-500"}`}>More detailed</div>
      </div>
    </div>
  );
};
