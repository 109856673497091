import { useState } from "react";
import { HiLockClosed } from "react-icons/hi";
import Badge from "../../elements/Badge";

const CourseStructureTopicView = ({ index, topic, className, didUpdate }: { index: number; topic: CourseStructure.Topic; didUpdate: boolean; className?: string }) => {
  const [showUpdated, setShowUpdated] = useState(didUpdate);

  return (
    <div
      className={`${
        showUpdated ? "bg-green-50/50 border-green-300 dark:bg-green-950/50 dark:border-green-700 hover:border-green-500" : "bg-systemGray-100  hover:bg-brand-25 border-transparent hover:border-brand-500"
      } rounded-lg border-2 overflow-hidden hover:shadow-lg-c  duration-300 ${className}`}
      onMouseLeave={() => setShowUpdated(false)}
    >
      <div className="p-2 flex flex-col gap-3">
        <div className="w-full flex items-baseline justify-between">
          <div className="w-full text-lg md:text-xl font-semibold">
            {index}. {topic.title}
          </div>
          {didUpdate && (
            <Badge className={showUpdated ? "opacity-100" : "opacity-0"} variant="green">
              New
            </Badge>
          )}
        </div>
        <div className="text-sm md:text-base text-foreground">{topic.description}</div>
        <LockedMaterials />
      </div>
    </div>
  );
};

export default CourseStructureTopicView;

const LockedMaterials = () => {
  return (
    <div className="relative">
      <button className="text-brand-500 flex gap-1 items-center peer">
        <HiLockClosed className="w-[1.0rem] md:w-[1.3rem] h-[1.0rem] md:h-[1.3rem]" />
        <div className="font-semibold text-sm md:text-base">Materials</div>
      </button>
      <div className="absolute -top-8 bg-background dark:shadow-systemGray-200 rounded-md px-2 py-1 shadow-md dark:shadow-lg pointer-events-none opacity-0 peer-hover:opacity-100 duration-200">
        <div className="text-foreground">Study materials will be available once you confirm this course.</div>
      </div>
    </div>
  );
};
