import { analytics } from "./FirebaseConfig";
import { logEvent, setUserProperties, setUserId } from "firebase/analytics";
import "../utils/StringExtensions";
import { CoursablePlan, CoursablePlanCode, CoursablePlanInterval } from "../utils/CoursablePlan";
import { ProjectDerivativeType } from "../backend/Projects/types";

export function GALogEvent(event: GAEvent, params?: any) {
  logEvent(analytics, event, params);
}

export function GALogPurchase(transactionID: string, price: number, plan: "plus", planCycle: "month" | "quarter" | "year") {
  logEvent(analytics, "purchase", {
    currency: "USD",
    value: price,
    transaction_id: transactionID,
    items: [
      {
        item_id: plan,
        item_name: `${plan.capitalize()} Plan`,
        price: price,
        quantity: 1,
        item_variant: planCycle,
      },
    ],
  });
}

export function GALogLogin(method: "google" | "password") {
  logEvent(analytics, "login", {
    method,
  });
}

export function GALogSignup(method: "google" | "password") {
  logEvent(analytics, "signup", {
    method,
  });
}

export function GALogGenerateLead() {
  logEvent(analytics, "generate_lead");
}

export function GALogBeginCheckout(plan: CoursablePlan, planCycle: CoursablePlanInterval) {
  if (planCycle === null || planCycle === "week") return;
  const price = planCycle === "month" ? plan.monthlyPrice : plan.annualMonthlyPrice * 12;
  logEvent(analytics, "begin_checkout", {
    value: price,
    currency: "USD",
    items: [
      {
        item_id: plan.title,
        item_name: `${plan.title} Plan`,
        price: price,
        quantity: 1,
        item_variant: planCycle,
      },
    ],
  });
}

export function GASetUserID(id: string) {
  setUserId(analytics, id);
}

export function GASetUserProperties(params: GAUserProperties) {
  setUserProperties(analytics, {
    ...params,
  });
}

export function GALogProjectMaterialAdded(extension?: string) {
  logEvent(analytics, GAEvent.Project.Material.added, {
    extension,
  });
}

export function GALogProjectMaterialRejected(reason: string) {
  logEvent(analytics, GAEvent.Project.Material.added, {
    reason,
  });
}

export function GALogPublishedProjectDerivative(type: ProjectDerivativeType) {
  logEvent(analytics, type === "summary" ? GAEvent.Project.Summary.published : type === "flashcards" ? GAEvent.Project.Flashcards.published : GAEvent.Project.Quiz.published);
}

export function GALogUnpublishedProjectDerivative(type: ProjectDerivativeType) {
  logEvent(analytics, type === "summary" ? GAEvent.Project.Summary.unpublished : type === "flashcards" ? GAEvent.Project.Flashcards.unpublished : GAEvent.Project.Quiz.unpublished);
}

interface GAUserProperties {
  plan?: CoursablePlanCode;
  plan_cycle?: CoursablePlanInterval;
  affiliation?: string;
  source?: string;
  educationLevel?: string | null;
  educationField?: string | null;
  emailOptIn?: boolean;
}

type GAEvent = GAEvent.Course | GAEvent.Course.Structure | GAEvent.Course.Topic | GAEvent.Course.Editing | GAEvent.User | GAEvent.Project | GAEvent.Project.Chat | GAEvent.Sales;

export namespace GAEvent {
  export enum Course {
    generated = "course_generated",
    deleted = "course_deleted",
    duplicated = "course_duplicated",
    completed = "course_completed",
  }

  export namespace Course {
    export enum Structure {
      generate = "course_structure_generate",
      personalize = "course_structure_personalize",
      confirm = "course_structure_confirm",
    }
    export enum Topic {
      completed = "topic_completed",
      materialCompleted = "topic_material_completed",
    }

    export enum Editing {
      started = "course_editing_started",
      saved = "course_editing_saved",
      cancelled = "course_editing_cancelled",
    }
  }

  export enum User {
    logout = "logout",
    deletedAccount = "deleted_account",
  }

  export enum Project {
    created = "project_created",
    deleted = "project_deleted",
    renamed = "project_renamed",
    reset = "project_reset",
    addedFiles = "project_added_files",
    deletedFiles = "project_deleted_files",
    sentMessage = "project_sent_message",
    viewedNews = "project_viewed_news",
  }

  export namespace Project {
    export enum Chat {
      suggestionClicked = "project_chat_suggestion_clicked",
    }
    export enum Summary {
      published = "project_summary_published",
      unpublished = "project_summary_unpublished",
    }
    export enum Flashcards {
      published = "project_flashcards_published",
      unpublished = "project_flashcards_unpublished",
    }
    export enum Quiz {
      published = "project_quiz_published",
      unpublished = "project_quiz_unpublished",
    }
    export enum Material {
      added = "project_material_added",
    }
  }

  export enum Sales {
    stripePortalOpened = "stripe_portal_opened",
    upgradePopupShown = "upgrade_popup_shown",
  }
}
