import CoursableIcons from "../../../../../utils/CoursableIcons";
import { ProjectFile } from "../../../../../backend/Projects/types";
import MaterialsViewer from "./MaterialsViewer";

interface ExpandedMaterialsViewerProps {
  selectedFile: ProjectFile;
  Collapse: () => void;
  SelectFile: (id: string) => void;
  OpenAddFiles: () => void;
}

const ExpandedMaterialsViewer = ({ selectedFile, Collapse, SelectFile, OpenAddFiles }: ExpandedMaterialsViewerProps) => {
  return (
    <div className="overflow-auto w-full flex bg-background relative">
      <button onClick={Collapse} className="absolute top-4 right-4 text-xl rounded-lg hover:bg-systemGray-200 active:bg-systemGray-300 text-systemGray-500 hover:text-foreground active:text-systemGray-700 duration-100 p-1.5">
        {CoursableIcons.Expand("down-left")}
      </button>
      <MaterialsViewer selectedFile={selectedFile} shown={true} SelectFile={SelectFile} peek={false} filesGridRef={null} OpenAddFiles={OpenAddFiles} />
    </div>
  );
};

export default ExpandedMaterialsViewer;
