import { DocumentData, DocumentSnapshot, Unsubscribe, collection, doc, getDoc, getDocs, onSnapshot, setDoc } from "firebase/firestore";
import { GetIDToken, auth, db } from "../../firebase/FirebaseConfig";
import { CoursableProject } from "./types";
import { ExtractServerResponse } from "../Shared";
import { GAEvent, GALogEvent } from "../../firebase/GoogleAnalytics";

export async function CreateNewProject(files: File[], links: string[], projectName?: string): Promise<CoursableProject> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });

  if (projectName !== undefined) formData.append("name", projectName);
  formData.append("links", JSON.stringify(links));

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/create`, {
    method: "POST",
    body: formData,
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  const { project }: { project: CoursableProject } = (await ExtractServerResponse(response)).data;

  GALogEvent(GAEvent.Project.created, {
    totalFiles: files.length,
  });

  return project;
}

export async function FetchAllProjects(): Promise<CoursableProject[]> {
  const currentUser = auth.currentUser;
  if (!currentUser) return [];

  const projectDocs = await getDocs(collection(db, `users/${currentUser.uid}/projects`));

  const projects: CoursableProject[] = projectDocs.docs
    .map((doc) => {
      try {
        return projectFromDoc(doc);
      } catch (e) {
        return null;
      }
    })
    .filter((p) => p !== null) as CoursableProject[];
  projects.sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime());
  return projects;
}

export async function FetchProject(projectID: string): Promise<CoursableProject> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const projectDoc = await getDoc(doc(db, `users/${currentUser.uid}/projects/${projectID}`));
  return projectFromDoc(projectDoc);
}

export async function ResetProjectChat(id: string): Promise<string> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${id}/reset-chat`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { newChatThreadID } = (await ExtractServerResponse(response)).data;

  GALogEvent(GAEvent.Project.reset);

  return newChatThreadID;
}

export async function DeleteProject(id: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${id}/delete`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  GALogEvent(GAEvent.Project.deleted);

  return ExtractServerResponse(response);
}

export async function RenameProject(projectID: string, name: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) return;

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}`);
  await setDoc(docRef, { name }, { merge: true });

  GALogEvent(GAEvent.Project.renamed);
}

export function SubscribeToProjectChanges(projectID: string, callback: (project: CoursableProject) => void): Unsubscribe {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  return onSnapshot(doc(db, `users/${currentUser.uid}/projects/${projectID}`), (doc) => {
    callback(projectFromDoc(doc));
  });
}

export function SubscribeToAllProjectsChanges(callback: (projects: CoursableProject[]) => void): Unsubscribe {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  return onSnapshot(collection(db, `users/${currentUser.uid}/projects`), (snapshot) => {
    const projects = snapshot.docs
      .map((doc) => {
        try {
          return projectFromDoc(doc);
        } catch (e) {
          return null;
        }
      })
      .filter((p) => p !== null) as CoursableProject[];
    projects.sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime());
    callback(projects);
  });
}

const projectFromDoc = (doc: DocumentSnapshot<DocumentData>): CoursableProject => {
  const data = doc.data();
  if (!data) throw new Error(`Project ${doc.id} not found`);

  return {
    id: doc.id,
    chatThreadID: data.chatThreadID,
    vectorStoreID: data.vectorStoreID,
    name: data.name,
    openAIFilesUploaded: data.openAIFilesUploaded,
    lastActiveDate: data.lastActiveDate?.toDate() ?? undefined,
    dateCreated: data.dateCreated.toDate(),
    isGenerating: data.isGenerating ?? false,
    isGeneratingSummary: data.isGeneratingSummary ?? false,
    isGeneratingFlashcards: data.isGeneratingFlashcards ?? false,
    isGeneratingQuiz: data.isGeneratingQuiz ?? false,
  };
};
