import { ButtonHTMLAttributes, MouseEvent, useRef } from "react";
import { useState } from "react";
import { cn } from "../../utils/UtilityMethods";
import Button from "./Button";
import FollowMouseBlock from "./FollowMouseBlock";
import { Link } from "react-router-dom";

interface Props extends ButtonHTMLAttributes<HTMLElement> {
  to?: string;
}

const CTAButton = ({ className, to, ...props }: Props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const aRef = useRef<HTMLAnchorElement>(null);

  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isMouseIn, setIsMouseIn] = useState(false);

  const onMouseMove = (event: MouseEvent) => {
    const rect = (ref?.current ?? aRef?.current)?.getBoundingClientRect();
    if (!rect) return;
    setIsMouseIn(true);

    const posX = Math.abs(event.clientX - rect.left) - rect.width * 0.5;
    const posY = Math.abs(event.clientY - rect.top) - rect.height * 0.5;

    setOffset({ x: posX * 0.01, y: posY * 0.025 });
  };
  const onMouseLeave = (event: MouseEvent) => {
    setIsMouseIn(false);
    setOffset({ x: 0, y: 0 });
  };

  const sharedStyle = {
    transform: `translate(${offset.x * 5}px, ${offset.y * 5}px) ${isMouseIn ? "scale(1.05)" : "scale(1)"}`,
    transition: `transform ${isMouseIn ? "0.1s" : "0.3s"} ease-out, box-shadow ${isMouseIn ? "0.1s" : "0.3s"} ease-out`,
  };
  const sharedClassName = cn(
    "stroke-white flex gap-2 items-center justify-center bg-brand-500 rounded-full font-semibold px-8 py-1 text-white shadow-lg shadow-brand-200 hover:shadow-brand-300 active:bg-brand-600 disabled:opacity-50 disabled:pointer-events-none shrink-0 select-none",
    className
  );

  return to === undefined ? (
    <button ref={ref} style={sharedStyle} {...props} className={sharedClassName} onMouseMove={(e) => onMouseMove(e)} onMouseLeave={(e) => onMouseLeave(e)}>
      {props.children}
    </button>
  ) : (
    <Link ref={aRef} style={sharedStyle} className={sharedClassName} {...props} onMouseMove={(e) => onMouseMove(e)} onMouseLeave={(e) => onMouseLeave(e)} to={to}>
      {props.children}
    </Link>
  );
};

export default CTAButton;
