import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import { GALogPurchase } from "../../../firebase/GoogleAnalytics";
import { useAuth } from "../../../firebase/AuthContext";
import cookies from "../../../utils/CookiesManager";
import { PlanBadge } from "../../page-elements/AppHeader";
import Button from "../../elements/Button";
import AppPageTemplate from "../../page-elements/AppPageTemplate";
import PlanCard from "./ProfileCard";
import WhatsNewCard from "./WhatsNewCard";
import ProjectsCard from "./ProjectsCard";
import CoursesCard from "./CoursesCard";
import ReferralsCard from "./ReferralsCard";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const DashboardPage = () => {
  const [query] = useSearchParams();

  const event = query.get("event");
  const plan = query.get("plan");
  const cycle = query.get("cycle");
  const price: number | null = query.get("price") === null ? null : Number(query.get("price"));
  const transactionID = query.get("transactionID");

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  function RecordAnalytics() {
    if (event !== "subscribed") return;
    if (plan !== "plus") return;
    if (cycle !== "month" && cycle !== "quarter" && cycle !== "year") return;
    if (price === null || isNaN(price)) return;
    if (transactionID === null) return;

    GALogPurchase(transactionID, price, plan, cycle);
  }

  function CheckCourseGenerationPrompt() {
    if (cookies.get("course-prompt")) {
      navigate("/app/courses/generate", { state: { prompt: cookies.get("course-prompt") } });
      cookies.remove("course-prompt");
    }
  }

  useEffect(() => {
    RecordAnalytics();
    CheckCourseGenerationPrompt();
  }, []);

  return (
    <AppPageTemplate helmet={pageMetatags({ title: "Dashboard", canonical: "/app" })}>
      <div className="w-full h-full flex items-start justify-center bg-systemGray-100 overflow-auto">
        <div className="w-full max-w-4xl flex flex-col px-4 pb-10">
          <div className="w-full flex flex-col gap-4 items-center justify-center py-10">
            <PlanBadge className="!text-sm" />
            <div className="text-3xl font-semibold text-foreground">Hi, {currentUser?.displayName?.split(" ")[0]} 😉</div>
            <div className="text-systemGray-500">What would you like to work on today?</div>
          </div>
          <div className="w-full flex-centered flex-col gap-6">
            <div className="w-full flex-centered flex-col md:flex-row gap-6">
              <ProjectsCard className="w-full md:w-1/2" />
              <CoursesCard className="w-full md:w-1/2" />
            </div>
            <div className="w-full flex flex-col-reverse md:flex-row items-start justify-start gap-6">
              <WhatsNewCard className="w-full md:w-3/5" />
              <div className="w-full md:w-2/5 flex flex-col items-start justify-start gap-6">
                <PlanCard className="w-full" />
                <ReferralsCard className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppPageTemplate>
  );
};

export default DashboardPage;

export const EmptyDashboardCard = ({ icon, text, cta, to }: { icon: ReactNode; text: string; cta: string; to: string }) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-4">
      <div className="text-3xl text-systemGray-400 rounded-full p-4 bg-systemGray-100">{icon}</div>
      <div className="text-systemGray-500 text-sm">{text}</div>
      <Button to={to}>{cta}</Button>
    </div>
  );
};
