import { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthContext";
import { cn } from "../../../utils/UtilityMethods";
import AppPageTemplate from "../../page-elements/AppPageTemplate";
import HomePageTemplate from "../../page-elements/HomePageTemplate";
import Button from "../../elements/Button";
import CoursableIcons from "../../../utils/CoursableIcons";
import useDidScroll from "../../../utils/Hooks/useDidScroll";
import { ProjectDerivativeType, ProjectFlashcardSet, ProjectQuiz, ProjectSummary } from "../../../backend/Projects/types";
import "../../../utils/UtilityMethods";
import useShareLinkModal from "../../elements/ShareLinkModal";
import { pageMetatags } from "../../../utils/MetatagsGenerator";
import { useParams } from "react-router-dom";

export type PublicProjectsPageState = "loading" | "loaded" | "error" | "not-found";

interface PublicProjectsPageProps {
  state: PublicProjectsPageState;
  type: ProjectDerivativeType;
  header: React.ReactNode;
  content: React.ReactNode;
  popup?: React.ReactNode;
  pageTitle?: string;
  pageDescription?: string;
}

export const PublicProjectsPageTemplate = ({ state, header, content, popup, type, pageTitle, pageDescription }: PublicProjectsPageProps) => {
  const { currentUser } = useAuth();
  const loggedIn = !!currentUser;

  const { scrollRef, didScroll } = useDidScroll<HTMLDivElement>();

  return (
    <PageTemplate type={type} pageTitle={pageTitle} pageDescription={pageDescription}>
      <div className={cn("w-full", loggedIn && "flex flex-col h-full overflow-auto")}>
        {state === "loading" && <Loading type={type} />}
        {state === "not-found" && <DidNotFind type={type} />}
        {state === "error" && <Error type={type} />}
        {state === "loaded" && header}
        <div className={cn("flex items-center justify-start flex-col bg-background h-full w-full overflow-auto relative", !loggedIn && "md:pt-8", state !== "loaded" && "h-0 pointer-events-none opacity-0")}>
          <div className={cn("flex flex-col w-full overflow-auto", !loggedIn && "max-w-7xl")}>
            <div ref={scrollRef} className={cn("w-full p-4 overflow-auto flex items-start justify-center", didScroll && "border-t")}>
              {content}
            </div>
            {popup}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

const PageTemplate = ({ children, type, pageTitle, pageDescription }: { children: React.ReactNode; type: ProjectDerivativeType; pageTitle?: string; pageDescription?: string }) => {
  const { currentUser } = useAuth();
  const { publicID } = useParams<{ publicID: string }>();

  const metadata = pageMetatags({
    title: pageTitle ?? `Public ${type}`,
    description: pageDescription,
    canonical: `/public/${type}/${publicID}`,
  });

  return currentUser ? (
    <AppPageTemplate helmet={metadata}>{children}</AppPageTemplate>
  ) : (
    <HomePageTemplate headerScrollBehaviour="shadow" helmet={metadata}>
      {children}
    </HomePageTemplate>
  );
};

interface PublicProjectsHeaderProps {
  title: string;
  type: ProjectDerivativeType;
  derivative: ProjectSummary | ProjectFlashcardSet | ProjectQuiz;
  buttons?: React.ReactNode;
  header: string;
}

export const PublicProjectsHeader = ({ title, type, derivative, buttons, header }: PublicProjectsHeaderProps) => {
  const [didScroll, setDidScroll] = useState(false);
  const { currentUser } = useAuth();
  const link = `${process.env.REACT_APP_CLIENT_URL}/public/${type}/${derivative.publicID}`;

  const { ShareDropdown, SharePopup } = useShareLinkModal({
    title: `Share ${type}`,
    link,
    socialMediaShareText: derivative.publicID ? `Check out ${type === "flashcards" ? "these flashcards" : `this ${type}`} on Coursable: ${derivative.name}\n\n${link}` : undefined,
    description: `Anyone with this link can to view this summary.`,
  });

  const loggedIn = !!currentUser;

  useEffect(() => {
    const scrollHandler = () => {
      setDidScroll(window.scrollY > 1);
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <>
      <div className={cn("w-full flex-centered px-4 z-1", !loggedIn && "md:sticky md:top-28")}>
        <div
          className={cn(
            "flex items-center justify-between gap-2 w-full max-w-7xl border border-transparent px-0 md:px-4 py-4 rounded-2xl duration-300 @container",
            didScroll && "md:px-4 md:max-w-6xl md:border-background md:backdrop-blur-2xl md:bg-systemGray-300/50 md:shadow-xl md:dark:shadow-systemGray-100"
          )}
        >
          <div className="flex-started flex-col">
            <h3 className="text-xs md:text-sm text-brand-400">{header}</h3>
            <h1 className="text-lg md:text-xl font-bold">{title}</h1>
          </div>
          <div className="grid grid-cols-2 gap-2 place-items-end md:flex md:items-center md:justify-end md:gap-4 shrink-0">
            {ShareDropdown}
            {buttons}
          </div>
        </div>
      </div>
      {SharePopup}
    </>
  );
};

const Loading = ({ type }: { type: ProjectDerivativeType }) => {
  return (
    <div className="w-full h-full flex-centered flex-col gap-8 py-28">
      <div className="rounded-full bg-systemGray-200 p-4 text-3xl text-systemGray-400 animate-pulse">{CoursableIcons.ProjectFill()}</div>
      <div className="text-xl text-systemGray-500">Loading {type}</div>
    </div>
  );
};

const DidNotFind = ({ type }: { type: ProjectDerivativeType }) => {
  return (
    <div className="w-full h-full flex-centered flex-col gap-8 py-28">
      <div className="font-bold text-7xl">404</div>
      <div className="text-xl text-systemGray-500">{type === "flashcards" ? "Flashcard set" : type.capitalize()} does not exist or was hidden by the owner</div>
      <Button to="/">Go home</Button>
    </div>
  );
};

const Error = ({ type }: { type: ProjectDerivativeType }) => {
  return (
    <div className="w-full h-full flex-centered flex-col gap-8 py-28">
      <div className="font-bold text-7xl">😥</div>
      <div className="text-xl text-systemGray-500">Oops, something went wrong loading the {type === "flashcards" ? "flashcard set" : type}</div>
      <Button to="/">Go home</Button>
    </div>
  );
};
