import { Routes, Route } from "react-router-dom";
import HomePage from "./components/pages/Home/HomePage";
import LoginPage from "./components/pages/Auth/LoginPage";
import SignupPage from "./components/pages/Auth/SignupPage";
import PageNotFound from "./components/pages/404";
import PrivateRoute from "./utils/PrivateRoute";
import AuthProvider from "./firebase/AuthContext";
import CourseGenerationPage from "./components/pages/Course Generation/CourseGenerationPage";
import PricingPage from "./components/pages/Home/PricingPage";
import ConfirmEmailPage from "./components/pages/Auth/ConfirmEmailPage";
import SettingsPage from "./components/pages/Settings/SettingsPage";
import SettingsProfile from "./components/pages/Settings/SettingsProfile";
import SettingsPlans from "./components/pages/Settings/SettingsPlans";
import ResetPassword from "./components/pages/Auth/ResetPassword";
import TermsPage from "./components/pages/Legal/Terms";
import PrivacyPage from "./components/pages/Legal/Privacy";
import usePageTracking from "./utils/Hooks/usePageTracking";
import CoursePDFPage from "./components/pages/Courses/PDF/CoursePDFPage";
import PressPage from "./components/pages/Home/PressPage";
import SettingsNone from "./components/pages/Settings/SettingsNone";
import NotificationsProvider from "./utils/NotificationsContext";
import SettingsEnterprise from "./components/pages/Settings/SettingsEnterprise";
import CourseEditingPage from "./components/pages/Course Editing/CourseEditingPage";
import ProjectsPage from "./components/pages/Projects/ProjectsPage";
import NoProjectView from "./components/pages/Projects/NoProjectView";
import { useEffect } from "react";
import { ConfigureLogRocket } from "./utils/LogRocket";
import DevLoginPage from "./components/pages/Dev/DevLoginPage";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MaintenancePage from "./components/pages/Maintenance/MaintenancePage";
import CoursesDashboardPage from "./components/pages/Courses/CoursesDashboardPage";
import cookies from "./utils/CookiesManager";
import ProjectsHomePage from "./components/pages/Home/Projects/ProjectsHomePage";
import CoursesHomePage from "./components/pages/Home/Courses/CoursesHomePage";
import ProjectView from "./components/pages/Projects/ProjectView";
import ProjectSummaryPDFPage from "./components/pages/Projects/Workspace/Summaries/PDF/ProjectSummaryPDFPage";
import { HeaderBannerProvider } from "./components/page-elements/HeaderBannerProvider";
import CompleteRegistrationPage from "./components/pages/Auth/CompleteRegistrationPage";
import ProjectFlashcardsPDFPage from "./components/pages/Projects/Workspace/Flashcards/PDF/FlashcardsPDFPage";
import ProjectQuizPDFPage from "./components/pages/Projects/Workspace/Quizzes/PDF/QuizzPDFPage";
import UIDesignPage from "./components/pages/Dev/UIDesignPage";
import DashboardPage from "./components/pages/Dashboard/DashboardPage";
import { ThemeProvider } from "./utils/ThemeContext";
import PublicProjectsFlashcardsPage from "./components/pages/Public Projects/PublicFlashcardsPage";
import PublicProjectsSummaryPage from "./components/pages/Public Projects/PublicSummariesPage";
import PublicProjectsQuizPage from "./components/pages/Public Projects/PublicQuizzesPage";
import { pageMetatags } from "./utils/MetatagsGenerator";
import ProjectNotesPDFPage from "./components/pages/Projects/Workspace/Notes/PDF/NotesPDFPage";

function App() {
  usePageTracking();

  useEffect(() => {
    ConfigureLogRocket();
  }, []);

  if (process.env.REACT_APP_MAINTENANCE === "true") {
    return (
      <HelmetProvider>
        <ThemeProvider>
          <MaintenancePage />
        </ThemeProvider>
      </HelmetProvider>
    );
  }

  if (process.env.REACT_APP_LOCK_PASSWORD && cookies.get("devLoggedIn") !== "true") {
    return (
      <HelmetProvider>
        <ThemeProvider>
          <DevLoginPage />
        </ThemeProvider>
      </HelmetProvider>
    );
  }

  return (
    <HelmetProvider>
      <ThemeProvider>
        <AuthProvider>
          <NotificationsProvider>
            <HeaderBannerProvider>
              <Helmet>
                {pageMetatags({
                  description:
                    "Power up your study materials with AI flashcards, summaries, and quizzes; pull insights and find answers faster with documents chat. Learn something new with personalized AI courses and approved study materials from the web.",
                  ogImage: "/Images/OpenGraph/Promo.png",
                })}
              </Helmet>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/courses" element={<CoursesHomePage />} />
                <Route path="/projects" element={<ProjectsHomePage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/press" element={<PressPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                <Route path="/complete-registration" element={<CompleteRegistrationPage />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/app">
                  <Route path="" element={<PrivateRoute route={<DashboardPage />} />} />
                  <Route path="courses">
                    <Route path="" element={<PrivateRoute route={<CoursesDashboardPage />} />} />
                    <Route path="generate" element={<PrivateRoute route={<CourseGenerationPage />} />} />
                    <Route path=":id/pdf" element={<PrivateRoute route={<CoursePDFPage />} />} />
                    <Route path=":id/edit" element={<PrivateRoute route={<CourseEditingPage />} />} />
                  </Route>
                  <Route path="projects" element={<PrivateRoute route={<ProjectsPage />} />}>
                    <Route path="" element={<PrivateRoute route={<NoProjectView />} />} />
                    <Route path="new" element={<PrivateRoute route={<NoProjectView openNewProject />} />} />
                    <Route path=":id" element={<PrivateRoute route={<ProjectView />} />} />
                  </Route>
                  <Route path="projects/:projectID/summary/:summaryID/pdf" element={<PrivateRoute route={<ProjectSummaryPDFPage />} />} />
                  <Route path="projects/:projectID/flashcards/:flashcardSetID/pdf" element={<PrivateRoute route={<ProjectFlashcardsPDFPage />} />} />
                  <Route path="projects/:projectID/quizzes/:quizID/pdf" element={<PrivateRoute route={<ProjectQuizPDFPage />} />} />
                  <Route path="projects/:projectID/notes/:noteID/pdf" element={<PrivateRoute route={<ProjectNotesPDFPage />} />} />
                  <Route path="settings" element={<PrivateRoute route={<SettingsPage />} />}>
                    <Route path="" element={<SettingsNone />} />
                    <Route path="profile" element={<SettingsProfile />} />
                    <Route path="plans" element={<SettingsPlans />} />
                    <Route path="enterprise" element={<SettingsEnterprise />} />
                  </Route>
                </Route>
                <Route path="/public/summary/:publicID" element={<PublicProjectsSummaryPage />} />
                <Route path="/public/summary/:publicID/pdf" element={<ProjectSummaryPDFPage />} />
                <Route path="/public/flashcards/:publicID" element={<PublicProjectsFlashcardsPage />} />
                <Route path="/public/flashcards/:publicID/pdf" element={<ProjectFlashcardsPDFPage />} />
                <Route path="/public/quiz/:publicID" element={<PublicProjectsQuizPage />} />
                <Route path="/public/quiz/:publicID/pdf" element={<ProjectQuizPDFPage />} />
                <Route path="/dev/ui-design" element={<PrivateRoute route={<UIDesignPage />} />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </HeaderBannerProvider>
          </NotificationsProvider>
        </AuthProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
