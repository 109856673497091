import { useContext, useEffect } from "react";
import { useAuth } from "../../../firebase/AuthContext";
import CTAButton from "../../elements/CTAButton";
import { ProjectsContext } from "./ProjectsPage";

const NoProjectView = ({ openNewProject }: { openNewProject?: boolean }) => {
  const { currentUser } = useAuth();
  const firstName = currentUser?.displayName?.split(" ")[0] || "Learner";

  const projectsContext = useContext(ProjectsContext);

  useEffect(() => {
    if (openNewProject) projectsContext.OpenNewProjectModal?.();
  }, []);

  return (
    <div className="w-full h-full flex flex-col gap-4 items-center justify-center p-4 shrink-0">
      <div className="text-3xl">👋</div>
      <div className="text-xl font-semibold">Hi, {firstName}</div>
      <div className="text-systemGray-500 text-center">Select an existing project from the sidemenu to get started</div>
      {projectsContext.OpenNewProjectModal && (
        <>
          <div className="w-full max-w-[250px] flex gap-2 items-center">
            <div className="h-[1px] bg-systemGray-300 w-full" />
            <div className="text-systemGray-400 text-sm font-light">or</div>
            <div className="h-[1px] bg-systemGray-300 w-full" />
          </div>
          <CTAButton onClick={() => projectsContext.OpenNewProjectModal?.()} className="mt-2">
            Create a New Project
          </CTAButton>
        </>
      )}
    </div>
  );
};

export default NoProjectView;
