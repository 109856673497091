import { GetIDToken, auth, storage } from "../../firebase/FirebaseConfig";
import { ProjectFile, CoursableProject } from "./types";
import { ExtractServerResponse } from "../Shared";
import { GAEvent, GALogEvent } from "../../firebase/GoogleAnalytics";
import { getDownloadURL, getMetadata, listAll, ref } from "firebase/storage";
import { getFileExtension } from "../../utils/UtilityMethods";

export async function FetchProjectFiles(projectID: string): Promise<ProjectFile[]> {
  const currentUser = auth.currentUser;
  if (!currentUser) return [];

  const storageRef = ref(storage, `users/${currentUser.uid}/projects/${projectID}/documents`);

  const listFiles = await listAll(storageRef);

  const files: ProjectFile[] = await Promise.all(
    listFiles.items.map(async (item) => {
      const [url, metadata] = await Promise.all([getDownloadURL(item), getMetadata(item)]);
      return {
        id: item.name,
        openaiID: metadata.customMetadata?.openaiID ?? "",
        projectID,
        name: metadata.customMetadata?.name ?? "Unknown file",
        extension: getFileExtension(item.name),
        downloadUrl: url,
        sourceUrl: metadata.customMetadata?.sourceUrl,
      };
    })
  );

  return files;
}

export async function CheckIfOpenAIFilesUploaded(project: CoursableProject) {
  if (project.openAIFilesUploaded) return;

  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${project.id}/reupload-openai-files`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  return ExtractServerResponse(response);
}

export async function RenameFile(file: ProjectFile, name: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) return;

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${file.projectID}/file/${file.id}/rename`, {
    method: "POST",
    body: JSON.stringify({
      newName: name,
    }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  return await ExtractServerResponse(response);
}

export async function AddMaterialsToProject(projectID: string, files: File[], links: string[]) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  formData.append("links", JSON.stringify(links));

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/add-materials`, {
    method: "POST",
    body: formData,
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  GALogEvent(GAEvent.Project.addedFiles);

  return await ExtractServerResponse(response);
}

export async function DeleteFileFromProject(file: ProjectFile) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${file.projectID}/file/${file.id}/delete`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  GALogEvent(GAEvent.Project.deletedFiles);

  return await ExtractServerResponse(response);
}
