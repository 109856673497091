import { Dispatch, useEffect, useRef, useState } from "react";
import { AssistantCreativityLevel, AssistantMessage, CoursableProject, Flashcard } from "../../../../backend/Projects/types";
import { WaitFor } from "../../../../utils/UtilityMethods";
import { FetchProjectMessages, SendProjectMessage, StopProjectMessageStream, StreamProjectMessage } from "../../../../backend/Projects/ProjectMessages";
import { Unsubscribe } from "firebase/firestore";
import { ResetProjectChat, SubscribeToProjectChanges } from "../../../../backend/Projects/ProjectBase";
import useCancellablePromise from "../../../../utils/Hooks/useCancellablePromise";
import { useNotifications } from "../../../../utils/NotificationsContext";
import { AttachAttachment, MessageAttachment } from "../Chat/MessageAttachments";

const useStreams = true;
const placeholderMessageID = "generating-response";

const useProjectMessages = (project: CoursableProject | undefined, setProject: Dispatch<React.SetStateAction<CoursableProject | undefined>>) => {
  const [resettingChat, setResettingChat] = useState(false);

  const [messages, setMessages] = useState<AssistantMessage[]>([]);
  const streamingMessageID = useRef<string | undefined>(undefined);

  const { cancellablePromise } = useCancellablePromise(false);
  const { sendError } = useNotifications();

  const creativityLevel = useRef<AssistantCreativityLevel>("regular");

  const resettingChatAbortController = useRef<AbortController | undefined>(undefined);
  const sendMessageAbortController = useRef<AbortController | undefined>(undefined);
  const messageGenerationListener = useRef<Unsubscribe | undefined>(undefined);
  const initializingProjectID = useRef<string | undefined>(undefined);

  async function Initialize(project: CoursableProject) {
    setMessages([]);

    try {
      initializingProjectID.current = project.id;
      let messages = await FetchProjectMessages(project.id);
      if (initializingProjectID.current !== project.id) return;

      messages = messages.filter((m) => {
        if (m.sender === "assistant" && m.content === "") return false;
        return true;
      });

      setMessages(messages);

      if (project.isGenerating) {
        AddPlaceholderMessage();
        WaitForGenerationEnd(project);
      }
    } catch (error: any) {
      if (error.name === "AbortError" || error.isCanceled) return console.log("ABORTED", error);
      console.log(error);
      sendError();
    }
  }

  async function SendMessage(message: string, attachments?: MessageAttachment[]) {
    if (!project) return;

    sendMessageAbortController.current?.abort();
    sendMessageAbortController.current = new AbortController();

    ToggleIsGenerating(true);
    try {
      const messageWithAttachments =
        attachments?.reverse().reduce((prev, curr) => {
          return AttachAttachment(prev, curr);
        }, message) ?? message;

      const newMessage: AssistantMessage = {
        threadID: project.chatThreadID,
        content: messageWithAttachments,
        sender: "user",
        sentAt: new Date(),
        annotations: [],
      };
      AddMessage(newMessage);

      await WaitFor(0.5);
      AddPlaceholderMessage();
      if (!useStreams) {
        const responseMessages = await SendProjectMessage(project.id, newMessage, creativityLevel.current, false, sendMessageAbortController.current);
        if (!responseMessages) throw new Error("No response messages");

        await AddNewMessages(responseMessages);
      } else {
        await StreamProjectMessage(project.id, newMessage, creativityLevel.current, onStreamMessageCreated, onStreamMessage, onStreamMessageEnd, sendMessageAbortController.current);
      }
    } catch (err: any) {
      if (err.name === "AbortError") return;
      console.log(err);

      const errorMessage: AssistantMessage = {
        threadID: project.id,
        content: "Oops, something went wrong. Please try again later.",
        sender: "system",
        sentAt: new Date(),
        annotations: [],
      };
      if (messages.some((m) => m.id === placeholderMessageID)) ReplacePlaceholderMessage(errorMessage);
      else AddMessage(errorMessage);
      streamingMessageID.current = undefined;
    }
    ToggleIsGenerating(false);
  }

  async function StopStream() {
    if (!project || !streamingMessageID.current) return;
    try {
      const streamingMessage = messages.find((m) => m.id === streamingMessageID.current);
      if (!streamingMessage) return;

      await StopProjectMessageStream(project.id, streamingMessage.threadID);
      streamingMessageID.current = undefined;
    } catch (error: any) {
      sendError("Failed to stop the generation.", "This should not happen. Please try again.");
    }
  }

  function AddMessage(message: AssistantMessage) {
    setMessages((prev) => {
      return [message, ...prev];
    });
  }
  function AddPlaceholderMessage() {
    AddMessage({
      id: placeholderMessageID,
      threadID: project?.id ?? "",
      content: "",
      sender: "assistant",
      sentAt: new Date(),
      annotations: [],
    });
  }
  async function AddNewMessages(messages: AssistantMessage[]) {
    for (let i = messages.length - 1; i >= 0; i--) {
      if (i === messages.length - 1) {
        ReplacePlaceholderMessage(messages[i]);
      } else {
        await WaitFor(1);
        AddMessage(messages[i]);
      }
    }
  }
  function ReplacePlaceholderMessage(message?: AssistantMessage) {
    setMessages((prev) => {
      const newPrev = [...prev];
      const id = newPrev.findIndex((m) => m.id === placeholderMessageID);
      if (id === -1) return prev;
      if (message) newPrev[id] = message;
      else newPrev.splice(id, 1);
      return newPrev;
    });
  }

  function onStreamMessageCreated(streamingMessage: AssistantMessage) {
    ReplacePlaceholderMessage();
    streamingMessageID.current = streamingMessage.id;
    setMessages((prev) => [streamingMessage, ...prev]);
  }
  function onStreamMessage(content: string) {
    setMessages((prev) => {
      const streamingMessageIndex = prev.findIndex((m) => m.id === streamingMessageID.current);
      if (streamingMessageIndex === -1) return prev;

      const newPrev = [...prev];
      newPrev[streamingMessageIndex].content = content;
      return newPrev;
    });
  }
  function onStreamMessageEnd() {
    ToggleIsGenerating(false);
    streamingMessageID.current = undefined;
  }

  function ToggleIsGenerating(isGenerating: boolean) {
    setProject((prev) => {
      if (!prev) return prev;
      return { ...prev, isGenerating };
    });
  }

  function WaitForGenerationEnd(project: CoursableProject) {
    messageGenerationListener.current?.();
    messageGenerationListener.current = SubscribeToProjectChanges(project.id, async (project) => {
      if (project.isGenerating) return;
      messageGenerationListener.current?.();
      setProject(project);
      const messages = await cancellablePromise(FetchProjectMessages(project.id));
      setMessages(messages);
    });
  }

  async function ResetChat() {
    if (!project) return;

    setResettingChat(true);

    try {
      const newChatThreadID = await ResetProjectChat(project.id);

      resettingChatAbortController.current?.abort();
      resettingChatAbortController.current = new AbortController();

      let messages = await FetchProjectMessages(project.id, resettingChatAbortController.current.signal);
      messages = messages.filter((m) => {
        if (m.sender === "assistant" && m.content === "") return false;
        return true;
      });
      setMessages(messages);
      setProject((prev) => {
        if (!prev) return prev;
        return { ...prev, chatThreadID: newChatThreadID };
      });
    } catch (err) {
      console.error(err);
      sendError("Oops, failed resetting this chat. Please try again.");
    }
    setResettingChat(false);
  }

  useEffect(() => {
    return () => {
      messageGenerationListener.current?.();
      resettingChatAbortController.current?.abort();
      sendMessageAbortController.current?.abort();
    };
  }, [project?.id]);

  return {
    creativityLevel,
    messages,
    streamingMessageID: streamingMessageID.current,
    resettingChat,
    SendMessage,
    StopStream,
    ResetChat,
    Initialize,
  };
};

export default useProjectMessages;
