import { useContext, useEffect, useMemo, useState } from "react";
import { ProjectFile, MultipleChoiceQuestion, ProjectQuiz } from "../../../../../backend/Projects/types";
import { GenerationBlock, useGenerationBlock } from "../GenerationBlock";
import { useNotifications } from "../../../../../utils/NotificationsContext";
import { DoneButton, formattedDateGenerated, ShareProjectDerivative, useWorkspaceStates } from "../Shared";
import Dropdown from "../../../../elements/DropdownMenu/Dropdown";
import { ContextMenu, ContextMenuButton, ContextMenuDivider, ContextMenuLoadingLabel } from "../../../../elements/DropdownMenu/ContextMenu";
import { ContextMenuDeleteConfirmation } from "../../../../elements/DropdownMenu/ContextMenuConfirmation";
import LoadingIndicator from "../../../../elements/LoadingIndicator";
import useDidScroll from "../../../../../utils/Hooks/useDidScroll";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import ProjectQuizzesPractice from "./ProjectQuizzesPractice";
import Button from "../../../../elements/Button";
import InputField from "../../../../elements/InputField";
import InputBox from "../../../../elements/InputBox";
import { cn } from "../../../../../utils/UtilityMethods";
import { useProjectManager } from "../../Hooks/useProjectManager";

const ProjectQuizzes = () => {
  const { project, Quizzes, Files } = useProjectManager();
  const { prompt, setPrompt, selectedFiles, setSelectedFiles } = useGenerationBlock(Files.files);

  const [selectedQuizIndex, setSelectedQuizIndex] = useState<number | null>(Quizzes.quizzes.length > 0 ? 0 : null);
  const selectedQuiz = selectedQuizIndex !== null ? Quizzes.quizzes[selectedQuizIndex] : null;
  const [editingQuiz, setEditingQuiz] = useState<ProjectQuiz | null>(null);

  const [quizName, setQuizName] = useState<string | null>(selectedQuiz?.name ?? null);
  const dateGenerated = useMemo(() => {
    if (!selectedQuiz) return "";
    return formattedDateGenerated(selectedQuiz.dateCreated);
  }, [selectedQuiz]);

  const { renaming, setRenaming, editing, setEditing, saving, setSaving, deleting, setDeleting } = useWorkspaceStates();

  const [shownQuizNameIndex, setShownQuizNameIndex] = useState<number>(selectedQuiz?.name.length ?? 0);

  const [showingDeleteConfirmation, setShowingDeleteConfirmation] = useState<boolean>(false);
  const [revealAnswers, setRevealAnswers] = useState<boolean | null>(null);
  const [practice, setPractice] = useState<boolean>(false);
  const { ShareDropdown, SharePopup } = ShareProjectDerivative({
    type: "quiz",
    derivative: selectedQuiz ?? undefined,
    projectID: project?.id,
    setPublicID: (publicID) => (selectedQuiz ? Quizzes.SetPublicID(selectedQuiz.id, publicID) : undefined),
  });

  const { scrollRef, didScroll } = useDidScroll<HTMLDivElement>();
  const { sendError } = useNotifications();

  async function ClickGenerateQuiz() {
    try {
      const quiz = await Quizzes.Generate(selectedFiles, prompt, selectedQuiz?.id);
      setQuizName(quiz.name);
      setSelectedQuizIndex(0);
      StartTypingQuizName(quiz.name.length);
    } catch (error) {
      sendError("Quiz generation failed.", "Could not generate quiz from your materials, please try again.");
      Quizzes.SetIsGenerating(false);
    }
  }

  async function ClickSaveQuizName() {
    if (!selectedQuiz) return;

    setSaving(true);
    try {
      await Quizzes.SaveName(selectedQuiz.id, quizName ?? "");
      setRenaming(false);
    } catch (error) {
      sendError();
    }
    setSaving(false);
  }

  async function ClickSaveQuiz() {
    if (!editingQuiz) return;

    setSaving(true);
    try {
      await Quizzes.SaveContent(editingQuiz);
      setEditingQuiz(null);
      setEditing(false);
    } catch (error) {
      sendError();
    }
    setSaving(false);
  }

  async function ClickDeleteQuiz() {
    if (!selectedQuiz) return;

    setDeleting(true);
    try {
      await Quizzes.Delete(selectedQuiz.id);
      setSelectedQuizIndex(null);
    } catch (error) {
      sendError();
    }
    setDeleting(false);
  }

  async function StartEditingQuiz() {
    if (!selectedQuiz) return;
    setEditingQuiz(selectedQuiz);
    setEditing(true);
  }

  function ToggleRevealAnswers(isOn: boolean) {
    setRevealAnswers(isOn);
    setTimeout(() => {
      setRevealAnswers(null);
    }, 300);
  }

  function StartTypingQuizName(length: number, index: number = 0) {
    if (index !== 0 && index > length) return;
    setShownQuizNameIndex(index);

    setTimeout(() => {
      StartTypingQuizName(length, index + 1);
    }, 50);
  }

  function StartPractice() {
    if (!selectedQuiz) return;

    ToggleRevealAnswers(false);
    setPractice(true);
  }

  useEffect(() => {
    setSelectedQuizIndex(Quizzes.quizzes.length > 0 ? 0 : null);
  }, [Quizzes.quizzes]);

  useEffect(() => {
    setQuizName(selectedQuiz?.name ?? null);
  }, [selectedQuiz]);

  return (
    <div className={`w-full flex flex-col items-start justify-start gap-4 pt-6 overflow-auto h-full`}>
      <div className="w-full text-sm text-systemGray-500 px-4">Generate educational quizzes to test your knowledge and study more efficiently.</div>
      <GenerationBlock
        prompt={prompt}
        generating={project?.isGeneratingQuiz ?? false}
        empty={!selectedQuiz}
        files={Files.files}
        selectedFiles={selectedFiles}
        setPrompt={setPrompt}
        setSelectedFiles={setSelectedFiles}
        ClickGenerate={ClickGenerateQuiz}
        promptPlaceholder="Ask for anything specific for your quiz"
        className="px-4"
      />
      <div className="border-b mt-4 w-full" />
      {selectedQuiz ? (
        <>
          <div className={`w-full flex justify-between items-start gap-4 overflow-x-clip shrink-0 z-1 px-4 ${saving && "pointer-events-none opacity-70"} @container`}>
            {renaming ? (
              <>
                <InputField className="w-full" value={quizName ?? ""} onValueChange={setQuizName} />
                <DoneButton onClick={ClickSaveQuizName} saving={saving} />
              </>
            ) : (
              <>
                <div className="w-full flex flex-col items-start justify-start relative my-2">
                  {selectedQuiz.publicID && <div className="absolute bottom-full left-0 text-xs text-brandBlue-300 flex items-center justify-start gap-1">{CoursableIcons.PersonsFill("text-base -mt-0.5")} Shared</div>}
                  <div className="text-brand-500 w-full text-sm md:text-base">{quizName?.slice(0, shownQuizNameIndex)}</div>
                  <div className="text-systemGray-400 text-xs font-light">
                    {selectedQuiz.multipleChoice.length} questions | {dateGenerated}
                  </div>
                </div>
                {editing ? (
                  <DoneButton onClick={ClickSaveQuiz} saving={saving} />
                ) : (
                  <div className="flex flex-col items-end @md:flex-row gap-2 mt-2">
                    <Button variant="outline" onClick={StartPractice}>
                      Practice{CoursableIcons.Quizzes()}
                    </Button>
                    <div className="flex items-center justify-end gap-2">
                      {ShareDropdown}
                      <div className="relative">
                        <Dropdown
                          label={
                            <>
                              <span className="hidden @md:inline">More</span>
                              {CoursableIcons.Ellipsis()}
                            </>
                          }
                          chevron={false}
                          className="@md:w-auto h-9 w-9"
                        >
                          <ContextMenuButton label="Reveal answers" icon={CoursableIcons.Eye()} onClick={() => ToggleRevealAnswers(true)} />
                          <ContextMenuButton label="Hide answers" icon={CoursableIcons.EyeSlash()} onClick={() => ToggleRevealAnswers(false)} />
                          <ContextMenuDivider />
                          <ContextMenuButton label="Rename" icon={CoursableIcons.Edit()} onClick={() => setRenaming(true)} />
                          <ContextMenuButton label="Edit" icon={CoursableIcons.Edit()} onClick={StartEditingQuiz} />
                          <ContextMenuButton
                            label="Export as PDF"
                            icon={CoursableIcons.Share()}
                            onClick={() => {
                              window.open(`/app/projects/${project?.id}/quizzes/${selectedQuiz.id}/pdf`, "_blank");
                            }}
                          />
                          <ContextMenuDivider />
                          <ContextMenuButton label="Delete" icon={CoursableIcons.Delete()} onClick={() => setShowingDeleteConfirmation(true)} destructive />
                        </Dropdown>
                        <ContextMenuDeleteConfirmation label="Are you sure you want to delete this quiz?" width={200} isOpen={showingDeleteConfirmation} setIsOpen={setShowingDeleteConfirmation} onDelete={ClickDeleteQuiz} />
                        <ContextMenu autoClose={false} isOpen={deleting} setIsOpen={setDeleting}>
                          <ContextMenuLoadingLabel label="Deleting" />
                        </ContextMenu>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div ref={scrollRef} className={cn("w-full h-full px-8 pb-16 overflow-auto", didScroll && "border-t")}>
            <QuizView quiz={selectedQuiz} editing={editing} reveal={revealAnswers} editingQuiz={editingQuiz} setEditingQuiz={setEditingQuiz} />
          </div>
        </>
      ) : (
        <div className="flex items-center justify-start w-full gap-1 opacity-60 px-4">
          {!!project?.isGeneratingQuiz ? (
            <>
              <span className="text-lg">🔍</span> Generating quiz <LoadingIndicator className="inline h-4 stroke-background mt-2 -ml-1 fill-systemGray-800" type="dots" />
            </>
          ) : (
            <>
              <span className="text-lg">📖</span> Your generated quiz will appear here
            </>
          )}
        </div>
      )}
      {selectedQuiz && <ProjectQuizzesPractice quiz={selectedQuiz} isOpen={practice} setIsOpen={setPractice} />}
      {SharePopup}
    </div>
  );
};

export default ProjectQuizzes;

interface QuizViewProps {
  quiz: ProjectQuiz;
  reveal: boolean | null;
  editing?: boolean;
  editingQuiz?: ProjectQuiz | null;
  setEditingQuiz?: (f: ProjectQuiz | null) => void;
}

export const QuizView = ({ quiz, editing, editingQuiz, reveal, setEditingQuiz }: QuizViewProps) => {
  function EditQuestion(atIndex: number, updatedQuestion: MultipleChoiceQuestion) {
    if (!editingQuiz || setEditingQuiz === undefined) return;
    const newQuestions = [...editingQuiz.multipleChoice];
    newQuestions[atIndex] = updatedQuestion;
    setEditingQuiz({ ...editingQuiz, multipleChoice: newQuestions });
  }

  function DeleteQuestion(atIndex: number) {
    if (!editingQuiz || setEditingQuiz === undefined) return;
    const newQuestions = [...editingQuiz.multipleChoice];
    newQuestions.splice(atIndex, 1);
    setEditingQuiz({ ...editingQuiz, multipleChoice: newQuestions });
  }

  function AddQuestion() {
    if (!editingQuiz || setEditingQuiz === undefined) return;
    setEditingQuiz({ ...editingQuiz, multipleChoice: [...editingQuiz.multipleChoice, { question: "", choices: ["", ""], correctChoice: 1 }] });
  }

  return (
    <div className={`w-full flex flex-col items-start justify-start ${editing ? "gap-4" : "gap-8"}`}>
      {(editingQuiz ?? quiz).multipleChoice.map((question, index) => (
        <MultipleChoice
          key={index}
          index={index}
          question={question}
          reveal={reveal}
          editing={editing}
          canDelete={editingQuiz ? editingQuiz.multipleChoice.length > 1 : false}
          EditQuestion={(question) => EditQuestion(index, question)}
          DeleteQuiz={() => DeleteQuestion(index)}
        />
      ))}
      {editing && (
        <button
          onClick={AddQuestion}
          className="w-full bg-systemGray-100 hover:bg-background text-systemGray-500 hover:text-foreground active:bg-systemGray-100 hover:border-brand-500 hover:shadow-lg duration-100 rounded-xl border-2 flex-centered gap-2 p-4"
        >
          {CoursableIcons.Plus()} Add Question
        </button>
      )}
    </div>
  );
};

interface MultipleChoiceProps {
  question: MultipleChoiceQuestion;
  reveal: boolean | null;
  index: number;
  editing?: boolean;
  canDelete: boolean;
  EditQuestion: (question: MultipleChoiceQuestion) => void;
  DeleteQuiz: () => void;
}

const MultipleChoice = ({ question, reveal, index, editing, canDelete, EditQuestion, DeleteQuiz }: MultipleChoiceProps) => {
  const projectManager = useProjectManager();

  const [selectedChoice, setSelectedChoice] = useState<number | null>(null);

  const defaultStyles = "bg-transparent";
  const editingStyles = "bg-systemGray-100 hover:shadow-lg-c hover:bg-background hover:border-brand-500 p-4";

  const optionLetter = (index: number): string => {
    return ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"][index].toUpperCase();
  };

  function UpdateQuestion(value: string) {
    EditQuestion({ ...question, question: value });
  }

  function UpdateCorrectChoice(index: number) {
    EditQuestion({ ...question, correctChoice: index + 1 });
  }

  function UpdateChoice(index: number, value: string) {
    const newChoices = [...question.choices];
    newChoices[index] = value;
    EditQuestion({ ...question, choices: newChoices });
  }

  function AddOption() {
    EditQuestion({ ...question, choices: [...question.choices, ""] });
  }

  function DeleteOption(index: number) {
    const newChoices = [...question.choices];
    newChoices.splice(index, 1);
    const newCorrectChoice = question.correctChoice > newChoices.length ? newChoices.length : question.correctChoice;
    EditQuestion({ ...question, correctChoice: newCorrectChoice, choices: newChoices });
  }

  useEffect(() => {
    if (reveal === null) return;
    setSelectedChoice(reveal ? question.correctChoice - 1 : null);
  }, [reveal]);

  useEffect(() => {
    setSelectedChoice(null);
  }, [question]);

  return (
    <div className={`${editing ? editingStyles : defaultStyles} w-full flex-started flex-col gap-4 rounded-xl border-2 border-transparent duration-100 group relative`}>
      <div className={`w-full flex gap-2 items-start justify-start  text-sm md:text-base`}>
        <div className="text-systemGray-500">{index + 1}.</div>
        {editing ? (
          <InputBox className="w-full h-20" containerClassName="w-full pr-8" placeholder="Enter your question here" value={question.question} onValueChange={UpdateQuestion} />
        ) : (
          <>
            <h5 className="font-semibold">{question.question || <span className="text-systemGray-500">Empty question</span>}</h5>
            {projectManager !== null && (
              <Dropdown label={CoursableIcons.Ellipsis("horizontal", "text-lg")} size="sm" chevron={false} variant="ghost" containerClassName="-mt-1 ml-auto z-0">
                <ContextMenuButton
                  label="Explain"
                  icon={CoursableIcons.SparklesFill("text-brand-500")}
                  onClick={() => projectManager.Messages.SendMessage("Please briefly explain this multiple choice question.", [{ type: "MultipleChoiceQuestion", content: question }])}
                />
                <ContextMenuDivider />
                <ContextMenuButton label="Attach to message" icon={CoursableIcons.Attachment()} onClick={() => projectManager.ChatInput.AddAttachment({ type: "MultipleChoiceQuestion", content: question })} />
              </Dropdown>
            )}
          </>
        )}
      </div>
      <div className="w-full flex-started flex-col pl-4 pr-4 gap-2">
        {question.choices.map((choice, index) => (
          <MultipleChoiceButton
            key={index}
            optionLetter={optionLetter(index)}
            isCorrect={question.correctChoice - 1 === index}
            choice={choice}
            isSelected={index === selectedChoice}
            editing={editing}
            canDelete={question.choices.length > 2}
            Select={() => setSelectedChoice(index)}
            EditChoice={(value) => UpdateChoice(index, value)}
            SetAsCorrect={() => UpdateCorrectChoice(index)}
            DeleteOption={() => DeleteOption(index)}
          />
        ))}
        {editing && question.choices.length < 26 && (
          <button onClick={AddOption} className="flex items-center justify-start gap-2 text-sm text-systemGray-500 hover:text-foreground active:text-systemGray-700 duration-100 pt-2">
            {CoursableIcons.Plus()} Add option
          </button>
        )}
      </div>
      {editing && (
        <button onClick={DeleteQuiz} className={`text-systemGray-500 hover:text-red-500 active:text-red-400 duration-100 absolute top-4 right-4 ${!canDelete && "hidden"}`}>
          {CoursableIcons.Delete()}
        </button>
      )}
    </div>
  );
};

interface MultipleChoiceButtonProps {
  choice: string;
  optionLetter: string;
  isCorrect: boolean;
  isSelected: boolean;
  editing?: boolean;
  canDelete: boolean;
  EditChoice: (value: string) => void;
  SetAsCorrect: () => void;
  DeleteOption: () => void;
  Select: () => void;
}

const MultipleChoiceButton = ({ choice, optionLetter, isCorrect, isSelected, editing, canDelete, EditChoice, SetAsCorrect, DeleteOption, Select }: MultipleChoiceButtonProps) => {
  const [reveal, setReveal] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setReveal(isSelected);
    }, 150);
  }, [isSelected]);

  return editing ? (
    <div className="w-full flex items-center justify-start gap-4">
      <label className="relative flex items-center justify-center hover:shadow duration-100 cursor-pointer group/checkbox">
        <input
          className={`appearance-none aspect-square border-2 rounded peer w-5 h-5 ${isCorrect ? "border-green-500" : "border-systemGray-400 group-hover/checkbox:border-green-500 group-active/checkbox:border-green-400"} duration-100`}
          type="checkbox"
          checked={isCorrect}
          onChange={() => SetAsCorrect()}
        />
        {CoursableIcons.Check(`absolute text-green-500 opacity-0 scale-90 peer-checked:opacity-100 peer-checked:scale-100 duration-100 left-0.5 top-0.5`)}
      </label>
      <InputField placeholder="Empty option" className="w-full" value={choice} onValueChange={EditChoice} />
      <button onClick={DeleteOption} className={`text-systemGray-500 hover:text-red-500 active:text-red-400 duration-100 ${!canDelete && "hidden"}`}>
        {CoursableIcons.Xmark()}
      </button>
    </div>
  ) : (
    <button onClick={Select} className={`flex items-start justify-start gap-2 md:gap-4 border border-transparent hover:bg-systemGray-100 hover:border-systemGray-300 active:bg-systemGray-200 hover:shadow duration-100 rounded-xl p-1`}>
      <div
        style={{
          transform: isSelected ? "rotateY(180deg)" : "rotateY(0deg)",
          transition: "transform 0.3s linear",
        }}
        className={`h-6 w-6 md:h-7 md:w-7 flex-centered aspect-square ${
          reveal ? (isCorrect ? "bg-green-200 dark:bg-green-950 border-green-500 text-green-500" : "bg-red-200 dark:bg-red-950 border-red-500 text-red-500") : "bg-systemGray-200 text-systemGray-500"
        } border text-xs rounded-full`}
      >
        {reveal ? (isCorrect ? CoursableIcons.Check("-scale-x-100") : CoursableIcons.Xmark()) : optionLetter}
      </div>
      <p className={`text-sm md:text-base text-left mr-2 translate-y-0.5 ${reveal ? (isCorrect ? "text-green-500" : "text-red-500") : "text-foreground"}`}>{choice || <span className="text-systemGray-500">Empty option</span>}</p>
    </button>
  );
};
