import { Page, Text, Document, StyleSheet, Link, View } from "@react-pdf/renderer";
import "../../../../../../utils/StringExtensions";
import { ProjectFlashcardSet } from "../../../../../../backend/Projects/types";

const PDFFromFlashcardSet = ({ flashcardSet }: { flashcardSet: ProjectFlashcardSet }) => {
  return (
    <Document title={flashcardSet.name + ".pdf"} author="Coursable.io" creator="Coursable.io">
      <Page style={styles.document} size="LETTER">
        <Text style={styles.header} fixed>
          Generated with{" "}
          <Link style={styles.header} src={process.env.REACT_APP_CLIENT_URL as string}>
            Coursable
          </Link>
        </Text>
        <Text style={styles.name}>{flashcardSet.name}</Text>
        <Text style={styles.titleLabel}>Questions</Text>
        {flashcardSet.flashcards.map((flashcard, index) => (
          <View style={styles.questionView}>
            <Text>{index + 1}.</Text>
            <Text>{flashcard.question}</Text>
          </View>
        ))}

        <Text style={styles.titleLabel} break>
          Answers
        </Text>
        {flashcardSet.flashcards.map((flashcard, index) => (
          <View style={styles.answerView}>
            <Text>{index + 1}.</Text>
            <Text>{flashcard.answer}</Text>
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber}`} fixed />
      </Page>
    </Document>
  );
};

export default PDFFromFlashcardSet;

const styles = StyleSheet.create({
  document: {
    padding: "0.75in",
    fontFamily: "Times-Roman",
  },
  name: {
    fontSize: 12,
    color: "#D12424",
    paddingBottom: 12,
  },
  titleLabel: {
    fontSize: 14,
    fontFamily: "Times-Bold",
    paddingBottom: 16,
  },
  questionView: {
    flexDirection: "row",
    gap: 4,
    fontSize: 10,
    paddingBottom: 60,
  },
  answerView: {
    flexDirection: "row",
    gap: 4,
    fontSize: 10,
    paddingVertical: 4,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    top: "0.25in",
    left: 0,
    right: 0,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: "0.5in",
    textAlign: "right",
  },
});
