import { useEffect, useState } from "react";
import { PopupModal, PopupModalContent } from "./PopupModal";
import { ReauthenticateUserWithGoogle, ReauthenticateUserWithPassword } from "../../firebase/FirestoreManager";
import { useAuth } from "../../firebase/AuthContext";
import Button from "./Button";
import { useNotifications } from "../../utils/NotificationsContext";
import InputField from "./InputField";

const ReauthenticatePopup = ({
  isOpen,
  title,
  description,
  confirmTitle,
  onConfirm,
  onSuccess,
  Close,
}: {
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmTitle?: string;
  onConfirm?: () => void;
  onSuccess: () => void;
  Close: () => void;
}) => {
  const { currentUser } = useAuth();

  const isGoogle = currentUser?.providerData[0].providerId === "google.com";

  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { sendError } = useNotifications();

  async function Confirm() {
    if (!currentUser || isLoading) return;
    if (!isGoogle && password.length <= 0) return;

    setIsLoading(true);

    try {
      onConfirm?.();
      if (isGoogle) {
        await ReauthenticateUserWithGoogle();
      } else {
        await ReauthenticateUserWithPassword(password);
      }
      onSuccess();
      Close();
    } catch (error: any) {
      if (error.code === "auth/wrong-password") sendError("Incorrect password, please try again.");
      else if (error.code === "auth/too-many-requests") sendError("Too many failed attempts. Try again later or reset your password.");
      else sendError("Oops, something went wrong. Please try again later.");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (isOpen) setPassword("");
  }, [isOpen]);

  return (
    <PopupModal open={isOpen} className="w-full max-w-md">
      <PopupModalContent className="w-full flex-centered flex-col gap-8">
        <div className="font-semibold text-lg text-center">{title ?? "Authenticate to continue"}</div>
        {description && <div className="max-w-[20rem] text-center text-sm text-systemGray-500 whitespace-pre-wrap">{description}</div>}
        <form
          className={`w-full ${isGoogle ? "hidden" : ""}`}
          onSubmit={(e) => {
            e.preventDefault();
            Confirm();
          }}
        >
          <p className="text-sm text-center text-systemGray-500 mb-2">Enter password to confirm.</p>
          <InputField required autoComplete="current-password" type="password" placeholder="Your password" className="w-full" value={password} onValueChange={setPassword} />
        </form>
        <div className="grid grid-cols-2 gap-4">
          <Button disabled={isLoading} onClick={Close} variant="secondary">
            Cancel
          </Button>
          <Button loading={isLoading} onClick={Confirm} disabled={isLoading || (password.length === 0 && !isGoogle)}>
            {confirmTitle ?? "Confirm"}
          </Button>
        </div>
      </PopupModalContent>
    </PopupModal>
  );
};

export default ReauthenticatePopup;
