export type CoursablePlanInterval = "week" | "month" | "quarter" | "year" | null;
export type CoursablePlanCode = "free" | "plus" | "flex" | "student" | "professor";

export class CoursablePlan {
  title: string;
  code: CoursablePlanCode;

  //Pricing
  monthlyPrice: number;
  quaterlyMonthlyPrice: number;
  annualMonthlyPrice: number;
  static studentDiscount: number = 0.5;
  static trialDays: number = 14;
  static referralCredit: number = 10;
  static maxReferrals: number = 12;

  // Courses
  courseLimitMonthly: number | null;
  personalizationsLimit: number | null;

  // Projects
  projectsLimitMonthly: number | null;
  materialsPerProject: number | null;
  maxProjectsFileSize = 50;

  // Storage
  storageLimit: number | null;

  constructor(
    title: string,
    code: CoursablePlanCode,
    monthlyPrice: number,
    quarterlyMonthlyPrice: number,
    annualMonthlyPrice: number,
    courseLimitMonthly: number | null,
    personalizationsLimit: number | null,
    projectsLimitMonthly: number | null = null,
    materialsPerProject: number | null = null,
    storageLimit: number | null = null
  ) {
    this.title = title;
    this.code = code;
    this.monthlyPrice = monthlyPrice;
    this.quaterlyMonthlyPrice = quarterlyMonthlyPrice;
    this.annualMonthlyPrice = annualMonthlyPrice;
    this.courseLimitMonthly = courseLimitMonthly;
    this.personalizationsLimit = personalizationsLimit;
    this.projectsLimitMonthly = projectsLimitMonthly;
    this.materialsPerProject = materialsPerProject;
    this.storageLimit = storageLimit;
  }

  static Free = new CoursablePlan("Free", "free", 0, 0, 0, 2, 5, 2, 1, 512);
  static Plus = new CoursablePlan("Plus", "plus", 10, 8, 6, null, null, null, 5, 5120);

  // Outdated plans
  static Flex = new CoursablePlan("Flex", "flex", 5, 5, 5, null, 10, 0, 0, 0);
  static Student = new CoursablePlan("Student", "student", 10, 8, 7.5, 10, 50, 10, 3, 2048);
  static Professor = new CoursablePlan("Professor", "professor", 20, 17.5, 15, null, null, null, 5, 5120);

  static get = (code: CoursablePlanCode = "free"): CoursablePlan => {
    if (code === CoursablePlan.Free.code) return CoursablePlan.Free;
    if (code === CoursablePlan.Plus.code) return CoursablePlan.Plus;
    if (code === CoursablePlan.Flex.code) return CoursablePlan.Flex;
    if (code === CoursablePlan.Student.code) return CoursablePlan.Student;
    if (code === CoursablePlan.Professor.code) return CoursablePlan.Professor;
    return CoursablePlan.Free;
  };

  coursesFeatures = (): string[] => {
    const features: string[] = [];
    if (this.courseLimitMonthly) features.push(`${this.courseLimitMonthly} courses per month`);
    else features.push("Unlimited courses per month");

    if (this.personalizationsLimit) features.push(`${this.personalizationsLimit} personalizations per course`);
    else features.push("Unlimited personalizations per course");

    if (this.code === "professor" || this.code === "plus") {
      features.push("Edit courses");
      features.push("Export as PDF, JSON, and XML");
    }
    return features;
  };

  projectFeatures = (): string[] => {
    const features: string[] = [];
    if (this.projectsLimitMonthly) features.push(`${this.projectsLimitMonthly} projects per month`);
    else features.push("Unlimited projects per month");

    if (this.materialsPerProject) features.push(`${this.materialsPerProject} ${this.materialsPerProject === 1 ? "material" : "materials"} per project`);
    if (this.storageLimit) features.push(`${this.storageLimit / 1024} GB storage`);

    return features;
  };

  allFeatures = (): { category: string; features: string[] }[] => {
    return [
      { category: "Projects", features: this.projectFeatures() },
      { category: "Courses", features: this.coursesFeatures() },
    ];
  };
}
