import { IconType } from "react-icons";
import { FiTrash2 as DeleteIcon } from "react-icons/fi";
import { LuLock as LockIcon } from "react-icons/lu";
import { LuUnlock as UnlockIcon } from "react-icons/lu";
import { BiDuplicate as DuplicateIcon } from "react-icons/bi";
import { LuShare as ShareIcon } from "react-icons/lu";
import { LuDownload as DownloadIcon } from "react-icons/lu";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { VscDebugRestart as ResetIcon } from "react-icons/vsc";
import { FaRegCopy as CopyIcon } from "react-icons/fa";
import { FaCopy as CopyIconFill } from "react-icons/fa";
import { FaChevronDown as ChevronDownIcon } from "react-icons/fa";
import { FaChevronUp as ChevronUpIcon } from "react-icons/fa";
import { FaChevronLeft as ChevronLeftIcon } from "react-icons/fa";
import { FaChevronRight as ChevronRightIcon } from "react-icons/fa";
import { FaGraduationCap as GraduationIconFill } from "react-icons/fa6";
import { PiGraduationCap as GraduationIcon } from "react-icons/pi";
import { IoSettingsOutline as GearIcon } from "react-icons/io5";
import { IoSettingsSharp as GearIconFill } from "react-icons/io5";
import { IoCloseCircle as XmarkFillIcon } from "react-icons/io5";
import { FaXmark as XmarkIcon } from "react-icons/fa6";
import { FiLogOut as LogoutIcon } from "react-icons/fi";
import { FaUser as PersonFillIcon } from "react-icons/fa";
import { FaUsers as PersonsFillIcon } from "react-icons/fa";
import { FaUsersSlash as PersonsFillSlashIcon } from "react-icons/fa";
import { FaUserSlash as PersonFillSlashIcon } from "react-icons/fa";
import { FaUserGraduate as StudentFillIcon } from "react-icons/fa";
import { FaCode as CodeIcon } from "react-icons/fa";
import { FiMenu as MenuIcon } from "react-icons/fi";
import { BsFiletypePdf as PdfDocIcon } from "react-icons/bs";
import { BsFiletypeJson as JsonDocIcon } from "react-icons/bs";
import { BsFiletypeXml as XmlDocIcon } from "react-icons/bs";
import { BsFiletypeDocx as DocxDocIcon } from "react-icons/bs";
import { BsFiletypePptx as PptxDocIcon } from "react-icons/bs";
import { BsFiletypeTxt as TxtDocIcon } from "react-icons/bs";
import { BsFiletypeMd as MdDocIcon } from "react-icons/bs";
import { IoDocumentTextOutline as DocIcon } from "react-icons/io5";
import { RxDragHandleDots2 as DragHandleHorizontalIcon } from "react-icons/rx";
import { FaPlus as PlusIcon } from "react-icons/fa6";
import { FaMinus as MinusIcon } from "react-icons/fa6";
import { FaCheck as CheckIcon } from "react-icons/fa6";
import { LuChevronFirst as ChevronBarLeftIcon } from "react-icons/lu";
import { LuChevronLast as ChevronBarRightIcon } from "react-icons/lu";
import { TbChevronCompactLeft as ChevronTallLeftIcon } from "react-icons/tb";
import { TbChevronCompactRight as ChevronTallRightIcon } from "react-icons/tb";
import { TbChevronCompactUp as ChevronTallUpIcon } from "react-icons/tb";
import { TbChevronCompactDown as ChevronTallDownIcon } from "react-icons/tb";
import { IoChatboxOutline as ChatBubbleIcon } from "react-icons/io5";
import { CgArrowsExpandUpRight as ExpandUpRightIcon } from "react-icons/cg";
import { CgArrowsExpandDownLeft as ExpandDownLeftIcon } from "react-icons/cg";
import { GoProject as ProjectIcon } from "react-icons/go";
import { AiFillProject as ProjectFillIcon } from "react-icons/ai";
import { FaInfo as InfoIcon } from "react-icons/fa6";
import { BsLayoutTextSidebarReverse as LayoutSidebarLeftIcon } from "react-icons/bs";
import { BsLayoutTextSidebar as LayoutSidebarRightIcon } from "react-icons/bs";
import { BsLayoutThreeColumns as LayoutThreeColumnsIcon } from "react-icons/bs";
import { BsLayoutSplit as LayoutTwoColumnsIcon } from "react-icons/bs";
import { IoSend as SendIcon } from "react-icons/io5";
import { FaArrowLeftLong as ArrowLeftIcon } from "react-icons/fa6";
import { FaArrowRightLong as ArrowRightIcon } from "react-icons/fa6";
import { FaArrowDownLong as ArrowDownIcon } from "react-icons/fa6";
import { FaArrowUpLong as ArrowUpIcon } from "react-icons/fa6";
import { LuLayoutList as QuizzesIcon } from "react-icons/lu";
import { BsTextCenter as TextIcon } from "react-icons/bs";
import { BsCardText as FlashcardIcon } from "react-icons/bs";
import { IoHomeOutline as HomeIcon } from "react-icons/io5";
import { HiSparkles as SparklesFillIcon } from "react-icons/hi2";
import { HiOutlineSparkles as SparklesIcon } from "react-icons/hi2";
import { FaRegEye as EyeIcon } from "react-icons/fa";
import { FaRegEyeSlash as EyeSlashIcon } from "react-icons/fa";
import { IoPeopleOutline as PeopleIcon } from "react-icons/io5";
import { IoPeople as PeopleFillIcon } from "react-icons/io5";
import { IoSearch as SearchIcon } from "react-icons/io5";
import { AiOutlineYoutube as YouTubeIcon } from "react-icons/ai";
import { FaPlay as PlayIcon } from "react-icons/fa";
import { FaStop as StopIcon } from "react-icons/fa6";
import { IoWalletOutline as WalletIcon } from "react-icons/io5";
import { IoWallet as WalletFillIcon } from "react-icons/io5";
import { IoNewspaperOutline as NewspaperIcon } from "react-icons/io5";
import { IoNewspaper as NewspaperFillIcon } from "react-icons/io5";
import { IoGlobe as GlobeIcon } from "react-icons/io5";
import { IoSunnyOutline as SunIcon } from "react-icons/io5";
import { IoSunnyOutline as SunFillIcon } from "react-icons/io5";
import { IoMoonOutline as MoonIcon } from "react-icons/io5";
import { IoMoon as MoonFillIcon } from "react-icons/io5";
import { CgDarkMode as SystemThemeIcon } from "react-icons/cg";
import { IoSync as SyncIcon } from "react-icons/io5";
import { RiFacebookFill as FacebookLogoIcon } from "react-icons/ri";
import { RiTwitterXFill as TwitterLogoIcon } from "react-icons/ri";
import { FaRedditAlien as RedditLogoIcon } from "react-icons/fa";
import { FaTelegramPlane as TelegramLogoIcon } from "react-icons/fa";
import { FaLinkedinIn as LinkedInLogoIcon } from "react-icons/fa";
import { RiWhatsappFill as WhatsAppLogoIcon } from "react-icons/ri";
import { FaEllipsis as EllipsisHorizontalIcon } from "react-icons/fa6";
import { FaEllipsisVertical as EllipsisVerticalIcon } from "react-icons/fa6";
import { FaRegEnvelope as EnvelopeIcon } from "react-icons/fa";
import { FaEnvelope as EnvelopeFillIcon } from "react-icons/fa";
import { IoGift as GiftFillIcon } from "react-icons/io5";
import { IoGiftOutline as GiftIcon } from "react-icons/io5";
import { FaSignature as SignatureIcon } from "react-icons/fa6";
import { CgAttachment as AttachmentIcon } from "react-icons/cg";

class CoursableIcons {
  protected static IconTemplate = (ReactIcon: IconType, className?: string): React.ReactNode => {
    return <ReactIcon className={`shrink-0 ${className && className}`} />;
  };

  static Delete = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(DeleteIcon, className);
  };

  static Duplicate = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(DuplicateIcon, className);
  };

  static Lock = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(LockIcon, className);
  };

  static Unlock = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(UnlockIcon, className);
  };

  static Share = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(ShareIcon, className);
  };

  static Download = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(DownloadIcon, className);
  };

  static Edit = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(EditIcon, className);
  };

  static Reset = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(ResetIcon, className);
  };

  static Copy = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(CopyIcon, className);
  };

  static CopyFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(CopyIconFill, className);
  };

  static Chevron = (dir: "down" | "up" | "left" | "right", className?: string): React.ReactNode => {
    const icon = dir === "down" ? ChevronDownIcon : dir === "up" ? ChevronUpIcon : dir === "left" ? ChevronLeftIcon : ChevronRightIcon;
    return CoursableIcons.IconTemplate(icon, className);
  };

  static ChevronTall = (dir: "down" | "up" | "left" | "right", className?: string): React.ReactNode => {
    const icon = dir === "down" ? ChevronTallDownIcon : dir === "up" ? ChevronTallUpIcon : dir === "left" ? ChevronTallLeftIcon : ChevronTallRightIcon;
    return CoursableIcons.IconTemplate(icon, className);
  };

  static ChevronBar = (dir: "left" | "right", className?: string): React.ReactNode => {
    const icon = dir === "left" ? ChevronBarLeftIcon : ChevronBarRightIcon;
    return CoursableIcons.IconTemplate(icon, className);
  };

  static GraduationCap = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(GraduationIcon, className);
  };

  static GraduationCapFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(GraduationIconFill, className);
  };

  static Gear = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(GearIcon, className);
  };

  static GearFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(GearIconFill, className);
  };

  static Logout = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(LogoutIcon, className);
  };

  static PersonFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PersonFillIcon, className);
  };

  static PersonFillSlash = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PersonFillSlashIcon, className);
  };

  static PersonsFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PersonsFillIcon, className);
  };

  static PersonsFillSlash = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PersonsFillSlashIcon, className);
  };

  static StudentFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(StudentFillIcon, className);
  };

  static Code = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(CodeIcon, className);
  };

  static Menu = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(MenuIcon, className);
  };

  static Xmark = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(XmarkIcon, className);
  };

  static XmarkFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(XmarkFillIcon, className);
  };

  static PdfDoc = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PdfDocIcon, className);
  };

  static JsonDoc = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(JsonDocIcon, className);
  };

  static XmlDoc = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(XmlDocIcon, className);
  };

  static DocxDoc = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(DocxDocIcon, className);
  };

  static PptxDoc = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PptxDocIcon, className);
  };

  static TxtDoc = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(TxtDocIcon, className);
  };

  static MdDoc = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(MdDocIcon, className);
  };

  static Doc = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(DocIcon, className);
  };

  static DragHandleHorizontal = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(DragHandleHorizontalIcon, className);
  };

  static Plus = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PlusIcon, className);
  };

  static Minus = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(MinusIcon, className);
  };

  static Check = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(CheckIcon, className);
  };

  static ChatBubble = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(ChatBubbleIcon, className);
  };

  static Expand = (dir: "up-right" | "down-left", className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(dir === "up-right" ? ExpandUpRightIcon : ExpandDownLeftIcon, className);
  };

  static Project = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(ProjectIcon, className);
  };

  static ProjectFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(ProjectFillIcon, className);
  };

  static Info = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(InfoIcon, className);
  };

  static LayoutSidebar = (dir: "left" | "right", className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(dir === "left" ? LayoutSidebarLeftIcon : LayoutSidebarRightIcon, className);
  };

  static LayoutColumns = (columns: "two" | "three", className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(columns === "two" ? LayoutTwoColumnsIcon : LayoutThreeColumnsIcon, className);
  };

  static Send = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SendIcon, className);
  };

  static Arrow = (dir: "up" | "down" | "left" | "right", className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(dir === "down" ? ArrowDownIcon : dir === "up" ? ArrowUpIcon : dir === "left" ? ArrowLeftIcon : ArrowRightIcon, className);
  };

  static Quizzes = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(QuizzesIcon, className);
  };

  static Text = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(TextIcon, className);
  };

  static Flashcard = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(FlashcardIcon, className);
  };

  static Home = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(HomeIcon, className);
  };

  static Sparkles = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SparklesIcon, className);
  };

  static SparklesFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SparklesFillIcon, className);
  };

  static Eye = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(EyeIcon, className);
  };

  static EyeSlash = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(EyeSlashIcon, className);
  };

  static People = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PeopleIcon, className);
  };

  static PeopleFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PeopleFillIcon, className);
  };

  static Search = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SearchIcon, className);
  };

  static YouTube = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(YouTubeIcon, className);
  };

  static Play = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(PlayIcon, className);
  };

  static Stop = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(StopIcon, className);
  };

  static Wallet = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(WalletIcon, className);
  };

  static WalletFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(WalletFillIcon, className);
  };

  static Newspaper = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(NewspaperIcon, className);
  };

  static NewspaperFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(NewspaperFillIcon, className);
  };

  static Globe = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(GlobeIcon, className);
  };

  static Sun = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SunIcon, className);
  };

  static SunFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SunFillIcon, className);
  };

  static Moon = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(MoonIcon, className);
  };

  static MoonFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(MoonFillIcon, className);
  };

  static SystemTheme = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SystemThemeIcon, className);
  };

  static Sync = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SyncIcon, className);
  };

  static FacebookLogo = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(FacebookLogoIcon, className);
  };

  static TwitterLogo = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(TwitterLogoIcon, className);
  };

  static RedditLogo = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(RedditLogoIcon, className);
  };

  static WhatsAppLogo = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(WhatsAppLogoIcon, className);
  };

  static TelegramLogo = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(TelegramLogoIcon, className);
  };

  static LinkedInLogo = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(LinkedInLogoIcon, className);
  };

  static Ellipsis = (dir: "horizontal" | "vertical" = "horizontal", className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(dir === "horizontal" ? EllipsisHorizontalIcon : EllipsisVerticalIcon, className);
  };

  static Envelope = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(EnvelopeIcon, className);
  };

  static EnvelopeFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(EnvelopeFillIcon, className);
  };

  static Gift = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(GiftIcon, className);
  };

  static GiftFill = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(GiftFillIcon, className);
  };

  static Signature = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(SignatureIcon, className);
  };

  static Attachment = (className?: string): React.ReactNode => {
    return CoursableIcons.IconTemplate(AttachmentIcon, className);
  };
}

export default CoursableIcons;
