import { useState } from "react";
import { useNotifications } from "../../utils/NotificationsContext";
import { PopupModal, PopupModalContent } from "./PopupModal";
import LoadingIndicator from "./LoadingIndicator";
import CoursableIcons from "../../utils/CoursableIcons";
import Button from "./Button";

interface ConfirmationPopupProps {
  title?: string;
  highlightInTitle?: string;
  description?: string;
  confirmText?: string;
  confirmIcon?: React.ReactNode;
  cancelText?: string;
  cancelIcon?: React.ReactNode;
  open: boolean;
  Close: () => void;
  onConfirm: () => void | Promise<void>;
}

const ConfirmationPopup = ({ title, highlightInTitle, description, confirmText, confirmIcon, cancelText, cancelIcon, open, Close, onConfirm }: ConfirmationPopupProps) => {
  const { sendError } = useNotifications();

  const [confirming, setConfirming] = useState<boolean>(false);

  const getTitleContent = () => {
    if (!title) return "Are you sure?";

    if (highlightInTitle) {
      const split = title.split(highlightInTitle);
      if (split.length !== 2) return title;
      return (
        <>
          {split[0]}
          <span className="text-brand-500">{highlightInTitle}</span>
          {split[1]}
        </>
      );
    }
    return title;
  };

  async function ClickConfirm() {
    setConfirming(true);
    try {
      await onConfirm();
      Close();
    } catch (error) {
      sendError();
    }
    setConfirming(false);
  }

  return (
    <PopupModal className="w-full max-w-md" open={open}>
      <PopupModalContent className="w-full flex flex-col gap-8 items-center justify-center">
        <div className="font-semibold text-center">{getTitleContent()}</div>
        {description && <div className="text-center text-systemGray-500 text-sm">{description}</div>}
        <div className={`w-full grid grid-cols-2 gap-6 items-center justify-center ${confirming && "opacity-70 pointer-events-none"}`}>
          <Button onClick={Close} variant="secondary">
            {cancelIcon ?? CoursableIcons.Xmark()}
            {cancelText ?? "Cancel"}
          </Button>
          <Button onClick={ClickConfirm}>
            {confirming ? <LoadingIndicator className="stroke-white w-4 h-4" /> : confirmIcon ?? CoursableIcons.Check()}
            {confirmText ?? "Confirm"}
          </Button>
        </div>
      </PopupModalContent>
    </PopupModal>
  );
};

export default ConfirmationPopup;
