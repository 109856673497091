import { useAuth } from "../../../firebase/AuthContext";
import SettingsSidemenu from "./SettingsSidemenu";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import AppPageTemplate from "../../page-elements/AppPageTemplate";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const SettingsPage = () => {
  const [verticalLayout, setVerticalLayout] = useState<boolean>(false);

  const { currentUser } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedMenu = pathname.split("/")[3];

  function SelectMenu(submenu: string | null) {
    if (submenu === null) return navigate("/app/settings");

    navigate(`${submenu}`);
  }

  useEffect(() => {
    function CheckWidth() {
      if (window.innerWidth < 768) setVerticalLayout(true);
      else setVerticalLayout(false);
    }
    window.addEventListener("resize", CheckWidth);
    CheckWidth();
    if (window.innerWidth > 768 && selectedMenu === undefined) navigate("/app/settings/profile");
    return () => {
      window.removeEventListener("resize", CheckWidth);
    };
  }, []);

  if (!currentUser) return <div />;
  return (
    <AppPageTemplate helmet={pageMetatags({ title: "Settings", canonical: "/app/settings" })}>
      <div className="h-full w-full flex flex-col bg-background overflow-auto">
        {!verticalLayout ? (
          <div className="grid grid-cols-[1fr_2fr] xl:grid-cols-[400px_3fr] h-full">
            <SettingsSidemenu user={currentUser} selectedMenu={selectedMenu} setSelectedMenu={SelectMenu} />
            <Outlet />
          </div>
        ) : (
          <div className="w-full h-full flex relative overflow-auto">
            <div className={`w-full h-full flex ${selectedMenu !== undefined && "hidden"}`}>
              <SettingsSidemenu user={currentUser} selectedMenu={selectedMenu} setSelectedMenu={SelectMenu} />
            </div>
            <div className={`absolute inset-0 ${selectedMenu === undefined && "hidden"}`}>
              <BackButton onClick={() => SelectMenu(null)} />
              <Outlet />
            </div>
          </div>
        )}
      </div>
    </AppPageTemplate>
  );
};

export default SettingsPage;

const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button onClick={onClick} className="mx-2 mt-4 text-brand-500 font-semibold flex items-center hover:text-brand-600 duration-100">
      <IoChevronBack />
      <span>Back</span>
    </button>
  );
};
