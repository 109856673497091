import { ProjectFile, ProjectQuiz } from "./types";
import { GetIDToken, auth, db } from "../../firebase/FirebaseConfig";
import { WaitFor } from "../../utils/UtilityMethods";
import { ExtractServerResponse } from "../Shared";
import { DocumentData, DocumentSnapshot, collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";

export async function GenerateQuiz(projectID: string, files: ProjectFile[], prompt: string, quizID?: string): Promise<ProjectQuiz> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  // await WaitFor(3);
  // return MockQuiz;

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/generate/quiz`, {
    method: "POST",
    body: JSON.stringify({
      quizID,
      prompt,
      files: files.map((file) => {
        return {
          fileID: file.id,
          openaiID: file.openaiID,
        };
      }),
    }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { quiz } = (await ExtractServerResponse(response)).data;

  const cleanedQuiz: ProjectQuiz = {
    ...quiz,
    dateCreated: new Date(quiz.dateCreated),
  };

  return cleanedQuiz;
}

export async function FetchQuizzes(projectID: string): Promise<ProjectQuiz[]> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const quizzesDocs = await getDocs(collection(db, `users/${currentUser.uid}/projects/${projectID}/quizzes`));

  const quizzes: ProjectQuiz[] = quizzesDocs.docs
    .map((doc) => {
      try {
        return quizFromDoc(doc);
      } catch (e) {
        return null;
      }
    })
    .filter((quiz) => quiz !== null) as ProjectQuiz[];
  return quizzes;
}

export async function FetchQuiz(projectID: string, quizID: string): Promise<ProjectQuiz> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const quizDoc = await getDoc(doc(db, `users/${currentUser.uid}/projects/${projectID}/quizzes/${quizID}`));

  return quizFromDoc(quizDoc);
}

export async function SaveQuiz(projectID: string, quiz: ProjectQuiz) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/quizzes/${quiz.id}`);
  await setDoc(docRef, { multipleChoice: quiz.multipleChoice }, { merge: true });
}

export async function DeleteQuiz(projectID: string, quizID: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/quizzes/${quizID}`);
  await deleteDoc(docRef);
}

export async function RenameQuiz(projectID: string, quizID: string, newName: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const docRef = doc(db, `users/${currentUser.uid}/projects/${projectID}/quizzes/${quizID}`);
  await setDoc(docRef, { name: newName }, { merge: true });
}

const quizFromDoc = (doc: DocumentSnapshot<DocumentData>): ProjectQuiz => {
  const data = doc.data();
  if (!data) throw new Error(`No quiz with ID ${doc.id} found.`);
  return {
    id: doc.id,
    name: data.name,
    dateCreated: data.dateCreated.toDate(),
    generatedFrom: data.generatedFrom,
    multipleChoice: data.multipleChoice,
    annotations: data.annotations,
    publicID: data.publicID,
  };
};
