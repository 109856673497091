import { doc, updateDoc } from "firebase/firestore";
import { GetIDToken, auth, db } from "../../firebase/FirebaseConfig";
import { GAEvent, GALogEvent } from "../../firebase/GoogleAnalytics";
import { ExtractServerResponse } from "../Shared";
import { AssistantCreativityLevel, AssistantMessage } from "./types";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";

export async function SendProjectMessage(projectID: string, message: AssistantMessage, creativityLevel: AssistantCreativityLevel, stream: boolean, abortController: AbortController): Promise<AssistantMessage[] | undefined> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  GALogEvent(GAEvent.Project.sentMessage);
  // await WaitFor(3);
  // return [MockAssistantMessage];

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/send-message`, {
    method: "POST",
    body: JSON.stringify({
      message,
      stream,
      creativityLevel,
    }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    signal: abortController.signal,
  });

  if (!stream) {
    const { responseMessages } = (await ExtractServerResponse(response)).data as { responseMessages: AssistantMessage[] };
    responseMessages.forEach((m: AssistantMessage) => assistantMessageFromData(m));
    return responseMessages.reverse();
  }
  await ExtractServerResponse(response);
}

export async function StreamProjectMessage(
  projectID: string,
  message: AssistantMessage,
  creativityLevel: AssistantCreativityLevel,
  onMessageCreated: (m: AssistantMessage) => void,
  onStreamMessage: (message: string) => void,
  onStreamEnd: () => void,
  abortController: AbortController
) {
  await SendProjectMessage(projectID, message, creativityLevel, true, abortController);

  const idToken = await GetIDToken();
  await fetchEventSource(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/send-message/${message.threadID}/stream`, {
    method: "POST",
    body: JSON.stringify({
      creativityLevel,
    }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    signal: abortController?.signal,
    openWhenHidden: true,
    async onopen(response) {
      if (response.ok && response.headers.get("content-type") === EventStreamContentType) return;
      throw new Error("Failed to open stream.");
    },
    onmessage(msg) {
      if (msg.event === "message_created") {
        onMessageCreated(assistantMessageFromData(JSON.parse(msg.data)));
      } else if (msg.event === "message_snapshot") {
        onStreamMessage(JSON.parse(msg.data));
      } else if (msg.event === "stream_end") {
        abortController.abort();
        onStreamEnd();
      } else if (msg.event === "stream_error") {
        throw new Error(msg.data);
      }
    },
    onclose() {
      onStreamEnd();
    },
    onerror(err) {
      onStreamEnd();
      throw err;
    },
  });
}

export async function StopProjectMessageStream(projectID: string, threadID: string) {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/send-message/${threadID}/cancel`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  await ExtractServerResponse(response);
}

export async function FetchProjectMessages(projectID: string, signal?: AbortSignal): Promise<AssistantMessage[]> {
  // await WaitFor(1);
  // return MockAssistantMessages;

  const currentUser = auth.currentUser;
  if (!currentUser) return [];

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectID}/get-messages`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    signal,
  });

  const { messages } = (await ExtractServerResponse(response)).data as { messages: AssistantMessage[] };
  messages.forEach((m) => assistantMessageFromData(m));

  const projectDocRef = doc(db, `users/${currentUser.uid}/projects/${projectID}`);
  await updateDoc(projectDocRef, { lastActiveDate: new Date() });

  return messages.reverse();
}

export const assistantMessageFromData = (data: any): AssistantMessage => {
  data.sentAt = new Date(data.sentAt);
  return data;
};
