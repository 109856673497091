import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { GetCourseFromDatabase } from "../../../../firebase/FirestoreManager";
import { PDFViewer } from "@react-pdf/renderer";
import { PDFFromCourse } from "./PDFFromCourse";
import { Helmet } from "react-helmet-async";
import { CoursablePlan } from "../../../../utils/CoursablePlan";
import { useAuth } from "../../../../firebase/AuthContext";
import { pageMetatags } from "../../../../utils/MetatagsGenerator";

const CoursePDFPage = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();

  const navigate = useNavigate();
  const [course, setCourse] = useState<Course | null>(null);

  const durationSeconds = course?.topics.reduce((acc: number, topic: Course.Topic) => {
    const total = topic.materials.reduce((acc: number, material: Course.Topic.Material) => {
      return acc + material.duration;
    }, 0);
    return acc + total;
  }, 0);
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const duration = `${hours > 0 ? `${hours} hours and ` : ""}${minutes} minutes`;

  useEffect(() => {
    async function GetCourse() {
      if (!id) return;

      const course = await GetCourseFromDatabase(id);
      setCourse(course);
    }
    GetCourse();
  }, []);

  const meta = <Helmet>{pageMetatags({ title: course?.title ?? "Course" })}</Helmet>;

  if (currentUser?.customClaims.plan.tier !== CoursablePlan.Plus.code) {
    return <Navigate to="/app/courses" />;
  }

  if (!course)
    return (
      <div className="w-screen h-screen bg-systemGray-200 text-systemGray-500 flex items-center justify-center">
        {meta}
        <TypeAnimation sequence={["Preparing PDF", 1000, ""]} repeat={Infinity} />
      </div>
    );
  return (
    <>
      {meta}
      <PDFViewer className={`w-screen h-screen`}>
        <PDFFromCourse course={course} duration={duration} />
      </PDFViewer>
    </>
  );
};

export default CoursePDFPage;
