import { Page, Text, Document, StyleSheet, Link, View } from "@react-pdf/renderer";
import "../../../../utils/StringExtensions";

export const PDFFromCourse = ({ course, duration }: { course: Course; duration: string }) => {
  return (
    <Document title={course.title + ".pdf"} author="Coursable.io" creator="Coursable.io">
      <Page style={styles.document} size="LETTER">
        <Text style={styles.header} fixed>
          Generated with{" "}
          <Link style={styles.header} src={process.env.REACT_APP_CLIENT_URL as string}>
            Coursable
          </Link>
        </Text>
        <Text style={styles.title}>{course.title}</Text>
        <Text style={styles.duration}>Duration: {duration}</Text>
        {course.topics.map((topic: Course.Topic, index: number) => {
          return (
            <View style={styles.topicWrapper}>
              <Text style={styles.topicIndex}>{`Topic ${index + 1}`}</Text>
              <Text style={styles.topicTitle}>{topic.title}</Text>
              <Text style={styles.topicDescription}>{topic.description}</Text>
              <Text style={styles.topicContent}>{topic.content}</Text>
              <Text style={styles.materialsTitle}>Materials</Text>
              {topic.materials.map((material: Course.Topic.Material, index: number) => {
                return (
                  <Text style={styles.materialIndex}>
                    {index + 1}. {`${material.type.capitalize()}: `}
                    <Link style={styles.materialLink} src={material.url}>
                      {material.name}
                    </Link>
                  </Text>
                );
              })}
              ;
            </View>
          );
        })}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber}`} fixed />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  document: {
    padding: "0.75in",
    fontFamily: "Times-Roman",
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    top: "0.25in",
    left: 0,
    right: 0,
  },
  title: {
    fontSize: 20,
    fontFamily: "Times-Bold",
    marginBottom: 2,
  },
  duration: {
    fontSize: 10,
    color: "grey",
    marginBottom: 10,
  },
  topicWrapper: {
    marginVertical: 20,
  },
  topicIndex: {
    fontSize: 10,
    color: "#D12424",
    fontFamily: "Times-Bold",
  },
  topicTitle: {
    fontSize: 14,
    marginVertical: 5,
    fontFamily: "Times-Bold",
  },
  topicDescription: {
    fontSize: 10,
    color: "grey",
    marginBottom: 20,
  },
  topicContent: {
    fontSize: 10,
    textAlign: "justify",
    marginBottom: 20,
  },
  materialsTitle: {
    fontSize: 12,
    marginBottom: 4,
    fontFamily: "Times-Bold",
  },
  materialIndex: {
    fontSize: 10,
    marginVertical: 2,
  },
  materialLink: {
    fontSize: 10,
    color: "#D12424",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: "0.5in",
    textAlign: "right",
  },
});
