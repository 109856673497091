import { BsCheck } from "react-icons/bs";
import { GAEvent, GALogEvent } from "../../../firebase/GoogleAnalytics";
import "../../../utils/StringExtensions";
import YouTube, { YouTubePlayer } from "react-youtube";
import { useRef, useState } from "react";
import { extractYoutubeVideoID } from "../../../backend/Projects/ProjectLinks";
import Checkbox from "../../elements/Checkbox";
import Button from "../../elements/Button";
import { cn } from "../../../utils/UtilityMethods";

interface Props {
  topic: Course.Topic | null;
  course: Course;
  updateCourse: (course: Course) => void;
}
const DashboardTopicView = ({ topic, course, updateCourse }: Props) => {
  const firstVideoMaterial = () => {
    const material = topic?.materials.find((material) => material.type === "video");
    if (!material) return null;
  };

  const youtubePlayer = useRef<YouTubePlayer>();
  const [selectedVideo, setSelectedVideo] = useState<Course.Topic.Material | undefined>(firstVideoMaterial() || undefined);
  const selectedVideoID = selectedVideo ? extractYoutubeVideoID(selectedVideo.url) : undefined;

  const index = topic && course.topics.indexOf(topic);

  function ToggleTopicCompletion(isCompleted: boolean) {
    if (!topic || index === null) return;

    const newCourse = { ...course };
    newCourse.topics[index].isCompleted = isCompleted;

    updateCourse(newCourse);

    GALogEvent(GAEvent.Course.Topic.completed);
    if (completedPercentage(newCourse) === 1) GALogEvent(GAEvent.Course.completed);
  }

  function ToggleMaterialCompletion(material: Course.Topic.Material, isCompleted: boolean) {
    if (!topic || index === null) return;

    const newCourse = { ...course };
    const materialIndex = topic.materials.indexOf(material);
    newCourse.topics[index].materials[materialIndex].isCompleted = isCompleted;

    updateCourse(newCourse);

    if (isCompleted) GALogEvent(GAEvent.Course.Topic.materialCompleted);
    if (completedPercentage(newCourse) === 1) GALogEvent(GAEvent.Course.completed);
  }

  function OnMaterialClick(material: Course.Topic.Material) {
    if (material.type === "video") {
      const id = extractYoutubeVideoID(material.url);
      if (!id) return window.open(material.url, "_blank");
      return setSelectedVideo(material);
    }
    window.open(material.url, "_blank");
  }

  if (!topic) {
    return (
      <div className="w-full">
        <div className="text-systemGray-400 text-center relative top-1/2 text-lg">No topic is selected</div>
      </div>
    );
  }

  const topicDuration = topic.materials.reduce((acc, material) => acc + material.duration, 0);

  return (
    <div className="flex flex-col gap-8 w-full overflow-auto p-4">
      <div className={`flex flex-col gap-4 w-full ${topic.isCompleted && "opacity-50"} duration-300`}>
        <div className="">
          <div className="text-lg text-brand-500 font-semibold">{`Topic ${index !== null ? index + 1 : ""}`}</div>
          <div className="text-2xl font-semibold">{topic.title}</div>
          <div className="text-sm text-systemGray-500">{GetDurationString(topicDuration)}</div>
        </div>
        {topic.content.split("\n\n").map((paragraph, index) => {
          return (
            <div key={index} className="whitespace-pre-line text-sm md:text-base">
              {paragraph}
            </div>
          );
        })}
        <div className="flex flex-col justify-center-center gap-1">
          <div className="text-lg font-semibold">Materials</div>
          {topic.materials.map((material: Course.Topic.Material, materialIndex) => {
            return (
              <MaterialRow
                material={material}
                isCompleted={material.isCompleted}
                isSelected={material.type === "video" && extractYoutubeVideoID(material.url) === selectedVideoID}
                ToggleCompletion={ToggleMaterialCompletion}
                key={`${index}:${materialIndex}`}
                OnClick={() => OnMaterialClick(material)}
              />
            );
          })}
        </div>
      </div>
      <CompleteTopicButton isCompleted={topic.isCompleted} ToggleCompletion={ToggleTopicCompletion} />
      {selectedVideo && selectedVideoID && (
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center justify-between  border-b pb-2 mb-4">
            <div className="font-semibold text-lg">{selectedVideo.name}</div>
            <MaterialTag type="video" />
          </div>
          <YouTube
            opts={{
              playerVars: {
                rel: 0,
              },
            }}
            onReady={(event: any) => (youtubePlayer.current = event.target)}
            videoId={selectedVideoID}
            className="relative w-full h-0 pb-[56.25%] overflow-hidden"
            iframeClassName="w-full h-full absolute top-0 left-0"
          />
        </div>
      )}
    </div>
  );
};

export function GetDurationString(seconds: number): string {
  if (seconds === 0) return "";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours > 0 ? `${hours} ${hours === 1 ? "hour" : "hours"} and ` : ""}${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
}

export default DashboardTopicView;

export const MaterialTag = ({ type }: { type: "web" | "video" | string }) => {
  let bgStyle = "bg-amber-500";
  if (type === "video") bgStyle = "bg-brandBlue-500";
  if (type === "web") bgStyle = "bg-brandGreen-500";

  return <div className={`${bgStyle} rounded-full text-background text-micro md:text-mini px-2`}>{type.capitalize()}</div>;
};

const MaterialRow = ({
  material,
  isCompleted,
  isSelected,
  ToggleCompletion,
  OnClick,
}: {
  material: Course.Topic.Material;
  isCompleted: boolean;
  isSelected: boolean;
  ToggleCompletion: (material: Course.Topic.Material, isCompleted: boolean) => void;
  OnClick: () => void;
}) => {
  return (
    <div className="w-full flex gap-2 items-center justify-between select-none">
      <div className={`flex gap-2 items-center truncate`}>
        <Checkbox variant="bare" checked={material.isCompleted} setChecked={() => ToggleCompletion(material, !isCompleted)} />
        <Button size="sm" variant="link" onClick={OnClick} className={cn("pl-0 justify-start text-left truncate", isSelected && "text-brand-500 font-semibold", material.isCompleted && "opacity-50")}>
          <span className="truncate">{material.name}</span>
        </Button>
      </div>
      <div className={`flex flex-col items-end ${material.isCompleted && "opacity-50"} duration-100`}>
        <MaterialTag type={material.type} />
        <div className="text-micro md:text-mini text-systemGray-500 min-w-max">{GetDurationString(material.duration)}</div>
      </div>
    </div>
  );
};

const CompleteTopicButton = ({ isCompleted, ToggleCompletion }: { isCompleted: boolean; ToggleCompletion: (isCompleted: boolean) => void }) => {
  return (
    <Button
      onClick={() => {
        ToggleCompletion(!isCompleted);
      }}
      variant="outline"
    >
      <div className={`bg-background rounded-md h-5 w-5 flex-shrink-0 cursor-pointer relative duration-100`}>
        <BsCheck className={`text-brand-500 text-3xl absolute left-1/2 top-1/2 -translate-y-[50%] -translate-x-[50%] ${isCompleted ? "opacity-100" : "opacity-0"} duration-100`} />
      </div>
      {isCompleted ? "Completed topic" : "Complete topic"}
    </Button>
  );
};

function completedPercentage(course: Course): number {
  return (
    course.topics.reduce((topicSum: number, topic: Course.Topic) => {
      if (topic.isCompleted) return topicSum + 1;

      const materials = topic.materials.reduce((materialSum: number, material: Course.Topic.Material) => {
        return materialSum + (material.isCompleted ? 1 : 0);
      }, 0);

      return topicSum + materials / topic.materials.length;
    }, 0) / course.topics.length
  );
}
