import { cva, VariantProps } from "class-variance-authority";
import CoursableIcons from "../../utils/CoursableIcons";
import { cn } from "../../utils/UtilityMethods";
import { controlsStyles } from "./Button";
import clsx from "clsx";

interface Props extends VariantProps<typeof checkboxVariants> {
  className?: string;
  checked?: boolean;
  setChecked?: (b: boolean) => void;
  label?: string | React.ReactNode;
}

const checkboxVariants = cva([controlsStyles.base, "flex items-center justify-center gap-2 cursor-pointer"], {
  variants: {
    variant: {
      outline: cn(controlsStyles.variants.outline),
      ghost: cn(controlsStyles.variants.ghost, "border-transparent"),
      bare: "",
      dynamic: "",
    },
    size: {
      sm: cn(controlsStyles.size.sm, "px-2"),
      default: cn(controlsStyles.size.default, "px-2"),
      lg: cn(controlsStyles.size.lg, "px-2.5"),
    },
  },
  defaultVariants: {
    variant: "outline",
    size: "default",
  },
});

const Checkbox = ({ variant, size, className, checked, setChecked, label }: Props) => {
  return (
    <label
      className={cn(
        checkboxVariants({
          variant: variant === "dynamic" ? (checked ? "outline" : "ghost") : variant,
          className: clsx(className, variant === "dynamic" && "border", variant === "bare" && "p-0"),
          size,
        })
      )}
    >
      <div className="relative flex items-center justify-center">
        <input
          className={`appearance-none aspect-square border-2 rounded peer ${size === "sm" ? "w-4 h-4" : "w-5 h-5"} ${checked ? "border-brand-500" : "border-systemGray-400"}`}
          type="checkbox"
          checked={checked}
          onChange={() => {
            setChecked?.(!checked);
          }}
        />
        {CoursableIcons.Check(`absolute text-brand-500 opacity-0 scale-90 peer-checked:opacity-100 peer-checked:scale-100 duration-100 ${size === "sm" ? "text-mini" : "left-0.5 top-0.5"}`)}
      </div>
      {label && typeof label === "string" ? <span className="w-full truncate font-normal">{label}</span> : label}
    </label>
  );
};

export default Checkbox;
