import { useState } from "react";
import { DeleteCourseFromDatabase } from "../../../firebase/FirestoreManager";
import { useAuth } from "../../../firebase/AuthContext";
import { GAEvent, GALogEvent } from "../../../firebase/GoogleAnalytics";
import { useNavigate } from "react-router-dom";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import QuizModal from "./QuizModal";
import exportFromJSON from "export-from-json";
import { DuplicateCourseToDatabase } from "../../../backend/Dashboard";
import CoursableIcons from "../../../utils/CoursableIcons";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { ContextMenuButton, ContextMenuDivider } from "../../elements/DropdownMenu/ContextMenu";
import ConfirmationPopup from "../../elements/ConfirmationPopup";
import { useNotifications } from "../../../utils/NotificationsContext";
import { UpgradePopup } from "../../elements/UpgradePopup";

interface DashboardPageProps {
  course: Course | null;
  ReloadCourses: () => void;
  selectedTopic: Course.Topic | null;
  selectTopic: (course: Course.Topic | null) => void;
}

const DashboardCourseView = ({ course, ReloadCourses, selectedTopic, selectTopic }: DashboardPageProps) => {
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  const { currentUser } = useAuth();
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);

  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [lockedFeature, setLockedFeature] = useState<"edit" | "export">("edit");

  const navigate = useNavigate();
  const { sendError } = useNotifications();

  if (!course) {
    return (
      <div className="w-full border-x flex flex-col gap-4 justify-center items-center">
        <div className="text-4xl">👋</div>
        <div className="text-2xl font-semibold">Hi {currentUser?.displayName?.split(" ")[0]}, welcome to Coursable</div>
        <div className="text-systemGray-400 text-center text-lg">Select a course or generate a new one to get started</div>
      </div>
    );
  }

  const dateCreated = new Date(course.dateGenerated).toLocaleDateString();
  const durationSeconds = course.topics.reduce((acc: number, topic: Course.Topic) => {
    const total = topic.materials.reduce((acc: number, material: Course.Topic.Material) => {
      return acc + material.duration;
    }, 0);
    return acc + total;
  }, 0);
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const duration = `${hours > 0 ? `${hours} hours and ` : ""}${minutes} minutes`;

  async function DuplicateCourse() {
    if (!course) return;

    try {
      await DuplicateCourseToDatabase(course);
      GALogEvent(GAEvent.Course.duplicated);
      ReloadCourses();
    } catch (error) {
      console.log(error);
      sendError("Failed to duplicate course ☹️");
    }
  }

  async function DeleteCourse() {
    if (!course) return;
    try {
      await DeleteCourseFromDatabase(course);
      GALogEvent(GAEvent.Course.deleted);
      await ReloadCourses();
    } catch (error) {
      console.log(error);
    }
  }

  function EditCourse() {
    if (!course) return;
    if (plan !== CoursablePlan.Plus) {
      setLockedFeature("edit");
      setShowUpgradePopup(true);
      GALogEvent(GAEvent.Sales.upgradePopupShown, {
        source: "course_edit",
      });
      return;
    }

    GALogEvent(GAEvent.Course.Editing.started);
    navigate(`${course.id}/edit`);
  }

  function ExportPDF() {
    if (!course) return;
    if (plan !== CoursablePlan.Plus) {
      setLockedFeature("export");
      setShowUpgradePopup(true);
      GALogEvent(GAEvent.Sales.upgradePopupShown, {
        source: "course_export_pdf",
      });
      return;
    }

    window.open(`/app/courses/${course.id}/pdf`, "_blank");
  }

  function Download(as: "json" | "xml") {
    if (!course) return;
    if (plan !== CoursablePlan.Plus) {
      setLockedFeature("export");
      setShowUpgradePopup(true);
      GALogEvent(GAEvent.Sales.upgradePopupShown, {
        source: `course_export_${as}`,
      });
      return;
    }

    const courseOrder = {
      id: null,
      title: null,
      dateGenerated: null,
      topics: null,
      quiz: null,
    };
    const topicOrder = {
      title: null,
      description: null,
      content: null,
      materials: null,
    };
    const data: Course = Object.assign(courseOrder, course);
    data.dateGenerated = new Date(data.dateGenerated).toLocaleString();
    data.topics = course.topics.map((topic) => {
      const topicData = Object.assign(topicOrder, topic);
      delete topicData.isCompleted;
      topicData.materials.forEach((material: any) => {
        delete material.isCompleted;
      });
      return topicData;
    });

    const fileName = course.title;
    const exportType = as;

    exportFromJSON({ data: data, fileName, exportType });
  }

  return (
    <div onClick={() => selectTopic(null)} className="w-full border-x px-2 md:px-4 py-4 relative overflow-auto">
      <div className="flex gap-2 items-start justify-between px-2 md:px-4">
        <div className="text-2xl md:text-4xl font-semibold">{course.title}</div>
        <Dropdown label="Actions" containerClassName="mt-1">
          <ContextMenuButton label="Edit" icon={plan === CoursablePlan.Plus ? CoursableIcons.Edit() : CoursableIcons.Lock()} onClick={EditCourse} />
          <ContextMenuButton label="Duplicate" icon={CoursableIcons.Duplicate()} onClick={DuplicateCourse} />
          <ContextMenuButton label="Download as PDF" icon={plan === CoursablePlan.Plus ? CoursableIcons.Share() : CoursableIcons.Lock()} onClick={ExportPDF} />
          <ContextMenuButton label="Download as JSON" icon={plan === CoursablePlan.Plus ? CoursableIcons.Share() : CoursableIcons.Lock()} onClick={() => Download("json")} />
          <ContextMenuButton label="Download as XML" icon={plan === CoursablePlan.Plus ? CoursableIcons.Share() : CoursableIcons.Lock()} onClick={() => Download("xml")} />
          <ContextMenuDivider />
          <ContextMenuButton label="Delete" icon={CoursableIcons.Delete()} onClick={() => setShowDeletePopup(true)} destructive />
        </Dropdown>
      </div>
      <div className="text-sm text-systemGray-500 my-2 md:my-4 px-2 md:px-4">
        <div>Created: {dateCreated}</div>
        <div>Duration: {duration}</div>
      </div>
      <div className="flex flex-col gap-2">
        {course.topics.map((topic, index) => (
          <TopicTableCell
            topic={topic}
            key={index}
            index={index}
            isSelected={selectedTopic === topic}
            onClick={() => {
              selectTopic(topic);
            }}
          />
        ))}
        {course.quiz && <TableQuizCell quiz={course.quiz} />}
      </div>
      <ConfirmationPopup
        open={showDeletePopup}
        Close={() => setShowDeletePopup(false)}
        title={`Delete ${course.title}?`}
        highlightInTitle={course.title}
        description="You won't be able to restore it later."
        confirmText="Delete"
        confirmIcon={CoursableIcons.Delete()}
        onConfirm={DeleteCourse}
      />
      <UpgradePopup
        isOpen={showUpgradePopup}
        Close={() => setShowUpgradePopup(false)}
        highlightCourses
        header={
          <>
            {lockedFeature === "export" ? "Exporting" : "Editing"} courses is only available on the <span className={`font-semibold text-brand-500`}>Plus</span> plan.
          </>
        }
      />
    </div>
  );
};

export default DashboardCourseView;

const TopicTableCell = ({ topic, index, isSelected, onClick }: { topic: Course.Topic; index: number; isSelected: boolean; onClick: () => void }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className={`px-2 md:px-4 py-2 rounded-lg border-2 cursor-pointer ${isSelected ? "border-brand-500 shadow bg-brand-25 dark:bg-brand-100" : "border-transparent hover:bg-systemGray-100"} ${
        topic.isCompleted && "line-through text-systemGray-400"
      }`}
    >
      <div className={`text-lg md:text-xl font-semibold duration-300`}>
        {index + 1}. {topic.title || <span className="text-systemGray-400">Empty title</span>}
      </div>
      <div className={`text-sm md:text-base mt-2 duration-300 ${topic.description === "" && "text-systemGray-400"}`}>{topic.description || "Empty description"}</div>
    </div>
  );
};

const TableQuizCell = ({ quiz }: { quiz: Course.Quiz }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
      className={`mt-8 px-2 md:px-4 py-2 rounded-lg ${!open && "cursor-pointer hover:bg-brand-25 active:bg-brand-50"} text-center`}
    >
      <div className={`text-lg md:text-xl font-semibold duration-300 text-brand-500`}>Final Quiz</div>
      <div className={`text-mini md:text-sm text-systemGray-500 duration-300`}>{quiz.multipleChoice.length} questions</div>
      <QuizModal quiz={quiz} isOpen={open} Close={() => setOpen(false)} />
    </div>
  );
};
