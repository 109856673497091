import { cva } from "class-variance-authority";
import { cn } from "../../utils/UtilityMethods";

const switchVariants = cva("cursor-pointer rounded-full p-0.5 duration-200 relative shadow-sm hover:shadow bg-systemGray-200", {
  variants: {
    size: {
      default: "w-11 h-6",
      sm: "w-9 h-5",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

const Switch = ({ on, setOn, size = "default", className }: { on?: boolean; setOn?: (v: boolean) => void; size?: "default" | "sm"; className?: string }) => {
  const translateX = size === "sm" ? "translate-x-4" : "translate-x-5";

  return (
    <div className={cn(switchVariants({ size, className }), on && "bg-brand-500")} onClick={() => setOn?.(!on)}>
      <div className={cn(`h-full aspect-square bg-background rounded-full duration-200 shadow-lg`, on && translateX)} />
    </div>
  );
};

export default Switch;
