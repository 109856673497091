import HomePageTemplate from "../../page-elements/HomePageTemplate";
import { useEffect, useState } from "react";
import MarkdownViewWithIDs from "./MarkdownViewWithIDs";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const TermsPage = () => {
  const [terms, setTerms] = useState<string>("");

  const termsMd = require("./Terms.md");

  useEffect(() => {
    fetch(termsMd)
      .then((response) => response.text())
      .then((text) => setTerms(text))
      .catch(console.error);
  }, []);

  return (
    <HomePageTemplate
      helmet={pageMetatags({
        title: "Terms of Service",
        canonical: "/terms",
      })}
    >
      <div className="w-full flex items-start justify-center py-8 overflow-auto px-4">
        <MarkdownViewWithIDs>{terms}</MarkdownViewWithIDs>
      </div>
    </HomePageTemplate>
  );
};

export default TermsPage;
