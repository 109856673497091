export const pageMetatags = ({ title, description, ogImage, noindex, canonical }: { title?: string; description?: string; ogImage?: string; noindex?: boolean; canonical?: string }): React.ReactNode => {
  return (
    <>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta property="og:title" content={title ? `${title} | ${defaultTitle}` : defaultTitle} />
      <meta name="twitter:title" content={title ? `${title} | ${defaultTitle}` : defaultTitle} />

      <meta name="description" content={description ?? defaultDescription} />
      <meta property="og:description" content={description ?? defaultDescription} />
      <meta name="twitter:description" content={description ?? defaultDescription} />

      {canonical && <meta property="og:url" content={`${process.env.REACT_APP_CLIENT_URL}${canonical}`} />}
      {canonical && <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}${canonical}`} />}

      {ogImage && <meta property="og:image" content={`${process.env.REACT_APP_CLIENT_URL}${ogImage ?? "/Images/OpenGraph/Promo.png"}`} />}
      {ogImage && <meta name="twitter:image" content={`${process.env.REACT_APP_CLIENT_URL}${ogImage ?? "/Images/OpenGraph/Promo.png"}`} />}

      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@CoursableIO" />

      {noindex === true && <meta name="robots" content="noindex" />}
    </>
  );
};

const defaultTitle = "Coursable - Student workspace powered by AI";
const defaultDescription =
  "Power up your study materials with AI flashcards, summaries, and quizzes; pull insights and find answers faster with documents chat. Learn something new with personalized AI courses and approved study materials from the web.";
