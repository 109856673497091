import { useEffect, useRef, useState } from "react";

const useRollingAppear = (length: number, showDelay: number = 200, hideDelay: number = 100) => {
  const [indexShown, setIndexShown] = useState(-1);
  const interval = useRef<NodeJS.Timeout | null>(null);

  function StartRollingAppear() {
    interval.current = setInterval(() => {
      setIndexShown((prev) => {
        if (prev >= length) {
          if (interval.current) clearInterval(interval.current);
          return prev;
        }
        return prev + 1;
      });
    }, showDelay);
  }

  function StartRollingDisappear() {
    interval.current = setInterval(() => {
      setIndexShown((prev) => {
        if (prev < 0) {
          if (interval.current) clearInterval(interval.current);
          return prev;
        }
        return prev - 1;
      });
    }, hideDelay);
  }

  function Reset() {
    setIndexShown(-1);
    if (interval.current) clearInterval(interval.current);
  }

  useEffect(() => {
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  return {
    indexShown,
    StartRollingAppear,
    StartRollingDisappear,
    Reset,
  };
};

export default useRollingAppear;
