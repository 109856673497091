import { auth, GetIDToken } from "../../firebase/FirebaseConfig";
import { ExtractServerResponse } from "../Shared";
import { ProjectFile } from "./types";

export async function FetchYouTubeVideoInfo(url: string, signal?: AbortSignal): Promise<YouTubeVideoInfo> {
  if (!isURLYouTubeVideo(url)) {
    throw new Error("Invalid YouTube URL");
  }

  if (!auth.currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/utils/youtube-video-info`, {
    method: "POST",
    body: JSON.stringify({ url }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    signal,
  });

  const { videoInfo } = (await ExtractServerResponse(response)).data;

  return videoInfo;
}

export const isURLYouTubeVideo = (url: string): boolean => {
  if (!url.startsWith("https://")) return false;
  return url.includes("youtu.be/") || url.includes("youtube.com/watch?v=");
};

export const extractYoutubeVideoID = (url: string): string | null => {
  const videoID = url.includes("youtu.be/") ? url.split("youtu.be/")[1] : url.split("v=")[1];
  const id = videoID.split("&")[0].slice(0, 11);
  if (id.length !== 11) return null;
  return id;
};

export async function DownloadVideoTranscript(file: ProjectFile): Promise<string> {
  if (!file.sourceUrl) throw new Error("No source URL found");
  if (!isURLYouTubeVideo(file.sourceUrl)) throw new Error("Source URL is not a YouTube video.");

  const response = await fetch(file.downloadUrl);
  if (!response.ok) throw new Error("Failed to download video transcript");
  return await response.text();
}

export async function FetchWebArticleInfo(url: string, signal?: AbortSignal): Promise<WebArticleInfo> {
  if (!isURLWebArticle(url)) {
    throw new Error("Invalid web article URL");
  }

  if (!auth.currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/utils/web-article-info`, {
    method: "POST",
    body: JSON.stringify({ url }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    signal,
  });

  const { webArticleInfo } = (await ExtractServerResponse(response)).data;

  return webArticleInfo;
}

export const isURLWebArticle = (url: string): boolean => {
  return url.startsWith("https://") || url.startsWith("http://");
};

export async function FetchUrlFileInfo(url: string, signal?: AbortSignal): Promise<UrlFileInfo> {
  if (!isURLFile(url)) throw new Error("Invalid file URL");

  if (!auth.currentUser) throw new Error("User not logged in");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/utils/url-file-info`, {
    method: "POST",
    body: JSON.stringify({ url }),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    signal,
  });

  const { fileInfo } = (await ExtractServerResponse(response)).data;

  return fileInfo;
}

export const isURLFile = (url: string): boolean => {
  if (!url.startsWith("https://") && !url.startsWith("http://")) return false;

  const noQueryParamsUrl = url.split("?")[0]; // Remove query params
  const fileName = noQueryParamsUrl.split("/").pop(); // Get last part of the URL
  if (!fileName) return false;

  const regex = /(?:\.([^.]+))?$/;
  const extension: string | undefined = regex.exec(fileName.toLocaleLowerCase())![1];
  if (!extension) return false;

  return true;
};

export interface ProjectLinkInfo {
  type: "youtube" | "web" | "file";
  url: string;
  title: string;
  previewImageURL?: string;
}

export interface YouTubeVideoInfo {
  id: string;
  title: string;
  description: string;
  isLive: boolean;
  thumbnailURL: string;
  duration: number;
  hasCaptions: boolean;
}

export interface WebArticleInfo {
  url: string;
  title: string;
  source: string;
  author?: string;
  faviconUrl?: string;
  hasContent: boolean;
}

export interface UrlFileInfo {
  url: string;
  name: string;
  extension: string;
  mbSize: number;
}
