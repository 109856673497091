import { useRef, useState } from "react";
import { CoursableProject, ProjectFile } from "../../../../backend/Projects/types";
import { AddMaterialsToProject, DeleteFileFromProject, FetchProjectFiles, RenameFile } from "../../../../backend/Projects/ProjectFiles";

const useProjectFiles = (project: CoursableProject | undefined) => {
  const [files, setFiles] = useState<ProjectFile[]>([]);
  const initializingProjectID = useRef<string | undefined>(undefined);

  async function Initialize(project: CoursableProject) {
    setFiles([]);

    initializingProjectID.current = project.id;
    const files = await FetchProjectFiles(project.id);
    if (initializingProjectID.current !== project.id) return;

    setFiles(files);
  }

  async function Upload(files: File[], links: string[]) {
    if (!project) return;

    const allFiles = await FetchProjectFiles(project.id);
    setFiles(allFiles);
    await AddMaterialsToProject(project.id, files, links);
  }

  async function SaveName(file: ProjectFile, newName: string) {
    if (!project) return;

    setFiles((prev) => prev.map((f) => (f.id === file.id ? file : f)));
    await RenameFile(file, newName);
  }

  async function Delete(file: ProjectFile) {
    await DeleteFileFromProject(file);
    setFiles((prev) => prev.filter((f) => f.id !== file.id));
  }

  const fileName = (fileOpenaiID: string) => {
    return files.find((f) => f.openaiID === fileOpenaiID)?.name ?? "Unknown File";
  };

  return { files, fileName, Initialize, Upload, SaveName, Delete };
};

export default useProjectFiles;
