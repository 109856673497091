import { AssistantAnnotation, AssistantMessage } from "../../../../backend/Projects/types";
import CoursableLogo from "../../../elements/CoursableLogo";
import { useEffect, useRef, useState } from "react";
import CoursableIcons from "../../../../utils/CoursableIcons";
import LoadingIndicator from "../../../elements/LoadingIndicator";
import { useAnnotations, AnnotationView, MarkdownWithAnnotations } from "../ProjectAnnotations";
import { GAEvent, GALogEvent } from "../../../../firebase/GoogleAnalytics";
import date from "date-and-time";
import { useProjectManager } from "../Hooks/useProjectManager";
import { useAuth } from "../../../../firebase/AuthContext";
import MessageAttachments, { useAttachments } from "./MessageAttachments";
import { cn } from "../../../../utils/UtilityMethods";
import OnOffTransition from "../../../elements/OnOffTransition";

const ProjectChat = () => {
  const { Messages } = useProjectManager();

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [opacity, setOpacity] = useState(0);

  const { shownAnnotation, lockAnnotation, setLockAnnotation, annotationY, ShowAnnotation } = useAnnotations();

  function ClickSuggestion(suggestion: string) {
    Messages.SendMessage(suggestion);
    GALogEvent(GAEvent.Project.Chat.suggestionClicked, { suggestion });
  }

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <div className="grow overflow-auto flex flex-col items-center justify-end relative @container">
      <div ref={scrollRef} className={`flex flex-col-reverse w-full items-start md:px-2 pb-4 pt-20 gap-2 overflow-auto ${opacity === 1 ? "opacity-100 duration-300" : "opacity-0"}`}>
        {Messages.messages.map((message, index) => {
          return <MessageBox key={index} message={message} ShowAnnotation={ShowAnnotation} />;
        })}
      </div>
      {Messages.messages.length === 0 && (
        <>
          <div className={`${opacity === 1 ? "opacity-100 duration-300" : "opacity-0"} w-full h-full text-center p-8 flex flex-col items-center justify-center gap-4 max-w-xl`}>
            <CoursableLogo className="w-6 @sm:w-8 h-6 @sm:h-8" />
            <div className="font-semibold text-lg @sm:text-xl relative">
              Coursable <span className="text-brand-500">Projects</span>
            </div>
            <div className="flex flex-col gap-4 w-full text-systemGray-500 text-sm @sm:text-base mt-8">
              <div>A few tips before you start</div>
              <ol className="list-decimal text-left flex flex-col gap-2">
                <li>Ask Coursable any questions about your materials.</li>
                <li>Use the Workspace on the right to generate summaires, flashcards, and quizzes, practice with AI and export your results.</li>
                <li>Manange your materials by expanding the materials tab from the bottom of the Workspace.</li>
                <li>
                  If anything goes wrong, try resetting the chat or{" "}
                  <a href="mailto:support@coursable.io" className="text-brand-400 hover:text-brand-500 duration-100 active:text-brand-600 underline">
                    contact us
                  </a>
                  .
                </li>
              </ol>
            </div>
          </div>
          <PromptsSuggestions onClick={ClickSuggestion} />
        </>
      )}
      <AnnotationView
        annotation={shownAnnotation}
        locked={lockAnnotation}
        setLock={setLockAnnotation}
        ShowAnnotation={ShowAnnotation}
        annotationY={annotationY}
        containerHeight={scrollRef.current?.parentElement?.getBoundingClientRect().height}
      />
    </div>
  );
};

export default ProjectChat;

const MessageBox = ({ message, ShowAnnotation }: { message: AssistantMessage; ShowAnnotation: (a: AssistantAnnotation | null, y: number) => void }) => {
  const { currentUser } = useAuth();
  const [copied, setCopied] = useState(false);

  const generatingResponse = message.id === "generating-response";
  const [showGenerating, setShowGenerating] = useState(false);

  const { cleanMessage, attachments } = useAttachments(message.content);

  useEffect(() => {
    if (generatingResponse) return setShowGenerating(true);

    setTimeout(() => {
      setShowGenerating(false);
    }, 300);
  }, [generatingResponse]);

  if (message.sender === "system") return <SystemMessage message={message} />;

  let name = message.sender === "assistant" ? "Coursable Assistant" : currentUser?.displayName || currentUser?.email || currentUser?.phoneNumber || "You";

  let icon = null;
  if (message.sender === "assistant") {
    icon = <CoursableLogo className="-mt-1" />;
  } else if (message.sender === "user") {
    icon = (
      <div className="rounded-full bg-gradient-to-t from-systemGray-500 to-systemGray-400 shadow-md shadow-systemGray-500/30 h-6 w-6 text-mini text-center text-white font-semibold flex items-center justify-center">
        <p>{currentUser?.displayName?.slice(0, 1).toUpperCase()}</p>
      </div>
    );
  }

  function CopyContent() {
    navigator.clipboard.writeText(message.content);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  return (
    <div className="w-full p-2 rounded-lg flex items-start gap-2 hover:bg-systemGray-200/50 relative group/message duration-100 @container">
      <div className="w-6 select-none">{icon}</div>
      <div className="w-full flex flex-col items-start justify-start relative">
        <div className="font-semibold select-none text-sm @sm:text-base">{name}</div>
        {showGenerating && (
          <div className={`absolute bottom-0 left-0 w-full ${generatingResponse ? "opacity-100" : "opacity-0"} duration-300 pointer-events-none`}>
            <LoadingIndicator type="dots" className="h-6 fill-systemGray-400" />
          </div>
        )}
        <div className={`${generatingResponse ? "opacity-0" : "opacity-100"} duration-300 w-full min-h-[1.75rem]`}>
          <MessageAttachments attachments={attachments} />
          <MarkdownWithAnnotations annotations={message.annotations} ShowAnnotation={ShowAnnotation} className="@sm:prose-base prose-sm" size={null}>
            {cleanMessage}
          </MarkdownWithAnnotations>
        </div>
      </div>
      {message.id !== "generating-response" && <div className="text-mini text-systemGray-400 absolute top-2 right-8">{formattedMessageTime(message.sentAt)}</div>}
      <button
        onClick={() => CopyContent()}
        className={cn(
          "absolute top-2 right-2 text-systemGray-400 hover:text-systemGray-500 active:text-systemGray-600 opacity-0 group-hover/message:opacity-100 text-sm duration-100",
          copied && "text-foreground hover:text-foreground active:text-foreground"
        )}
      >
        <OnOffTransition trigger={copied} onElement={CoursableIcons.Check()} offElement={CoursableIcons.CopyFill()} />
      </button>
    </div>
  );
};

const SystemMessage = ({ message }: { message: AssistantMessage }) => {
  return <div className="w-full p-2 rounded-lg bg-systemGray-100 border border-systemGray-400 text-center text-systemGray-500">{message.content}</div>;
};

const PromptsSuggestions = ({ onClick }: { onClick: (v: string) => void }) => {
  const allSuggestions = [
    "What is the main idea of this document?",
    "Summarize this document in bullet-points.",
    "List key takeaways and conclusions.",
    "Are there any references in this document?",
    "What are the main arguments in this document?",
    "Who are the authors of this document?",
    "What topic is discussed on page one?",
    "Convert this document to a presentation.",
    "List all questions asked in this document.",
    "Analize the tone and writing style.",
    "Compare different viewpoints in this document.",
    "Who is the primary audience of this document?",
    "Generate a list of keywords for this document.",
  ];

  const randomSuggestions = () => {
    const shuffled = allSuggestions.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 4);
  };

  const suggestions = useRef<string[]>(randomSuggestions());
  const [shown, setShown] = useState<boolean[]>(Array(suggestions.current.length).fill(false));
  const [showTitle, setShowTitle] = useState(false);

  useEffect(() => {
    const globalDelay = 500;

    for (let i = 0; i < suggestions.current.length; i++) {
      setTimeout(() => {
        setShown((prev) => {
          const copy = [...prev];
          copy[i] = true;
          return copy;
        });
      }, globalDelay + i * 100);
    }
    setTimeout(() => {
      setShowTitle(true);
    }, globalDelay + 300 + suggestions.current.length * 100);
  }, []);

  return (
    <div className="w-full flex flex-col gap-4 items-center justify-center max-w-2xl my-4">
      <div className={`text-mini md:text-sm text-systemGray-500 ${showTitle ? "opacity-100" : "opacity-0"} duration-500`}>Try asking</div>
      <div className="w-full grid grid-cols-2 gap-2">
        {suggestions.current.map((suggestion, index) => {
          return (
            <button
              style={{
                opacity: shown[index] ? 1 : 0,
                transition: "opacity 500ms ease-in-out, background-color 100ms",
              }}
              onClick={() => onClick(suggestion)}
              key={index}
              className="text-left text-mini md:text-sm text-systemGray-500 border border-systemGray-300 py-2 px-3 rounded-lg hover:bg-systemGray-200 active:bg-systemGray-300"
            >
              <p className="line-clamp-3">{suggestion}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const formattedMessageTime = (time: Date) => {
  const now = new Date();
  const isToday = date.isSameDay(time, now);
  if (isToday) return date.format(time, "hh:mm A");
  const isThisYear = now.getFullYear() === time.getFullYear();
  if (isThisYear) return date.format(time, "D MMM, hh:mm A");
  return date.format(time, "D MMM Y, hh:mm A");
};
