import CoursableIcons from "../../utils/CoursableIcons";
import { CoursablePlan, CoursablePlanInterval } from "../../utils/CoursablePlan";
import { PopupModal, PopupModalContent, PopupModalHeader } from "./PopupModal";
import { useState } from "react";
import { BuyPlan } from "../../backend/Payment";
import ShineEffect from "./ShineEffect/ShineEffect";
import Button from "./Button";
import { CycleSwitch } from "../pages/Home/PricingPage";

interface UpgradePopupProps {
  isOpen: boolean;
  Close: () => void;
  header: React.ReactNode;
  highlightCourses?: boolean;
  highlightProjects?: boolean;
}

export const UpgradePopup = ({ isOpen, Close, header, highlightCourses, highlightProjects }: UpgradePopupProps) => {
  const [cycle, setCycle] = useState<CoursablePlanInterval>("quarter");

  return (
    <PopupModal className="flex flex-col text-center items-center max-w-2xl" open={isOpen} tapToClose={true} Close={Close}>
      <PopupModalHeader dynamicScaleOrigin="center" dynamicType="scale" className="gap-4 flex flex-col items-center justify-start">
        {CoursableIcons.GraduationCapFill("text-brand-500 text-4xl")}
        <div className="text-lg md:text-xl font-semibold max-w-md">{header}</div>
        <div className="text-sm md:text-base text-systemGray-500 font-normal">
          Upgrade to <span className="text-brand-500 font-semibold">Plus</span> to get the most out of Coursable.
        </div>
      </PopupModalHeader>
      <PopupModalContent className="w-full flex flex-col items-center justify-start gap-8">
        <div className="w-full flex-centered gap-2 text-sm mt-2">
          <CycleSwitch cycle={cycle} setCycle={setCycle} showDiscount={false} />
        </div>
        <div className={`grid grid-cols-1 w-full gap-4`}>
          <PlanBlock plan={CoursablePlan.Plus} cycle={cycle} focusCourses={highlightCourses ?? false} focusProjects={highlightProjects ?? false} />
        </div>
        <div className="grid grid-cols-[1fr_0fr_1fr] items-center w-full">
          <div className="h-[1px] bg-systemGray-200" />
          <div className="mx-2 text-systemGray-500 text-sm">or</div>
          <div className="h-[1px] bg-systemGray-200" />
        </div>
        <Button variant="inverse" to="/pricing">
          Explore Plus
        </Button>
      </PopupModalContent>
    </PopupModal>
  );
};

const PlanBlock = ({ plan, cycle, focusCourses, focusProjects }: { plan: CoursablePlan; cycle: CoursablePlanInterval; focusCourses: Boolean; focusProjects: Boolean }) => {
  const [isLoading, setIsLoading] = useState(false);

  const features = plan.allFeatures();

  async function ClickBuyPlus() {
    setIsLoading(true);
    try {
      await BuyPlan(plan, cycle);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  return (
    <div className="w-full flex flex-col items-start justify-start gap-2 text-left p-4 rounded-lg bg-systemGray-100 hover:shadow-xl-c hover:scale-101 duration-300">
      <div className="w-full flex justify-between items-center gap-4 text-lg font-semibold">
        <div>{plan.title}</div>
        <div className="text-base text-systemGray-500 font-normal shrink-0">${cycle === "month" ? plan.monthlyPrice : cycle === "quarter" ? plan.quaterlyMonthlyPrice : plan.annualMonthlyPrice} / month</div>
      </div>
      {features.map((category, index) => (
        <div className={`w-full flex flex-col items-start justify-start gap-2 relative`} key={index}>
          <div className="w-full border-b text-systemGray-500 text-sm py-1 z-10 flex items-center justify-start gap-1">
            {category.category == "Courses" ? CoursableIcons.GraduationCap() : CoursableIcons.Project()}
            {category.category}
          </div>
          {category.features.map((feature, index) => (
            <li key={`${index} ${index}`} className="flex items-center gap-2 text-sm  z-10">
              {CoursableIcons.Check("text-brand-500")}
              <p>{feature}</p>
            </li>
          ))}
          {((category.category === "Courses" && focusCourses) || (category.category === "Projects" && focusProjects)) && (
            <div className="absolute rounded-lg -inset-x-2 -inset-y-1 bg-brand-25 dark:bg-brand-50 border border-brand-100 z-0">
              <ShineEffect />
            </div>
          )}
        </div>
      ))}
      <div className="w-full flex justify-center mt-auto">
        <Button loading={isLoading} disabled={isLoading} onClick={ClickBuyPlus} className="!text-sm mt-2">
          Upgrade Now
        </Button>
      </div>
    </div>
  );
};
