import { TypeAnimation } from "react-type-animation";
import CoursableIcons from "../../../utils/CoursableIcons";
import { useEffect, useMemo, useRef, useState } from "react";
import { ArticleMaterial, PaperMaterial, VideoMaterial } from "./HomeCoursesPanel";

const HeroGraphic = () => {
  return (
    <div className="h-[500px] w-full md:w-1/2 z-[1] relative">
      <Chat
        style={{
          boxShadow: "15px 15px 30px -12px rgb(0 0 0 / 0.25)",
        }}
        className="absolute -bottom-12 left-0 md:-left-12 z-[2]"
      />
      <Courses
        style={{
          boxShadow: "25px 25px 50px -12px rgb(0 0 0 / 0.25)",
        }}
        className="absolute left-10 right-[-8rem] -top-4 bottom-4 z-[1]"
      />
    </div>
  );
};

export default HeroGraphic;

const Chat = ({ style, className }: { className?: string; style?: React.CSSProperties }) => {
  const userMessages1 = [`Summarize this article`, `Which apples are analyzed?`, `List key take-aways`];
  const aiMessages1 = [
    `Your document is a review article titled "Apple phytochemicals and their health benefits" published ...`,
    `Looks like authors looked into a large list of apples, including Fuji, Red Delicious, Gala, Libery, Northern Spy ...`,
    `Sure! Firstly, apples may contribute to decreased risk of chronic diseases such as cardiovascular disease ...`,
  ];

  const userMessages2 = [`How much is participation?`, `Will we have any projects?`, `What is it about?`];
  const aiMessages2 = [
    `From you syllabus, I can see that Preparation, Participation & Reflection accounts for 35% of your grade.`,
    `Yes, the professor mentioned that you will have one group project at the end of the semester.`,
    `Your team will analyze a negotiation currently taking place and being reported in the news and create ...`,
  ];

  const messages = [
    userMessages1.map((message, index) => [{ from: "user", content: message } as { from: "ai" | "user"; content: string }, { from: "ai", content: aiMessages1[index] } as { from: "ai" | "user"; content: string }]).flat(),
    userMessages2.map((message, index) => [{ from: "user", content: message } as { from: "ai" | "user"; content: string }, { from: "ai", content: aiMessages2[index] } as { from: "ai" | "user"; content: string }]).flat(),
  ];

  const messagesRef = useRef<HTMLDivElement>(null);
  const [translateY, setTranslateY] = useState(messagesRef.current?.clientHeight || 500);
  function MoveChatToMessage(number: number) {
    if (number === 0) return setTranslateY(messagesRef.current?.clientHeight || 0);
    let height = messagesRef.current?.clientHeight || 0;
    for (let i = 0; i < number; i++) {
      height -= messagesRef.current?.children[i].clientHeight || 0;
    }
    setTranslateY(height);
  }
  function SendMessage(number: number) {
    // Remove message from chat box
    setCurrentTypedMessage(null);
    // Show message in chat
    MoveChatToMessage(number + 1);
    setTimeout(() => {
      // Show response in chat
      MoveChatToMessage(number + 2);
      setTimeout(() => {
        // Start typing new message
        if (number + 2 === messages[currentMessagesGroup].length) {
          setCurrentTypedMessage(0);
          MoveChatToMessage(0);
          setCurrentMessagesGroup((currentMessagesGroup + 1) % messages.length);
          return;
        }
        setCurrentTypedMessage(number + 2);
      }, 2500);
    }, 1500);
  }

  const [animate, setAnimate] = useState(false);
  const [currentMessagesGroup, setCurrentMessagesGroup] = useState<number>(1);
  const [currentTypedMessage, setCurrentTypedMessage] = useState<number | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
      setTimeout(() => {
        setCurrentMessagesGroup(0);
        setCurrentTypedMessage(0);
      }, 300);
    }, 2000);
  }, []);

  return (
    <div style={style} className={`${className} w-[270px] aspect-[0.6] flex flex-col items-center justify-end bg-systemGray-200/50 backdrop-blur-lg rounded-3xl p-4 select-none overflow-clip border border-background`}>
      <div className="text-mini font-semibold text-center max-w-full -mt-2 text-foreground">
        Coursable <span className="text-transparent bg-clip-text bg-gradient-to-tr from-brand-500 to-brand-400">Projects</span>
      </div>
      <div className="w-full h-full flex items-end justify-end overflow-hidden">
        <div
          style={{
            transform: animate ? `translateY(${translateY}px)` : "0px",
          }}
          className="w-full flex flex-col duration-300"
          ref={messagesRef}
        >
          {messages[currentMessagesGroup].map((message, index) => (
            <MessageBox key={index} from={message.from}>
              {message.content}
            </MessageBox>
          ))}
        </div>
      </div>
      <div className="w-full text-mini bg-background text-foreground py-1 px-2 rounded-md flex items-center shadow">
        {currentTypedMessage === null ? (
          <TypeAnimation key={-1} className="w-full" speed={60} sequence={[""]} />
        ) : currentTypedMessage === 0 ? (
          <TypeAnimation key={0} className="w-full" speed={60} sequence={[messages[currentMessagesGroup][0].content, 300, () => SendMessage(0)]} />
        ) : currentTypedMessage === 2 ? (
          <TypeAnimation key={1} className="w-full" speed={60} sequence={[messages[currentMessagesGroup][2].content, 300, () => SendMessage(2)]} />
        ) : currentTypedMessage === 4 ? (
          <TypeAnimation key={2} className="w-full" speed={60} sequence={[messages[currentMessagesGroup][4].content, 300, () => SendMessage(4)]} />
        ) : null}

        {CoursableIcons.Send("shrink-0 text-systemGray-400")}
      </div>
    </div>
  );
};

const MessageBox = ({ from, children }: { from: "user" | "ai"; children: string }) => {
  return (
    <div className={`w-full flex flex-col ${from === "user" ? "items-end justify-end" : "items-start justify-start"}`}>
      <div
        className={`${
          from === "user" ? "bg-background text-right text-foreground rounded-bl-xl" : "bg-gradient-to-tr from-brand-500 to-brand-300 text-white text-left rounded-br-xl"
        } rounded-t-xl px-2 py-1 leading-4 line-clamp-4 text-mini max-w-[80%] shadow`}
      >
        {children}
      </div>
      <div className="w-full h-2" />
    </div>
  );
};

const Courses = ({ style, className }: { className?: string; style?: React.CSSProperties }) => {
  const topics = [
    { title: "Introduction to Product Management", description: "In this topic, you will learn the basics of product management and understand the role and responsibilities of a product manager." },
    {
      title: "Market Research and Customer Analysis",
      description: "This topic will cover the importance of market research and customer analysis in product management, and teach you how to gather and analyze data to make informed decisions.",
    },
    { title: "Defining Product Strategy", description: "In this topic, you will learn how to define a product strategy to align with business goals, target market, and customer needs." },
    { title: "User Experience Design", description: "This topic covers the process of product roadmapping, prioritizing features, and creating a roadmap to guide the product development process." },
  ];

  return (
    <div style={style} className={`${className} bg-systemGray-200/50 backdrop-blur-lg rounded-xl border border-background p-4 pointer-events-none select-none`}>
      <div className="flex w-full h-full gap-2">
        <div className="w-2/5 flex flex-col gap-1">
          <div className="font-semibold px-2 text-brand-500 text-sm">Courses</div>
          <SidemenuItem title="Negotiation Skills Mastery" />
          <SidemenuItem title="Product Management 101" selected />
          <SidemenuItem title="Introduction to political science" />
          <SidemenuItem title="Molecular Biology for High School Students" />
          <SidemenuItem title="Advanced dynamic programming" />
        </div>
        <div className="w-3/5 flex flex-col items-start justify-start gap-2 px-2 border-l-2 border-systemGray-200">
          <div className="w-full flex items-center justify-between gap-2">
            <div className="font-semibold text-left shrink-0 text-foreground">Product Management 101</div>
            <div className="w-1/4 rounded-full bg-systemGray-300 h-0.5 relative">
              <div className="w-1/3 rounded-full absolute left-0 top-0 bottom-0 bg-gradient-to-r from-brand-500 to-brand-400" />
            </div>
          </div>
          {topics.map((topic, index) => (
            <Topic key={index} index={index + 1} title={topic.title} description={topic.description} />
          ))}
          <div className="bg-gradient-to-tr from-brand-500 to-brand-300 px-10 py-0.5 text-white text-xs mt-auto rounded-full shadow m-2">Take Quiz</div>
        </div>
      </div>
    </div>
  );
};

const SidemenuItem = ({ title, selected }: { title: string; selected?: boolean }) => {
  return (
    <div className={`w-full flex flex-col items-start justify-start rounded-md px-2 py-1  ${!!selected && "shadow bg-background"}`}>
      <div className="text-mini line-clamp-1 text-foreground">{title}</div>
      <div className="text-systemGray-400 text-micro">Completion: 33%</div>
    </div>
  );
};

const Topic = ({ index, title, description }: { index: number; title: string; description: string }) => {
  const materials = useMemo(
    () => [
      Math.random() > 0.5 ? <VideoMaterial className="!text-micro" /> : <ArticleMaterial className="!text-micro" />,
      Math.random() > 0.5 ? <VideoMaterial className="!text-micro" /> : <ArticleMaterial className="!text-micro" />,
      Math.random() > 0.5 ? <PaperMaterial className="!text-micro" /> : <ArticleMaterial className="!text-micro" />,
    ],
    [title]
  );

  return (
    <div className="w-full flex flex-col items-start justify-start gap-1 px-2 py-1 bg-background rounded-lg shadow">
      <div className="text-mini line-clamp-1 text-foreground">
        {index}. {title}
      </div>
      <div className="text-systemGray-400 text-micro line-clamp-2">{description}</div>
      <div className="flex gap-1 items-baseline justify-center -mt-1">
        <span className="text-foreground text-xs mr-1">Materials:</span>
        {materials.map((material, i) => (
          <span key={i}>{material}</span>
        ))}
      </div>
    </div>
  );
};
