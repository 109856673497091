import { cn } from "../../utils/UtilityMethods";

const CoursableLogo = ({ onClick, size = 8, wide, className }: { onClick?: () => void; size?: number; wide?: boolean; className?: string }) => {
  return (
    <div onClick={onClick} className={cn("flex items-center justify-center gap-1", onClick && "cursor-pointer", className)}>
      <Logo
        style={{
          width: `${size / 4}rem`,
          height: `${size / 4}rem`,
        }}
      />
      {wide && (
        <div
          className="text-foreground"
          style={{
            fontFamily: "'Open Sans', sans-serif",
            fontSize: `${size / 4.5}rem`,
          }}
        >
          Coursable
        </div>
      )}
    </div>
  );
};

const Logo = ({ style, className }: { style?: React.CSSProperties; className?: string }) => {
  return (
    <svg style={style} className={`shrink-0 max-w-full max-h-full aspect-square ${className && className}`} width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M781.402 510.346C781.402 643.234 673.612 750.961 540.645 750.961C407.679 750.961 299.888 643.234 299.888 510.346C299.888 377.458 407.679 269.731 540.645 269.731C673.612 269.731 781.402 377.458 781.402 510.346ZM540.645 638.333C611.372 638.333 668.708 581.031 668.708 510.346C668.708 439.661 611.372 382.359 540.645 382.359C469.918 382.359 412.583 439.661 412.583 510.346C412.583 581.031 469.918 638.333 540.645 638.333Z"
        fill="url(#paint0_linear_121_7)"
        shapeRendering="geometricPrecision"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.192 562.433C333.572 691.458 460.879 773.606 590.383 746.096C700.862 722.627 777.401 626.901 781.001 518.94C782.046 487.607 751.185 467.69 720.502 474.208C690.251 480.635 672.121 511.083 664.763 541.105C653.435 587.327 616.649 625.371 566.953 635.929C498.329 650.506 430.864 607.197 415.429 539.015L305.192 562.433Z"
        fill="url(#paint1_linear_121_7)"
        shapeRendering="geometricPrecision"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M542.353 0.105317C700.184 0.105317 841.343 71.4433 935.309 183.616C965.747 219.952 951.341 273.107 910.277 296.801C869.629 320.255 818.276 305.553 785.288 272.184C723.377 209.561 637.399 170.754 542.353 170.754C353.748 170.754 200.853 323.559 200.853 512.053C200.853 700.547 353.748 853.351 542.353 853.351C637.399 853.351 723.377 814.545 785.288 751.921C818.276 718.553 869.629 703.85 910.277 727.305C951.341 750.999 965.747 804.154 935.309 840.489C841.343 952.662 700.184 1024 542.353 1024C259.445 1024 30.1037 794.793 30.1037 512.053C30.1037 229.312 259.445 0.105317 542.353 0.105317Z"
        fill="url(#paint2_linear_121_7)"
        shapeRendering="geometricPrecision"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M858.397 109.141C743.148 18.9447 590.233 -21.5031 435.852 11.2924C188.844 63.7645 21.5742 285.998 30.328 529.812C32.0294 577.2 78.3839 607.125 124.794 597.266C170.67 587.521 198.929 542.311 201.173 495.491C208.417 344.378 316.329 211.144 471.353 178.212C564.322 158.463 656.493 178.556 730.079 226.947C769.289 252.731 822.578 256.442 857.459 225.054C892.696 193.345 895.729 138.358 858.397 109.141Z"
        fill="url(#paint3_linear_121_7)"
        shapeRendering="geometricPrecision"
      />
      <defs>
        <linearGradient id="paint0_linear_121_7" x1="438" y1="845.5" x2="624.589" y2="493.547" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E04848" />
          <stop offset="1" stopColor="#A51C1C" />
        </linearGradient>
        <linearGradient id="paint1_linear_121_7" x1="425" y1="446.5" x2="725" y2="527" gradientUnits="userSpaceOnUse">
          <stop offset="0.00127836" stopColor="#A51C1C" stopOpacity="0" />
          <stop offset="1" stopColor="#E04848" />
        </linearGradient>
        <linearGradient id="paint2_linear_121_7" x1="575.5" y1="491.5" x2="812.683" y2="948.28" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A51C1C" />
          <stop offset="1" stopColor="#E04848" />
        </linearGradient>
        <linearGradient id="paint3_linear_121_7" x1="918" y1="300" x2="124" y2="599" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A51C1C" stopOpacity="0" />
          <stop offset="0.358407" stopColor="#BD2E2E" />
          <stop offset="0.895155" stopColor="#E04848" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CoursableLogo;
