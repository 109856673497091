import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";
import { ProjectFile } from "../../../../../backend/Projects/types";
import { DownloadVideoTranscript } from "../../../../../backend/Projects/ProjectLinks";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import InputField from "../../../../elements/InputField";
import { useVirtualizer } from "@tanstack/react-virtual";
import Button from "../../../../elements/Button";

const YoutubeViewer = ({ file, videoID }: { file: ProjectFile; videoID: string }) => {
  const youtubePlayer = useRef<YouTubePlayer>();

  const [transcript, setTranscript] = useState<TranscriptLine[]>();
  const interval = useRef<NodeJS.Timeout>();
  const [currentLineIndex, setCurrentLineIndex] = useState<number | null>(null);

  async function FetchVideoTranscript() {
    try {
      const raw = await DownloadVideoTranscript(file);
      const parsed = ParseTranscript(raw);
      setTranscript(parsed);
    } catch (error) {
      console.error(error);
    }
  }

  function SeekTo(line: TranscriptLine) {
    setCurrentLineIndex(transcript?.findIndex((l) => l === line) ?? 0);
    youtubePlayer.current?.playVideo();
    youtubePlayer.current?.seekTo(line.seconds, true);
  }

  function OnStateChange(event: YouTubeEvent<number>) {
    if (event.data === 1) OnPlay();
    else ClearInterval();
  }

  function OnPlay() {
    interval.current = setInterval(() => {
      const currentTime = youtubePlayer.current.getCurrentTime();
      const nextLineIndex = transcript?.findIndex((line) => line.seconds > currentTime) ?? 0;
      const currentLineIndex = nextLineIndex > 0 ? nextLineIndex - 1 : nextLineIndex;
      setCurrentLineIndex(currentLineIndex ?? null);
    }, 200);
  }

  function ClearInterval() {
    if (!interval.current) return;
    clearInterval(interval.current);
  }

  useEffect(() => {
    FetchVideoTranscript();
  }, [videoID]);

  useEffect(() => {
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  return (
    <div className="w-full h-full flex flex-col overflow-auto">
      <YouTube
        opts={{
          playerVars: {
            rel: 0,
          },
        }}
        onStateChange={OnStateChange}
        onReady={(event: any) => (youtubePlayer.current = event.target)}
        videoId={videoID}
        className="relative w-full h-0 pb-[56.25%] overflow-hidden"
        iframeClassName="w-full h-full absolute top-0 left-0"
      />
      {transcript && <TranscriptView transcript={transcript} SeekTo={SeekTo} currentLineIndex={currentLineIndex} />}
    </div>
  );
};

export default YoutubeViewer;

const TranscriptView = ({ transcript, SeekTo, currentLineIndex }: { transcript: TranscriptLine[]; SeekTo: (line: TranscriptLine) => void; currentLineIndex: number | null }) => {
  const [search, setSearch] = useState("");
  const [synced, setSynced] = useState(true);
  const isAutoScroll = useRef(false);

  const [filteredTranscript, setFilteredTrans] = useState<TranscriptLine[]>([]);
  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: filteredTranscript.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 28,
  });

  const items = virtualizer.getVirtualItems();

  function ScrollToCurrentLine(sync: boolean = false) {
    if (filteredTranscript.length === 0) return;
    virtualizer.scrollToIndex(currentLineIndex ?? 0, { align: "center" });
    if (sync) setTimeout(() => setSynced(true), 100);
  }

  useEffect(() => {
    const handleScroll = () => {
      if (!isAutoScroll.current) setSynced(false);
    };
    parentRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      parentRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const isCurrentLineInView = items.slice(1, items.length - 4).some((item) => item.index === currentLineIndex);
    if (!isCurrentLineInView && synced) {
      isAutoScroll.current = true;
      ScrollToCurrentLine();
      setTimeout(() => (isAutoScroll.current = false), 100);
    }
  }, [currentLineIndex]);

  useEffect(() => {
    setFilteredTrans(transcript.filter((line) => SearchTranscript(line, search)));
  }, [transcript, search]);

  return (
    <div className="w-full flex flex-col h-full overflow-auto">
      <div className="w-full flex items-center p-4 border-y gap-4">
        <div className="w-full flex items-center justify-start gap-2 font-semibold text-lg truncate">{CoursableIcons.Text()} Transcript</div>
        {!synced && (
          <Button onClick={() => ScrollToCurrentLine(true)} variant="outline" size="icon" className="shrink-0">
            {CoursableIcons.Sync()}
          </Button>
        )}
        <InputField
          className="!w-1/5 min-w-[100px] focus-within:!w-1/2 shrink-0"
          value={search}
          onValueChange={setSearch}
          placeholder="Search"
          icon={
            search.length > 0 ? (
              <button className="flex-centered text-systemGray-400 hover:text-foreground active:text-systemGray-500 duration-100" onClick={() => setSearch("")}>
                {CoursableIcons.XmarkFill()}
              </button>
            ) : (
              CoursableIcons.Search()
            )
          }
        />
      </div>
      <div ref={parentRef} className="w-full h-full overflow-auto">
        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
          }}
          className="w-full relative"
        >
          <div
            className="flex-started flex-col gap-1 p-4"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
          >
            {items.map((virtualRow) => (
              <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                <TranscriptLineView
                  key={virtualRow.key}
                  line={filteredTranscript[virtualRow.index]}
                  searchTerm={search}
                  onClick={() => {
                    setSynced(true);
                    SeekTo(filteredTranscript[virtualRow.index]);
                  }}
                  isCurrentLine={currentLineIndex === null ? false : filteredTranscript[virtualRow.index] === transcript[currentLineIndex]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TranscriptLineView = ({ line, searchTerm, isCurrentLine, onClick }: { line: TranscriptLine; searchTerm: string; isCurrentLine: boolean; onClick: () => void }) => {
  const [focus, setFocus] = useState(false);
  const [highlightedText, setHighlightedText] = useState<string | JSX.Element>();

  const getHighlightedText = useCallback(() => {
    if (!searchTerm) return line.text;
    if (searchTerm.length < 2) return line.text;

    const searchIndex = line.text.toLowerCase().indexOf(searchTerm.toLowerCase());
    const searchLength = searchTerm.length;

    const text = line.text.substring(0, searchIndex);
    const foundText = line.text.substring(searchIndex, searchIndex + searchLength);
    const rest = line.text.substring(searchIndex + searchLength);
    return (
      <span>
        {text}
        <span className="text-brand-500 font-semibold">{foundText}</span>
        {rest}
      </span>
    );
  }, [line, searchTerm]);

  useEffect(() => {
    setHighlightedText(getHighlightedText());
  }, [line, searchTerm]);

  useEffect(() => {
    setFocus(isCurrentLine);
  }, [isCurrentLine]);

  return (
    <button
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => {
        if (isCurrentLine) return;
        setFocus(false);
      }}
      onClick={onClick}
      className={`flex gap-2 items-baseline text-sm group`}
    >
      <span className={`font-mono text-brand-500 px-2 py-1 rounded-md ${focus ? "bg-brand-100" : "bg-brand-50"} group-active:bg-brand-200 duration-100`}>{line.timeText}</span>
      <span className={`${focus ? "text-foreground font-semibold" : "text-systemGray-500"} group-active:text-systemGray-700 duration-100 text-left`}>{highlightedText}</span>
    </button>
  );
};

function ParseTranscript(transcript: string): TranscriptLine[] {
  try {
    const regex = /\[\d{2,}:\d{2}\]/;
    const lines = transcript.split("Video transcript:\n\n")[1].split("\n");
    const result = lines.map((line) => {
      const time = line.match(regex)?.[0] ?? "";
      const text = line.replace(regex, "");

      const timeSplit = time.split(":");
      let hours = 0;
      let minutes = parseInt(timeSplit[0].slice(1));
      if (minutes >= 60) {
        hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
      }
      const seconds = parseInt(timeSplit[1].slice(0, -1));
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      const timeText = `${hours > 0 ? `${hours.toString().padStart(2, "0")}:` : ""}${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      return { text, timeText: timeText, seconds: totalSeconds };
    });

    return result;
  } catch (error) {
    return [];
  }
}

function SearchTranscript(line: TranscriptLine, searchTerm: string): boolean {
  if (!searchTerm) return true;
  if (searchTerm.length < 2) return true;
  return line.text.toLowerCase().includes(searchTerm.toLowerCase());
}

interface TranscriptLine {
  text: string;
  timeText: string;
  seconds: number;
}
