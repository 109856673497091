import { MouseEvent } from "react";
import CTAButton from "../../../elements/CTAButton";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { useNotifications } from "../../../../utils/NotificationsContext";
import { useAuth } from "../../../../firebase/AuthContext";
import { CoursablePlan } from "../../../../utils/CoursablePlan";
import { UpgradePopup } from "../../../elements/UpgradePopup";
import cookies from "../../../../utils/CookiesManager";
import { GAEvent, GALogEvent } from "../../../../firebase/GoogleAnalytics";

const CourseGenerator = ({ className }: { className?: string }) => {
  const [prompt, setPrompt] = useState<string>("");
  const [animateError, setAnimateError] = useState<boolean>(false);
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const statsClaims = currentUser?.customClaims.stats;
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);

  const { sendError } = useNotifications();

  function ClickLetsGo() {
    if (!prompt) {
      setAnimateError(true);
      return;
    }
    if (!currentUser) {
      cookies.set("course-prompt", prompt, { expires: new Date(Date.now() + 1000 * 60 * 60) });
      navigate("/signup");
      return;
    }

    if (!english.test(prompt)) {
      setAnimateError(true);
      sendError("Please use only english letters, numbers, and punctuation.");
      return;
    }

    if (plan.courseLimitMonthly && (statsClaims?.coursesGeneratedCurrentCycle ?? 0) >= plan.courseLimitMonthly) {
      GALogEvent(GAEvent.Sales.upgradePopupShown, {
        source: "courses_generated_limit",
      });
      setShowUpgradePopup(true);
      return;
    }

    return navigate("/app/courses/generate", { state: { prompt } });
  }

  function HandleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") ClickLetsGo();
  }

  return (
    <div className={`${className} w-full flex flex-col gap-10 items-center justify-center px-4`} onKeyDown={HandleKeyPress}>
      <PromptInputField animateError={animateError} setAnimateError={setAnimateError} prompt={prompt} setPrompt={setPrompt} />
      <CTAButton className="text-lg md:text-xl" onClick={ClickLetsGo}>
        Let's go
      </CTAButton>
      <UpgradePopup
        isOpen={showUpgradePopup}
        Close={() => setShowUpgradePopup(false)}
        highlightCourses
        header={
          <>
            You have generated {statsClaims?.coursesGeneratedCurrentCycle ?? 0} out of {plan.courseLimitMonthly} courses available this month with the <span className={`font-bold text-brandBlue-500`}>{plan.title}</span> plan.
          </>
        }
      />
    </div>
  );
};

const english = /^[A-Za-z0-9\s,!?.()-]*$/;

export default CourseGenerator;

interface Props {
  prompt: string;
  setPrompt: (prompt: string) => void;
  animateError: boolean;
  setAnimateError: (animateError: boolean) => void;
}

const PromptInputField = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isMouseIn, setIsMouseIn] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { sendError } = useNotifications();

  const onMouseMove = (event: MouseEvent) => {
    const rect = ref?.current?.getBoundingClientRect();
    if (!rect) return;
    setIsMouseIn(true);

    const posX = Math.abs(event.clientX - rect.left) - rect.width * 0.5;
    const posY = Math.abs(event.clientY - rect.top) - rect.height * 0.5;

    setOffset({ x: posX * 0.025, y: posY * 0.1 });
  };
  const onMouseLeave = (event: MouseEvent) => {
    setIsMouseIn(false);
    setOffset({ x: 0, y: 0 });
  };

  function TypePrompt(v: string) {
    const maxLength = 150;
    if (v.length > maxLength) {
      props.setPrompt(v.slice(0, maxLength));

      sendError(`Please keep your prompt under ${maxLength} characters.`);
      props.setAnimateError(true);
      return;
    }

    props.setPrompt(v);
  }
  return (
    <div
      className={`${props.animateError && "animate-error"} bg-systemGray-100 w-full rounded-full grid grid-cols-[auto_1fr] shadow-xl-c hover:shadow-2xl-c focus-within:shadow-2xl-c focus-within:scale-[1.02] relative`}
      onMouseMove={(e) => onMouseMove(e)}
      onMouseLeave={(e) => onMouseLeave(e)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onAnimationEnd={() => props.setAnimateError(false)}
      ref={ref}
      style={{
        transform: `translate(${offset.x}px, ${offset.y}px) ${isMouseIn || isFocused ? "scale(1.02)" : "scale(1)"}`,
        transition: `transform ${isMouseIn ? "0.1s" : "0.3s"} ease-out, box-shadow ${isMouseIn ? "0.1s" : "0.3s"} ease-out`,
      }}
    >
      <div className="bg-brand-500 rounded-l-full flex items-center py-3 md:py-4 pl-4 md:pl-6 pr-2 text-lg md:text-2xl font-semibold text-white shadow-lg shadow-brand-200">Teach me</div>
      <input className="w-full bg-transparent px-2 text-lg md:text-2xl outline-none peer" type="text" value={props.prompt} onChange={(e) => TypePrompt(e.currentTarget.value)} />
      <div className={`absolute left-0 right-0 grid grid-cols-[auto_1fr] ${props.prompt === "" ? "opacity-100" : "opacity-0"} peer-focus:opacity-0 pointer-events-none`}>
        <div className="py-3 md:py-4 pl-4 md:pl-6 pr-2 text-lg md:text-2xl opacity-0">Teach me</div>
        <TypeAnimation deletionSpeed={60} speed={40} sequence={placeholders.map((text) => [text, delay]).flat()} repeat={Infinity} className="px-2 py-3 md:py-4 text-lg md:text-2xl text-systemGray-500" />
      </div>
    </div>
  );
};

const delay = 1500;
const placeholders = [
  "project management",
  "data structures",
  "how to play guitar",
  "computer vision",
  "how to meditate",
  "web development",
  "software engineering",
  "calculus",
  "linear algebra",
  "basic statistics",
  "how to cook",
  "how to play piano",
  "machine learning",
  "snowboarding",
  "chess",
  "algorithmic programming",
  "database systems",
  "deep learning",
  "natural language processing",
  "cybersecurity",
  "cloud computing",
  "mobile app development",
  "quantum physics",
  "advanced statistics",
  "digital marketing",
  "game design",
  "data science",
  "yoga for beginners",
  "how to play drums",
  "skiing",
  "how to swim",
  "painting with watercolors",
  "sculpture making",
  "photography",
  "gardening basics",
];
