import { useEffect, useState } from "react";
import { useNotifications } from "../../../utils/NotificationsContext";
import { FaCopy } from "react-icons/fa";
import { GetEnterpriseAPIKeys } from "../../../firebase/FirestoreManager";
import { useAuth } from "../../../firebase/AuthContext";
import { Navigate } from "react-router-dom";
import { OpenStripePortal } from "../../../backend/Payment";
import { EnterpriseHealthCheck, EnterpriseUserResetKey } from "../../../backend/Enterprise";
import Button from "../../elements/Button";
import ConfirmationPopup from "../../elements/ConfirmationPopup";

const SettingsEnterprise = () => {
  const { currentUser } = useAuth();

  if (!currentUser?.customClaims.enterprise?.isEnterpriseUser) return <Navigate to="/app/settings/profile" />;

  return (
    <div className="w-full border-l flex flex-col gap-8 p-4 md:p-8">
      <div className="">
        <div className="text-2xl md:text-4xl font-semibold mb-2">Enterprise</div>
        <div className="text-sm text-systemGray-500">Manage your API access, payment methods, and invoices.</div>
      </div>
      <ManageEnterprise />
      <Access />
    </div>
  );
};

export default SettingsEnterprise;

const ManageEnterprise = () => {
  const [loading, setLoading] = useState(false);

  const { sendError } = useNotifications();

  async function ClickManage() {
    setLoading(true);
    try {
      await OpenStripePortal();
    } catch (error: any) {
      sendError("Oops, something went wrong. Please try again later.");
      console.error(error);
    }
    setLoading(false);
  }

  return (
    <div className="border-y py-4 flex items-center justify-between">
      <div>
        <div>Enterprise API</div>
        <div className="text-systemGray-500 text-mini md:text-sm">Manage billing, check usage, and view invoices.</div>
      </div>
      <Button loading={loading} onClick={ClickManage} disabled={loading}>
        Manage
      </Button>
    </div>
  );
};

const Access = () => {
  const [live, setLive] = useState<boolean | undefined>(undefined);
  const [keys, setKeys] = useState<(string | null)[]>([]);

  const [openResetDev, setOpenResetDev] = useState(false);
  const [openResetProd, setOpenResetProd] = useState(false);

  const { sendSuccess } = useNotifications();

  async function ResetKey() {
    const keyType = openResetDev ? "dev" : "prod";
    await EnterpriseUserResetKey(keyType);
    sendSuccess(`Successfully reset ${keyType} key.`);
    Close();
  }

  function Close() {
    setOpenResetDev(false);
    setOpenResetProd(false);
  }

  async function APIHealthCheck() {
    try {
      const isLive = await EnterpriseHealthCheck();
      setLive(isLive);
    } catch (error) {
      console.log(error);
      setLive(false);
    }
  }

  useEffect(() => {
    async function GetKeys() {
      const keys = await GetEnterpriseAPIKeys();
      setKeys(keys);
    }
    GetKeys();
    APIHealthCheck();
  }, [openResetDev, openResetProd]);

  return (
    <div className="flex flex-col w-full">
      <div className="w-full text-2xl font-semibold pb-4">API details</div>
      <div className="w-full flex flex-col gap-2">
        <div className="flex gap-2 w-full items-center">
          <div className="w-2/5 md:w-1/5 text-systemGray-500">Server status</div>
          <div className={`w-3/5 md:w-4/5 ${live === undefined ? "" : live ? "text-green-700 dark:text-green-500" : "text-red-700 dark:text-red-500"} font-semibold`}>
            {live === undefined ? <div className="h-5 w-16 rounded bg-systemGray-200 animate-pulse" /> : live ? "Live" : "Down"}
            {live !== undefined && !live && (
              <a href="mailto:support@coursable.io" className="ml-2 text-red-700 hover:text-red-600 duration-100 underline">
                (Contact support)
              </a>
            )}
          </div>
        </div>
        <div className="flex flex-row gap-2 w-full items-start md:items-center">
          <div className="w-2/5 md:w-1/5 text-systemGray-500">Documentation</div>
          <a className="w-3/5 md:w-4/5 ml-auto md:ml-0 underline text-ellipsis overflow-clip text-brand-400 hover:text-brand-600 duration-100" href="https://coursable.io/enterprise/docs" target="_blank" rel="noreferrer">
            https://coursable.io/enterprise/docs
          </a>
        </div>
        <div className="flex flex-col md:flex-row gap-2 w-full items-start md:items-center">
          <div className="w-full md:w-1/5 text-systemGray-500">Development key</div>
          <div className="w-full md:w-4/5 flex gap-2 justify-between items-center">
            {keys[0] === null ? <div className="text-systemGray-500">None</div> : <CodeField value={keys[0]} />}
            <ResetButton onClick={() => setOpenResetDev(true)}>Reset</ResetButton>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 w-full items-start md:items-center">
          <div className="w-full md:w-1/5 text-systemGray-500">Production key</div>
          <div className="w-full md:w-4/5 flex gap-2 justify-between items-center">
            {keys[1] === null ? <div className="text-systemGray-500">None</div> : <CodeField value={keys[1]} />}
            <ResetButton onClick={() => setOpenResetProd(true)}>Reset</ResetButton>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        open={openResetDev || openResetProd}
        Close={Close}
        onConfirm={ResetKey}
        title={`Reset your ${openResetDev ? "development" : "production"} key?`}
        highlightInTitle={openResetDev ? "development" : "production"}
        description="Current key will be deactiaved and new one will be generated instead."
        confirmText="Reset key"
      />
    </div>
  );
};

const CodeField = ({ value }: { value?: string }) => {
  const [copied, setCopied] = useState(false);

  if (!value) return <div className="bg-systemGray-200 rounded-lg h-6 border animate-pulse w-60" />;

  const hiddenDots = Array(Math.max(0, Math.min(value.length - 10, window.innerWidth < 768 ? 10 : 50)))
    .fill(".")
    .reduce((acc, curr) => acc + curr, "");

  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}
      className="bg-systemGray-100 rounded-lg flex gap-2 items-center px-2 py-0.5 text-sm border active:bg-systemGray-200 duration-100 hover:shadow relative text-mini text-systemGray-500"
    >
      <span className={`font-mono ${copied ? "opacity-0" : "opacity-100"} duration-300 text-left`}>{value.slice(0, 6) + hiddenDots + value.slice(value.length - 4)}</span>
      <FaCopy className="text-systemGray-400 text-micro" />
      <div className={`duration-300 inset-0 py-0.5 absolute ${copied ? "opacity-100" : "opacity-0"}`}>Copied</div>
    </button>
  );
};

const ResetButton = ({ children, onClick, isEnabled, className }: { children: React.ReactNode; onClick?: () => void; isEnabled?: boolean; className?: string }) => {
  return (
    <button
      disabled={isEnabled === undefined ? false : !isEnabled}
      onClick={onClick}
      className={`flex items-center gap-2 text-mini md:text-sm font-semibold border border-systemGray-300 text-systemGray-500 py-0.5 px-2 my-auto rounded-md duration-100 disabled:opacity-50 disabled:pointer-events-none hover:bg-red-600 hover:border-red-600 hover:text-white active:bg-red-400 active:border-red-400 active:text-white ${className}`}
    >
      {children}
    </button>
  );
};
