import { TypeAnimation } from "react-type-animation";
import FollowMouseBlock from "../../../../elements/FollowMouseBlock";
import { useState } from "react";
import { IoCheckmark as CorrectIcon, IoClose as WrongIcon } from "react-icons/io5";

const QuizGenerationBlock = () => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [nIndexShown, setNIndexShown] = useState(-1);

  const questions = [question1, question2, question3, question4];
  const question = questions[questionIndex];

  function ChangeQuestion() {
    setQuestionIndex((prev) => (prev + 1) % questions.length);
    setSelectedIndex(null);
  }

  function ShowOptions() {
    const interval = setInterval(() => {
      setNIndexShown((prev) => {
        if (prev >= question.options.length) {
          clearInterval(interval);
          return prev;
        }

        return prev + 1;
      });
    }, 200);
  }

  function HideOptions() {
    const interval = setInterval(() => {
      setNIndexShown((prev) => {
        if (prev < 0) {
          clearInterval(interval);
          return prev;
        }

        return prev - 1;
      });
    }, 100);
  }

  return (
    <div className="w-full flex items-center justify-end select-none">
      <FollowMouseBlock sensitivity={0.2} className="w-full">
        <div className="p-4 flex flex-col gap-1 duration-300 w-full hover:bg-background hover:shadow-lg hover:scale-[1.02] rounded-lg">
          <div className="text-sm font-semibold text-brand-500 px-2">question</div>
          <div className="font-semibold w-full text-sm sm:text-base h-6 text-foreground">
            <TypeAnimation
              className="truncate"
              cursor={false}
              speed={60}
              deletionSpeed={80}
              repeat={Infinity}
              sequence={[
                questions[0].question,
                () => ShowOptions(),
                10000,
                () => HideOptions(),
                "",
                250,
                () => ChangeQuestion(),
                250,
                questions[1].question,
                () => ShowOptions(),
                10000,
                () => HideOptions(),
                "",
                250,
                () => ChangeQuestion(),
                250,
                questions[2].question,
                () => ShowOptions(),
                10000,
                () => HideOptions(),
                "",
                250,
                () => ChangeQuestion(),
                250,
                questions[3].question,
                () => ShowOptions(),
                10000,
                () => HideOptions(),
                "",
                250,
                () => ChangeQuestion(),
                250,
              ]}
            />
          </div>
          <div className="text-systemGray-400 text-sm px-2">options</div>
          <div className="flex flex-col gap-1 w-full">
            {question.options.map((text, index) => {
              return (
                <Option key={index} className={`${index <= nIndexShown ? "opacity-100 scale-100" : "opacity-0 scale-105"}`} text={text} index={index} correctIndex={question.corret} selectedIndex={selectedIndex} Select={setSelectedIndex} />
              );
            })}
          </div>
        </div>
      </FollowMouseBlock>
    </div>
  );
};

export default QuizGenerationBlock;

const Option = ({ text, index, correctIndex, selectedIndex, Select, className }: { text: string; index: number; correctIndex: number; selectedIndex: number | null; Select: (index: number) => void; className?: string }) => {
  const isCorrect = index === correctIndex;
  const isSelected = index === selectedIndex;

  return (
    <button
      onClick={() => Select(index)}
      key={index}
      className={`${className} flex items-center gap-2 px-2 py-1 rounded-md group duration-300 border ${
        isSelected ? (isCorrect ? "bg-green-100 dark:bg-green-950 border-green-500" : "bg-red-100 dark:bg-red-950 border-red-500") : "hover:bg-systemGray-200 border-transparent"
      }`}
    >
      <div className={`duration-300 flex items-center justify-center aspect-square rounded-md border h-5 ${isSelected ? (isCorrect ? "border-green-500" : "border-red-500") : "border-systemGray-400 group-hover:border-systemGray-500"}`}>
        {isSelected && (isCorrect ? <CorrectIcon className="text-lg text-green-500 mx-auto" /> : <WrongIcon className="text-lg text-red-500" />)}
      </div>
      <div className={`${isSelected ? "text-foreground" : "text-systemGray-500 group-hover:text-foreground"} duration-100`}>{text}</div>
    </button>
  );
};
const question1 = {
  question: "What aspect of music focuses on interacting chords?",
  options: ["Rhythm", "Harmony", "Melody", "Counterpoint"],
  corret: 1,
};
const question2 = {
  question: "Which language is used to build web pages?",
  options: ["C#", "Swift", "Python", "HTML"],
  corret: 3,
};
const question3 = {
  question: "Which enzyme is responsible for unwinding DNA?",
  options: ["Polymerase", "Helicase", "Ligase", "Topoisomerase"],
  corret: 1,
};
const question4 = {
  question: "What is the first step in project closure?",
  options: ["Ensure all deliverables are met", "Gather feedback", "Prepare final reports", "Evaluate success"],
  corret: 0,
};
