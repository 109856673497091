import { useEffect, useRef, useState } from "react";
import { AssistantAnnotation } from "../../../backend/Projects/types";
import CoursableIcons from "../../../utils/CoursableIcons";
import React from "react";
import MarkdownView from "../../elements/MarkdownView";
import { ExtraProps } from "react-markdown";
import { Transition } from "@headlessui/react";
import { useProjectManager } from "./Hooks/useProjectManager";

export const useAnnotations = () => {
  const [shownAnnotation, setShownAnnotation] = useState<AssistantAnnotation | null>(null);
  const [lockAnnotation, setLockAnnotation] = useState<boolean>(false);
  const [annotationY, setAnnotationY] = useState(0);

  function ShowAnnotation(annotation: AssistantAnnotation | null, y: number) {
    if (!annotation) return setShownAnnotation(null);
    setLockAnnotation(false);
    setShownAnnotation(annotation);
    setAnnotationY(y - 46);
  }

  return {
    shownAnnotation,
    lockAnnotation,
    setLockAnnotation,
    annotationY,
    ShowAnnotation,
  };
};

interface AnnotationViewProps {
  containerHeight: number | undefined;
  annotationY: number;
  annotation: AssistantAnnotation | null;
  locked: boolean;
  setLock: (v: boolean) => void;
  ShowAnnotation: (annotation: AssistantAnnotation | null, y: number) => void;
}

export const AnnotationView = ({ containerHeight, annotationY, annotation, locked, setLock, ShowAnnotation }: AnnotationViewProps) => {
  const { Files } = useProjectManager();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [quote, setQuote] = useState<string | undefined>(undefined);

  const quoteRef = useRef<HTMLParagraphElement | null>(null);
  const [isClamped, setIsClamped] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    if (annotation) {
      setFileName(Files.fileName(annotation.fileID));
      setQuote(annotation.quote);
    }
  }, [annotation]);

  useEffect(() => {
    if (quoteRef.current) {
      setIsClamped(quoteRef.current.scrollHeight > quoteRef.current.clientHeight);
    }
  }, [quoteRef.current]);

  function ToggleCollapse(isCollapsed: boolean) {
    setCollapsed(isCollapsed);
    if (isCollapsed) {
      quoteRef.current?.scrollTo({
        top: 0,
      });
      setShowScroll(false);
    } else {
      setTimeout(() => {
        setShowScroll(true);
      }, 400);
    }
  }

  return (
    <Transition
      style={{
        bottom: `${(containerHeight ?? 0) - annotationY}px`,
      }}
      className={`absolute left-0 right-0 px-8 z-1`}
      show={!!annotation}
      enter="duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        onMouseLeave={() => {
          if (locked) return;
          ShowAnnotation(null, 0);
        }}
        className="w-full p-4 pb-6 bg-transparent"
      >
        <div className={`w-full flex flex-col items-start justify-start bg-background p-2 rounded-lg shadow-2xl border z-50 duration-300 overflow-auto relative`}>
          <h5 className="font-semibold text-sm pb-1">{fileName}</h5>
          <label className="w-full text-systemGray-500 text-mini">Quote</label>
          <p
            ref={quoteRef}
            className={`text-systemGray-800 whitespace-pre-wrap text-sm py-1 w-full  ${collapsed ? "max-h-[100px] border-t" : "max-h-[400px] border-y"} ${showScroll ? "overflow-auto" : "overflow-clip"} duration-300 cursor-text`}
          >
            {quote}
          </p>
          {(isClamped || !collapsed) && (
            <div className="w-full flex items-end justify-end pt-2">
              <button onClick={() => ToggleCollapse(!collapsed)} className="text-systemGray-500 hover:text-foreground duration-100 text-mini flex items-center justify-end gap-1">
                {collapsed ? "Expand" : "Collapse"} {CoursableIcons.Chevron("up", `${collapsed ? "transform rotate-180" : ""} duration-100 `)}
              </button>
            </div>
          )}
          <button
            onClick={() => setLock(!locked)}
            className={`absolute top-2 right-2 text-sm rounded-md p-1 duration-300 ${locked ? "bg-systemGray-200 text-foreground" : "text-systemGray-500 bg-transparent hover:bg-systemGray-200 hover:text-systemGray-700"}`}
          >
            {locked ? CoursableIcons.Lock() : CoursableIcons.Unlock()}
          </button>
        </div>
      </div>
    </Transition>
  );
};

interface MarkdownWithAnnotationsProps {
  annotations: AssistantAnnotation[];
  children: string | null | undefined;
  ShowAnnotation: (a: AssistantAnnotation | null, y: number) => void;
  size?: "default" | "sm" | null;
  className?: string;
}

export const MarkdownWithAnnotations = ({ annotations, children, ShowAnnotation, className, size }: MarkdownWithAnnotationsProps) => {
  const ProcessString = (str: string) => {
    const regex = /(【.*?】)/g;
    const parts = str.split(regex);

    const combined = parts.map((part, index) => {
      if (!regex.test(part)) return part;
      const annotation = annotations.find((a) => a.citation === part);
      if (!annotation || annotation.quote.length === 0) return "";

      return (
        <span
          key={index}
          onClick={(e) => {
            ShowAnnotation(annotation, e.currentTarget.getBoundingClientRect().y);
          }}
          className="inline"
        >
          {CoursableIcons.Info("inline-block my-0 p-0.5 bg-brand-100 text-brand-400 ml-1 mb-1 rounded hover:scale-105 hover:shadow-md duration-100 cursor-pointer")}
        </span>
      );
    });
    return combined;
  };

  function AnnotationsExtension(tag: keyof JSX.IntrinsicElements, props: React.ClassAttributes<HTMLElement> & React.LiHTMLAttributes<HTMLElement> & ExtraProps) {
    const { children, className, ...rest } = props;

    if (typeof children === "string") {
      return React.createElement(tag, { ...rest, className }, ProcessString(children));
    } else if (Array.isArray(children) && children.some((c) => typeof c === "string")) {
      const parts: React.ReactNode[] = [];
      children.forEach((child, index) => {
        if (typeof child !== "string") return parts.push(child);
        parts.push(...ProcessString(child));
      });
      return React.createElement(tag, { ...rest, className }, parts);
    } else {
      return React.createElement(tag, { ...rest, className }, children);
    }
  }

  return (
    <MarkdownView
      className={className}
      size={size}
      components={{
        h1(props) {
          return AnnotationsExtension("h1", props);
        },
        h2(props) {
          return AnnotationsExtension("h2", props);
        },
        h3(props) {
          return AnnotationsExtension("h3", props);
        },
        h4(props) {
          return AnnotationsExtension("h4", props);
        },
        h5(props) {
          return AnnotationsExtension("h5", props);
        },
        h6(props) {
          return AnnotationsExtension("h6", props);
        },
        li(props) {
          return AnnotationsExtension("li", props);
        },
        p(props) {
          return AnnotationsExtension("p", props);
        },
      }}
    >
      {children}
    </MarkdownView>
  );
};
