import { useTheme } from "../../../utils/ThemeContext";
import { cn } from "../../../utils/UtilityMethods";
import "./Shine.css";

const ShineEffect = ({ color, className }: { color?: string; className?: string }) => {
  const { theme } = useTheme();

  const style = {
    "--shine-color": color ?? (theme === "dark" ? "rgb(68, 68, 68, 0.5)" : "rgb(255, 255, 255, 0.5)"),
    "--delay": `${Math.random() * 4000}ms`,
  } as React.CSSProperties;

  return <div style={style} className={cn("absolute inset-0 bg-shine z-50 pointer-events-none", className)} />;
};

export default ShineEffect;
