import { useEffect, useRef, useState } from "react";
import MarkdownView from "../../../../elements/MarkdownView";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { cn, WaitFor } from "../../../../../utils/UtilityMethods";
import Button from "../../../../elements/Button";
import LoadingIndicator from "../../../../elements/LoadingIndicator";

const SummariesGraphic = () => {
  const [generating, setGenerating] = useState(false);
  const [summary, setSummary] = useState(summaryContent[4]);
  const [shownIndex, setShownIndex] = useState(summaryContent[4].length);

  const typeInterval = useRef<NodeJS.Timer | null>(null);

  async function StartGenerating() {
    setSummary(summaryContent[Math.round(Math.random() * (summaryContent.length - 1))]);
    setGenerating(true);
    setShownIndex(0);

    await WaitFor(1 + Math.random());
    if (typeInterval.current) clearInterval(typeInterval.current);
    typeInterval.current = setInterval(() => {
      setShownIndex((prev) => {
        const index = prev + 5;
        if (index >= summary.length) StopGenerating();
        return index;
      });
    }, 100);
  }

  async function StopGenerating() {
    if (typeInterval.current) clearInterval(typeInterval.current);
    setGenerating(false);
  }

  useEffect(() => {
    return () => {
      if (typeInterval.current) clearInterval(typeInterval.current);
    };
  }, []);

  return (
    <div className="w-full md:w-[90%] h-full flex flex-col gap-4 justify-start select-none">
      <div className="text-sm text-systemGray-400 border-b border-systemGray-200 w-full font-light -mb-2">Source materials</div>
      <div className="w-full flex items-center justify-start flex-wrap gap-2">
        <HomePageMaterialPreview name="ANLY Project" icon={CoursableIcons.DocxDoc("text-blue-500")} />
        <HomePageMaterialPreview name="Final presentation" icon={CoursableIcons.PptxDoc("text-red-500")} />
        <HomePageMaterialPreview name="Lab video" icon={CoursableIcons.YouTube("text-red-500")} />
      </div>
      <div className="bg-background w-full p-4 rounded-xl shadow-md relative text-left">
        <MarkdownView size="sm" className="pointer-events-none opacity-0">
          {summaryContent[0]}
        </MarkdownView>
        {shownIndex === 0 ? (
          <div className="absolute inset-4 text-sm text-systemGray-500 animate-pulse">AI generated summary will appear here.</div>
        ) : (
          <MarkdownView size="sm" className="absolute inset-4">
            {summary.slice(0, shownIndex)}
          </MarkdownView>
        )}
        {shownIndex !== 0 && <div className="text-brandBlue-500 hidden absolute top-4 right-4 text-xs md:flex items-center gap-1 ">{CoursableIcons.Share()} Share</div>}
      </div>
      <div className="w-full flex justify-end">
        <Button onClick={StartGenerating} variant="inverse" size="sm" className={cn("rounded-full relative sm:hidden", generating && "pointer-events-none animate-pulse")}>
          {generating ? <LoadingIndicator className="w-4 h-4" /> : CoursableIcons.SparklesFill()}
          {generating ? "Generating" : "Regenerate"}
        </Button>
        <Button onClick={StartGenerating} variant="inverse" className={cn("rounded-full relative hidden sm:inline-flex", generating && "pointer-events-none animate-pulse")}>
          {generating ? <LoadingIndicator className="w-4 h-4" /> : CoursableIcons.SparklesFill()}
          {generating ? "Generating" : "Regenerate"}
        </Button>
      </div>
    </div>
  );
};

export default SummariesGraphic;

export const HomePageMaterialPreview = ({ name, icon, className }: { name: string; icon: React.ReactNode; className?: string }) => {
  return (
    <div className={cn(`flex items-center justify-start gap-2 p-1 px-2 border border-systemGray-200 rounded-md duration-100 text-mini md:text-sm bg-background select-none shadow hover:scale-105 group-hover:scale-105`, className)}>
      {icon}
      <div className="w-full text-start truncate break-all text-foreground">{name}</div>
    </div>
  );
};

const summaryContent = [
  `
### Project Overview
The project revolves around exploring the composition of red wine to understand the physicochemical properties influencing its quality.

### Data and Analysis
- **Composition of Red Wine**: Red wine primarily consists of water (84%), ethanol (13%), and other chemicals (3%).
- **Data Phases**: The project progresses through data acquisition, cleaning, preprocessing, exploratory...
`,
  `
### Overview of Data Analytics Lecture

The video lecture introduces the rudimentary concepts of data analytics, aiming to elucidate the fundamentals and provide a comprehensive understanding of various data analytics categories and tools.

### Explanation of Data Analytics and its Roles

- **Inception of the lecture**: the lecture is initiated with a broad perspective on the influence of Information and Communication Technology (ICT) on data generation...
`,
  `
### Summary of the model

#### Model structure
\`\`\`
quality ~ fixed_acidity * volatile_acidity + other variables
\`\`\`

#### Results
The regression model identified key predictors of wine quality:

- Negative impact: Volatile acidity and density.
- Positive impact: Sulphates and alcohol content.
`,
  `
### Project Overview

#### 1. Background and Objective

Red wine is immensely popular, with U.S. consumption averaging 2.86 gallons per person, mainly comprising water, ethanol, and other chemicals.

#### 2. Data and Methodology

The study leverages data from physiochemical tests like acidity levels, sugar, and alcohol, paired against quality scores from sensory data.
`,
  `
### Exploratory Analysis for Wine Quality

In the project focusing on red wine quality prediction, exploratory data analysis (EDA) is a crucial step to uncovering insights from the dataset.

### Data Overview

- The data set contains several physicochemical properties of red wine, including acidity levels, sugar content, chlorides, sulfur dioxide levels, density, pH levels, sulphates, and alcohol content...
`,
];
