import CoursableIcons from "../../../utils/CoursableIcons";
import Card from "../../elements/Card";
import "../../../utils/StringExtensions";
import { cn } from "../../../utils/UtilityMethods";
import Badge from "../../elements/Badge";
import date from "date-and-time";
import Button from "../../elements/Button";
import { useEffect, useState } from "react";
import { DashboardPlaceholderItem } from "./ProjectsCard";
import { PopupModal, PopupModalContent, PopupModalHeader } from "../../elements/PopupModal";
import MarkdownView from "../../elements/MarkdownView";
import { CoursableNews, GetCoursableNews } from "../../../backend/Website";
import cookies from "../../../utils/CookiesManager";
import { GAEvent, GALogEvent } from "../../../firebase/GoogleAnalytics";

const maxItems = 4;

const WhatsNewCard = ({ className }: { className?: string }) => {
  const [loading, setLoading] = useState(true);

  const [news, setNews] = useState<CoursableNews[]>([]);
  const [selectedNewsIndex, setSelectedNewsIndex] = useState<number | null>(null);
  const selectedNews = selectedNewsIndex !== null ? news[selectedNewsIndex] : undefined;
  const [showNews, setShowNews] = useState<boolean>(false);

  const [newNewsIDs, setNewNewsIDs] = useState<string[]>([]);

  function SetNewNewsIDs() {
    const allIDs = news.map((n) => n.id);
    const firstTwo = allIDs.slice(0, 2);

    const viewedNewsIDs = cookies.get("viewedNewsIDs") || [];
    const newNews = firstTwo.filter((id) => !viewedNewsIDs.includes(id));
    setNewNewsIDs(newNews);
  }

  async function GetNews() {
    setLoading(true);
    const news = await GetCoursableNews();
    setNews(news);
    setLoading(false);
  }

  function ViewNews(viewedNews: CoursableNews) {
    const index = news.findIndex((n) => n.id === viewedNews.id);
    if (index === -1) return;

    const id = news[index].id;
    const viewedNewsIDs: string[] = cookies.get("viewedNewsIDs") || [];
    if (!viewedNewsIDs.includes(id)) viewedNewsIDs.push(id);
    cookies.set("viewedNewsIDs", viewedNewsIDs, { expires: date.addYears(new Date(), 1) });

    setSelectedNewsIndex(index);
    setShowNews(true);
    GALogEvent(GAEvent.Project.viewedNews, { newsID: id });
  }

  useEffect(() => {
    SetNewNewsIDs();
  }, [news]);

  useEffect(() => {
    GetNews();
  }, []);

  return (
    <Card className={cn("relative", className)} title="What's new" description="News and updates about Coursable" icon={CoursableIcons.Newspaper()}>
      <div
        style={{
          height: `${3.5 * maxItems}rem`,
        }}
        className="w-full flex flex-col"
      >
        {loading ? (
          <>
            <DashboardPlaceholderItem />
            <DashboardPlaceholderItem />
            <DashboardPlaceholderItem />
          </>
        ) : news.length === 0 ? (
          <Empty />
        ) : (
          news.slice(0, maxItems).map((news, index) => <NewsItem key={index} news={news} isNew={newNewsIDs.includes(news.id)} onClick={() => ViewNews(news)} />)
        )}
      </div>
      <NewsPopup
        news={selectedNews}
        isOpen={showNews}
        setIsOpen={(b) => {
          if (!b) SetNewNewsIDs();
          setShowNews(b);
        }}
        isNew={selectedNews?.id ? newNewsIDs.includes(selectedNews.id) : false}
      />
    </Card>
  );
};

export default WhatsNewCard;

const NewsItem = ({ news, onClick, isNew }: { news: CoursableNews; onClick: () => void; isNew: boolean }) => {
  return (
    <Button onClick={onClick} variant="ghost" className="h-auto font-normal px-2 -mx-2">
      <div className="w-full flex-started flex-col truncate">
        <div className="text-systemGray-400 text-xs shrink-0">{date.format(news.date, "MMMM D, YYYY")}</div>
        <div className="max-w-full truncate">{news.title}</div>
      </div>
      {isNew && <Badge variant="inverse">New</Badge>}
    </Button>
  );
};

const Empty = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-4 mt-4">
      <div className="text-3xl text-systemGray-400 rounded-full p-4 bg-systemGray-100">{CoursableIcons.NewspaperFill()}</div>
      <div className="text-systemGray-500 text-sm">Nothing here yet</div>
    </div>
  );
};

const NewsPopup = ({ news, isOpen, setIsOpen, isNew }: { news: CoursableNews | undefined; isOpen: boolean; setIsOpen: (b: boolean) => void; isNew: boolean }) => {
  if (!news) return null;

  const isUrlWithinApp = (url: string) => {
    return !url.startsWith("https://") && !url.startsWith("http://");
  };

  return (
    <PopupModal open={isOpen} Close={() => setIsOpen(false)} tapToClose className="w-full max-w-3xl">
      <PopupModalHeader>
        <div className="w-full flex items-center justify-start gap-2">
          <div className="text-systemGray-400 text-sm font-normal">{date.format(news.date, "MMMM D, YYYY")}</div>
          {isNew && <Badge variant="inverse">New</Badge>}
        </div>
        {news.title}
      </PopupModalHeader>
      <PopupModalContent className="flex-started flex-col">
        <MarkdownView>{news.content}</MarkdownView>
        {news.buttons.length > 0 && (
          <div className="w-full flex items-start justify-start gap-4 pt-8">
            {news.buttons.map((button) => (
              <Button variant={button.variant} to={isUrlWithinApp(button.url) ? button.url : undefined}>
                {isUrlWithinApp(button.url) ? (
                  button.label
                ) : (
                  <a href={button.url} target="_blank">
                    {button.label}
                  </a>
                )}
              </Button>
            ))}
          </div>
        )}
      </PopupModalContent>
    </PopupModal>
  );
};
