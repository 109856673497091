import { Dispatch, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FetchUrlFileInfo, FetchWebArticleInfo, FetchYouTubeVideoInfo, isURLFile, isURLWebArticle, isURLYouTubeVideo, ProjectLinkInfo } from "../../../../backend/Projects/ProjectLinks";
import { useNotifications } from "../../../../utils/NotificationsContext";
import { Magika } from "magika";
import { useAuth } from "../../../../firebase/AuthContext";
import { CoursablePlan } from "../../../../utils/CoursablePlan";
import { useDropzone } from "react-dropzone";
import { cn, WaitFor } from "../../../../utils/UtilityMethods";
import { FilePreview, LinkPreview, MaterialPreviewsGrid } from "../NewProjectModal/MaterialPreviews";
import InputField from "../../../elements/InputField";
import Button from "../../../elements/Button";
import CoursableIcons from "../../../../utils/CoursableIcons";
import LoadingIndicator from "../../../elements/LoadingIndicator";
import React from "react";
import { GALogProjectMaterialAdded, GALogProjectMaterialRejected } from "../../../../firebase/GoogleAnalytics";

const useMaterialUpload = ({ open }: { open?: Boolean }) => {
  const startedLoadingModel = useRef(false);
  const magika = useRef<Magika>(new Magika());

  const [files, setFiles] = useState<File[]>([]);
  const [links, setLinks] = useState<ProjectLinkInfo[]>([]);

  function ClearMaterials() {
    setFiles([]);
    setLinks([]);
  }

  const MaterialUploadSection = () => {
    return <MaterialUploadView files={files} setFiles={setFiles} links={links} setLinks={setLinks} magika={magika.current} />;
  };

  useEffect(() => {
    if (open && !magika.current.model.config.loaded && !startedLoadingModel.current) {
      startedLoadingModel.current = true;
      magika.current.load();
    }
  }, [open]);

  return {
    files,
    links,
    MaterialUploadSection,
    ClearMaterials,
  };
};

export default useMaterialUpload;

interface MaterialUploadSectionProps {
  files: File[];
  links: ProjectLinkInfo[];
  setLinks: (v: ProjectLinkInfo[]) => void;
  setFiles: Dispatch<React.SetStateAction<File[]>>;
  magika: Magika;
}

const MaterialUploadView = ({ files, links, setLinks, setFiles, magika }: MaterialUploadSectionProps) => {
  const { currentUser } = useAuth();
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);
  const reachedFileLimit = plan.materialsPerProject !== null && files.length + links.length >= plan.materialsPerProject;

  return (
    <div className="w-full flex-centered flex-col">
      <LinksSection links={links} setLinks={setLinks} disabled={reachedFileLimit} />
      <div className="flex-centered w-1/2 my-8">
        <div className="h-[1px] bg-systemGray-200 w-full" />
        <span className="shrink-0 px-4 text-systemGray-400 text-mini font-light">and / or</span>
        <div className="h-[1px] bg-systemGray-200 w-full" />
      </div>
      <FilesSection files={files} links={links} setFiles={setFiles} plan={plan} disabled={reachedFileLimit} magika={magika} />
    </div>
  );
};

const LinksSection = ({ links, setLinks, disabled }: { links: ProjectLinkInfo[]; setLinks: (v: ProjectLinkInfo[]) => void; disabled: boolean | undefined }) => {
  const [url, setUrl] = useState("");
  const [addingLink, setAddingLink] = useState(false);

  const [error, setError] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);

  const { sendError, sendInfo } = useNotifications();

  const addingLinkAbortController = useRef<AbortController | null>(null);

  function TypeURL(url: string) {
    setUrl(url);
    setError("");
    setCanSubmit(false);

    if (url.length <= 6) return;

    if (isURLYouTubeVideo(url) || isURLWebArticle(url) || isURLFile(url)) {
      setCanSubmit(true);
    } else {
      setError("Oops, looks like this is not a link.");
      setCanSubmit(false);
    }
  }

  async function AddUrl(url: string) {
    if (disabled) return;
    if (url.length === 0) return sendInfo("No link provided.", "Please enter a YouTube video or web article link.");

    addingLinkAbortController.current?.abort();
    addingLinkAbortController.current = new AbortController();

    setAddingLink(true);
    try {
      const linkInfo = await FetchProjectLinkInfo(url, addingLinkAbortController.current.signal);
      setLinks([...links, linkInfo]);
      setUrl("");
    } catch (error: any) {
      if (error.name !== "AbortError") {
        if (error.message === "youtube_captions_disabled") sendError("Can't add this video", "Looks like captions are disabled, so we can't process this video.");
        else if (error.message === "youtube_is_live") sendError("Can't add this video", "Looks like this video is a live stream, which is not supported yet.");
        else if (error.message === "web_no_content") sendError("Can't add this link", "Could not read this link. Please try another one.");
        else if (error.message === "invalid_file_format") sendError("Can't add this file", `Only ${supportedProjectFileExtensions.map((f) => f).join(", ")} are supported.`);
        else if (error.type === "no_access") sendError("Can't access this resource", "This website's owner blocks access to this link.");
        else if (error.type === "no_file") sendError("Can't access this file", "Please make sure its a direct link to a public file.");
        else sendError("Could not add link.", "Please try again with a different url.");
        console.log(error);
      }
    }
    setAddingLink(false);
  }

  async function CancelAddUrl() {
    setAddingLink(false);
    addingLinkAbortController.current?.abort();
  }

  async function RemoveURL(index: number) {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  }

  function OnPaste(e: React.ClipboardEvent<HTMLInputElement>) {
    const text = e.clipboardData.getData("text").trim();
    if (!text) return;

    if (!isURLYouTubeVideo(text) && !isURLFile(text) && !isURLWebArticle(text)) return;

    AddUrl(text);
  }

  return (
    <div className="w-full flex flex-col items-start justify-center gap-4">
      <div className={`w-full flex-centered gap-2 ${disabled && "opacity-50 pointer-events-none"}`}>
        <InputField onPaste={OnPaste} className="w-full" disabled={addingLink} value={url} onValueChange={TypeURL} placeholder="Paste links: files, web articles or YouTube videos" />
        <Button
          variant={addingLink ? "outline" : "default"}
          onClick={() => {
            if (addingLink) CancelAddUrl();
            else AddUrl(url);
          }}
          loading={addingLink}
          disabled={addingLink ? false : !canSubmit}
        >
          {addingLink ? "Cancel" : <>{CoursableIcons.Plus()} Add</>}
        </Button>
      </div>
      {error && <div className="w-full text-center p-2 rounded-md border border-red-300 text-mini text-red-600 -mt-2">{error}</div>}
      {links.length > 0 && (
        <MaterialPreviewsGrid>
          {links.map((info, index) => (
            <LinkPreview key={index} info={info} Remove={() => RemoveURL(index)} />
          ))}
        </MaterialPreviewsGrid>
      )}
    </div>
  );
};

interface FilesSectionProps {
  files: File[];
  links: ProjectLinkInfo[];
  setFiles: Dispatch<React.SetStateAction<File[]>>;
  plan: CoursablePlan;
  magika: Magika;
  disabled?: boolean;
  className?: string;
}

const FilesSection = ({ files, links, setFiles, plan, disabled, magika, className }: FilesSectionProps) => {
  const [processingFiles, setProcessingFiles] = useState(false);
  const { sendError } = useNotifications();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setProcessingFiles(true);

      if (links.length + files.length + acceptedFiles.length > (plan.materialsPerProject ?? 1)) {
        return sendError(`Oops, looks like you've selected too many files.`);
      }

      const acceptedFilesBytes = await Promise.all(acceptedFiles.map(async (file) => new Uint8Array(await file.arrayBuffer())));

      for (let i = acceptedFiles.length - 1; i >= 0; i--) {
        const { label, accept, reason } = await filterWithMagika(magika, acceptedFilesBytes[i], acceptedFiles[i]);
        if (!accept) {
          if (reason === "model_not_loaded" || reason === "error") {
            sendError("Could not add files.", "Please try again later.");
          } else {
            const fileName = acceptedFiles[i].name;
            const title = reason === "empty_file" ? `"${fileName}" is empty.` : reason === "unsupported_file" ? `"${fileName}" is unsupported.` : `"${fileName}" is suspicious.`;
            sendError(title, "Please try again with a different PDF, Word, PowerPoint, Markdown or Text document.");
          }
          acceptedFiles = acceptedFiles.filter((_, index) => i !== index);
          GALogProjectMaterialRejected(reason);
        } else {
          GALogProjectMaterialAdded(label);
        }
      }

      setFiles((prev) => [...prev, ...acceptedFiles]);
      setProcessingFiles(false);
    },
    [files, links, plan.materialsPerProject]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: {
      "text/plain": [".txt"],
      "text/markdown": [".md"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc", ".docx"],
      "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
    },
    maxFiles: plan.materialsPerProject ?? 1,
    maxSize: plan.maxProjectsFileSize * 1024 * 1024,
  });

  function RemoveFile(file: File) {
    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
  }

  useEffect(() => {
    if (fileRejections.some((r) => r.errors.some((e) => e.code === "file-too-large"))) {
      sendError(`Maximum file size is ${plan.maxProjectsFileSize}MB.`, "Please try again with a smaller file.");
    } else if (fileRejections.some((r) => r.errors.some((e) => e.code === "too-many-files"))) {
      sendError(`You've selected too many files.`, `Up to ${plan.materialsPerProject} documents are allowed with the ${plan.title} plan.`);
    } else if (fileRejections.some((r) => r.errors.some((e) => e.code === "file-invalid-type"))) {
      sendError(`This file format is unsupported.`, "Please try again with a PDF, Word, PowerPoint, Markdown or Text document.");
    } else if (fileRejections.length > 0) {
      fileRejections.forEach((r) => {
        r.errors.forEach((e) => console.log(e));
      });
      sendError(`Oops, something went wrong. Please try again or with different files.`);
    }
  }, [fileRejections]);

  return (
    <div className="w-full flex-centered flex-col gap-4 relative">
      <div
        {...getRootProps({
          className: cn(
            `w-full dropzone focus:outline-none select-none rounded-xl border-2 border-dashed p-4 md:p-6 duration-100 cursor-pointer`,
            disabled && "pointer-events-none",
            isDragActive ? "bg-brand-50 border-brand-300" : "group bg-brand-50 hover:bg-brand-100 border-brand-300 hover:border-brand-400",
            disabled && "group bg-systemGray-100 border-systemGray-300",
            className
          ),
        })}
      >
        <input {...getInputProps()} />
        <p className={`${isDragActive ? "text-brand-500" : disabled ? "text-systemGray-400" : `text-brand-400 group-hover:text-brand-500`} text-center w-full text-sm md:text-base`}>Drag & drop documents here, or click to select files.</p>
        <p className={`${isDragActive ? "text-brand-500/70" : disabled ? "text-systemGray-400/70" : `text-brand-400/70 group-hover:text-brand-500/70`} text-center w-full text-mini`}>PDF, Word, Powerpoint, Markdown or Text.</p>
        {processingFiles && (
          <div className="absolute top-2 left-0 right-0 flex-centered">
            <LoadingIndicator className="stroke-brand-300 w-4 h-4" />
          </div>
        )}
      </div>
      {files.length > 0 && (
        <MaterialPreviewsGrid>
          {files.map((file, index) => (
            <FilePreview key={index} file={file} RemoveFile={RemoveFile} />
          ))}
        </MaterialPreviewsGrid>
      )}
    </div>
  );
};

async function FetchProjectLinkInfo(url: string, signal?: AbortSignal): Promise<ProjectLinkInfo> {
  if (isURLYouTubeVideo(url)) {
    const videoInfo = await FetchYouTubeVideoInfo(url, signal);
    if (videoInfo.isLive) throw new Error("youtube_is_live");
    if (!videoInfo.hasCaptions) throw new Error("youtube_captions_disabled");
    return {
      type: "youtube",
      url,
      title: videoInfo.title,
      previewImageURL: videoInfo.thumbnailURL,
    };
  }
  if (isURLFile(url)) {
    const fileInfo = await FetchUrlFileInfo(url, signal);
    if (!supportedProjectFileExtensions.includes(fileInfo.extension)) throw new Error("invalid_file_format");
    return {
      type: "file",
      url,
      title: fileInfo.name,
    };
  }
  if (isURLWebArticle(url)) {
    const webArticleInfo = await FetchWebArticleInfo(url, signal);
    if (!webArticleInfo.hasContent) throw new Error("web_no_content");
    return {
      type: "web",
      url,
      title: webArticleInfo.title,
      previewImageURL: webArticleInfo.faviconUrl,
    };
  }
  throw new Error("Invalid URL");
}

interface IMagikaResults {
  reason: string;
  accept: Boolean;
  label?: string;
}

const filterWithMagika = async (magika: Magika, fileBytes: Uint8Array, file: File): Promise<IMagikaResults> => {
  try {
    let waitedSeconds = 0;
    while (!magika?.model.config.loaded) {
      if (waitedSeconds >= 10) return { reason: "model_not_loaded", accept: false };
      await WaitFor(0.5);
      waitedSeconds += 0.5;
    }
    const typePrediction = await magika.identifyBytes(fileBytes);
    let fileType = "." + typePrediction.label;

    if (typePrediction.label === "empty") return { label: typePrediction.label, reason: "empty_file", accept: false }; // Empty files are not allowed.
    if (!supportedProjectFileExtensions.includes(fileType)) return { label: typePrediction.label, reason: "unsupported_file", accept: false }; // Filter out unsupported file types
    if (file.type.startsWith("application/") && typePrediction.score < 0.99) return { label: typePrediction.label, reason: "suspicious_file", accept: false }; // Filter out Docx, PPTx, PDF, etc that have low confidence.

    return { label: typePrediction.label, reason: "accepted", accept: true };
  } catch (error) {
    return { reason: "error", accept: true };
  }
};

const supportedProjectFileExtensions = [".txt", ".md", ".markdown", ".pdf", ".doc", ".docx", ".ppt", ".pptx"];
