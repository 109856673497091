import { Page, Text, Document, StyleSheet, Link, View } from "@react-pdf/renderer";
import "../../../../../../utils/StringExtensions";
import { ProjectQuiz } from "../../../../../../backend/Projects/types";

const PDFFromQuiz = ({ quiz }: { quiz: ProjectQuiz }) => {
  const optionLetter = (index: number): string => {
    return ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"][index].toUpperCase();
  };

  return (
    <Document title={quiz.name + ".pdf"} author="Coursable.io" creator="Coursable.io">
      <Page style={styles.document} size="LETTER">
        <Text style={styles.header} fixed>
          Generated with{" "}
          <Link style={styles.header} src={process.env.REACT_APP_CLIENT_URL as string}>
            Coursable
          </Link>
        </Text>
        <Text style={styles.name}>{quiz.name}</Text>
        <Text style={styles.titleLabel}>Quiz</Text>
        {quiz.multipleChoice.map((question, index) => (
          <>
            <View style={styles.questionView}>
              <Text>{index + 1}.</Text>
              <Text>{question.question}</Text>
            </View>
            <View style={styles.multipleChoiceList}>
              {question.choices.map((choice, index) => (
                <View style={styles.questionRow}>
                  <Text style={{ color: "gray" }}>{optionLetter(index)}.</Text>
                  <Text>{choice}</Text>
                </View>
              ))}
            </View>
          </>
        ))}

        <Text style={styles.titleLabel} break>
          Answers
        </Text>
        {quiz.multipleChoice.map((question, index) => (
          <View style={styles.answerView}>
            <Text>{index + 1}.</Text>
            <Text>{optionLetter(question.correctChoice - 1)}</Text>
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber}`} fixed />
      </Page>
    </Document>
  );
};

export default PDFFromQuiz;

const styles = StyleSheet.create({
  document: {
    padding: "0.75in",
    fontFamily: "Times-Roman",
  },
  name: {
    fontSize: 12,
    color: "#D12424",
    paddingBottom: 12,
  },
  titleLabel: {
    fontSize: 14,
    fontFamily: "Times-Bold",
    paddingBottom: 16,
  },
  questionView: {
    flexDirection: "row",
    gap: 4,
    fontSize: 10,
    paddingBottom: 8,
    fontFamily: "Times-Bold",
  },
  multipleChoiceList: {
    gap: 4,
    fontSize: 10,
    paddingLeft: 20,
    paddingBottom: 20,
  },
  questionRow: {
    flexDirection: "row",
    gap: 8,
  },
  answerView: {
    flexDirection: "row",
    gap: 4,
    fontSize: 10,
    paddingVertical: 4,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    top: "0.25in",
    left: 0,
    right: 0,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: "0.5in",
    textAlign: "right",
  },
});
