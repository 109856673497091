import { useState } from "react";
import { cn } from "../../../../utils/UtilityMethods";
import { TypeAnimation } from "react-type-animation";
import CoursableIcons from "../../../../utils/CoursableIcons";

const HeroGraphic = () => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className="w-full h-full flex justify-end">
      <div className="w-full md:w-[95%] flex-centered relative -translate-y-10">
        <Document image="/Images/Home/Projects/Doc3.png" className={cn("absolute left-[15%]", hover ? "-translate-x-[70%]" : "translate-y-[16px] rotate-[-25deg]")} />
        <Document image="/Images/Home/Projects/Doc2.png" className={cn("absolute right-[15%]", hover ? "translate-x-[70%]" : "translate-y-[16px] rotate-[25deg]")} />
        <Document image="/Images/Home/Projects/Doc1.png" className="z-1 shadow-lg" />
        <Video className={cn("absolute left-[15%] right-[15%] -bottom-[30%] z-2", hover && "scale-105 -bottom-[120px]")} />
        <ChatBox className={cn("absolute bottom-[10%] z-3 duration-300", hover && "bottom-[30%] scale-110")} />
      </div>
    </div>
  );
};

export default HeroGraphic;

export const Document = ({ image, className }: { image?: string; className?: string }) => {
  return (
    <div
      style={{
        aspectRatio: "1 / 1.414",
      }}
      className={cn("w-[37%] bg-background rounded-xl shrink-0 shadow-md duration-300 border border-systemGray-200 p-0.5", className)}
    >
      <img src={image} alt="Document thumbail" className="w-full h-full fit object-cover rounded-xl" />
    </div>
  );
};

export const Video = ({ className }: { className?: string }) => {
  return (
    <div className={cn("rounded-xl aspect-video duration-300 shadow-lg overflow-clip border border-systemGray-200 bg-background/50 backdrop-blur-md p-2", className)}>
      <img src="/Images/Home/Projects/VideoThumbnail.png" alt="Video thumbail" className="w-full h-full fit object-cover rounded-md" />
      <div className="absolute flex-centered inset-2 rounded-md bg-black/20">{CoursableIcons.Play("text-white text-3xl shadow")}</div>
    </div>
  );
};

const ChatBox = ({ className }: { className?: string }) => {
  return (
    <div className={cn("w-full bg-background/70 backdrop-blur-sm rounded-full flex items-center justify-between py-2 px-4 ring-2 shadow-lg ring-brand-200", className)}>
      <TypeAnimation className="w-full" repeat={Infinity} sequence={["What are the key points?", 3000, "Compare these two reports", 3000, "Who are the authors?", 3000, "Summarize the lecture", 3000, "What do they talk about?", 3000]} />
      {CoursableIcons.Send("text-brand-500")}
    </div>
  );
};
