import React from "react";
import MarkdownView from "../../elements/MarkdownView";
import { ExtraProps } from "react-markdown";

const MarkdownViewWithIDs = ({ children }: { children: string }) => {
  function ExtendWithID(tag: keyof JSX.IntrinsicElements, props: React.ClassAttributes<HTMLElement> & React.LiHTMLAttributes<HTMLElement> & ExtraProps) {
    const { children, className, ...rest } = props;

    const id = typeof props.children === "string" ? props.children?.replace(/ /g, "-").replace(/,/g, "").toLowerCase() : undefined;

    return React.createElement(tag, { ...rest, id, className: `${className} scroll-m-32` }, children);
  }
  return (
    <MarkdownView
      components={{
        h2(props) {
          return ExtendWithID("h2", props);
        },
      }}
      className="max-w-5xl"
    >
      {children}
    </MarkdownView>
  );
};

export default MarkdownViewWithIDs;
