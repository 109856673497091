import { useState } from "react";
import { AuthPageModalBackground, ContinueWithGoogleButton, SigninTypeDivider } from "./AuthShared";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthContext";
import { Helmet } from "react-helmet-async";
import CoursableLogo from "../../elements/CoursableLogo";
import Button from "../../elements/Button";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isWrongCredentials, setIsWrongCredentials] = useState(false);

  const { SignIn, SignInWithGoogle, currentUser } = useAuth();
  const navigate = useNavigate();
  const [isSigningIn, setIsSigningIn] = useState(false);

  async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      setIsSigningIn(true);
      await SignIn(email, password);
      setIsSigningIn(false);
      setIsWrongCredentials(false);
      navigate("/app");
    } catch (error) {
      setIsSigningIn(false);
      setIsWrongCredentials(true);
      console.log(error);
    }
  }

  async function ContinueWithGoogle() {
    try {
      await SignInWithGoogle();
      navigate("/app");
    } catch (error) {
      console.log(error);
    }
  }

  function OnEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);

    setEmailError(e.target.value.length === 0 ? "Please enter your email." : "");
  }

  function OnPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);

    setPasswordError(e.target.value.length === 0 ? "Please enter your password." : "");
  }

  if (currentUser) {
    return <Navigate to="/app" />;
  }

  return (
    <AuthPageModalBackground>
      <Helmet>{pageMetatags({ title: "Login", canonical: "/login" })}</Helmet>
      <CoursableLogo size={10} />
      <h3 className="font-semibold text-center text-xl mb-4">Welcome back! 👋</h3>
      <ContinueWithGoogleButton onClick={ContinueWithGoogle} />
      <SigninTypeDivider />
      {isWrongCredentials && (
        <div className="max-w-full rounded-md border bg-red-50 dark:bg-red-950/70 border-red-300 dark:border-red-700 text-mini px-2 py-1.5 text-red-500">
          Incorrect email address or password. If you don't have an account,{" "}
          <Link className="text-brandBlue-500 hover:text-brandBlue-600" to={"/signup"}>
            create one here.
          </Link>
        </div>
      )}
      <form onSubmit={OnSubmit} className="flex flex-col gap-4 w-full">
        <label className="text-sm">
          Email
          <div className="mt-1">
            <input type="email" className="border outline-none focus:border-brand-500 duration-100 rounded-md w-full py-2 px-3 hover:shadow" placeholder="Your email" value={email} onChange={OnEmailChange} required />
          </div>
          {emailError && <div className="text-sm text-red-500 mt-1">{emailError}</div>}
        </label>
        <label className="text-sm">
          Password
          <div className="mt-1">
            <input type="password" className="border outline-none focus:border-brand-500 duration-100 rounded-md w-full py-2 px-3 hover:shadow" placeholder="Your password" value={password} onChange={OnPasswordChange} required />
          </div>
          {passwordError && <div className="text-sm text-red-500 mt-1">{passwordError}</div>}
        </label>
        <Link to="/reset-password" className="text-sm text-brand-500 hover:font-semibold -my-2">
          Forgot password?
        </Link>
        <Button loading={isSigningIn} disabled={isSigningIn} type="submit" className="mt-4">
          Sign in
        </Button>
      </form>
      <p className="text-sm text-center text-systemGray-500">
        New to Coursable?{" "}
        <Link to="/signup" className="text-brand-500 hover:font-semibold">
          Create an account
        </Link>
      </p>
    </AuthPageModalBackground>
  );
};

export default LoginPage;
