import { User, getRedirectResult, updateProfile, verifyBeforeUpdateEmail } from "firebase/auth";
import { useAuth } from "../../../firebase/AuthContext";
import { useEffect, useState } from "react";
import LoadingIndicator from "../../elements/LoadingIndicator";
import ReauthenticatePopup from "../../elements/ReauthenticatePopup";
import { DeleteUser, SendPasswordResetEmail } from "../../../firebase/FirestoreManager";
import { auth } from "../../../firebase/FirebaseConfig";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import cookies from "../../../utils/CookiesManager";
import Button from "../../elements/Button";
import { useNotifications } from "../../../utils/NotificationsContext";
import InputField from "../../elements/InputField";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { useTheme } from "../../../utils/ThemeContext";
import "../../../utils/StringExtensions";
import { ContextMenuButton, ContextMenuDivider } from "../../elements/DropdownMenu/ContextMenu";
import CoursableIcons from "../../../utils/CoursableIcons";

const SettingsProfile = () => {
  const { currentUser } = useAuth();

  if (!currentUser) return <div />;

  return (
    <div className="w-full flex flex-col p-4 md:p-8 border-l">
      <div className="mb-8">
        <div className="text-2xl md:text-4xl font-semibold mb-2">Profile</div>
        <div className="text-sm text-systemGray-500">Manage your Coursable profile</div>
      </div>
      <NameField user={currentUser} />
      {currentUser.providerData[0].providerId === "google.com" ? (
        <GoogleAccount user={currentUser} />
      ) : (
        currentUser.providerData[0].providerId === "password" && (
          <>
            <EmailField user={currentUser} />
            <PasswordResetField user={currentUser} />
          </>
        )
      )}
      <AppTheme />
      <DeleteAccount currentUser={currentUser} />
    </div>
  );
};

export default SettingsProfile;

const NameField = ({ user }: { user: User }) => {
  const [name, setName] = useState<string>(user.displayName ?? "");

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function SaveNewName() {
    if (!name || auth.currentUser === null) return;
    setLoading(true);
    try {
      await updateProfile(auth.currentUser, {
        displayName: name,
      });
      setShowEdit(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (showEdit) setName(user.displayName ?? "");
  }, [showEdit, user.displayName]);

  return (
    <div>
      <div className="border-t py-4 flex items-center justify-between">
        <div>
          <div>Name</div>
          <div className="text-systemGray-500 text-mini md:text-sm">{user.displayName}</div>
        </div>
        <Button variant="outline" size="sm" className={`${showEdit && "hidden"}`} onClick={() => setShowEdit(true)}>
          Edit
        </Button>
      </div>
      {showEdit && (
        <div className="w-full flex flex-col gap-4 bg-systemGray-100 p-4 rounded-lg border mb-4">
          <label className="text-mini md:text-sm">
            Name
            <input
              className="w-full border border-systemGray-400 px-2 p-1 rounded-md outline-none text-sm md:text-base mt-1 focus:border-brandBlue-500"
              type="text"
              value={name}
              onChange={(e) => {
                if (e.currentTarget.value.length > 30) return;
                setName(e.currentTarget.value);
              }}
              required
            />
          </label>
          <div className="ml-auto flex gap-2">
            <Button disabled={loading} onClick={() => setShowEdit(false)} variant="outline" size="sm">
              Cancel
            </Button>
            <Button loading={loading} onClick={SaveNewName} disabled={name.length <= 2 || loading} size="sm">
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const EmailField = ({ user }: { user: User }) => {
  const [newEmail, setNewEmail] = useState<string>();
  const [confirmNewEmail, setConfirmNewEmail] = useState<string>();

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showReauthenticatePopup, setShowReauthenticatePopup] = useState<boolean>(false);

  const { sendError, sendSuccess } = useNotifications();

  function ShowReauthentication() {
    setLoading(true);
    setShowReauthenticatePopup(true);
  }

  function CancelReauthentication() {
    setLoading(false);
    setShowReauthenticatePopup(false);
  }

  function isValidEmail(email: string) {
    return /^\S+@\S+\.\S+$/.test(email);
  }

  async function SaveNewEmail() {
    if (!newEmail || !confirmNewEmail) return;

    try {
      await verifyBeforeUpdateEmail(user, newEmail, {
        url: `${process.env.REACT_APP_CLIENT_URL}/app`,
      });
      setShowEdit(false);
      sendSuccess(`Email verification link was sent to ${newEmail}.`);
    } catch (error) {
      console.log(error);
      sendError("Oops, something went wrong. Please try again later.");
    }
    setLoading(false);
  }

  useEffect(() => {
    if (showEdit) {
      setNewEmail("");
      setConfirmNewEmail("");
    }
  }, [showEdit]);

  return (
    <div>
      <div className="border-t py-4 flex items-center justify-between">
        <div>
          <div>Email</div>
          <div className="text-systemGray-500 text-mini md:text-sm">{user.email}</div>
        </div>
        <Button variant="outline" size="sm" className={`${showEdit && "hidden"}`} onClick={() => setShowEdit(true)}>
          Edit
        </Button>
      </div>

      {showEdit && (
        <div className="w-full flex flex-col gap-4 bg-systemGray-100 p-4 rounded-lg border mb-4">
          <div className="flex w-full gap-2 items-center">
            <label className="text-mini md:text-sm w-1/2">
              New email
              <InputField type="email" value={newEmail} onValueChange={setNewEmail} variant="ghost" className="bg-background" />
            </label>
            <label className="text-mini md:text-sm w-1/2">
              Confirm new email
              <InputField type="email" value={confirmNewEmail} onValueChange={setConfirmNewEmail} variant="ghost" className="bg-background" />
            </label>
          </div>
          <div className="ml-auto flex gap-2">
            <Button onClick={() => setShowEdit(false)} variant="outline" size="sm">
              Cancel
            </Button>
            <Button loading={loading} onClick={ShowReauthentication} disabled={newEmail === undefined || newEmail.length <= 3 || newEmail !== confirmNewEmail || loading || !isValidEmail(newEmail ?? "")} size="sm">
              Save
            </Button>
          </div>
        </div>
      )}
      <ReauthenticatePopup isOpen={showReauthenticatePopup} onSuccess={SaveNewEmail} Close={CancelReauthentication} />
    </div>
  );
};

const PasswordResetField = ({ user }: { user: User }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { sendError, sendSuccess } = useNotifications();

  async function SendLink() {
    if (!user.email) return;

    setLoading(true);
    try {
      await SendPasswordResetEmail(user.email);
      sendSuccess("Password reset link was sent to your email.");
    } catch (error) {
      sendError("Oops, something went wrong. Please try again later.");
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <div className="border-t py-4 flex items-center justify-between">
      <div>
        <div>Password</div>
        <div className="text-systemGray-500 text-mini md:text-sm">Change your password</div>
      </div>
      <Button onClick={SendLink} variant="outline" disabled={loading} size="sm">
        {loading ? <LoadingIndicator className="h-4 w-4 stroke-systemGray-500" /> : <>Send password reset link</>}
      </Button>
    </div>
  );
};

const GoogleAccount = ({ user }: { user: User }) => {
  return (
    <div className="border-t py-4 flex justify-between">
      <div>
        <div>Google Account</div>
        <div className="text-systemGray-500 text-sm">{user.email}</div>
      </div>
    </div>
  );
};

const AppTheme = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div className="border-t py-4 flex items-center justify-between">
      <div>
        <div>App theme</div>
        <div className="text-systemGray-500 text-mini md:text-sm">Select the visual theme of Coursable.</div>
      </div>
      <Dropdown size="sm" label={theme.capitalize()}>
        <ContextMenuButton label="Light" onClick={() => setTheme("light")} icon={CoursableIcons.Sun()} />
        <ContextMenuButton label="Dark" onClick={() => setTheme("dark")} icon={CoursableIcons.Moon()} />
        <ContextMenuDivider />
        <ContextMenuButton label="System" onClick={() => setTheme("system")} icon={CoursableIcons.SystemTheme()} />
      </Dropdown>
    </div>
  );
};

const DeleteAccount = ({ currentUser }: { currentUser: User }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const { sendError } = useNotifications();

  async function TryDeletingAccount() {
    setLoading(true);

    try {
      const plan = CoursablePlan.get(currentUser.customClaims.plan.tier);
      if (plan === CoursablePlan.Free) {
        setShowConfirmation(true);
      } else {
        sendError("Cannot delete an account with an active subscription. Please cancel your subscription and wait for it to expire.");
      }
    } catch (error) {
      sendError("Oops, something went wrong. Please try again later.sss");
      console.log(error);
    }
    setLoading(false);
  }

  function HideConfirmation() {
    setLoading(false);
    setShowConfirmation(false);
  }

  async function DeleteAccount() {
    try {
      await DeleteUser();
    } catch (error) {
      sendError("Oops, something went wrong. Please try again later.");
      console.log(error);
    }
    setLoading(false);
  }

  async function CheckGoogleAccountDeletion() {
    try {
      const result = await getRedirectResult(auth);
      if (!result || !auth.currentUser || cookies.get("deletingGoogleAccount") !== "true") return;

      if (result.user.uid === auth.currentUser.uid) {
        DeleteAccount();
      }
    } catch (error: any) {
      if (error.code === "auth/user-mismatch") sendError(`Wrong Google account. Please use the ${auth.currentUser?.email ?? "current"} account.`);
      else sendError("Oops, something went wrong. Please try again later.");
      console.log(error);
    }
    cookies.remove("deletingGoogleAccount", { path: "/" });
  }
  function StartDeletingAccount() {
    if (auth.currentUser?.providerData[0].providerId !== "google.com") return;

    cookies.set("deletingGoogleAccount", "true", { path: "/", expires: new Date(Date.now() + 1000 * 60 * 5) });
  }

  useEffect(() => {
    CheckGoogleAccountDeletion();
  }, []);

  return (
    <div className="border-y py-4 flex items-center justify-between">
      <div>
        <div>Delete account</div>
        <div className="text-systemGray-500 text-mini md:text-sm">Delete your Coursable account and all data</div>
      </div>
      <Button loading={loading} onClick={TryDeletingAccount} disabled={loading} size="sm">
        Delete account
      </Button>
      <ReauthenticatePopup
        isOpen={showConfirmation}
        title="Delete account"
        description={`Are you sure you would like to delete your account? This action is irreversable and all your data will be lost.`}
        confirmTitle="Delete account"
        onConfirm={StartDeletingAccount}
        onSuccess={DeleteAccount}
        Close={HideConfirmation}
      />
    </div>
  );
};
