import { ReactNode } from "react";
import { cn } from "../../utils/UtilityMethods";

interface Props {
  trigger: boolean;
  onElement: ReactNode;
  offElement: ReactNode;
  className?: string;
  scale?: boolean;
  horizontalAlign?: "left" | "right" | "center";
}

const OnOffTransition = ({ trigger, onElement, offElement, className, scale = true, horizontalAlign = "center" }: Props) => {
  return (
    <div className={cn("relative", className)}>
      <div className={cn("duration-200 opacity-0 scale-50", trigger && "opacity-100", trigger && "scale-100", !scale && "scale-100")}>{onElement}</div>
      <div
        className={cn(
          "duration-200 absolute top-1/2 -translate-y-1/2 opacity-100 scale-100",
          horizontalAlign === "left" ? "left-0" : horizontalAlign === "right" ? "right-0" : "left-1/2 -translate-x-1/2",
          trigger && "opacity-0",
          trigger && "scale-50",
          !scale && "scale-100"
        )}
      >
        {offElement}
      </div>
    </div>
  );
};

export default OnOffTransition;
