import React, { MouseEvent, useRef, useState } from "react";

const FollowMouseBlock = ({ children, sensitivity, className }: { children: React.ReactNode; sensitivity?: number; className?: string }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isMouseIn, setIsMouseIn] = useState(false);

  const onMouseMove = (event: MouseEvent) => {
    const rect = ref?.current?.getBoundingClientRect();
    if (!rect) return;
    setIsMouseIn(true);

    const posX = Math.abs(event.clientX - rect.left) - rect.width * 0.5;
    const posY = Math.abs(event.clientY - rect.top) - rect.height * 0.5;

    setOffset({ x: posX * 0.02 * (sensitivity ?? 1), y: posY * 0.02 * (sensitivity ?? 1) });
  };
  const onMouseLeave = (event: MouseEvent) => {
    setIsMouseIn(false);
    setOffset({ x: 0, y: 0 });
  };

  return (
    <div
      className={className}
      style={{
        transform: `translate(${offset.x * 5}px, ${offset.y * 5}px)`,
        transition: `transform ${isMouseIn ? "0.1s" : "0.3s"} ease-out`,
      }}
      ref={ref}
      onMouseMove={(e) => onMouseMove(e)}
      onMouseLeave={(e) => onMouseLeave(e)}
    >
      {children}
    </div>
  );
};

export default FollowMouseBlock;
