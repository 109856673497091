import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectNote } from "../../../../../../backend/Projects/types";
import { FetchNote } from "../../../../../../backend/Projects/ProjectNotes";
import { Helmet } from "react-helmet-async";
import { pageMetatags } from "../../../../../../utils/MetatagsGenerator";
import { TypeAnimation } from "react-type-animation";
import { PDFViewer } from "@react-pdf/renderer";
import PDFFromNote from "./PDFFromNote";

const ProjectNotesPDFPage = () => {
  const { projectID, noteID } = useParams();

  const [note, setNote] = useState<ProjectNote | null>(null);
  const [failed, setFailed] = useState<boolean>(false);

  async function GetNote() {
    if (!projectID || !noteID) return;

    try {
      const note = await FetchNote(projectID, noteID);
      setNote(note);
    } catch (error) {
      setFailed(true);
    }
  }

  useEffect(() => {
    GetNote();
  }, []);

  const meta = (
    <Helmet>
      {pageMetatags({
        title: note?.name ?? "Note",
      })}
    </Helmet>
  );

  if (!note)
    return (
      <div className="w-screen h-screen bg-systemGray-200 text-systemGray-500 flex items-center justify-center">
        {meta}
        {failed ? <div>Failed to load note</div> : <TypeAnimation sequence={["Preparing PDF", 1000, ""]} repeat={Infinity} />}
      </div>
    );
  return (
    <>
      {meta}
      <PDFViewer className={`w-screen h-screen`}>
        <PDFFromNote note={note} />
      </PDFViewer>
    </>
  );
};

export default ProjectNotesPDFPage;
