import { doc, getDoc } from "firebase/firestore";
import { auth, db, GetIDToken } from "../firebase/FirebaseConfig";
import cookies from "../utils/CookiesManager";
import { ExtractServerResponse } from "./Shared";
import { convertTimestampsToDates } from "../utils/UtilityMethods";
import { CoursablePlan } from "../utils/CoursablePlan";

export async function CheckSignupWithReferralID() {
  const user = auth.currentUser;
  if (!user) return;

  const referralID = cookies.get("coursableReferralID");
  if (!referralID) return;

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/referrals/${referralID}/signup`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  await ExtractServerResponse(response);

  cookies.remove("coursableReferralID");
}

export async function FetchReferralDetails(): Promise<ReferralDetails> {
  const user = auth.currentUser;
  if (!user) throw new Error("User is not logged in.");

  const userDoc = await getDoc(doc(db, `users/${user.uid}`));
  const referralID = userDoc.get("referralID");
  if (!referralID) return await CreateReferralDetails();

  return await GetReferralDetails(referralID);
}

async function GetReferralDetails(referralID: string): Promise<ReferralDetails> {
  const user = auth.currentUser;
  if (!user) throw new Error("User is not logged in.");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/referrals/${referralID}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { referralDetails } = (await ExtractServerResponse(response)).data;

  convertTimestampsToDates(referralDetails);

  return referralDetails;
}

async function CreateReferralDetails(): Promise<ReferralDetails> {
  const user = auth.currentUser;
  if (!user) throw new Error("User is not logged in.");

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/referrals/create`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { referralDetails } = (await ExtractServerResponse(response)).data;

  convertTimestampsToDates(referralDetails);

  return referralDetails;
}

export async function RecordReferralImpression(referralID: string) {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/referrals/${referralID}/impression`, {
    method: "PATCH",
  });

  await ExtractServerResponse(response);
}

export interface ReferralDetails {
  id: string;
  impressions: number;
  signedUpUsers: {
    userID: string;
    signedUpAt: Date;
  }[];
  totalSignedUpUsers: number;
  subscribedUsers: {
    userID: string;
    subscribedAt: Date;
  }[];
  totalSubscribedUsers: number;
  userID: string;
}

export async function FetchUserBalance(): Promise<number> {
  const user = auth.currentUser;
  if (!user) throw new Error("User is not logged in.");

  const stripeClaims = user?.customClaims.stripe;
  if (stripeClaims?.balance !== undefined) return stripeClaims.balance / 100;

  const userDoc = await getDoc(doc(db, `users/${user.uid}`));
  const referredBy = userDoc.get("referredBy") as string | undefined;

  if (stripeClaims?.customerID === undefined && referredBy !== undefined) return CoursablePlan.referralCredit; // Was referred by someone

  return 0;
}
