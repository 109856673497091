import { useEffect, useState } from "react";
import { ProjectFlashcardSet } from "../../../backend/Projects/types";
import { useNotifications } from "../../../utils/NotificationsContext";
import { FlashcardSetView } from "../Projects/Workspace/Flashcards/ProjectFlashcards";
import Button from "../../elements/Button";
import { cn } from "../../../utils/UtilityMethods";
import CoursableIcons from "../../../utils/CoursableIcons";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { ContextMenuButton, ContextMenuDivider } from "../../elements/DropdownMenu/ContextMenu";
import exportFromJSON from "export-from-json";
import ProjectsFlashcardsPractice from "../Projects/Workspace/Flashcards/ProjectsFlashcardsPractice";
import { PublicProjectsPageTemplate, PublicProjectsHeader, PublicProjectsPageState } from "./Shared";
import { useParams } from "react-router-dom";
import { FetchPublicProjectFlashcardSet } from "../../../backend/Projects/PublicProjects";

const PublicProjectsFlashcardsPage = () => {
  const { publicID } = useParams<{ publicID: string }>();
  const [state, setState] = useState<PublicProjectsPageState>("loading");

  const [owner, setOwner] = useState<string | null>(null);
  const [flashcardSet, setFlashcardSet] = useState<ProjectFlashcardSet | null>(null);
  const [revealAll, setRevealAll] = useState(false);
  const [practice, setPractice] = useState(false);

  const pageTitle = flashcardSet ? flashcardSet.name : undefined;
  const pageDescription = flashcardSet ? `Flashcards generated in Coursable by ${owner}. Chat with AI about your existing study materials to pull insights, generate flashcards, answer questions, and much more.` : undefined;

  async function LoadFlashcardSet() {
    if (!publicID) return;

    setState("loading");
    try {
      const { derivative, ownerName } = await FetchPublicProjectFlashcardSet(publicID);
      setFlashcardSet(derivative);
      setOwner(ownerName ?? null);
      setState("loaded");
    } catch (error: any) {
      if (error.code === 404) setState("not-found");
      else setState("error");
    }
  }

  useEffect(() => {
    LoadFlashcardSet();
  }, []);

  return (
    <PublicProjectsPageTemplate
      type="flashcards"
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      state={state}
      header={flashcardSet && <FlashcardsHeader owner={owner} flashcardSet={flashcardSet} revealAll={revealAll} setRevealAll={setRevealAll} OpenPractice={() => setPractice(true)} />}
      content={flashcardSet && <FlashcardSetView flashcardSet={flashcardSet} revealAll={revealAll} />}
      popup={flashcardSet && <ProjectsFlashcardsPractice flashcardSet={flashcardSet} isOpen={practice} setIsOpen={setPractice} />}
    />
  );
};

export default PublicProjectsFlashcardsPage;

const FlashcardsHeader = ({ flashcardSet, revealAll, setRevealAll, OpenPractice, owner }: { flashcardSet: ProjectFlashcardSet; revealAll: boolean; setRevealAll: (b: boolean) => void; OpenPractice: () => void; owner: string | null }) => {
  function Download(as: "json" | "xml") {
    const fileName = flashcardSet.name;
    const exportType = as;

    const dataToDownload = {
      name: flashcardSet.name,
      flashcards: flashcardSet.flashcards,
    };

    exportFromJSON({ data: dataToDownload, fileName, exportType });
  }

  return (
    <PublicProjectsHeader
      type="flashcards"
      derivative={flashcardSet}
      header={`Flashcards${owner !== null ? ` generated by ${owner}` : ""}`}
      title={flashcardSet.name}
      buttons={
        <>
          <Dropdown
            label={
              <>
                {CoursableIcons.Download()} <span className="hidden @md:inline">Download</span>
              </>
            }
            className="@md:w-auto h-9 w-9"
            chevron={false}
            variant="outline"
          >
            <ContextMenuButton label="PDF" icon={CoursableIcons.PdfDoc("text-red-500")} onClick={() => window.open(`/public/flashcards/${flashcardSet.publicID}/pdf`, "_blank")} />
            <ContextMenuDivider />
            <ContextMenuButton label="JSON" icon={CoursableIcons.JsonDoc("text-purple-500")} onClick={() => Download("json")} />
            <ContextMenuButton label="XML" icon={CoursableIcons.XmlDoc("text-orange-500")} onClick={() => Download("xml")} />
          </Dropdown>
          <Button onClick={() => setRevealAll(!revealAll)} variant="outline" className="relative @md:w-auto h-9 w-9">
            {revealAll ? CoursableIcons.EyeSlash() : CoursableIcons.Eye()}
            <span className={cn("hidden @md:inline duration-100", !revealAll ? "opacity-100 scale-100" : "opacity-0 scale-0")}>Reveal</span>
            <span className={cn("hidden @md:inline duration-100 absolute right-6", revealAll ? "opacity-100 scale-100" : "opacity-0 scale-0")}>Hide</span>
          </Button>
          <Button className="@md:w-auto h-9 w-9" onClick={OpenPractice}>
            {CoursableIcons.Flashcard()} <span className="hidden @md:inline">Practice</span>
          </Button>
        </>
      }
    />
  );
};
