import { GetIDToken } from "../firebase/FirebaseConfig";
import { ExtractServerResponse } from "./Shared";

export const GenerateTopicFull = async (courseStructure: CourseStructure, topicIndex: number, excludeURLs: string[], details: string): Promise<Course.Topic> => {
  const body = {
    courseStructure,
    topicIndex,
    excludeURLs,
    details,
  };

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/topic/generate/full`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { topic } = (await ExtractServerResponse(response)).data;
  if (!topic) throw new Error("Server returned an invalid topic");
  return topic;
};

export const GenerateTopicDescription = async (courseStructure: CourseStructure, topicIndex: number, details: string) => {
  const body = {
    courseStructure,
    topicIndex,
    details,
  };

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/topic/generate/description`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { description } = (await ExtractServerResponse(response)).data;
  if (!description) throw new Error("Server returned an invalid description");
  return description;
};

export const GenerateTopicContent = async (courseStructure: CourseStructure, topicIndex: number, details: string) => {
  const body = {
    courseStructure,
    topicIndex,
    details,
  };

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/topic/generate/content`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { content } = (await ExtractServerResponse(response)).data;
  if (!content) throw new Error("Server returned an invalid content");
  return content;
};

export const GenerateTopicFindMaterials = async (courseStructure: CourseStructure, topicIndex: number, excludeURLs: string[], details: string) => {
  const body = {
    courseStructure,
    topicIndex,
    excludeURLs,
    details,
  };

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/topic/generate/find-materials`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { materials } = (await ExtractServerResponse(response)).data;
  if (!materials) throw new Error("Server returned an invalid materials");
  return materials;
};
