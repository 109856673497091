import { useEffect, useMemo, useState } from "react";
import CoursableIcons from "../../../utils/CoursableIcons";
import Card from "../../elements/Card";
import { EmptyDashboardCard } from "./DashboardPage";
import date from "date-and-time";
import { FetchAllProjects } from "../../../backend/Projects/ProjectBase";
import Button from "../../elements/Button";
import { FetchProjectFiles } from "../../../backend/Projects/ProjectFiles";
import { fileExtensionIcon } from "../Projects/NewProjectModal/MaterialPreviews";
import { isURLYouTubeVideo } from "../../../backend/Projects/ProjectLinks";
import { cn } from "../../../utils/UtilityMethods";
import { CoursableProject, ProjectFile } from "../../../backend/Projects/types";

const maxItems = 4;

const ProjectsCard = ({ className }: { className?: string }) => {
  const [loading, setLoading] = useState(true);

  const [projects, setProjects] = useState<CoursableProject[]>([]);
  const [projectFiles, setProjectFiles] = useState<{ [key: string]: ProjectFile[] }>({});

  async function FetchProjects() {
    setLoading(true);

    const projects = await FetchAllProjects();
    const maxProjects = projects.slice(0, maxItems);
    setProjects(maxProjects);

    await Promise.all(
      maxProjects.map(async (project) => {
        const files = await FetchProjectFiles(project.id);
        setProjectFiles((prev) => {
          return { ...prev, [project.id]: files };
        });
      })
    );
    setLoading(false);
  }

  useEffect(() => {
    FetchProjects();
  }, []);

  return (
    <Card title="Projects" description="Student workspace powered by AI" icon={CoursableIcons.Project()} className={className}>
      <div
        style={{
          height: `${3.5 * (maxItems + 1)}rem`,
        }}
        className="w-full flex flex-col"
      >
        {loading ? (
          <>
            <DashboardPlaceholderItem />
            <DashboardPlaceholderItem />
            <DashboardPlaceholderItem />
          </>
        ) : projects.length === 0 ? (
          <EmptyDashboardCard icon={CoursableIcons.ProjectFill()} text="You don't have any projects yet" cta="New project" to="/app/projects/new" />
        ) : (
          projects.slice(0, maxItems).map((project, index) => <ProjectItem key={index} project={project} files={projectFiles[project.id]} />)
        )}
        <div className="w-full flex items-center justify-center gap-4 mt-auto">
          <Button to="/app/projects" className={cn("w-full", !loading && projects.length === 0 && "hidden")} size="sm" variant="outline">
            View all
          </Button>
          <Button to="/app/projects/new" className={cn("w-full", !loading && projects.length === 0 && "hidden")} size="sm" variant="default">
            New project
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ProjectsCard;

const ProjectItem = ({ project, files }: { project: CoursableProject; files?: ProjectFile[] }) => {
  return (
    <Button to={`/app/projects/${project.id}`} variant="ghost" className="font-normal px-2 -mx-2 h-[3.5rem]">
      <div className="w-full flex-started flex-col">
        <div className="text-systemGray-400 text-xs shrink-0">{date.format(project.dateCreated, "MMMM D, YYYY")}</div>
        <div>{project.name}</div>
      </div>
      <div
        style={{
          direction: "rtl",
        }}
        className="grid grid-cols-2 gap-1"
      >
        {files?.map((file, index) => (
          <div key={index}>{documentIcon(file)}</div>
        ))}
      </div>
    </Button>
  );
};

export const DashboardPlaceholderItem = () => {
  const percent = useMemo(() => 50 + Math.random() * 40, []);
  return (
    <div className="w-full flex flex-col h-[3.5rem] py-2 gap-1">
      <div className="w-1/5 h-2/5 rounded bg-systemGray-200 animate-pulse" />
      <div
        style={{
          width: `${percent}%`,
        }}
        className="w-4/5 h-3/5 rounded bg-systemGray-200 animate-pulse"
      />
    </div>
  );
};

const documentIcon = (file: ProjectFile) => {
  const isYouTube = file.sourceUrl !== undefined ? isURLYouTubeVideo(file.sourceUrl) : undefined;
  if (isYouTube) return CoursableIcons.YouTube("text-red-600");
  const isWebArticle = file.sourceUrl !== undefined;
  if (isWebArticle) return CoursableIcons.Globe("text-cyan-500");
  return fileExtensionIcon(file.extension);
};
