import { User } from "firebase/auth";
import CoursableIcons from "../../../utils/CoursableIcons";

interface SettingsSidemenuProps {
  user: User;
  selectedMenu: string;
  setSelectedMenu: (submenu: string) => void;
}
const SettingsSidemenu = ({ user, selectedMenu, setSelectedMenu }: SettingsSidemenuProps) => {
  return (
    <div className="flex flex-col gap-1 md:max-w-sm w-full px-2 md:px-4 py-4 overflow-auto">
      <div className="text-3xl font-semibold mb-2 ml-2 md:ml-4">Settings</div>
      <SidemenuItem submenu={"profile"} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}>
        {CoursableIcons.PersonFill("shrink-0 text-systemGray-400")}
        <div>Profile</div>
      </SidemenuItem>
      <SidemenuItem submenu={"plans"} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}>
        {CoursableIcons.GearFill("shrink-0 text-systemGray-400")}
        <div>Plans and billing</div>
      </SidemenuItem>
      {user.customClaims.enterprise?.isEnterpriseUser && (
        <SidemenuItem submenu={"enterprise"} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}>
          {CoursableIcons.Code("shrink-0 text-systemGray-400")}
          <div>Enterprise</div>
        </SidemenuItem>
      )}
    </div>
  );
};

export default SettingsSidemenu;

interface SidemenuItemProps {
  children: React.ReactNode;
  submenu: string;
  selectedMenu: string;
  setSelectedMenu: (submenu: string) => void;
}
const SidemenuItem = ({ children, submenu, selectedMenu, setSelectedMenu }: SidemenuItemProps) => {
  const isSelected = selectedMenu === submenu;

  return (
    <button onClick={() => setSelectedMenu(submenu)} className={`text-left rounded-md px-2 md:px-4 py-2 flex items-center gap-2 duration-100 ${isSelected ? "bg-systemGray-200" : "hover:bg-systemGray-100"}`}>
      {children}
    </button>
  );
};
