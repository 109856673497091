import { collection, deleteField, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/FirebaseConfig";
import { GALogGenerateLead } from "../firebase/GoogleAnalytics";
import { ExtractServerResponse } from "./Shared";
import { controlsStyles } from "../components/elements/Button";

export async function FormSubmitEnterprise(name: string, company: string, email: string, findCoursable: string, message: string) {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/website/form/submit/enterprise`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, company, email, findCoursable, message }),
  });

  const { code } = await ExtractServerResponse(response);
  if (code === 200) {
    GALogGenerateLead();
  }
}

export async function ServerHandshake() {
  const user = auth.currentUser;
  if (!user) return;

  const userDocRef = doc(db, "users", user.uid);
  const serverDocRef = doc(db, "admin", "server");

  const [serverDoc, userDoc] = await Promise.all([(await getDoc(serverDocRef)).data(), (await getDoc(userDocRef)).data()]);

  if (!serverDoc || !userDoc) return;

  const lastServerRestartDate = serverDoc.lastRestartDate?.toDate();
  const userLastServerRestartDate = userDoc.lastServerRestartDate?.toDate();

  if (!lastServerRestartDate) return;

  if (userLastServerRestartDate === undefined || userLastServerRestartDate.getTime() !== lastServerRestartDate.getTime()) {
    await setDoc(userDocRef, { isGenerating: false, lastServerRestartDate }, { merge: true });

    const projectsRef = await getDocs(collection(db, "users", user.uid, "projects"));
    projectsRef.forEach(async (projectDoc) => {
      await setDoc(
        doc(db, "users", user.uid, "projects", projectDoc.id),
        {
          isGenerating: false,
          isGeneratingFlashcards: false,
          isGeneratingSummary: false,
          isGeneratingQuiz: false,
        },
        { merge: true }
      );
    });
  }
}

export async function CheckHeaderBanner(): Promise<HeaderBanner | null> {
  const headerBannerDoc = await getDoc(doc(db, "admin", "client"));
  const data = (headerBannerDoc.get("headerBanner") as HeaderBanner) ?? null;

  if (!data || data.text === undefined || data.visibility === undefined) return null;

  return data;
}
export interface HeaderBanner {
  text: string;
  visibility: "none" | "all" | "logged-in-all" | "logged-in-admin";
  backgroundColor?: string;
  backgroundOpacity?: number;
}

export async function SaveUserDetails(affiliation: string, source: string, emailOptIn: boolean, educationLevel?: string, educationField?: string) {
  const user = auth.currentUser;
  if (!user) throw new Error("User is not logged in.");

  const userDocRef = doc(db, "users", user.uid);
  await setDoc(
    userDocRef,
    {
      userDetails: {
        affiliation,
        source,
        emailOptIn,
        educationLevel: educationLevel ?? deleteField(),
        educationField: educationField ?? deleteField(),
      },
    },
    { merge: true }
  );
}

export async function didUserFinishRegistration(): Promise<boolean> {
  const user = auth.currentUser;
  if (!user) return false;

  const userDoc = await getDoc(doc(db, "users", user.uid));

  return userDoc.get("userDetails") !== undefined;
}

export async function GetCoursableNews(): Promise<CoursableNews[]> {
  const newsDocs = await getDocs(collection(db, "admin/client/news"));

  const news = newsDocs.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      title: data.title,
      date: data.date.toDate(),
      content: data.content,
      buttons: data.buttons,
    };
  });

  news.sort((a, b) => b.date.getTime() - a.date.getTime());
  return news;
}

export interface CoursableNews {
  id: string;
  title: string;
  date: Date;
  content: string;
  buttons: { variant?: keyof typeof controlsStyles.variants; label: string; url: string }[];
}
