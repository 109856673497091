import { useCallback, useEffect, useRef, useState } from "react";
import { GetDurationString, MaterialTag } from "../Courses/DashboardTopicView";
import { PopupModal, PopupModalContent, PopupModalFooter, PopupModalHeader } from "../../elements/PopupModal";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { IoReorderThree as ReorderIcon } from "react-icons/io5";
import { ContextMenu, ContextMenuButton } from "../../elements/DropdownMenu/ContextMenu";
import Button from "../../elements/Button";
import InputField from "../../elements/InputField";
import CoursableIcons from "../../../utils/CoursableIcons";
import { cn } from "../../../utils/UtilityMethods";

interface DragItem {
  material: Course.Topic.Material;
  index: number;
}

interface MaterialsListProps {
  topic: Course.Topic;
  UpdateMaterials: (newMaterials: Course.Topic.Material[]) => void;
}

const MaterialsList = ({ topic, UpdateMaterials }: MaterialsListProps) => {
  const [materials, setMaterials] = useState(topic.materials);
  const [editingMaterial, setEditingMaterial] = useState<Course.Topic.Material | null>(null);

  function ClickAddMaterial() {
    const newMaterial: Course.Topic.Material = {
      name: "",
      url: "",
      type: "web",
      duration: 0,
      isCompleted: false,
    };
    const newMaterials = [...materials, newMaterial];
    setMaterials(newMaterials);
    setEditingMaterial(newMaterial);
    UpdateMaterials(newMaterials);
  }

  function ClickDeleteMaterial() {
    if (!editingMaterial) return;
    const index = materials.indexOf(editingMaterial);
    const newMaterials = [...materials];
    newMaterials.splice(index, 1);
    setMaterials(newMaterials);
    UpdateMaterials(newMaterials);
  }

  function ClickUpdateMaterial(newMat: Course.Topic.Material) {
    if (!editingMaterial) return;
    const index = materials.indexOf(editingMaterial);
    const newMaterials = [...materials];
    newMaterials[index] = newMat;
    setMaterials(newMaterials);
    UpdateMaterials(newMaterials);
  }

  const moveMaterial = useCallback((dragIndex: number, hoverIndex: number) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      const moveMat = newMaterials[dragIndex];
      newMaterials.splice(dragIndex, 1);
      newMaterials.splice(hoverIndex, 0, moveMat);
      return newMaterials;
    });
  }, []);

  useEffect(() => {
    setMaterials(topic.materials);
  }, [topic, topic.materials]);

  return (
    <div className="flex flex-col items-start gap-2 w-full">
      <div className="w-full font-semibold text-lg px-2">Materials</div>
      {materials.map((material: Course.Topic.Material, index) => {
        return <MaterialRow key={index} material={material} index={index} onClick={() => setEditingMaterial(material)} moveMaterial={moveMaterial} UpdateMaterials={() => UpdateMaterials(materials)} />;
      })}
      <button onClick={ClickAddMaterial} className="flex gap-1 items-center px-2 text-systemGray-500 hover:text-foreground active:text-systemGray-600 duration-100">
        {CoursableIcons.Plus()}
        Add material
      </button>
      <MaterialPopup material={editingMaterial} isOpen={editingMaterial !== null} Close={() => setEditingMaterial(null)} DeleteMaterial={ClickDeleteMaterial} UpdateMaterial={ClickUpdateMaterial} />
    </div>
  );
};

export default MaterialsList;

interface MaterialRowProps {
  material: Course.Topic.Material;
  index: number;
  onClick: () => void;
  moveMaterial: (dragIndex: number, hoverIndex: number) => void;
  UpdateMaterials: () => void;
}

const MaterialRow = ({ material, index, onClick, moveMaterial, UpdateMaterials }: MaterialRowProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "material",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveMaterial(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      UpdateMaterials();
    },
  });

  const [{ isDragging, originalDragging }, drag] = useDrag({
    type: "material",
    item: () => {
      return { material, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.getItem()?.index === index,
      originalDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <button
      ref={ref}
      data-handler-id={handlerId}
      onClick={onClick}
      className={`w-full flex gap-2 items-center border border-dashed text-left px-2 py-1.5 rounded-md duration-100 ${
        isDragging ? "border-systemGray-500 bg-transparent opacity-50" : `border-transparent ${originalDragging ? "bg-systemGray-100 opacity-100" : "bg-systemGray-100 hover:bg-systemGray-200 active:bg-systemGray-300 opacity-100"}`
      }`}
    >
      <div className={`w-full ${material.name === "" && "text-systemGray-500"}`}>{material.name || "Empty name"}</div>
      <MaterialTag type={material.type} />
      <ReorderIcon className="text-systemGray-400 text-2xl" />
    </button>
  );
};

interface MaterialPopupProps {
  material: Course.Topic.Material | null;
  isOpen: boolean;
  Close: () => void;
  DeleteMaterial: () => void;
  UpdateMaterial: (newMat: Course.Topic.Material) => void;
}

const MaterialPopup = ({ material, isOpen, Close, DeleteMaterial, UpdateMaterial }: MaterialPopupProps) => {
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [type, setType] = useState<"video" | "web" | string>("web");
  const [duration, setDuration] = useState<number>(0);

  const [showTypeDropdown, setShowTypeDropdown] = useState<boolean>(false);

  function SelectTypeDropdown(type: "video" | "web") {
    setType(type);
    setShowTypeDropdown(false);
  }

  function Cancel() {
    if (name === "" && url === "") {
      Close();
      Delete();
      return;
    }
    Close();
  }

  function Delete() {
    Close();
    DeleteMaterial();
  }

  function Save() {
    if (name === "" && url === "") {
      Close();
      Delete();
      return;
    }

    const newMaterial: Course.Topic.Material = {
      name,
      url,
      type,
      duration,
      isCompleted: false,
    };
    UpdateMaterial(newMaterial);
    Close();
  }

  useEffect(() => {
    if (material) {
      setName(material.name);
      setUrl(material.url);
      setType(material.type);
      setDuration(material.duration);
    }
  }, [material]);

  return (
    <PopupModal className="w-full max-w-2xl flex flex-col gap-2 items-start justify-center" open={isOpen}>
      <PopupModalHeader className="relative">
        <Button onClick={Delete} variant="ghost" className={cn("absolute -top-1 -right-1 hover:text-red-600 active:text-red-500")}>
          {CoursableIcons.Delete()} Delete
        </Button>
        <div className="w-full font-semibold text-center">Editing material</div>
      </PopupModalHeader>
      <PopupModalContent className="w-full flex-started flex-col gap-4">
        <div className="text-sm text-systemGray-500 px-3">Name</div>
        <InputField className="w-full" value={name} onValueChange={setName} placeholder="Name" icon={CoursableIcons.Edit()} />
        <div className="text-sm text-systemGray-500 px-3">Link</div>
        <InputField className="w-full" value={url} onValueChange={setUrl} placeholder="Link" icon={CoursableIcons.Edit()} />
        <div onFocus={() => setShowTypeDropdown(true)} onBlur={() => setShowTypeDropdown(false)} className="w-full relative flex flex-col gap-2">
          <div className="text-sm text-systemGray-500 px-3">Type</div>
          <InputField className="w-full" value={type.capitalize()} onValueChange={setType} placeholder="Type" icon={CoursableIcons.Edit()} />
          <ContextMenu className="!right-auto !left-0" isOpen={showTypeDropdown} setIsOpen={() => setShowTypeDropdown(false)}>
            <ContextMenuButton icon={<MaterialTag type="video" />} onClick={() => SelectTypeDropdown("video")} />
            <ContextMenuButton icon={<MaterialTag type="web" />} onClick={() => SelectTypeDropdown("web")} />
          </ContextMenu>
        </div>
        <div className="text-sm text-systemGray-500 px-3">Duration in seconds ({GetDurationString(duration) || "0 minutes"})</div>
        <InputField className="w-full" value={duration.toString()} onValueChange={(e) => setDuration(Number(e))} type="number" placeholder="Duration in seconds" icon={CoursableIcons.Edit()} />
      </PopupModalContent>
      <PopupModalFooter className="w-full flex gap-8 items-center justify-center">
        <Button onClick={Cancel} className="w-24" variant="secondary">
          Cancel
        </Button>
        <Button onClick={Save} className="w-24">
          Save
        </Button>
      </PopupModalFooter>
    </PopupModal>
  );
};
