import { BubbleMenu, EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { cn } from "../../../../../utils/UtilityMethods";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import useDidScroll from "../../../../../utils/Hooks/useDidScroll";
import FontFamily from "@tiptap/extension-font-family";
import TextStyle from "@tiptap/extension-text-style";
import { BubbleControls, Controls } from "./EditorControls";
import FontSize from "./Extensions/FontSize";
import { LineHeight } from "./Extensions/LineHeight";
import { Dispatch, useEffect, useState } from "react";
import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";
import { AIToolView } from "./AIToolsControls";
import { useProjectManager } from "../../Hooks/useProjectManager";
import Highlight from "@tiptap/extension-highlight";
import Selection from "./Extensions/Selection";
import Heading from "@tiptap/extension-heading";

interface NoteEditorProps {
  noteName?: string;
  content?: string;
  onContentChange?: (html: string, text: string) => void;
  onCreate?: (html: string, text: string) => void;
  exportDocx?: boolean;
}

const NoteEditor = ({ noteName, content, onContentChange, onCreate, exportDocx }: NoteEditorProps) => {
  const { scrollRef, didScroll } = useDidScroll<HTMLDivElement>();
  const { Notes } = useProjectManager();

  const [showGlobalTool, setShowGlobalTool] = useState(false);
  const [showBubbleTool, setShowBubbleTool] = useState(false);

  function EndWritingTool() {
    setShowGlobalTool(false);
    setShowBubbleTool(false);
    setTimeout(() => {
      Notes.DeselectWritingTool();
    }, 300);
  }

  return (
    <div ref={scrollRef} className="w-full h-full flex flex-col rounded-xl bg-systemGray-200 px-4 overflow-auto pb-10 relative">
      <EditorProvider
        content={content ?? ""}
        extensions={EditorExtensions}
        editorProps={{
          attributes: {
            class: "focus:outline-none bg-background p-16 cursor-text w-full",
          },
        }}
        enableInputRules={false}
        enablePasteRules={false}
        onUpdate={(e) => {
          onContentChange?.(e.editor.getHTML(), e.editor.getText());
        }}
        onCreate={(e) => {
          onCreate?.(e.editor.getHTML(), e.editor.getText());
        }}
        slotBefore={<SlotBefore showGlobalTool={showGlobalTool} setShowGlobalTool={setShowGlobalTool} didScroll={didScroll} EndWritingTool={EndWritingTool} />}
      >
        <BubbleView showBubbleTool={showBubbleTool} setShowBubbleTool={setShowBubbleTool} EndWritingTool={EndWritingTool} />
        <EmptySpace />
        <EditorFunctions noteName={noteName} content={content} exportDocx={exportDocx} />
      </EditorProvider>
    </div>
  );
};

export default NoteEditor;

const SlotBefore = ({ showGlobalTool, setShowGlobalTool, didScroll, EndWritingTool }: { didScroll: boolean; showGlobalTool: boolean; setShowGlobalTool: Dispatch<React.SetStateAction<boolean>>; EndWritingTool: () => void }) => {
  const { Notes } = useProjectManager();
  const { editor } = useCurrentEditor();

  let currentSelection: undefined | string = undefined;
  if (editor) {
    const { from, to } = editor?.state.selection;
    currentSelection = editor.state.doc.textBetween(from, to, "");
  }

  return (
    <div className={cn("flex -mx-4 px-2 pt-2 pb-4 duration-200 sticky top-0 z-1", didScroll && "px-4")}>
      <div className={cn("w-full flex bg-background duration-200 rounded-lg border z-1", didScroll && "shadow-lg translate-y-2")}>
        <Controls
          OnToolClick={(tool) => {
            setShowGlobalTool(true);
            Notes?.SelectWritingTool(tool, currentSelection);
          }}
          aiToolGroups={["Your materials"]}
          writingToolStyle={Notes.writingToolStyle}
          setWritingToolStyle={Notes.SetWritingToolStyle}
        />
      </div>
      <div className={cn("bg-gradient-to-b from-black/20 from-30% to-transparent absolute inset-0 z-0 transition-colors", didScroll ? "opacity-100" : "opacity-0")} />
      <div className={cn("flex-centered absolute left-8 right-8 duration-300 ease-out-back", showGlobalTool ? "top-24 opacity-100 scale-100" : "top-10 opacity-0 scale-95 pointer-events-none")}>
        {Notes.writingTool && (
          <div className="w-full max-w-[500px]">
            <AIToolView End={EndWritingTool} />
          </div>
        )}
      </div>
    </div>
  );
};

const BubbleView = ({ showBubbleTool, setShowBubbleTool, EndWritingTool }: { showBubbleTool: boolean; setShowBubbleTool: Dispatch<React.SetStateAction<boolean>>; EndWritingTool: () => void }) => {
  const { Notes } = useProjectManager();
  const { editor } = useCurrentEditor();

  let currentSelection: undefined | string = undefined;
  if (editor) {
    const { from, to } = editor?.state.selection;
    currentSelection = editor.state.doc.textBetween(from, to, "");
  }

  return (
    <BubbleMenu tippyOptions={{ duration: 100 }} editor={null} className={cn("min-w-min duration-200 relative")}>
      <div className={cn("flex-centered w-full shadow-lg dark:shadow-systemGray-200 rounded-lg border bg-background duration-200", showBubbleTool && "opacity-0")}>
        <BubbleControls
          OnToolClick={(tool) => {
            setShowBubbleTool(true);
            Notes?.SelectWritingTool(tool, currentSelection);
          }}
          aiToolGroups={["General writing"]}
          writingToolStyle={Notes.writingToolStyle}
          setWritingToolStyle={Notes.SetWritingToolStyle}
        />
      </div>
      <div className={cn("absolute left-8 duration-300 ease-out-back", showBubbleTool ? "top-20 opacity-100 scale-100" : "top-10 opacity-0 scale-95 pointer-events-none")}>
        {Notes.writingTool && (
          <div className="min-w-[300px] max-w-[500px]">
            <AIToolView End={EndWritingTool} />
          </div>
        )}
      </div>
    </BubbleMenu>
  );
};

export const EditorExtensions = [
  StarterKit.configure({
    bulletList: {
      HTMLAttributes: { class: "list-disc ml-8" },
    },
    orderedList: {
      HTMLAttributes: { class: "list-decimal ml-8" },
    },
    paragraph: {
      HTMLAttributes: { class: "leading-normal mb-4" },
    },
    heading: {
      HTMLAttributes: { class: "leading-normal mb-4" },
    },
    code: {
      HTMLAttributes: { class: "bg-brand-50 text-brand-500 rounded px-1" },
    },
    codeBlock: {
      HTMLAttributes: { class: "bg-brandBlue-900 text-background rounded-lg p-6" },
    },
  }),
  Underline,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  TextStyle,
  FontFamily,
  FontSize,
  LineHeight,
  Highlight,
  Selection,
  Heading.configure({
    HTMLAttributes: { class: "leading-normal mb-4 text-blue-500" },
  }),
];

const EmptySpace = ({ className }: { className?: string }) => {
  const { editor } = useCurrentEditor();

  return (
    <div
      onClick={(e) => {
        if (editor?.isFocused) return;
        editor?.commands.focus("end");
      }}
      className={cn("h-full bg-background cursor-text", className)}
    />
  );
};

const EditorFunctions = ({ noteName, content, exportDocx }: { noteName?: string; content?: string; exportDocx?: boolean }) => {
  const { editor } = useCurrentEditor();

  async function ExportDocx() {
    if (!editor) return;

    let html = editor?.getHTML();
    // Reduce line height by 50% since we add 50% to make it visually realistic in TipTap
    html = html.replace(/line-height:\s*([\d.]+)(%)/g, (match, p1, p2) => {
      const newValue = parseFloat(p1) - 50;
      return `line-height: ${newValue}${p2};`;
    });
    const doc = (await asBlob(html)) as Blob;

    saveAs(doc, `${noteName}.docx`);
  }

  useEffect(() => {
    if (exportDocx !== undefined) ExportDocx();
  }, [exportDocx]);

  useEffect(() => {
    if (!content) editor?.commands.clearContent(true);
  }, [content]);

  return null;
};
