import { TypeAnimation } from "react-type-animation";
import { useState } from "react";
import { GetDurationString } from "../../../Courses/DashboardTopicView";
import { IoCheckmarkDone as GoodIcon } from "react-icons/io5";
import { IoCloseOutline as PoorIcon } from "react-icons/io5";
import { IoCheckmarkOutline as AcceptableIcon } from "react-icons/io5";
import FollowMouseBlock from "../../../../elements/FollowMouseBlock";

const MaterialEvaluationBlock = () => {
  const [positions, setPositions] = useState([0, 1, 2]);
  const [moving, setMoving] = useState(false);

  const material1: Course.Topic.Material = {
    duration: 325,
    isCompleted: false,
    name: "Effective communication",
    url: "https://example.com",
    type: "video",
  };
  const material2: Course.Topic.Material = {
    ...material1,
    name: "Monitoring and evaluation",
    duration: 553,
  };
  const material3: Course.Topic.Material = {
    ...material1,
    name: "Time management",
    type: "web",
  };

  function SetPositions(positions: number[]) {
    setPositions(positions);
    setMoving(true);

    setTimeout(() => {
      setMoving(false);
    }, 300);
  }

  return (
    <div className="w-full flex items-center justify-end select-none">
      <FollowMouseBlock sensitivity={0.2} className="w-full">
        <div className="p-4 flex flex-col gap-1 duration-300 w-full hover:bg-background hover:shadow-lg hover:scale-[1.02] rounded-lg">
          <div className="text-sm font-semibold text-brand-500 pl-2">topic</div>
          <div className="bg-systemGray-200 rounded-md border border-systemGray-300 w-full text-foreground font-mono px-2 text-sm sm:text-base">
            <TypeAnimation
              speed={60}
              deletionSpeed={80}
              repeat={Infinity}
              sequence={["soft skills training", () => SetPositions([0, 2, 1]), 5000, "project management for begginers", () => SetPositions([2, 0, 1]), 5000, "corporate organization skills", () => SetPositions([2, 1, 0]), 5000]}
            />
          </div>
          <div className="text-systemGray-400 text-sm mt-2 pl-2">AI evaluation</div>
          <div className="flex flex-col gap-3 w-full">
            <MaterialRow material={material1} offset={positions[0]} label={<MatchLabel label="good" />} moving={moving} />
            <MaterialRow material={material2} offset={positions[1] - 1} label={<MatchLabel label="acceptable" />} moving={moving} />
            <MaterialRow material={material3} offset={positions[2] - 2} label={<MatchLabel label="poor" />} moving={moving} />
          </div>
        </div>
      </FollowMouseBlock>
    </div>
  );
};

export default MaterialEvaluationBlock;

const MaterialRow = ({ material, offset, label, moving }: { material: Course.Topic.Material; offset: number; moving: boolean; label: React.ReactNode }) => {
  return (
    <div className="flex gap-2 items-center justify-between w-full">
      {label}
      <div
        style={{
          transform: `translateY(${offset * 100}%) translateY(${offset * 0.375 * 2}rem)`,
          transition: "box-shadow 0.2s ease-out, transform 0.75s cubic-bezier(0, 0, 0.2, 1)",
        }}
        className={`flex gap-2 sm:gap-4 items-center justify-between px-3 py-1.5 bg-systemGray-100 rounded-md  ${moving && "shadow-lg scale-[1.02]"}`}
      >
        <div className="text-left text-sm truncate text-foreground">{material.name}</div>
        <div className="hidden sm:block whitespace-pre-wrap text-micro text-systemGray-500 font-mono text-right">
          {material.type} | {GetDurationString(material.duration)}
        </div>
      </div>
    </div>
  );
};

const MatchLabel = ({ label }: { label: "good" | "acceptable" | "poor" }) => {
  const textColor = label === "good" ? "text-green-600" : label === "acceptable" ? "text-green-800" : "text-red-600";

  return (
    <div className={`flex gap-1 items-center font-mono font-semibold text-sm shrink-0 ${textColor}`}>
      {label === "good" && <GoodIcon className="sm:text-lg" />}
      {label === "acceptable" && <AcceptableIcon className="sm:text-lg" />}
      {label === "poor" && <PoorIcon className="sm:text-lg" />}
      {label.capitalize()}
    </div>
  );
};
