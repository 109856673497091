import { useNavigate } from "react-router-dom";
import { Banner, UserProfilePanel } from "./Header";
import CoursableLogo from "../elements/CoursableLogo";
import { useAuth } from "../../firebase/AuthContext";
import { CoursablePlan } from "../../utils/CoursablePlan";
import { useEffect, useState } from "react";
import { useHeaderBanner } from "./HeaderBannerProvider";
import { useTheme } from "../../utils/ThemeContext";
import CoursableIcons from "../../utils/CoursableIcons";
import { cn } from "../../utils/UtilityMethods";
import Badge from "../elements/Badge";

const AppHeader = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const { headerBanner } = useHeaderBanner();
  const [smallSize, setSmallSize] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      setSmallSize(window.innerWidth < 768);
    };
    window.addEventListener("resize", resizeHandler);

    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <header className="w-full shrink-0 z-10">
      {headerBanner && <Banner banner={headerBanner} />}
      <div className={`w-full grid ${children ? "grid-cols-3" : "grid-cols-2"} border-b border-systemGray-200 h-16 flex-shrink-0 bg-background px-4 items-center `}>
        <div className="h-full flex justify-start">
          <CoursableLogo
            onClick={() => {
              navigate("/?appRedirect=false");
            }}
            wide
            size={smallSize ? 6 : 8}
          />
        </div>
        {children && <div className="h-full flex justify-center">{children}</div>}
        <div className="h-full flex justify-end">
          <div className="flex gap-2 items-center">
            <div className="h-full flex-centered">
              <ThemeToggle />
            </div>
            <PlanBadge className="my-auto auto" />
            <UserProfilePanel />
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppHeader;

export const PlanBadge = ({ className }: { className?: string }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);

  return (
    <Badge
      className={cn("cursor-pointer", className)}
      onClick={() => {
        navigate(plan === CoursablePlan.Free ? "/pricing" : "/app/settings/plans");
      }}
      variant={plan === CoursablePlan.Free ? "blue" : "inverse"}
    >
      {plan.title.slice(0, 1).toUpperCase() + plan.title.slice(1)}
    </Badge>
  );
};

export const ThemeToggle = () => {
  const { appliedTheme, setTheme } = useTheme();

  return (
    <button onClick={() => setTheme(appliedTheme === "dark" ? "light" : "dark")} className="w-10 h-10 rounded-full hover:bg-systemGray-100 active:bg-systemGray-200 text-systemGray-500 hover:text-foreground duration-200 relative">
      {CoursableIcons.Sun(cn("h-[50%] w-[50%] absolute inset-[25%] duration-200", appliedTheme === "dark" && "rotate-90 opacity-0"))}
      {CoursableIcons.Moon(cn("h-[50%] w-[50%] absolute inset-[25%] duration-200", appliedTheme === "light" && "-rotate-90 opacity-0"))}
    </button>
  );
};
