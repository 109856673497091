import CoursableIcons from "../../../utils/CoursableIcons";
import { ContextMenu, ContextMenuButton, ContextMenuDivider, ContextMenuLabel } from "./ContextMenu";

interface ContextMenuConfirmationProps {
  label?: string;
  confirmLabel?: string;
  confirmIcon?: React.ReactNode;
  cancelLabel?: string;
  cancelIcon?: React.ReactNode;
  width?: number;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  onConfirm: () => void;
}

export const ContextMenuConfirmation = ({ label, confirmLabel, confirmIcon, cancelLabel, cancelIcon, width, isOpen, setIsOpen, onConfirm }: ContextMenuConfirmationProps) => {
  return (
    <ContextMenu autoClose={false} isOpen={isOpen} setIsOpen={setIsOpen}>
      <ContextMenuLabel label={label ?? "Are you sure?"} width={width} />
      <ContextMenuDivider />
      <ContextMenuButton label={cancelLabel ?? "Cancel"} icon={cancelIcon ?? CoursableIcons.Xmark()} onClick={() => setIsOpen(false)} />
      <ContextMenuButton label={confirmLabel ?? "Confirm"} icon={confirmIcon ?? CoursableIcons.Check()} onClick={onConfirm} destructive />
    </ContextMenu>
  );
};

export const ContextMenuDeleteConfirmation = ({ label, width, isOpen, setIsOpen, onDelete }: { label?: string; width?: number; isOpen: boolean; setIsOpen: (b: boolean) => void; onDelete: () => void }) => {
  return <ContextMenuConfirmation label={label} confirmLabel="Delete" confirmIcon={CoursableIcons.Delete()} cancelIcon={CoursableIcons.Xmark()} width={width} isOpen={isOpen} setIsOpen={setIsOpen} onConfirm={onDelete} />;
};
