import { useEffect, useState } from "react";
import { ProjectSummary } from "../../../backend/Projects/types";
import CoursableIcons from "../../../utils/CoursableIcons";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { ContextMenuButton, ContextMenuDivider } from "../../elements/DropdownMenu/ContextMenu";
import exportFromJSON from "export-from-json";
import { PublicProjectsPageTemplate, PublicProjectsHeader, PublicProjectsPageState } from "./Shared";
import MarkdownView from "../../elements/MarkdownView";
import { useParams } from "react-router-dom";
import { FetchPublicProjectSummary } from "../../../backend/Projects/PublicProjects";

const PublicProjectsSummaryPage = () => {
  const { publicID } = useParams<{ publicID: string }>();
  const [state, setState] = useState<PublicProjectsPageState>("loading");

  const [owner, setOwner] = useState<string | null>(null);
  const [summary, setSummary] = useState<ProjectSummary | null>(null);

  const pageTitle = summary ? summary.name : undefined;
  const pageDescription = summary ? `Summary generated in Coursable by ${owner}. Chat with AI about your existing study materials to pull insights, generate flashcards, answer questions, and much more.` : undefined;

  async function LoadFlashcardSet() {
    if (!publicID) return;

    setState("loading");
    try {
      const { derivative, ownerName } = await FetchPublicProjectSummary(publicID);
      setSummary(derivative);
      setOwner(ownerName ?? null);
      setState("loaded");
    } catch (error: any) {
      if (error.code === 404) setState("not-found");
      else setState("error");
    }
  }

  useEffect(() => {
    LoadFlashcardSet();
  }, []);

  return (
    <PublicProjectsPageTemplate
      type="summary"
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      state={state}
      header={summary && <SummaryHeader summary={summary} owner={owner} />}
      content={summary && <MarkdownView className="w-full max-w-5xl">{summary.content}</MarkdownView>}
    />
  );
};

export default PublicProjectsSummaryPage;

const SummaryHeader = ({ summary, owner }: { summary: ProjectSummary; owner: string | null }) => {
  function Download(as: "json" | "xml") {
    const fileName = summary.name;
    const exportType = as;

    const dataToDownload = {
      name: summary.name,
      summary: summary.content,
    };

    exportFromJSON({ data: dataToDownload, fileName, exportType });
  }

  return (
    <PublicProjectsHeader
      type="summary"
      derivative={summary}
      header={`Summary${owner !== null ? ` generated by ${owner}` : ""}`}
      title={summary.name}
      buttons={
        <>
          <Dropdown
            label={
              <>
                {CoursableIcons.Download()}
                <span className="hidden @md:inline">Download</span>
              </>
            }
            className="@md:w-auto h-9 w-9"
            chevron={false}
            variant="outline"
          >
            <ContextMenuButton label="PDF" icon={CoursableIcons.PdfDoc("text-red-500")} onClick={() => window.open(`/public/summary/${summary.publicID}/pdf`, "_blank")} />
            <ContextMenuDivider />
            <ContextMenuButton label="JSON" icon={CoursableIcons.JsonDoc("text-purple-500")} onClick={() => Download("json")} />
            <ContextMenuButton label="XML" icon={CoursableIcons.XmlDoc("text-orange-500")} onClick={() => Download("xml")} />
          </Dropdown>
        </>
      }
    />
  );
};
