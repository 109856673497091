import { createContext, useContext, useEffect, useState } from "react";
import { CheckHeaderBanner, HeaderBanner } from "../../backend/Website";
import { useAuth } from "../../firebase/AuthContext";

interface IHeaderBannerContext {
  headerBanner: HeaderBanner | null;
}

const HeaderBannerContext = createContext<IHeaderBannerContext>({
  headerBanner: null,
});

export const HeaderBannerProvider = ({ children }: { children: React.ReactNode }) => {
  const [headerBanner, setHeaderBanner] = useState<HeaderBanner | null>(null);
  const { currentUser } = useAuth();

  async function FetchHeaderBanner() {
    try {
      const banner = await CheckHeaderBanner();
      if (!banner?.visibility || banner?.visibility === "none") return;
      if (banner?.visibility === "all") setHeaderBanner(banner);
      if (banner?.visibility === "logged-in-all" && currentUser) setHeaderBanner(banner);
      if (banner?.visibility === "logged-in-admin" && currentUser?.customClaims?.role === "admin") setHeaderBanner(banner);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    FetchHeaderBanner();
  }, []);

  return (
    <HeaderBannerContext.Provider
      value={{
        headerBanner,
      }}
    >
      {children}
    </HeaderBannerContext.Provider>
  );
};

export const useHeaderBanner = () => {
  return useContext(HeaderBannerContext);
};
