import { AuthBackground } from "./AuthBackground";
import Button from "../../elements/Button";

export const AuthPageModalBackground = ({ children, homeButton = true }: { children: React.ReactNode; homeButton?: boolean }) => {
  return (
    <div className="flex-centered h-screen w-screen bg-background">
      <div className="relative w-1/2 h-full">
        <AuthBackground className="w-full h-full hidden md:flex-centered bg-brand-700 overflow-hidden" />
        <div className="hidden dark:block absolute bg-black/20 inset-0" /> {/* Hack to make it darker in dark theme */}
      </div>
      <div className="flex items-start justify-center px-4 py-16 w-full max-h-full md:min-w-[40rem] md:w-1/2 shrink-0 overflow-auto">
        <div className="w-full flex flex-col gap-6 max-w-sm">{children}</div>
      </div>
      {homeButton && (
        <Button className="absolute top-4 right-4" to="/?appRedirect=false" variant="ghost">
          Home
        </Button>
      )}
    </div>
  );
};

export const SigninTypeDivider = () => {
  return (
    <div className="grid grid-cols-[1fr_0fr_1fr] items-center">
      <div className="h-[1px] bg-systemGray-200" />
      <div className="mx-4 text-systemGray-500 text-sm">or</div>
      <div className="h-[1px] bg-systemGray-200" />
    </div>
  );
};

export const ContinueWithGoogleButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <ContinueWithButton onClick={onClick}>
      <GoogleIcon />
      Continue with Google
    </ContinueWithButton>
  );
};

export const ContinueWithAppleButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <ContinueWithButton onClick={onClick}>
      <AppleIcon />
      Continue with Apple
    </ContinueWithButton>
  );
};

const ContinueWithButton = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
  return (
    <button onClick={onClick} className="border hover:border-systemGray-400 shadow-sm hover:shadow duration-100 rounded-md px-4 py-1.5 flex items-center justify-center gap-2 outline-brand-500">
      {children}
    </button>
  );
};

const GoogleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.69 190.5" className="w-4 h-4">
      <g transform="translate(1184.583 765.171)">
        <path clipPath="none" mask="none" d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z" fill="#4285f4" />
        <path
          clipPath="none"
          mask="none"
          d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
          fill="#34a853"
        />
        <path
          clipPath="none"
          mask="none"
          d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
          fill="#fbbc05"
        />
        <path
          d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
          fill="#ea4335"
          clipPath="none"
          mask="none"
        />
      </g>
    </svg>
  );
};

const AppleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 814 1000" className="w-4 h-4">
      <path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z" />
    </svg>
  );
};
