import { useEffect, useRef, useState } from "react";
import { Flashcard, FlashcardFeedback, ProjectFlashcardSet } from "../../../../../backend/Projects/types";
import { PopupModal, PopupModalContent, PopupModalFooter, PopupModalHeader } from "../../../../elements/PopupModal";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { useNotifications } from "../../../../../utils/NotificationsContext";
import { CheckFlashcardAnswer } from "../../../../../backend/Projects/ProjectFlashcards";
import LoadingIndicator from "../../../../elements/LoadingIndicator";
import { TypeAnimation } from "react-type-animation";
import InputBox from "../../../../elements/InputBox";
import "../../../../../utils/StringExtensions";
import { DifficultyBadge } from "./ProjectFlashcards";
import { cn } from "../../../../../utils/UtilityMethods";

export const ProjectsFlashcardsPractice = ({ flashcardSet, isOpen, setIsOpen }: { flashcardSet: ProjectFlashcardSet; isOpen: boolean; setIsOpen: (b: boolean) => void }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [currentFlashcard, setCurrentFlashcard] = useState(0);
  const selectedFlashcard = flashcardSet.flashcards[currentFlashcard];
  const showSwitchButtons = flashcardSet.flashcards.length > 1;

  const [loading, setLoading] = useState(false);

  const [userAnswer, setUserAnswer] = useState<string>("");
  const [flashcardFeedback, setFlashcardFeedback] = useState<FlashcardFeedback | null>(null);

  const { sendError } = useNotifications();

  function SwitchFlashcards(dir: "left" | "right") {
    let n = (currentFlashcard + (dir === "left" ? -1 : 1)) % flashcardSet.flashcards.length;
    if (n < 0) n = flashcardSet.flashcards.length - 1;
    setCurrentFlashcard(n);
    setFlashcardFeedback(null);
    setUserAnswer("");
  }

  async function CheckAnswer() {
    setLoading(true);
    setFlashcardFeedback(null);
    try {
      const feedback = await CheckFlashcardAnswer(flashcardSet.id, selectedFlashcard, userAnswer);
      setFlashcardFeedback(feedback);
    } catch (error) {
      console.error(error);
      sendError();
    }
    setLoading(false);
  }

  function TypeAnswer(answer: string) {
    setUserAnswer(answer);
    setFlashcardFeedback(null);
  }

  useEffect(() => {
    if (isOpen) {
      setCurrentFlashcard(0);
      setUserAnswer("");
      setFlashcardFeedback(null);
    }
  }, [isOpen]);

  return (
    <PopupModal className="w-full max-w-3xl" open={isOpen} Close={() => setIsOpen(false)}>
      <PopupModalHeader className="w-full flex-centered flex-col gap-2">
        <div className="text-center text-xs text-brand-500 font-normal">PRACTICE</div>
        <div className="text-center font-semibold text-lg">{flashcardSet.name}</div>
      </PopupModalHeader>
      <PopupModalContent className="p-8 w-full flex items-center justify-start flex-col">
        <div className="w-full flex-centered mt-8 md:mt-16 mb-4 relative">
          {showSwitchButtons && <SwitchFlashcardButton className="absolute -left-6" onClick={() => SwitchFlashcards("left")} dir="left" />}
          <div className="w-full relative overflow-x-clip" ref={containerRef}>
            <div
              style={{
                transform: `translateX(calc(-${currentFlashcard * 100}% - ${currentFlashcard}rem))`,
                transition: "transform 0.3s ease-out",
              }}
              className="w-full flex justify-start gap-4"
            >
              {flashcardSet.flashcards.map((flashcard, index) => (
                <FlashcardView key={index} flashcard={flashcard} isCurrent={index === currentFlashcard} width={containerRef.current?.clientWidth} className="shrink-0" />
              ))}
            </div>
          </div>
          {showSwitchButtons && <SwitchFlashcardButton className="absolute -right-6" onClick={() => SwitchFlashcards("right")} dir="right" />}
        </div>
        <div className="text-systemGray-400 text-sm mb-6 md:mb-12">
          {currentFlashcard + 1} / {flashcardSet.flashcards.length}
        </div>
        <div className="w-full flex-centered flex-col">
          <div className="w-full text-systemGray-400 mb-1 text-sm text-left px-4 ">Answer</div>
          <InputBox
            onKeyDown={(e) => {
              if (e.key === "Enter") CheckAnswer();
            }}
            className="w-full h-16"
            containerClassName="w-full"
            characterLimit={500}
            disabled={loading}
            placeholder="Type your answer here"
            value={userAnswer}
            onValueChange={TypeAnswer}
          />
          <div className="w-full text-left text-sm mt-3 md:mt-6 flex items-start justify-start gap-2">
            {flashcardFeedback?.explanation ? <TypeAnimation cursor={false} speed={80} className="w-full" sequence={[flashcardFeedback.explanation]} /> : <div className="w-full h-5" />}
          </div>
        </div>
      </PopupModalContent>
      <PopupModalFooter
        style={{
          gridTemplateColumns: "1fr auto 1fr",
        }}
        className="w-full grid gap-4"
      >
        <SwitchFlashcardButtonLong show={flashcardFeedback !== null && showSwitchButtons} onClick={() => SwitchFlashcards("left")} dir="left" />
        <CheckAnswerButton onClick={CheckAnswer} loading={loading} isCorrect={flashcardFeedback?.isCorrect ?? null} disabled={userAnswer.trim().length === 0} />
        <SwitchFlashcardButtonLong show={flashcardFeedback !== null && showSwitchButtons} onClick={() => SwitchFlashcards("right")} dir="right" />
      </PopupModalFooter>
    </PopupModal>
  );
};

export default ProjectsFlashcardsPractice;

const SwitchFlashcardButton = ({ onClick, dir, className }: { onClick: () => void; dir: "left" | "right"; className?: string }) => {
  return (
    <button onClick={onClick} className={cn("text-3xl text-systemGray-400 hover:text-systemGray-500 active:text-systemGray-400 duration-100", className)}>
      {CoursableIcons.ChevronTall(dir)}
    </button>
  );
};

const SwitchFlashcardButtonLong = ({ onClick, dir, show }: { onClick: () => void; dir: "left" | "right"; show: boolean }) => {
  return (
    <button onClick={onClick} className={`${show ? "opacity-100" : "opacity-0 pointer-events-none"} text-sm flex-centered gap-2 text-systemGray-400 hover:text-foreground active:text-neutra-500 duration-100`}>
      {dir === "left" && CoursableIcons.Arrow(dir)}
      <span className="hidden md:block">{dir === "left" ? "Previous" : "Next"}</span>
      {dir === "right" && CoursableIcons.Arrow(dir)}
    </button>
  );
};

const FlashcardView = ({ flashcard, width, isCurrent, className }: { flashcard: Flashcard; isCurrent: boolean; width?: number; className?: string }) => {
  const hasQuestion = flashcard.question.length > 0;

  const [showHint, setShowHint] = useState(false);

  function ShowHint() {
    setShowHint(true);
    setTimeout(() => setShowHint(false), 5000);
  }

  return (
    <div
      style={{
        width: width ? `${width}px` : "100%",
        transition: "transform 0.3s",
        transitionDelay: isCurrent ? "0.1s" : "0s",
      }}
      className={`px-4 ${className}`}
    >
      <div
        style={{
          transition: "transform 0.3s, border 0.3s, box-shadow 0.3s",
          transitionDelay: isCurrent ? "0.1s" : "0s",
        }}
        className={`bg-systemGray-100 w-full h-full flex-centered flex-col gap-2 p-8 md:p-16 rounded-xl border-2 text-center group ${isCurrent ? "border-brand-500 shadow-xl-c" : "scale-75"} relative`}
      >
        <div className="text-systemGray-500 text-center text-mini font-light">QUESTION</div>
        <div className={`cursor-text ${!hasQuestion && "text-systemGray-500"} text-sm md:text-base`}>{hasQuestion ? flashcard.question.replaceAll(/(【.*?】)/g, "") : "Blank question"}</div>
        <button
          onClick={ShowHint}
          className={`absolute bottom-2 text-mini text-systemGray-400 hover:text-foreground active:text-systemGray-500 flex-centered gap-1 ${showHint ? "opacity-0 pointer-events-none" : "md:opacity-0 group-hover:opacity-100"} duration-300`}
        >
          {CoursableIcons.Eye()} Hint
        </button>
        <div className={`${showHint ? "opacity-100" : "opacity-0 pointer-events-none"} absolute left-6 right-6 bottom-2 text-mini text-systemGray-400 text-center truncate duration-500`}>
          {flashcard.answer.replaceAll(/(【.*?】)/g, "") || "Blank answer"}
        </div>
        <DifficultyBadge difficulty={flashcard.difficulty} className={"absolute top-3 right-3"} />
      </div>
    </div>
  );
};

const CheckAnswerButton = ({ onClick, loading, isCorrect, disabled }: { onClick: () => void; loading: boolean; isCorrect: boolean | null; disabled: boolean }) => {
  const baseStyles = "border-transparent bg-brand-500 text-white hover:bg-brand-600 active:bg-brand-400";
  const correctStyles = "border-brandGreen-500 bg-background text-brandGreen-500 hover:bg-brandGreen-50 dark:hover:bg-brandGreen-100 active:bg-brandGreen-50/50 dark:active:bg-brandGreen-100/80";
  const falseStyles = "border-red-500 bg-background text-red-500 hover:bg-red-100 dark:hover:bg-red-950 active:bg-red-50 dark:active:bg-red-950/80";
  const loadingStyles = "border-systemGray-400 bg-background text-systemGray-500 animate-pulse pointer-events-none";

  const style = loading ? loadingStyles : isCorrect === null ? baseStyles : isCorrect ? correctStyles : falseStyles;

  return (
    <button disabled={disabled} onClick={onClick} className={`flex-centered gap-2 px-3 py-1.5 rounded-md border font-semibold transition-colors disabled:opacity-50 disabled:pointer-events-none shrink-0 ${style}`}>
      {loading ? "Checking" : isCorrect === null ? "Check" : isCorrect ? "Correct" : "Wrong"}
      {loading ? <LoadingIndicator className="stroke-systemGray-400 p-0.5 shrink-0" /> : isCorrect === null ? <></> : isCorrect ? CoursableIcons.Check() : CoursableIcons.Xmark()}
    </button>
  );
};
