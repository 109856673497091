import { useEffect, useRef, useState } from "react";
import FollowMouseBlock from "../../../../elements/FollowMouseBlock";
import { GetDurationString, MaterialTag } from "../../../Courses/DashboardTopicView";

const MaterialResearchBlock = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const timeoutRef = useRef<NodeJS.Timeout>();

  function SelectStep(index: number, userClicked: boolean = false) {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    setSelectedIndex(index);

    timeoutRef.current = setTimeout(() => {
      SelectStep((index + 1) % 3);
    }, 5000 * (userClicked ? 1.5 : 1));
  }

  useEffect(() => {
    SelectStep(0);
  }, []);

  return (
    <div className="w-full flex flex-col sm:flex-row gap-8 items-center justify-start select-none">
      <div className="flex w-full sm:w-2/5 flex-col gap-2 shrink-0">
        <div className="font-semibold text-lg sm:text-xl pl-3 text-foreground">KPI Course</div>
        <TopicButton title="Understanding KPIs" index={0} selectedIndex={selectedIndex} selectTopic={setSelectedIndex} />
        <TopicButton title="Identifying goals" index={1} selectedIndex={selectedIndex} selectTopic={setSelectedIndex} />
        <TopicButton title="Choosing metrics" index={2} selectedIndex={selectedIndex} selectTopic={setSelectedIndex} />
      </div>
      <div className="flex w-full flex-col gap-1">
        <div className="text-sm text-systemGray-400 pl-3">AI found materials</div>
        <FollowMouseBlock sensitivity={0.2} className="w-full">
          <div className="w-full bg-systemGray-200 p-3 rounded-lg relative hover:shadow-lg hover:scale-[1.02] duration-300">
            <div className={`${selectedIndex === 0 ? "opacity-100" : "opacity-0"} flex flex-col gap-4 duration-500`}>
              {topic1materials.map((material, index) => {
                return <MaterialRow material={material} key={index} />;
              })}
            </div>
            <div className={`${selectedIndex === 1 ? "opacity-100" : "opacity-0"} absolute inset-3 flex flex-col gap-4 duration-500`}>
              {topic2materials.map((material, index) => {
                return <MaterialRow material={material} key={index} />;
              })}
            </div>
            <div className={`${selectedIndex === 2 ? "opacity-100" : "opacity-0"} absolute inset-3 flex flex-col gap-4 duration-500`}>
              {topic3materials.map((material, index) => {
                return <MaterialRow material={material} key={index} />;
              })}
            </div>
          </div>
        </FollowMouseBlock>
      </div>
    </div>
  );
};

const TopicButton = ({ title, index, selectedIndex, selectTopic }: { title: string; index: number; selectedIndex: number; selectTopic: (index: number) => void }) => {
  const isSelected = index === selectedIndex;

  return (
    <button onClick={() => selectTopic(index)} className={`text-left px-3 py-1.5 rounded-md text-systemGray-500 ${isSelected ? "bg-systemGray-200 sm:-mr-10 font-semibold" : "hover:bg-systemGray-200"} duration-300`}>
      <span className={`${isSelected && "text-brand-500"}`}>{index + 1}. </span>
      <span className={`${isSelected && "text-foreground"}`}>{title}</span>
    </button>
  );
};

const MaterialRow = ({ material }: { material: Course.Topic.Material }) => {
  return (
    <div className="flex gap-8 items-center justify-between select-none">
      <div className="flex flex-col w-full">
        <div className={`text-sm font-semibold duration-100 truncate text-foreground`}>{material.name}</div>
        <div className="text-systemGray-500 text-mini -mt-1">{material.url}</div>
      </div>
      <div className={`flex flex-col items-end ${material.isCompleted && "opacity-50"} duration-100`}>
        <MaterialTag type={material.type} />
        <div className="text-micro md:text-mini text-systemGray-500 min-w-max">{GetDurationString(material.duration)}</div>
      </div>
    </div>
  );
};

export default MaterialResearchBlock;

const topic1materials: Course.Topic.Material[] = [
  {
    duration: 531,
    isCompleted: false,
    name: "KPI foundations",
    url: "https://example.com",
    type: "video",
  },
  {
    duration: 320,
    isCompleted: false,
    name: "Why setting good KPI ...",
    url: "https://example.com",
    type: "video",
  },
  {
    duration: 120,
    isCompleted: false,
    name: "The role of KPIs in ...",
    url: "https://example.com",
    type: "web",
  },
];

const topic2materials: Course.Topic.Material[] = [
  {
    duration: 120,
    isCompleted: false,
    name: "Objective-driven KPIs",
    url: "https://example.com",
    type: "web",
  },
  {
    duration: 720,
    isCompleted: false,
    name: "Establishing targets ...",
    url: "https://example.com",
    type: "web",
  },
  {
    duration: 120,
    isCompleted: false,
    name: "Analyzing KPI targets ...",
    url: "https://example.com",
    type: "video",
  },
];

const topic3materials: Course.Topic.Material[] = [
  {
    duration: 80,
    isCompleted: false,
    name: "Relevant metrics for ...",
    url: "https://example.com",
    type: "web",
  },
  {
    duration: 666,
    isCompleted: false,
    name: "Measuring perform...",
    url: "https://example.com",
    type: "video",
  },
  {
    duration: 421,
    isCompleted: false,
    name: "Qualitative vs. ...",
    url: "https://example.com",
    type: "web",
  },
];
