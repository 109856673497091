import { pageMetatags } from "../../../utils/MetatagsGenerator";
import HomePageTemplate from "../../page-elements/HomePageTemplate";

const PressPage = () => {
  return (
    <HomePageTemplate
      helmet={pageMetatags({
        title: "Press",
        description:
          "Coursable press kit. Find all relevant media, logos, explanations, and more. Coursable supports students by analyzing their documents, answering questions, generating study materials, and more, all with a personalized feel.",
        canonical: "/press",
      })}
    >
      <iframe key="press" className="w-full h-screen grow border-y-2 border-brand-600" title="Coursable Press Kit" src="https://impresskit.net/671eec89-d653-4003-a897-f38689098620" />
    </HomePageTemplate>
  );
};

export default PressPage;
