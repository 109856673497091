import { useState } from "react";
import CoursableIcons from "../CoursableIcons";

const useResizeHorizontal = <T extends HTMLElement>() => {
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const [leftWidth, setLeftWidth] = useState<number>(0);
  const [rightWidth, setRightWidth] = useState<number>(0);

  function StartResize() {
    document.body.style.cursor = "ew-resize";
    setIsResizing(true);
  }
  function EndResize() {
    document.body.style.cursor = "default";
    setIsResizing(false);
  }

  function OnResize(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!isResizing) return;
    const rect = e.currentTarget.getBoundingClientRect();
    let left = e.clientX - rect.x; // mouseX - parentOriginX;

    if (left <= 32) left = 32;
    if (left >= rect.width - 32) left = rect.width - 32;

    setLeftWidth(left);
    setRightWidth(rect.width - left);
  }

  const DragHandle = () => {
    return (
      <>
        <div onMouseMove={OnResize} onMouseUp={EndResize} className={`absolute inset-0 ${!isResizing && "pointer-events-none"} z-50 bg-transparent`} />
        <div onMouseDown={StartResize} className="cursor-ew-resize h-full bg-systemGray-300 w-full shrink-0 relative z-30">
          <div className="cursor-ew-resize absolute top-1/2 left-1/2 -translate-x-1/2 bg-background shadow-md border text-systemGray-500 rounded-md py-2 text-sm">{CoursableIcons.DragHandleHorizontal()}</div>
        </div>
      </>
    );
  };

  return {
    OnResize,
    isResizing,
    leftWidth,
    rightWidth,
    setLeftWidth,
    setRightWidth,
    DragHandle: DragHandle(),
  };
};

export default useResizeHorizontal;
