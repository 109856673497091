import { Navigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthContext";
import { AuthPageModalBackground } from "./AuthShared";
import { sendEmailVerification } from "firebase/auth";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import CoursableLogo from "../../elements/CoursableLogo";
import Button from "../../elements/Button";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const ConfirmEmailPage = () => {
  const { currentUser, SignOut } = useAuth();

  const [timer, setTimer] = useState<number>(120);

  async function SendConfirmationEmail() {
    if (!currentUser) return;
    setTimer(120);

    try {
      await sendEmailVerification(currentUser, {
        url: `${process.env.REACT_APP_CLIENT_URL}/app`,
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (timer === 0) {
      return;
    }

    const timeout = setTimeout(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [timer]);

  if (!currentUser) return <Navigate to="/login" />;
  if (currentUser?.emailVerified) return <Navigate to="/app" />;

  return (
    <AuthPageModalBackground>
      <Helmet>{pageMetatags({ title: "Confirm Email", canonical: "/confirm-email", noindex: true })}</Helmet>
      <CoursableLogo size={10} />
      <h1 className="font-semibold text-center text-xl">Let's verify your email 📧</h1>
      <p className="text-center max-w-md">
        Please verify your account by clicking the link in an email we've sent to <span className="text-brand-500 font-semibold">{currentUser?.email}</span>.
      </p>
      <Button disabled={timer > 0} onClick={SendConfirmationEmail}>
        {timer === 0 ? "Resend email" : `Resend email in ${timer} seconds`}
      </Button>
      <Button onClick={SignOut} variant="ghost" className="-mt-4">
        Log out
      </Button>
    </AuthPageModalBackground>
  );
};

export default ConfirmEmailPage;
