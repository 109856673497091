import { GetIDToken } from "../firebase/FirebaseConfig";
import { isValidCourseStructure } from "../utils/UtilityMethods";
import { ExtractServerResponse } from "./Shared";

export async function GenerateCourseStructure(prompt: string): Promise<CourseStructure> {
  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/structure/generate?prompt=${prompt}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });

  const { structure } = (await ExtractServerResponse(response)).data;
  if (isValidCourseStructure(structure)) return structure;
  throw new Error("Server returned an invalid course structure");
}

export const PersonalizeCourseStructure = async (courseStructure: CourseStructure, desiredComplexity: number, prompt: string): Promise<CourseStructure> => {
  const params = new URLSearchParams();
  params.append("dc", desiredComplexity.toString());
  params.append("prompt", prompt);

  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/structure/personalize?${params}`, {
    method: "POST",
    body: JSON.stringify(courseStructure),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  const { structure } = (await ExtractServerResponse(response)).data;
  if (isValidCourseStructure(structure)) return structure;
  throw new Error("Server returned an invalid course structure");
};

export const GenerateCourse = async (courseStructure: CourseStructure) => {
  const idToken = await GetIDToken();
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/app/course/generate`, {
    method: "POST",
    body: JSON.stringify(courseStructure),
    headers: {
      authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });

  await ExtractServerResponse(response);
};
