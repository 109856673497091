import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import { useNotifications } from "../../../utils/NotificationsContext";
import { OpenStripePortal } from "../../../backend/Payment";
import { useAuth } from "../../../firebase/AuthContext";
import Button from "../../elements/Button";

const SettingsPlans = () => {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const planClaims = currentUser?.customClaims.plan;
  const statsClaims = currentUser?.customClaims.stats;
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);

  const intervalText = currentUser?.customClaims.plan.cycle === "month" ? "Monthly" : currentUser?.customClaims.plan.cycle === "year" ? "Annual" : "";

  const [loading, setLoading] = useState(false);
  const { sendError } = useNotifications();

  async function ClickManage() {
    if (plan === CoursablePlan.Free) return navigate("/pricing");

    setLoading(true);
    try {
      await OpenStripePortal();
    } catch (error: any) {
      if (plan === CoursablePlan.Plus && error.type === "no_customer") {
        sendError("Your account is managed by a third party", "Please contact your administrator for assistance.");
      } else {
        sendError("Oops, something went wrong. Please try again later.");
        console.error(error);
      }
      setLoading(false);
    }
  }

  return (
    <div className="w-full border-l flex flex-col p-4 md:p-8">
      <div className="mb-8">
        <div className="text-2xl md:text-4xl font-semibold mb-2">Plans and billing</div>
        <div className="text-sm text-systemGray-500">Manage your subscription, payment method, and invoices.</div>
      </div>
      <div className="border-t py-4 flex items-center justify-between">
        <div>
          <div>Current plan</div>
          <div className="text-systemGray-500 text-mini md:text-sm">
            {plan.title.slice(0, 1).toUpperCase() + plan.title.slice(1)} {intervalText} {planClaims?.expireDate && ` - Cancels on ${planClaims.expireDate.toLocaleDateString("en-US", { month: "long", day: "numeric" })}`}
          </div>
        </div>
        <Button loading={loading} onClick={ClickManage} disabled={loading}>
          {plan === CoursablePlan.Free ? "Upgrade" : "Manage"}
        </Button>
      </div>
      <div className="border-y py-4 flex flex-col gap-2 justify-between">
        <div>Limits</div>
        <LimitPreview value={statsClaims?.projectsCreatedCurrentCycle ?? 0} limit={plan.projectsLimitMonthly} name="Projects created" />
        <LimitPreview value={Math.round(1000 * ((statsClaims?.storageUsed ?? 0) / 1024)) / 1000} limit={(plan.storageLimit ?? 0) / 1024} name="Storage used (gb)" />
        <LimitPreview value={statsClaims?.coursesGeneratedCurrentCycle ?? 0} limit={plan.courseLimitMonthly} name="Courses generated" />
        <div className="text-systemGray-500 text-mini md:text-sm">
          {planClaims?.trialEndDate
            ? `Trial until ${planClaims?.trialEndDate.toLocaleDateString("en-US", { month: "long", day: "numeric" })}`
            : `Reset on ${planClaims?.limitsResetDate.toLocaleDateString("en-US", { month: "long", day: "numeric" })}`}
        </div>
      </div>
      {plan === CoursablePlan.Flex && (
        <div className="border-b py-4 flex flex-col justify-between">
          <div>Usage</div>
          <div className="text-systemGray-500 text-mini md:text-sm shrink">
            {statsClaims?.coursesGeneratedCurrentCycle ?? 0} courses generated, ${statsClaims?.coursesGeneratedCurrentCycle ?? 0 * plan.monthlyPrice} billing on{" "}
            {planClaims?.nextCycleDate?.toLocaleDateString("en-US", { month: "long", day: "numeric" })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPlans;

const LimitPreview = ({ value, limit, name }: { value: number; limit: number | null; name: string }) => {
  return (
    <div className="w-full flex gap-4 items-center justify-start">
      <div className="text-systemGray-500 text-mini md:text-sm shrink-0">{name}</div>
      <div className="w-full h-1 rounded-full bg-systemGray-200">
        <div
          style={{
            width: limit === null ? "0%" : `${Math.max(0, Math.min((value / limit) * 100, 100))}%`,
          }}
          className="h-full rounded-full bg-gradient-to-r from-brand-500 to-brand-300"
        />
      </div>
      <div className={`${limit !== null && value >= limit ? "font-semibold text-red-600" : "text-systemGray-500"} text-mini md:text-sm shrink-0 flex items-center justify-end gap-1`}>
        <span>{value}</span>
        <span>/</span>
        <span>{limit ? `${limit}` : `Unlimited`}</span>
      </div>
    </div>
  );
};
