import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { PDFViewer } from "@react-pdf/renderer";
import { Helmet } from "react-helmet-async";
import PDFFromSummary from "./PDFFromSummary";
import { ProjectSummary } from "../../../../../../backend/Projects/types";
import { FetchSummary } from "../../../../../../backend/Projects/ProjectSummaries";
import { FetchPublicProjectSummary } from "../../../../../../backend/Projects/PublicProjects";
import { pageMetatags } from "../../../../../../utils/MetatagsGenerator";

const ProjectSummaryPDFPage = () => {
  const { publicID, projectID, summaryID } = useParams();

  const [summary, setSummary] = useState<ProjectSummary | null>(null);
  const [failed, setFailed] = useState<boolean>(false);

  async function GetSummary() {
    if (!projectID || !summaryID) return;

    try {
      const summary = await FetchSummary(projectID, summaryID);
      setSummary(summary);
    } catch (error) {
      setFailed(true);
    }
  }

  async function GetPublicSummary() {
    if (!publicID) return;

    try {
      const { derivative } = await FetchPublicProjectSummary(publicID);
      setSummary(derivative);
    } catch (error) {
      setFailed(true);
    }
  }

  useEffect(() => {
    if (publicID) GetPublicSummary();
    else GetSummary();
  }, []);

  const meta = (
    <Helmet>
      {pageMetatags({
        title: summary?.name ?? "Summary",
      })}
    </Helmet>
  );

  if (!summary)
    return (
      <div className="w-screen h-screen bg-systemGray-200 text-systemGray-500 flex items-center justify-center">
        {meta}
        {failed ? <div>Failed to load summary</div> : <TypeAnimation sequence={["Preparing PDF", 1000, ""]} repeat={Infinity} />}
      </div>
    );
  return (
    <>
      {meta}
      <PDFViewer className={`w-screen h-screen`}>
        <PDFFromSummary summary={summary} />
      </PDFViewer>
    </>
  );
};

export default ProjectSummaryPDFPage;
