import Markdown, { Components } from "react-markdown";
import { cn } from "../../utils/UtilityMethods";

const MarkdownView = ({
  children,
  components,
  inverseTheme = false,
  size = "default",
  className,
}: {
  children: string | null | undefined;
  inverseTheme?: boolean;
  size?: "default" | "sm" | null;
  components?: Partial<Components> | null;
  className?: string;
}) => {
  return (
    <Markdown
      components={components}
      className={cn(
        `prose prose-neutral  prose-a:text-brand-500 prose-img:rounded-xl prose-blockquote:border-l-brand-200 cursor-text`,
        size === "default" ? "prose-base" : size === "sm" ? "prose-sm" : undefined,
        !inverseTheme && "dark:prose-invert dark:prose-pre:bg-systemGray-200",
        className
      )}
    >
      {children}
    </Markdown>
  );
};

export default MarkdownView;
