import { Helmet } from "react-helmet-async";
import Header from "./Header";
import Footer from "./Footer";
import { useAuth } from "../../firebase/AuthContext";
import { useSearchParams } from "react-router-dom";
import date from "date-and-time";
import cookies from "../../utils/CookiesManager";
import { RecordReferralImpression } from "../../backend/Referrals";
import { useEffect, useRef } from "react";

const HomePageTemplate = ({ helmet, headerScrollBehaviour = "hover", children }: { helmet: React.ReactNode; headerScrollBehaviour?: "shadow" | "hover"; children: React.ReactNode }) => {
  const { currentUser } = useAuth();
  const [queryParams] = useSearchParams();

  const didReferralImpression = useRef(false);

  useEffect(() => {
    const referralID = queryParams.get("referralID");
    if (!currentUser && referralID && !didReferralImpression.current) {
      cookies.set("coursableReferralID", referralID, { expires: date.addDays(new Date(), 7) });
      RecordReferralImpression(referralID);
      didReferralImpression.current = true;
    }
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col bg-background">
      <Helmet>{helmet || ""}</Helmet>
      <Header scrollBehaviour={headerScrollBehaviour} />
      <main className="w-full h-full">{children}</main>
      <div className="bg-transparent w-full h-full grow" />
      <Footer />
    </div>
  );
};

export default HomePageTemplate;
