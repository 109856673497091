import { Navigate, Outlet, useParams } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthContext";
import ProjectsPageSidemenu from "./ProjectsPageSidemenu";
import CoursableIcons from "../../../utils/CoursableIcons";
import { createContext, useEffect, useState } from "react";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { ContextMenuButton } from "../../elements/DropdownMenu/ContextMenu";
import AppPageTemplate from "../../page-elements/AppPageTemplate";
import { UpgradePopup } from "../../elements/UpgradePopup";
import NewProjectModal from "./NewProjectModal/NewProjectModal";
import { GAEvent, GALogEvent } from "../../../firebase/GoogleAnalytics";
import { CoursablePlan } from "../../../utils/CoursablePlan";
import { pageMetatags } from "../../../utils/MetatagsGenerator";
import { ProjectManagerProvider } from "./Hooks/useProjectManager";

const ProjectsPage = () => {
  const { currentUser } = useAuth();
  const { id } = useParams();

  const [layout, setLayout] = useState<ProjectLayout>(projectLayouts[0]);
  const [isMobile, setIsMobile] = useState(false);

  const [collapsedSidemenu, setCollapsedSidemenu] = useState(false);

  const [newProject, setNewProject] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const plan = CoursablePlan.get(currentUser?.customClaims.plan.tier);

  function OpenNewProject() {
    if (!currentUser) return;

    if (plan.projectsLimitMonthly && (currentUser.customClaims.stats?.projectsCreatedCurrentCycle ?? 0) >= plan.projectsLimitMonthly) {
      GALogEvent(GAEvent.Sales.upgradePopupShown, {
        source: "project_count_limit",
      });
      return setShowUpgradePopup(true);
    }

    return setNewProject(true);
  }

  useEffect(() => {
    setCollapsedSidemenu(layout.sidemenuCollapsed);
  }, [layout]);
  useEffect(() => {
    setLayout(projectLayouts[0]);
  }, [id]);

  useEffect(() => {
    const resizeListener = () => {
      setCollapsedSidemenu(window.innerWidth > 768 && window.innerWidth < 1024);
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", resizeListener);

    resizeListener();
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <AppPageTemplate
      headerChildren={id && !isMobile && <LayoutButtons layout={layout} setLayout={setLayout} />}
      helmet={pageMetatags({
        title: "Projects",
        description: "Generate flashcards and quizzes from your lecture notes, articles, videos, or anything else. Summarize documents, pull insights, brainstorm ideas, and find answers faster.",
        ogImage: "/Images/OpenGraph/Projects Promo.png",
        canonical: "/app/projects",
      })}
    >
      <div className="flex-centered flex-col bg-background h-full w-full relative overflow-auto">
        <div className={`w-full grow grid ${isMobile ? "grid-cols-1" : collapsedSidemenu ? "grid-cols-[0rem_1fr]" : "grid-cols-[16rem_1fr]"} overflow-auto relative duration-300`}>
          <ProjectsContext.Provider
            value={{
              OpenNewProjectModal: OpenNewProject,
              isMobile,
              pageLayout: layout,
            }}
          >
            {(!isMobile || !id) && <ProjectsPageSidemenu collapsed={collapsedSidemenu} />}
            {(!isMobile || id) && (
              <ProjectManagerProvider>
                <Outlet />
              </ProjectManagerProvider>
            )}
            {!isMobile && <CollapseButton collapsed={collapsedSidemenu} setCollapsed={setCollapsedSidemenu} className={`absolute ${collapsedSidemenu ? "left-0" : "left-[16rem]"} top-1/2 -translate-y-1/2`} />}
          </ProjectsContext.Provider>
        </div>
      </div>
      <UpgradePopup
        isOpen={showUpgradePopup}
        Close={() => setShowUpgradePopup(false)}
        highlightProjects
        header={
          <>
            You have created {currentUser?.customClaims.stats?.projectsCreatedCurrentCycle ?? 0} out of {plan.projectsLimitMonthly} projects available this month with the <span className={`font-bold text-brandBlue-500`}>{plan.title}</span>{" "}
            plan.
          </>
        }
      />
      <NewProjectModal isOpen={newProject} Close={() => setNewProject(false)} />
    </AppPageTemplate>
  );
};

export default ProjectsPage;

export const CollapseButton = ({ collapsed, setCollapsed, collapseDir, className }: { collapsed: boolean; setCollapsed: (v: boolean) => void; collapseDir?: "left" | "right"; className?: string }) => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        setCollapsed(!collapsed);
      }}
      className={`text-systemGray-300 hover:text-systemGray-500 active:text-systemGray-400 text-4xl duration-300 ${className}`}
    >
      {CoursableIcons.ChevronTall(collapseDir === "left" ? "left" : collapseDir === "right" ? "right" : "left", `${collapsed && "rotate-180"} m-auto`)}
    </button>
  );
};

const projectLayouts: ProjectLayout[] = [
  {
    title: "Default",
    icon: CoursableIcons.LayoutColumns("three"),
    sidemenuCollapsed: false,
    workspaceCollapsed: false,
    workspaceRatio: 0.4,
  },
  {
    title: "Chat",
    icon: CoursableIcons.LayoutSidebar("left"),
    sidemenuCollapsed: false,
    workspaceCollapsed: true,
  },
  {
    title: "Workspace",
    icon: CoursableIcons.LayoutColumns("two"),
    sidemenuCollapsed: true,
    workspaceCollapsed: false,
    workspaceRatio: 0.5,
  },
];

export const ProjectsContext = createContext<{
  pageLayout: ProjectLayout;
  isMobile: boolean;
  OpenNewProjectModal: (() => void) | null;
}>({
  pageLayout: projectLayouts[0],
  isMobile: false,
  OpenNewProjectModal: null,
});

export const LayoutButtons = ({ layout, setLayout }: { layout: ProjectLayout; setLayout: (v: ProjectLayout) => void }) => {
  return (
    <div className="flex gap-20 items-center text-sm">
      <Dropdown
        label={
          <div className="flex-centered gap-4">
            {layout.icon} {layout.title}
          </div>
        }
        variant="ghost"
        size="sm"
      >
        {projectLayouts.map((layout, index) => {
          return <ContextMenuButton key={index} label={layout.title} icon={layout.icon} onClick={() => setLayout(layout)} />;
        })}
      </Dropdown>
    </div>
  );
};

export interface ProjectLayout {
  title: string;
  icon: React.ReactNode;
  sidemenuCollapsed: boolean;
  workspaceCollapsed: boolean;
  workspaceWidth?: number;
  workspaceRatio?: number;
}
