import { FaLinkedinIn } from "react-icons/fa";
import CoursableLogo from "../../elements/CoursableLogo";
import { AuthPageModalBackground } from "../Auth/AuthShared";
import { RiTwitterXFill } from "react-icons/ri";
import { BiLogoGmail } from "react-icons/bi";
import { SocialButton } from "../../page-elements/Footer";

const MaintenancePage = () => {
  return (
    <AuthPageModalBackground homeButton={false}>
      <CoursableLogo size={10} />
      <h1 className="text-center text-xl font-semibold">We'll be back soon!</h1>
      <p className="text-center text-systemGray-500">We are currently updating our systems and hope to be back within an hour. Thank you for your patience!</p>
      <div className="flex gap-4 items-center justify-center">
        <SocialButton className="!text-white" link="https://www.linkedin.com/company/coursable" icon={<FaLinkedinIn />} />
        <SocialButton className="!text-white" link="https://www.twitter.com/coursableIO" icon={<RiTwitterXFill />} />
        <SocialButton className="!text-white" link="mailto:hello@coursable.io" icon={<BiLogoGmail />} />
      </div>
    </AuthPageModalBackground>
  );
};

export default MaintenancePage;
