import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { auth, db } from "./FirebaseConfig";
import {
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateProfile,
  GoogleAuthProvider,
  signInWithRedirect,
  sendEmailVerification,
  getRedirectResult,
  getAdditionalUserInfo,
} from "firebase/auth";
import { LogRocketIdentifyUser } from "../utils/LogRocket";
import { doc, onSnapshot } from "firebase/firestore";
import { WaitFor, convertTimestampsToDates } from "../utils/UtilityMethods";
import { ResetPlanLimit } from "../backend/Payment";
import { GAEvent, GALogEvent, GALogLogin, GALogSignup, GASetUserID, GASetUserProperties } from "./GoogleAnalytics";
import { ServerHandshake, didUserFinishRegistration } from "../backend/Website";
import { CoursablePlanCode, CoursablePlanInterval } from "../utils/CoursablePlan";
import { CheckSignupWithReferralID } from "../backend/Referrals";
import { RedditPixelIdentifyUser } from "../utils/RedditPixel";

const AuthContext = React.createContext<AuthContextType>({
  currentUser: null,
  setCurrentUser: () => {},
  SignUp: () => new Promise(() => {}),
  SignIn: () => new Promise(() => {}),
  SignInWithGoogle: () => new Promise(() => {}),
  SignOut: () => new Promise(() => {}),
});

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const UnsubClaimsListener = useRef<() => void | undefined>();
  const LoadUserProperties = useCallback(async (user: User) => {
    let customClaims = undefined;
    let n = 0;
    while (!customClaims) {
      if (n > 60) break;
      const decodedToken = await user.getIdTokenResult(true);
      customClaims = decodedToken.claims.customClaims;
      if (!customClaims) await WaitFor(1);
      n++;
    }
    convertTimestampsToDates(customClaims);
    user.customClaims = customClaims as any;
    user.didFinishRegistration = await didUserFinishRegistration();

    GASetUserProperties({
      plan: user.customClaims.plan.tier,
      plan_cycle: user.customClaims.plan.cycle ?? null,
    });
  }, []);

  async function SignUp(name: string, email: string, password: string): Promise<User> {
    const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
    GALogSignup("password");

    await Promise.all([
      updateProfile(userCredentials.user, {
        displayName: name,
      }),
      sendEmailVerification(userCredentials.user, {
        url: `${process.env.REACT_APP_CLIENT_URL}/app`,
      }),
      CheckSignupWithReferralID(),
    ]);

    while (!auth.currentUser?.customClaims) {
      await WaitFor(0.5);
    }
    return auth.currentUser;
  }

  function SignInWithGoogle() {
    return signInWithRedirect(auth, new GoogleAuthProvider());
  }

  function SignIn(email: string, password: string): Promise<User> {
    return signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredentials) => {
        await LoadUser(userCredentials.user);
        GALogLogin("password");
        return userCredentials.user;
      })
      .catch((error) => {
        throw error;
      });
  }

  function SignOut(): Promise<void> {
    UnsubClaimsListener.current?.();
    GALogEvent(GAEvent.User.logout);
    return signOut(auth);
  }

  async function LoadUser(user: User | null) {
    if (user) {
      LogRocketIdentifyUser(user);
      GASetUserID(user.uid);
      if (user.email) RedditPixelIdentifyUser(user.email);
      await ServerHandshake();
      await LoadUserProperties(user);

      UnsubClaimsListener.current = onSnapshot(doc(db, "userClaims", user.uid), async (doc) => {
        await LoadUserProperties(user);
        const newUser: User = Object.create(user);
        setCurrentUser(newUser);
      });
      if (user.customClaims.plan.limitsResetDate < new Date()) {
        await ResetPlanLimit();
      }
    }
    setCurrentUser(user);
    setIsLoading(false);
  }

  useEffect(() => {
    const UnsubUserListener = onAuthStateChanged(auth, async (user) => {
      await LoadUser(user);
    });

    return () => {
      UnsubUserListener();
      UnsubClaimsListener.current?.();
    };
  }, []);

  useEffect(() => {
    async function DetectGoogleSignIn() {
      const userCred = await getRedirectResult(auth);
      if (!userCred || !userCred.user) return;

      const isNewUser = getAdditionalUserInfo(userCred)?.isNewUser;
      if (isNewUser) CheckSignupWithReferralID();

      GALogLogin("google");
    }

    DetectGoogleSignIn();
  }, []);

  const value: AuthContextType = {
    currentUser,
    setCurrentUser,
    SignUp,
    SignIn,
    SignInWithGoogle,
    SignOut,
  };

  return <AuthContext.Provider value={value}>{!isLoading && children}</AuthContext.Provider>;
};

export default AuthProvider;

type AuthContextType = {
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
  SignUp: (name: string, email: string, password: string) => Promise<User>;
  SignIn: (email: string, password: string) => Promise<User>;
  SignInWithGoogle: () => Promise<never>;
  SignOut: () => Promise<void>;
};

declare module "firebase/auth" {
  interface User {
    customClaims: {
      role: "user" | "admin";
      plan: {
        tier: CoursablePlanCode;
        limitsResetDate: Date;
        cycle?: CoursablePlanInterval;
        nextCycleDate?: Date;
        trialEndDate?: Date;
        expireDate?: Date;
      };
      enterprise?: {
        isEnterpriseUser?: boolean;
      };
      stripe?: {
        customerID?: string;
        subscriptionItemID?: string;
        balance?: number;
      };
      stats?: {
        coursesGeneratedCurrentCycle?: number;
        coursesGeneratedTotal?: number;
        projectsCreatedCurrentCycle?: number;
        projectsCreatedTotal?: number;
        storageUsed?: number;
      };
    };
    didFinishRegistration?: boolean;
  }
}
