import { useState } from "react";
import { AuthPageModalBackground } from "./AuthShared";
import { SendPasswordResetEmail } from "../../../firebase/FirestoreManager";
import { useAuth } from "../../../firebase/AuthContext";
import LoadingIndicator from "../../elements/LoadingIndicator";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CoursableLogo from "../../elements/CoursableLogo";
import Button from "../../elements/Button";
import { pageMetatags } from "../../../utils/MetatagsGenerator";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [didSend, setDidSend] = useState(false);

  const { currentUser } = useAuth();

  async function SendPasswordReset(e?: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setLoading(true);
    setError(null);
    try {
      await SendPasswordResetEmail(email);
      setDidSend(true);
    } catch (error: any) {
      console.log(error);
      if (error.code === "auth/user-not-found") setError("An account with this email does not exist.");
      else setError("Oops! Something went wrong, please try again later.");
    }
    setLoading(false);
  }

  if (currentUser) return <Navigate to="/app" />;

  return (
    <AuthPageModalBackground>
      <Helmet>{pageMetatags({ title: "Reset Password", canonical: "/reset-password" })}</Helmet>
      <CoursableLogo size={10} />
      <h3 className="font-semibold text-center text-xl">Reset your password 🔐</h3>
      <p className="text-sm text-systemGray-500 text-center">Enter your email to recieve a password reset link.</p>
      {error && <div className="w-full rounded-md border bg-red-50 dark:bg-red-950/70 border-red-300 dark:border-red-700 text-mini px-2 py-1.5 text-red-500">{error}</div>}
      <form onSubmit={SendPasswordReset} className="flex flex-col gap-2 w-full">
        <label className="text-sm">
          Email
          <input className="mt-1 border outline-none focus:border-brand-500 duration-100 rounded-md w-full py-2 px-3" type="email" placeholder="example@email.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <Button loading={loading} disabled={loading} type="submit" className={`mt-4 ${didSend && "bg-brandGreen-500 shadow-none pointer-events-none"}`}>
          {didSend ? "Reset link was sent!" : "Reset password"}
        </Button>
        <Button variant="ghost" to="/login">
          Login
        </Button>
      </form>
    </AuthPageModalBackground>
  );
};

export default ResetPassword;
