import { Page, Text, Document, StyleSheet, Link, Font } from "@react-pdf/renderer";
import "../../../../../../utils/StringExtensions";
import { ProjectSummary } from "../../../../../../backend/Projects/types";
import Markdown, { Components } from "react-markdown";

const components: Components = {
  p: ({ children }) => <Text style={styles.p}>{children}</Text>,
  h1: ({ children }) => <Text style={styles.h1}>{children}</Text>,
  h2: ({ children }) => <Text style={styles.h2}>{children}</Text>,
  h3: ({ children }) => <Text style={styles.h3}>{children}</Text>,
  h4: ({ children }) => <Text style={styles.h4}>{children}</Text>,
  h5: ({ children }) => <Text style={styles.h5}>{children}</Text>,
  h6: ({ children }) => <Text style={styles.h6}>{children}</Text>,
  a: ({ children, href }) => (
    <Link style={styles.a} src={href ?? ""}>
      {children}
    </Link>
  ),
  pre: ({ children }) => <Text style={styles.pre}>{children}</Text>,
  code: ({ children }) => <Text style={styles.code}>{children}</Text>,
  ul: ({ children }) => <Text style={styles.ul}>{children}</Text>,
  ol: ({ children }) => <Text style={styles.ul}>{children}</Text>,
  li: ({ children }) => <Text style={styles.li}>• &nbsp;{children}</Text>,
  blockquote: ({ children }) => <Text style={styles.blockquote}>{children}</Text>,
  strong: ({ children }) => <Text style={styles.strong}>{children}</Text>,
  br: () => <Text>{"\n"}</Text>,
  hr: () => <Text>{"\n"}</Text>,
};

const PDFFromSummary = ({ summary }: { summary: ProjectSummary }) => {
  return (
    <Document title={summary.name + ".pdf"} author="Coursable.io" creator="Coursable.io">
      <Page style={styles.document} size="LETTER">
        <Text style={styles.header} fixed>
          Generated with{" "}
          <Link style={styles.header} src={process.env.REACT_APP_CLIENT_URL as string}>
            Coursable
          </Link>
        </Text>
        <Text style={styles.title}>{summary.name}</Text>
        <Markdown components={components}>{summary.content}</Markdown>
        <Text style={styles.footer} render={({ pageNumber, totalPages }) => `${pageNumber}`} fixed />
      </Page>
    </Document>
  );
};

export default PDFFromSummary;

const styles = StyleSheet.create({
  document: {
    padding: "0.75in",
    fontFamily: "Times-Roman",
  },
  title: {
    fontSize: 12,
    color: "#D12424",
    paddingBottom: 12,
  },
  p: {
    fontSize: 10,
    paddingBottom: 12,
  },
  h1: {
    fontSize: 18,
    fontFamily: "Times-Bold",
    paddingBottom: 16,
  },
  h2: {
    fontSize: 16,
    fontFamily: "Times-Bold",
    paddingBottom: 14,
  },
  h3: {
    fontSize: 14,
    fontFamily: "Times-Bold",
    paddingBottom: 12,
  },
  h4: {
    fontSize: 12,
    fontFamily: "Times-Bold",
    paddingBottom: 12,
  },
  h5: {
    fontSize: 10,
    fontFamily: "Times-Bold",
    paddingBottom: 12,
  },
  h6: {
    fontSize: 10,
    fontFamily: "Times-Bold",
    paddingBottom: 12,
  },
  a: {
    color: "#D12424",
  },
  pre: {
    backgroundColor: "#262626",
    color: "white",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 6,
    marginVertical: 12,
  },
  code: {
    fontSize: 8,
    fontFamily: "Courier",
  },
  blockquote: {
    marginTop: 0,
    marginBottom: 12,
    paddingLeft: "0.25in",
    paddingTop: -12,
    borderLeft: "4px solid #E87878",
    fontFamily: "Times-Italic",
  },
  ul: {
    marginTop: -30,
    paddingVertical: 16,
    paddingLeft: "0.25in",
  },
  li: {
    fontSize: 10,
  },
  strong: {
    fontFamily: "Times-Bold",
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    top: "0.25in",
    left: 0,
    right: 0,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: "0.5in",
    textAlign: "right",
  },
});
