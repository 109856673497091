import { useContext, useRef, useState } from "react";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { ProjectFile } from "../../../../../backend/Projects/types";
import MaterialsViewer from "./MaterialsViewer";
import { ProjectsContext } from "../../ProjectsPage";

interface BottomSlideoverProps {
  selectedFile: ProjectFile;
  Expand: () => void;
  SelectFile: (id: string) => void;
  OpenAddFiles: () => void;
}

const BottomSlideover = ({ selectedFile, Expand, SelectFile, OpenAddFiles }: BottomSlideoverProps) => {
  const [shown, setShown] = useState(false);
  const [peek, setPeek] = useState(false);
  const peekOffsetRef = useRef<HTMLDivElement>(null);

  const projectsContext = useContext(ProjectsContext);

  function Peek(v: boolean) {
    if (shown) return setPeek(false);
    setPeek(v);
  }
  function Open(v: boolean) {
    setPeek(false);
    setShown(v);
  }

  return (
    <div
      onMouseEnter={() => Peek(true)}
      onMouseLeave={() => Peek(false)}
      style={{
        height: "calc(100% - 5rem)",
        bottom: `calc(${shown ? "0%" : "-100% + 7.5rem"})`,
        transform: `translateY(${peek ? `calc(-${peekOffsetRef.current?.offsetHeight}px - 0.25rem)` : "0px"})`,
      }}
      className={`w-full flex absolute bg-systemGray-100 shadow-2xl-c rounded-t-2xl border-t ease-out ${!shown ? `duration-300` : "duration-500"} z-[2]`}
    >
      <button
        onClick={() => Open(!shown)}
        className={`w-full outline-none absolute left-1/2 -translate-x-1/2 text-4xl ${shown || peek ? "text-systemGray-400 hover:text-systemGray-500 active:text-systemGray-400 -translate-y-1" : "text-systemGray-300"} duration-300`}
      >
        {CoursableIcons.ChevronTall(shown ? "down" : "up", "mx-auto")}
        {peek && <div className="text-mini -mt-5 opacity-75">Expand</div>}
      </button>
      {shown && !projectsContext.isMobile && (
        <button onClick={Expand} className="absolute top-4 right-4 text-xl rounded-lg hover:bg-systemGray-200 active:bg-systemGray-300 text-systemGray-500 hover:text-foreground active:text-systemGray-700 duration-100 p-1.5">
          {CoursableIcons.Expand("up-right")}
        </button>
      )}
      <MaterialsViewer
        selectedFile={selectedFile}
        shown={shown}
        peek={peek}
        filesGridRef={peekOffsetRef}
        SelectFile={(id) => {
          Open(true);
          SelectFile(id);
        }}
        OpenAddFiles={OpenAddFiles}
      />
    </div>
  );
};

export default BottomSlideover;
