import { BsQuestionCircleFill } from "react-icons/bs";

const Explainer = ({ side = "right", children, minWidth }: { side?: "left" | "right"; children: React.ReactNode; minWidth?: number }) => {
  return (
    <div className="relative">
      <BsQuestionCircleFill className="text-systemGray-300 hover:text-systemGray-500 duration-100 peer" />
      <div
        style={{
          minWidth: minWidth ?? 0,
        }}
        className={`${
          side === "left" ? "right-0" : "left-0"
        } absolute bottom-full opacity-0 peer-hover:opacity-100 text-sm bg-systemGray-100 text-systemGray-700 border rounded-lg px-2 py-1 z-10 mb-2 shadow-md duration-100 pointer-events-none`}
      >
        {children}
      </div>
    </div>
  );
};

export default Explainer;
