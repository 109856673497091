import { useEffect, useRef, useState } from "react";
import { CoursableProject, ProjectFile, ProjectQuiz } from "../../../../backend/Projects/types";
import { Unsubscribe } from "firebase/firestore";
import { DeleteQuiz, FetchQuizzes, GenerateQuiz, RenameQuiz, SaveQuiz } from "../../../../backend/Projects/ProjectQuizzes";
import { SubscribeToProjectChanges } from "../../../../backend/Projects/ProjectBase";

const useProjectQuizzes = (project: CoursableProject | undefined, setProject: React.Dispatch<React.SetStateAction<CoursableProject | undefined>>) => {
  const [quizzes, setQuizzes] = useState<ProjectQuiz[]>([]);

  const quizzesGenerationListener = useRef<Unsubscribe | undefined>(undefined);
  const initializingProjectID = useRef<string | undefined>(undefined);

  async function Initialize(project: CoursableProject) {
    setQuizzes([]);

    initializingProjectID.current = project.id;
    const quizzes = await FetchQuizzes(project.id);
    if (initializingProjectID.current !== project.id) return;

    setQuizzes(quizzes);

    if (project.isGeneratingQuiz) {
      quizzesGenerationListener.current?.();
      quizzesGenerationListener.current = SubscribeToProjectChanges(project.id, async (project) => {
        if (project.isGeneratingQuiz) return;
        quizzesGenerationListener.current?.();
        setProject(project);
        const quizzes = await FetchQuizzes(project.id);
        setQuizzes(quizzes);
      });
    }
  }

  async function Generate(files: ProjectFile[], prompt: string, quizID?: string) {
    if (!project) throw new Error("Project not set");

    SetIsGenerating(true);
    const quiz = await GenerateQuiz(project.id, files, prompt, quizID);
    const newArray = quizzes.some((s) => s.id === quiz.id) ? quizzes.map((s) => (s.id === quiz.id ? quiz : s)) : [quiz, ...quizzes];
    setQuizzes(newArray);
    SetIsGenerating(false);
    return quiz;
  }

  async function SaveName(quizID: string, name: string) {
    if (!project) return;

    await RenameQuiz(project.id, quizID, name);
    setQuizzes((prev) => prev.map((s) => (s.id === quizID ? { ...s, name } : s)));
  }

  async function SaveContent(quiz: ProjectQuiz) {
    if (!project) return;

    await SaveQuiz(project.id, quiz);
    setQuizzes((prev) => prev.map((q) => (q.id === quiz.id ? quiz : q)));
  }

  async function Delete(quizID: string) {
    if (!project) return;

    await DeleteQuiz(project.id, quizID);
    setQuizzes((prev) => prev.filter((q) => q.id !== quizID));
  }

  function SetPublicID(quizID: string, publicID: string | undefined) {
    setQuizzes((prev) => prev.map((s) => (s.id === quizID ? { ...s, publicID } : s)));
  }

  function SetIsGenerating(isGenerating: boolean) {
    setProject((prev) => prev && { ...prev, isGeneratingQuiz: isGenerating });
  }

  useEffect(() => {
    return () => {
      quizzesGenerationListener.current?.();
    };
  }, [project?.id]);

  return { quizzes, Initialize, Generate, SaveName, SaveContent, Delete, SetPublicID, SetIsGenerating };
};

export default useProjectQuizzes;
