export const InfoBlock = ({ side, title, description, Media, smallTitle }: { side: "left" | "right"; title: string; description: string; Media: React.ReactNode; smallTitle?: boolean }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-12 sm:gap-4 justify-between items-center w-full px-4 md:px-0">
      <div className={`${side === "right" ? "hidden sm:block" : "hidden"} w-full sm:w-1/2`}>{Media}</div>
      <div className={`w-full sm:w-1/2 flex flex-col gap-4 text-center items-center ${side === "left" ? "sm:text-left sm:items-start" : "sm:text-right sm:items-end"}`}>
        <h3 className={`text-foreground font-semibold ${smallTitle ? "text-xl md:text-2xl" : "text-2xl md:text-3xl"}`}>
          {title.split(" ")[0]}
          <span className="text-brand-500"> {title.split(" ")[1]}</span>
        </h3>
        <p className="text-systemGray-500 sm:max-w-sm text-sm md:text-base">{description}</p>
      </div>
      <div className={`${side === "left" ? "block" : "block sm:hidden"} w-full sm:w-1/2`}>{Media}</div>
    </div>
  );
};
