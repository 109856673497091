import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../utils/UtilityMethods";

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVarians> {}

const badgeVarians = cva("inline-flex items-center rounded-full border text-xs px-2.5 py-0.5 font-semibold transition-colors select-none", {
  variants: {
    variant: {
      default: "border-transparent bg-brand-500 text-white hover:bg-brand-500/80",
      inverse: "border-transparent bg-brand-50 dark:bg-brand-100 text-brand-500 hover:bg-brand-50/80 dark:hover:bg-brand-100/80",
      secondary: "border-transparent bg-systemGray-300 text-foreground hover:bg-systemGray-300/80",
      green: "border-transparent bg-brandGreen-50 dark:bg-brandGreen-100 text-brandGreen-500 hover:bg-brandGreen-50/80 dark:hover:bg-brandGreen-100/80",
      blue: "border-transparent bg-brandBlue-50 dark:bg-brandBlue-100 text-brandBlue-500 hover:bg-brandBlue-50/80 dark:hover:bg-brandBlue-100/80",
      purple: "border-transparent bg-violet-100 dark:bg-violet-950 text-violet-500 hover:bg-violet-100/80 dark:hover:bg-violet-950/80",
      yellow: "border-transparent bg-amber-100 dark:bg-amber-950 text-amber-500 hover:bg-amber-100/80 dark:hover:bg-amber-950/80",
      outline: "text-foreground",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Badge = ({ children, variant, className, ...props }: BadgeProps) => {
  return (
    <div {...props} className={cn(badgeVarians({ className, variant }))}>
      {children}
    </div>
  );
};

export default Badge;
