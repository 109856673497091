import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LoadingIndicator from "../../elements/LoadingIndicator";
import { TypeAnimation } from "react-type-animation";
import { useEffect, useState, useRef } from "react";
import PersonalizeCourseStructureView from "./PersonalizeCourseStructureView";
import CourseGenerator from "../Home/Courses/CourseGenerator";
import { useAuth } from "../../../firebase/AuthContext";
import { UserProfilePanel } from "../../page-elements/Header";
import { GAEvent, GALogEvent } from "../../../firebase/GoogleAnalytics";
import { Helmet } from "react-helmet-async";
import { GenerateCourse, GenerateCourseStructure } from "../../../backend/CourseGeneration";
import { ServerResponse } from "../../../backend/Shared";
import CoursableLogo from "../../elements/CoursableLogo";
import Button from "../../elements/Button";

const CourseGenerationPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const { state } = useLocation();
  const prompt = state?.prompt;
  const isGenerating = useRef<boolean>(false);

  const [isFinalizingCourse, setIsFinalizingCourse] = useState<boolean>(false);
  const [finalCourseTitle, setFinalCourseTitle] = useState<string | null>(null);

  const [courseStructure, setCourseStructure] = useState<CourseStructure | null>(null);
  const [error, setError] = useState<string | null>(null);

  async function StartGeneratingCourseStructure() {
    if (!prompt || isGenerating.current) return;

    isGenerating.current = true; // Need this because in dev React.StrictMode renders the component twice.
    try {
      GALogEvent(GAEvent.Course.Structure.generate, { prompt });
      const courseStructure = await GenerateCourseStructure(prompt);
      setCourseStructure(courseStructure);
    } catch (error) {
      const errorType = error as ServerResponse;
      setError(errorType.type ?? error);
      setCourseStructure(null);
    }
    isGenerating.current = false;
  }

  function RetryButton() {
    setError(null);
    if (isFinalizingCourse) {
      ConfirmCourse();
    } else {
      StartGeneratingCourseStructure();
    }
  }

  function GoHome() {
    navigate("/app/courses");
  }

  async function ConfirmCourse() {
    if (!currentUser || !courseStructure) return;

    setIsFinalizingCourse(true);
    setFinalCourseTitle(courseStructure.title);
    try {
      GALogEvent(GAEvent.Course.Structure.confirm, {
        prompt: prompt,
        courseTitle: courseStructure.title,
      });
      await GenerateCourse(courseStructure);
      GALogEvent(GAEvent.Course.generated);
      navigate(`/app/courses`, { state: { preselectedCourseID: courseStructure.id } });
    } catch (error: any) {
      console.log(error);
      setError(error);
    }
  }

  useEffect(() => {
    if (!prompt) return;

    StartGeneratingCourseStructure();
  }, [prompt]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (!prompt) {
    return (
      <div className="flex flex-col items-center h-screen w-screen justify-center bg-systemGray-100 relative">
        <Helmet>
          <title>Course Generation | Coursable - Student workspace powered by AI</title>
          <link rel="canonical" href="/course-generation" />
        </Helmet>
        <div className="flex items-center justify-between max-w-7xl w-full h-16 px-4 top-0 absolute">
          <CoursableLogo onClick={() => navigate("/")} wide size={8} />
          <UserProfilePanel />
        </div>
        <div className="flex flex-col items-center gap-10 max-w-2xl w-full">
          <CoursableLogo size={14} />
          <div className="text-2xl md:text-3xl font-semibold px-4 text-center">Hi {currentUser?.displayName?.split(" ")[0]}, lets get started 😉</div>
          <div className="text-base md:text-lg text-systemGray-500 text-center max-w-lg px-4">Type what you’d like to learn and get an original study plan personalized for you.</div>
          <CourseGenerator />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center h-screen w-screen justify-center bg-systemGray-100 relative">
      {courseStructure && !isFinalizingCourse ? (
        <PersonalizeCourseStructureView courseStructure={courseStructure} setCourseStructure={setCourseStructure} ConfirmCourse={ConfirmCourse} />
      ) : error ? (
        error === "busy_generating_for_this_user" ? (
          <AlreadyGeneratingView />
        ) : (
          <ErrorView prompt={prompt} finalCourseTitle={finalCourseTitle} GoHome={GoHome} Retry={RetryButton} />
        )
      ) : (
        <CourseStructureGenerationLoading prompt={prompt} isFinalizingCourse={isFinalizingCourse} finalCourseTitle={finalCourseTitle} />
      )}
    </div>
  );
};

export default CourseGenerationPage;

const CourseStructureGenerationLoading = ({ prompt, isFinalizingCourse, finalCourseTitle }: { prompt: string; isFinalizingCourse: boolean; finalCourseTitle: string | null }) => {
  const courseStructureSequence = ["Generating structure", 3000, "Generating topics", 3000, "Generating material", 3000];
  const finalCourseSequence = ["Finalizing course", 3000, "Researching materials", 3000, "Analyzing materials", 3000];

  return (
    <div className="flex flex-col items-center gap-4 px-4">
      <LoadingIndicator className="w-8 h-8 stroke-systemGray-400" />
      <TypeAnimation className="text-systemGray-500 text-xl font-semibold" sequence={isFinalizingCourse ? finalCourseSequence : courseStructureSequence} repeat={Infinity} />
      <div className="text-2xl font-semibold text-center">
        {finalCourseTitle ?? (
          <>
            Teach me <span className="text-brand-500">{prompt}</span>
          </>
        )}
      </div>
      <div className="text-systemGray-500 text-sm text-center">Don't close this tab, this should take less than a minute.</div>
    </div>
  );
};

const ErrorView = ({ prompt, finalCourseTitle, GoHome, Retry }: { prompt: string; finalCourseTitle: string | null; GoHome: () => void; Retry: () => void }) => {
  return (
    <div className="flex flex-col items-center gap-4 px-4">
      <div className="text-2xl font-semibold">
        {finalCourseTitle ? (
          <>{finalCourseTitle}</>
        ) : (
          <>
            Teach me <span className="text-brand-500">{prompt}</span>
          </>
        )}
      </div>
      <div className="text-systemGray-500 text-center">Oops, something went wrong, please try again later 😢</div>
      <div className="flex gap-4">
        <Button variant="secondary" onClick={GoHome}>
          Home
        </Button>
        <Button onClick={Retry}>Retry</Button>
      </div>
    </div>
  );
};

const AlreadyGeneratingView = () => {
  return (
    <div className="flex flex-col items-center gap-4 text-center px-4">
      <div className="text-4xl">👀</div>
      <div className="text-xl font-semibold">Opps, looks like something is already being generated for you.</div>
      <div className="text-systemGray-500">Check-in back later, this process usually takes less than a minutes.</div>
      <Button to="/courses">Dashboard</Button>
    </div>
  );
};
