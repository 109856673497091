export async function ExtractServerResponse(res: Response): Promise<ServerResponse> {
  const response = await ServerResponse.fromResponse(res);

  if (res.ok) {
    return response;
  } else {
    throw response;
  }
}

export class ServerResponse {
  code: number;
  type: string;
  message: string;
  data?: any;

  constructor(code: number, type: string, message: string, data?: any) {
    this.code = code;
    this.type = type;
    this.message = message;
    this.data = data;
  }

  static async fromResponse(response: Response) {
    const responseJSON: any = await response.json();
    if (responseJSON.code === undefined || responseJSON.type === undefined || responseJSON.message === undefined) {
      throw new Error("Got invalid response from the server.");
    }

    return new ServerResponse(responseJSON.code, responseJSON.type, responseJSON.message, responseJSON.data);
  }
}
